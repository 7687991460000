import * as colors from '@material-ui/core/colors';
import _ from '@lodash';

class EventEmitter {
  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  on(eventName, fn) {
    this._getEventListByName(eventName).add(fn); //eslint-disable-line
  }

  once(eventName, fn) {
    const self = this;

    const onceFn = function (...args) { //eslint-disable-line
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName, ...args) {
    this._getEventListByName(eventName).forEach((fn) => { //eslint-disable-line
      fn.apply(this, args);
    });
  }

  removeListener(eventName, fn) {
    this._getEventListByName(eventName).delete(fn); //eslint-disable-line
  }
}

class CentraverseUtils {
  static setRoutes(config) {
    let routes = [...config.routes];

    if (config.settings || config.auth) {
      routes = routes.map((route) => {
        let auth = config.auth ? config.auth : null;
        auth = route.auth ? route.auth : auth;
        return {
          ...(config.routeConfig ? config.routeConfig : {}),
          ...route,
          settings: {...config.settings, ...route.settings},
          auth,
        };
      });
    }

    return [...routes];
  }
  static generateRoutesFromConfigs(configs) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [
        ...allRoutes,
        ...this.setRoutes(config),
      ];
    });
    return allRoutes;
  }

  static hasPermission(auth) {
    return auth ? !!localStorage.getItem('user_id') : true;
  }
}

export default CentraverseUtils;
