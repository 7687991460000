import React from 'react';

const Step = React.forwardRef(({
  active,
  children,
}, ref) => (
  <>
    {children}
  </>
));

export default Step;
