import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTheme } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import InlineSvg from '../Utils/InlineSvg';
import useStyles from './MenuItemStyles';

import hasChildren from './sidebarUtils';

/* eslint-disable */
const SideMenuItems = ({
  items,
  drawerOpen,
  onClickSelectedmenu,
  setOnClickSelectedMenu,
  handleDrawerOpen,
  setCurrentSidebar,
  currentSidebar,
  addBorder,
}) => {
  return (
    items &&
    items.map((item, key) => (
      <MenuItem
        key={`${item} ${key}`}
        item={item}
        drawerOpen={drawerOpen}
        onClickSelectedmenu={onClickSelectedmenu}
        setOnClickSelectedMenu={setOnClickSelectedMenu}
        handleDrawerOpen={handleDrawerOpen}
        setCurrentSidebar={setCurrentSidebar}
        currentSidebar={currentSidebar}
        addBorder={addBorder}
      />
    ))
  );
}
export default withRouter(SideMenuItems);

const SingleLevel = ({ item, drawerOpen, currentSidebar, setCurrentSidebar, onClickSelectedmenu, setOnClickSelectedMenu }) => {
  let url = '';
  if(item.powerbi_report){
    url = item.route ? item.route : `/reporting/pbi/${(item && item.powerbi_report && item.powerbi_report.PBI_report_id)}`;
  }else{
    url = item.route ? item.route : `/e/${(item && item.embedsAndLinks && item.embedsAndLinks && item.embedsAndLinks.slug)}`;
  }
  const classes = useStyles();
  return (
    <React.Fragment key={item.name}>
      {url ? (
        item.is_external_link ?
        (
        <a
        href={url}
        target="_blank"
        rel="noopener noreferrer">
          <List className={currentSidebar === item.name ? classes.selectedBackgroud : classes.defaultBackround}>
            <ListItem button className={drawerOpen ? classes.item0 : classes.item16} onClick={() => setCurrentSidebar(item.name)}>
              <ListItemIcon className={classes.iconRoot}>
                {item && item.icon && <InlineSvg url={item.icon} selected={currentSidebar} location={item.name} />}
              </ListItemIcon>
              {drawerOpen && <ListItemText
                disableTypography
                primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: currentSidebar === item.name ? '1' : '0.5' }}>{item.name}</Typography>}
              />}
            </ListItem>
          </List>
        </a>
        ) : (
        <Link to={url}>
          <List className={currentSidebar === item.name ? classes.selectedBackgroud : classes.defaultBackround}>
            <ListItem button className={drawerOpen ? classes.item0 : classes.item16} onClick={() => setCurrentSidebar(item.name)}>
              <ListItemIcon className={classes.iconRoot}>
                {item && item.icon && <InlineSvg url={item.icon} selected={currentSidebar} location={item.name} />}
              </ListItemIcon>
              {drawerOpen && <ListItemText
                disableTypography
                primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: currentSidebar === item.name ? '1' : '0.5' }}>{item.name}</Typography>}
              />}
            </ListItem>
          </List>
        </Link>)
      ) : (
        <List className={currentSidebar === item.name ? classes.selectedBackgroud : classes.defaultBackround}>
          <ListItem button className={drawerOpen ? classes.item0 : classes.item16} onClick={() => setCurrentSidebar(item.name)}>
            <ListItemIcon className={classes.iconRoot}>
              {item && item.icon && <InlineSvg url={item.icon} selected={currentSidebar} location={item.name} />}
            </ListItemIcon>
            {drawerOpen && <ListItemText
              disableTypography
              primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: currentSidebar === item.name ? '1' : '0.5' }}>{item.name}</Typography>}
            />}
          </ListItem>
        </List>
      )}
    </React.Fragment>
  );
};

const MultiLevel = ({
  item,
  drawerOpen,
  onClickSelectedmenu,
  setOnClickSelectedMenu,
  handleDrawerOpen,
  setCurrentSidebar,
  currentSidebar,
  addBorder,
}) => {
  const { children } = item;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const handleClick = (name) => {
    setOpen((prev) => !prev);
    handleDrawerOpen();
    setCurrentSidebar(name);
  };
  const classes = useStyles()
  children.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  })
  return (
    <List
      key={item.name}
      className={currentSidebar === item.name ? classes.selectedBackgroud : classes.defaultBackround}
      {...(addBorder && { style: { borderBottom: '1px solid rgba(0,0,0,0.14)' } })}
    >
      <ListItem button onClick={() => handleClick(item.name)} className={drawerOpen ? classes.item0 : classes.item16}>
        <ListItemIcon className={classes.iconRoot}>
          {item && item.icon ? <InlineSvg url={item.icon} selected={currentSidebar} location={item.name} /> : null}
        </ListItemIcon>
        {drawerOpen && <ListItemText
          disableTypography
          primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: currentSidebar === item.name ? '1' : '0.5' }}>{item.name}</Typography>}
        />}
        {open ? (drawerOpen && <ExpandLessIcon className={classes.expandIcon} />) : (drawerOpen && <ExpandMoreIcon className={classes.expandIcon} />)}
      </ListItem>
      {drawerOpen && <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
        // style={{ paddingLeft: open ? theme.spacing(0.5) : '0px' }}
        >
          {children.map((child, key) => (
            <MenuItem
              key={`${key} ${key}`}
              item={child}
              drawerOpen={drawerOpen}
              onClickSelectedmenu={onClickSelectedmenu}
              setOnClickSelectedMenu={setOnClickSelectedMenu}
              handleDrawerOpen={handleDrawerOpen}
              setCurrentSidebar={setCurrentSidebar}
              currentSidebar={currentSidebar}
            />
          ))}
        </List>
      </Collapse>}
    </List>
  );
};

const MenuItem = ({ item,
  drawerOpen,
  onClickSelectedmenu,
  setOnClickSelectedMenu,
  handleDrawerOpen,
  setCurrentSidebar,
  currentSidebar,
  addBorder,
}) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component
    item={item}
    drawerOpen={drawerOpen}
    onClickSelectedmenu={onClickSelectedmenu}
    setOnClickSelectedMenu={setOnClickSelectedMenu}
    handleDrawerOpen={handleDrawerOpen}
    setCurrentSidebar={setCurrentSidebar}
    currentSidebar={currentSidebar}
    addBorder={addBorder}
  />;
};
