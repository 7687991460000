import React, { useEffect, useState } from 'react';
/* eslint-disable */

const SvgInline = props => {
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const { location, selected } = props;
  const selectedStyle = {
    fillOpacity: "0.8", fill: 'white',
  }
  const defaultStyle = {
    fillOpacity: "0.4", fill: 'white',
  }
  useEffect(() => {
    const ac = new AbortController();

    props.url && fetch(props.url)
      .then(res => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true))
    return () => ac.abort(); // Abort both fetches on unmount
  }, [props.url]);

  return (
    <div
      style={location === selected ? selectedStyle : defaultStyle}
      className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''} `}
      dangerouslySetInnerHTML={{ __html: svg }
      }

    />
  );
}
export default SvgInline;
