import * as types from '../../constants/actionTypes';

export const sendNotificationAction = ({success, message, title = ''}) => ({
  type: types.SET_NOTIFICATION,
  payload: {
    success,
    message,
    title,
  },
});

export const clearNotificationAction = () => ({
  type: types.CLEAR_NOTIFICATION,
});
