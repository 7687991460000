import React, {useState} from 'react';
import {Typography, LinearProgress} from '@material-ui/core';
import {useTimeout} from 'centraverse/hooks'; //eslint-disable-line
import PropTypes from 'prop-types';

function CentraverseLoading(props) {
  const [showLoading, setShowLoading] = useState(!props.delay);

  useTimeout(() => {
    setShowLoading(true);
  }, props.delay);

  if (!showLoading) {
    return null;
  }

  return (
    // <div className="flex flex-1 flex-col items-center justify-center">
    //   <Typography className="text-20 mb-16" color="textSecondary">Loading...</Typography>
    //   <LinearProgress className="w-xs" color="secondary" />
    // </div>
    <div className="load">
      <div className="load__icon-wrap">
        <svg className="load__icon">
          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
  );
}

CentraverseLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

CentraverseLoading.defaultProps = {
  delay: false,
};

export default CentraverseLoading;
