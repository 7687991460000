/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter, Link, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import ReactTable from 'shared/components/ReactTable/ReactTable';
import astroNoRecord from 'assets/images/astroNoRecord.svg';
import astroLoading from 'assets/images/astroLoading.svg';
import NoWidgetsFound from 'shared/components/Mui/NoResultsFound';
import AccessDenied from 'common/AccessDenied/AccessDenied';
import useStyles from '../../HR/NewHrUi/Utils/TimeSheetStyles';
import { fetchHRUserAutoClockedOutDetailsAction } from '../../../redux/actions/hrUserAutoClockedOutDetailsAction';


export default function AutoClockoutCardsPopup({
  setOpenDiagBox,
}) {
  const user = JSON.parse(localStorage.getItem('user'));
  const storeid = JSON.parse(localStorage.getItem('store_id'));
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const userId = user && user.id;
  const [open, setOpen] = React.useState(true);
  const [diag, setDiag] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalShifts, setTotalShifts] = React.useState(0);
  const userAutoClockOutDetails = useSelector(state => (state && state.hrUserAutoClockedDetails && state.hrUserAutoClockedDetails.hruserautoclokoutdetails) || []);
  const isSuccess = (useSelector(state => (state && state.hrUserAutoClockedDetails && state.hrUserAutoClockedDetails.is_success) || true));
  const isLoading = (useSelector(state => (state && state.hrUserAutoClockedDetails && state.hrUserAutoClockedDetails.loading) || false));

  const time = useSelector(state => (state.webclocks && state.webclocks.time) || []);
  const perPage = 20;

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const fetchData = () => {
    dispatch(fetchHRUserAutoClockedOutDetailsAction({
      user_id: userId,
      not_corrected: true,
      page_size: 5,
      page_number: 1,
      store_id: storeid,
      edit_request_sent: false,
    }));
  };

  useEffect(() => {
    fetchData();
  }, [userId, storeid, currentPage]);

  const handleClickClose = (e, val) => {
    e.preventDefault();
    setOpen(false);
    localStorage.setItem('showAutoClockoutTimeCards', false);
    setOpenDiagBox(val);
    if (val === false) localStorage.setItem('clock_in', false);
  };

  const totalCount = userAutoClockOutDetails.length > 0 ? userAutoClockOutDetails[0].total_count : 0;

  useEffect(() => {
    setTotalShifts(totalCount);
  }, [userAutoClockOutDetails && userAutoClockOutDetails.length]);

  const COLUMNS = [
    {
      Header: 'DATE',
      id: 'date',
      accessor: row => (row && row.date && row.date ? moment.tz(row.date, time.timezone).format('MMM DD, YYYY') : '-'),
      disableSortBy: true,
      Cell: row => (
        <div className={classes.darkTypo} style={{ textAlign: 'center' }}>
          {row.row.index > 0 &&
            row.rows[row.row.index - 1].values[row.column.id] === row.value ?
            ''
            : row && row.value && row.value
          }
        </div>
      ),
    },
    {
      Header: 'STORE NAME',
      accessor: 'store_name',
      disableSortBy: true,
      Cell: row => (
        <div className={classes.darkTypo} style={{ textAlign: 'center' }}>
          {row.row.index > 0 &&
            row.rows[row.row.index - 1].values.date === row.row.values.date &&
            row.rows[row.row.index - 1].values[row.column.id] === row.value ?
            ''
            : row && row.value && row.value
          }
        </div>
      ),
    },
    {
      Header: 'SCHEDULED TIME',
      accessor: 'schedule_start_time',
      disableSortBy: true,
      Cell: row => (
        <div className={classes.darkTypo} style={{ textAlign: 'center' }}>
          {
            row &&
              row.value &&
              row.row &&
              row.row.original &&
              row.row.original.schedule_end_time &&
              row.row.original.schedule_id ? (
              <>
                {moment.tz(row.value, time.timezone).format('h:mm A')} - {moment.tz(row.row.original.schedule_end_time, time.timezone).format('h:mm A')}
              </>
            ) : (
              'Unscheduled'
            )}
        </div>
      ),
    },
    {
      Header: 'CLOCK IN',
      accessor: 'clock_in',
      disableSortBy: true,
      Cell: row => (
        <div className={classes.darkTypo} style={{ textAlign: 'center' }}>
          {
            row &&
              row.value &&
              row.row &&
              row.row.original &&
              row.row.original.timecard_id ? (
              <>
                {moment.tz(row && row.value && row.value, time.timezone).format('h:mm A')}
              </>
            ) : (
              '-'
            )}
        </div>
      ),
    },
    {
      Header: 'CLOCK OUT',
      accessor: 'clock_out',
      disableSortBy: true,
      Cell: row => (
        <div className={classes.darkTypo} style={{ textAlign: 'center' }}>
          {
            row &&
              row.value &&
              row.row &&
              row.row.original &&
              row.row.original.timecard_id ? (
              <>
                {moment.tz(row && row.value && row.value, time.timezone).format('h:mm A')}
              </>
            ) : (
              '-'
            )}
        </div>
      ),
    },
  ];

  const getRowProps = row => ({ className: row.values.date === moment.tz(undefined, time.timezone).format('MMM DD, YYYY') ? classes.activeRow : '' });

  const changeRoute = (e) => {
    handleClickClose(e, false);
    history.push('/hr/dashboard/my_view');
  };

  return (
    <div>
      {diag === true ?
        (
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleClickClose}
            aria-labelledby="title"
            PaperProps={{
              style: {
                  width: '1000px',
                },
            }}
          >
            <DialogTitle
              id="title"
            >
              <Grid container direction="row" display="flex" alignItems="center" justifyContent="space-between">
                <Grid item xs={8} alignItems="center" justifyContent="center" display="flex">
                  <span
                    style={{
                      color: '#0551A3',
                    }}
                  >
                  Auto Clocked Out Schedules
                  </span>
                </Grid>
                <Grid item xs={4} display="flex" alignItems="center" justifyContent="flex-end" >
                  <span style={{marginLeft: '140px'}} >
                    <Button
                      onClick={e => handleClickClose(e, true)}
                    >
                      <CloseIcon color="primary" />
                    </Button>
                  </span>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <div style={{ position: 'relative', height: '100%' }}>
                {permissions && permissions.is_clockin_pop_up_required ?
                  (isLoading) ?
                    <NoWidgetsFound styles={{ imageHeight: 230, imageWidth: 125, textSize: 24 }} image={astroLoading} message="Loading..." />
                    :
                    userAutoClockOutDetails && userAutoClockOutDetails.length > 0
                      ?
                        <ReactTable
                          inputColumns={COLUMNS}
                          inputData={userAutoClockOutDetails}
                          getRowProps={getRowProps}
                        />
                      :
                      (isSuccess)// eslint-disable-line
                        ?
                          <NoWidgetsFound styles={{ imageHeight: 230, imageWidth: 125, textSize: 24 }} image={astroNoRecord} message="No Record Found" />
                        :
                          <NoWidgetsFound styles={{ imageHeight: 230, imageWidth: 125, textSize: 24 }} image={astroLoading} message="Server Error" />

                  : <AccessDenied />}
              </div>
            </DialogContent>
            <DialogActions>
              <Grid item xs={12} className="text-center">
                <Typography style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                >
                  To request for the auto clockout correction, please navigate to
                  <span
                    onClick={changeRoute}
                    style={{
                      cursor: 'pointer',
                      color: '#0551A3',
                    }}
                  > My View
                  </span>
                </Typography>
              </Grid>
            </DialogActions>
          </Dialog>
        ) : ('')}
    </div>
  );
}
