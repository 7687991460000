import { filter } from 'lodash';
import * as types from 'constants/actionTypes';

/* eslint-disable */

const initialState = {
  inventory_lead: {},
  inventory_leads: [],
  total: 0,
  loading: false,
  error: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_INVENTORY_LEADS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          inventory_leads: [],
          total: 0,
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        inventory_leads: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_INVENTORY_LEADS_REQUEST:
      return {
        ...state,
        inventory_leads: [],
        total: 0,
        loading: true,
      };
    case types.FETCH_INVENTORY_LEADS_FAILED:
      return {
        ...state,
        inventory_leads: [],
        total: 0,
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_INVENTORY_LEAD_REQUEST:
      return {
        ...state,
        inventory_lead: {},
        loading: true,
      };
    case types.FETCH_INVENTORY_LEAD_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          inventory_lead: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        inventory_lead: action.res.data || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_INVENTORY_LEAD_FAILED:
      return {
        ...state,
        inventory_lead: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_INVENTORY_LEAD_SUCCESS:
      return {
        ...state,
        // inventory_leads: [...state.inventory_leads, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_INVENTORY_LEAD_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_INVENTORY_LEAD_SUCCESS:
      // let inventory_lead = filter(state.inventory_lead, item => item.id !== action.res.data.id);
      return {
        ...state,
        inventory_lead: { ...state.inventory_lead, ...action.res.data },
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_INVENTORY_LEAD_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_INVENTORY_LEAD_STATE:
      const inventoryLeads = filter(state.inventory_leads, item => item.id !== action.payload.id);
      return {
        ...state,
        inventory_leads: [...inventoryLeads, action.payload],
        inventory_lead: action.payload,
        status: 'success',
        loading: false,
      };
    case types.DELETE_INVENTORY_LEAD_SUCCESS:
      const inventoryLeadsData = filter(state.inventory_leads, item => item.id !== action.payload.id);
      return {
        ...state,
        inventory_leads: [...inventoryLeadsData],
        status: true,
        loading: false,
      };
    case types.DELETE_INVENTORY_LEAD_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_INVENTORY_LEAD_SOURCES_BY_COMPANY_ID_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          inventory_lead_sources: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        inventory_lead_sources: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_INVENTORY_LEAD_SOURCES_BY_COMPANY_ID_REQUEST:
      return {
        ...state,
        inventory_lead_sources: [],
        loading: true,
      };
    case types.FETCH_INVENTORY_LEAD_SOURCES_BY_COMPANY_ID_FAILED:
      return {
        ...state,
        inventory_lead_sources: [],
        error: 'Bad Request',
        loading: false,
      };
      case types.ADD_INVENTORY_LEAD_FROM_CONTACT_US_SUCCESS:
        return {
          ...state,
          status: action.res.success,
          loading: false,
        };
      case types.ADD_INVENTORY_LEAD_FROM_CONTACT_US_FAILED:
        return {
          ...state,
          error: 'Bad Request',
          loading: false,
        };

    // case types.FETCH_OPEN_ORDER_COUNTS_SUCCESS:
    //   return {
    //     ...state,
    //     status: action.res.success,
    //     loading: false,
    //     theCount: action.res.data.count,
    //   };

    // case types.FETCH_TODAY_OPEN_ORDER_COUNTS_SUCCESS:
    //   return {
    //     ...state,
    //     status: action.res.success,
    //     loading: false,
    //     theCountToday: action.res.data.count,
    //   };

    case types.FETCH_INVENTORY_LEADS_MORE_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        inventory_leads: [...state.inventory_leads, ...((action.res.data && action.res.data.results) || [])],
        total: ((action.res.data && action.res.data.total) || 0),
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_INVENTORY_LEADS_MORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_INVENTORY_LEADS_MORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    default:
      return state;
  }
}
