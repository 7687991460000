import { filter, map } from 'lodash';
import * as types from '../../constants/actionTypes';

const initialState = {
  items: [],
};

/* eslint-disable no-case-declarations */

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ITEMS_CART:
      let items = action.payload.items; // eslint-disable-line
      localStorage.setItem('cart_items', JSON.stringify(items));
      return {
        ...state,
        items,
      };

    case types.ADD_ITEM_TO_CART:
      // items = [...state.items, action.payload]; // eslint-disable-line
      let isPresent = false;
      items = state.items.map((item) => {
        if (item.id === action.payload.id) {
          const newItem = item;
          newItem.quantity = item.quantity + action.payload.quantity;
          newItem.total = newItem.quantity * newItem.price;
          isPresent = true;
          return newItem;
        }
        return item;
      });
      if (!isPresent) {
        items = [...items, action.payload];
      }
      localStorage.setItem('cart_items', JSON.stringify(items));
      return {
        ...state,
        items,
      };

    case types.DELETE_ITEM_FROM_CART:
      items = filter(state.items, (item, index) => index !== action.index);
      localStorage.setItem('cart_items', JSON.stringify(items));
      return {
        ...state,
        items,
      };

    case types.CHANGE_QTY_FROM_CART:
      items = map(state.items, (item, index) => {
        if (index === action.payload.index) {
          return { ...item, quantity: action.payload.qty, total: action.payload.qty * (item.price + item.tax) };
        }

        return item;
      });
      localStorage.setItem('cart_items', JSON.stringify(items));
      return {
        ...state,
        items,
      };

    case types.CLEAR_CART:
      localStorage.setItem('cart_items', JSON.stringify([]));
      return {
        ...state,
        items: [],
      };

    default:
      return state;
  }
};
