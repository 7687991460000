import React, { useContext } from 'react';
import {Redirect} from 'react-router-dom';

export const OneViewAppConfig = { //eslint-disable-line
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'oneViewApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    { // Private routes
      path: '/oneview',
      component: React.lazy(() => import('./index')),
    },
    { // Private routes
      path: '/oneview-admin-panel',
      component: React.lazy(() => import('./AdminPanel/OneViewWidgets')),
    },
    { // Private routes
      path: '/oneview-admin-panel/company-widgets',
      component: React.lazy(() => import('./AdminPanel/CompaniesWidgets')),
    },
  ],
};
