import * as types from 'containers/WarehouseInventory/store/actionTypes';

export const addAccessoryItemToCart = payload => ({
  type: types.ADD_ACCESSORY_ITEM_TO_CART,
  payload,
});

export const deleteAccessoryItemFromCart = index => ({
  type: types.DELETE_ACCESSORY_ITEM_FROM_CART,
  index,
});

export const changeQtyAccessoryItemCart = (index, qty) => ({
  type: types.CHANGE_ACCESSORY_QTY_FROM_CART,
  payload: {
    index,
    qty,
  },
});

export const setAccessoryItemsCart = items => ({
  type: types.SET_ACCESSORY_ITEMS_CART,
  payload: {
    items,
  },
});

export const clearAccessoryCart = items => ({
  type: types.CLEAR_ACCESSORY_CART,
  payload: {
    items,
  },
});
