import { put, call, takeLatest, all } from 'redux-saga/effects';
import { fetchCompanyId} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_COMPANY_ID_REQUEST, fetchCompanyIdSaga);
}

export function* fetchCompanyIdSaga({ payload }) {
  try {
    const res = yield call(fetchCompanyId, payload);
    yield all([
      put({ type: types.FETCH_COMPANY_ID_SUCCESS, res }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.FETCH_COMPANY_ID_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
