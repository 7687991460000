import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
}));

const RedButton = ({children, classes, ...props}) => {
  const $classes = useStyles();

  return (
    <Button {...props} classes={{ root: $classes.redButton, ...classes }}>
      {children}
    </Button>
  );
};

export default RedButton;
