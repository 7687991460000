import { getTreeFromFlatData } from 'react-sortable-tree';

// get tree data from flat data

export const treeData = flatData =>
  getTreeFromFlatData({
    flatData,
    getKey: node => node.id, // resolve a node's key
    getParentKey: node => node.parent_id, // resolve a node's parent_id's key
    rootKey: null, // The value of the parent_id key when there is no parent_id (i.e., at root level)
  });

export const treeDatas = flatData =>
  getTreeFromFlatData({
    flatData,
    getKey: node => node.id, // resolve a node's key
    getParentKey: node => node.parent_id, // resolve a node's parent_id's key
    rootKey: null, // The value of the parent_id key when there is no parent_id (i.e., at root level)
  });
