import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  getTax, addTax, updateTax, deleteTax,
} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  console.log('entered function');
  yield takeLatest(types.ADD_TAX_REQUEST, addTaxSaga, context);
  yield takeLatest(types.FETCH_TAX_REQUEST, fetchTaxSaga);
  yield takeLatest(types.UPDATE_TAX_REQUEST, updateTaxSaga, context);
  yield takeLatest(types.DELETE_TAX_REQUEST, deleteTaxSaga);
}

export function* fetchTaxSaga({ payload }) {
  console.log('entered saga');
  try {
    const res = yield call(getTax, payload);
    console.log('res', res);
    yield all([
      put({ type: types.FETCH_TAX_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_TAX_FAILED, error });
  }
}

export function* addTaxSaga({ history }, { payload }) {
  try {
    const res = yield call(addTax, payload);
    yield all([
      put({ type: types.ADD_TAX_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Tax added' : res.message || 'Tax not added',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.ADD_TAX_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateTaxSaga({ history }, { payload }) {
  try {
    const res = yield call(updateTax, payload);
    yield all([
      put({ type: types.UPDATE_TAX_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Tax updated' : res.message || 'Tax not updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_TAX_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteTaxSaga({ payload }) {
  try {
    const res = yield call(deleteTax, payload);
    yield all([
      put({ type: types.DELETE_TAX_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Tax deleted' : res.message || 'Tax not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_TAX_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

