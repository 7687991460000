import React from 'react';

export const WarehouseInventoryAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'warehouseInventoryApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [

    // Cart

    {
      path: '/cart',
      component: React.lazy(() => import('./Cart/Cart')),
    },
    {
      path: '/cart/accessories',
      component: React.lazy(() => import('./Cart/AccessoryCart')),
    },
    {
      path: '/shop/products',
      component: React.lazy(() => import('./Cart/Shop')),
    },
    {
      path: '/shop/accessories',
      component: React.lazy(() => import('./Cart/ShopAccessories')),
    },

    // Warehouse
    {
      path: '/unfulfilled-orders',
      component: React.lazy(() => import('./Warehouse/UnfilfilledOrders')),
    },
    {
      path: '/unfulfilled-orders/accessory',
      component: React.lazy(() => import('./Warehouse/UnfilfilledAccessoryOrders')),
    },
    {
      path: '/fulfill-order/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/FulfillOrder')),
    },
    {
      path: '/receive-order/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/ReceiveOrder')),
    },
    {
      path: '/receive-order/accessory/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/ReceiveAccessoryOrder')),
    },
    {
      path: '/price-adjustment',
      component: React.lazy(() => import('./Warehouse/PriceAdjustment')),
    },
    {
      path: '/adjustment-reasons',
      component: React.lazy(() => import('./Warehouse/AdjustmentReason')),
    },
    {
      path: '/adjustment-reasons/new',
      component: React.lazy(() => import('./Warehouse/NewAdjustmentReason')),
    },
    {
      path: '/adjustment-reasons/edit/:id',
      component: React.lazy(() => import('./Warehouse/NewAdjustmentReason')),
    },
    {
      path: '/reports/accessory-inventoryt',
      component: React.lazy(() => import('./Warehouse/Reports/AccessoryInventoryReport')),
    },
    {
      path: '/reports/accessory-inventory',
      component: React.lazy(() => import('./Warehouse/Reports/InventoryAging')),
    },
    {
      path: '/reports/inventory',
      component: React.lazy(() => import('./Warehouse/Reports/InventoryReports')),
    },
    {
      path: '/reports/sale',
      component: React.lazy(() => import('./Warehouse/Reports/SaleReports')),
    },
    {
      path: '/reports/iverify-log',
      component: React.lazy(() => import('./Warehouse/Reports/IverifyLog')),
    },
    {
      path: '/reports/invoice',
      component: React.lazy(() => import('./Warehouse/Reports/InvoiceReport')),
    },
    {
      path: '/reports/rma',
      component: React.lazy(() => import('./Warehouse/Reports/RMAReport')),
    },
    {
      path: '/products',
      component: React.lazy(() => import('./Warehouse/Products')),
    },
    {
      path: '/accessories',
      component: React.lazy(() => import('./Warehouse/Accessories')),
    },
    {
      path: '/new-product',
      component: React.lazy(() => import('./Warehouse/NewProduct')),
    },
    {
      path: '/products/edit/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/NewProduct')),
    },
    {
      path: '/new-accessory',
      component: React.lazy(() => import('./Warehouse/NewAccessory')),
    },
    {
      path: '/accessories/edit/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/NewAccessory')),
    },
    {
      path: '/products/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/SingleProduct')),
    },
    {
      path: '/accessories/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/SingleAccessory')),
    },
    {
      path: '/manufacturers/new',
      component: React.lazy(() => import('./Warehouse/ManageManufacturers')),
    },
    {
      path: '/manufacturers/edit/:id(\\d+)',
      component: React.lazy(() => import('./Warehouse/ManageManufacturers')),
    },
    {
      path: '/manufacturers',
      component: React.lazy(() => import('./Warehouse/Manufacturers')),
    },

    // Inventory

    {
      path: '/inventory-items',
      component: React.lazy(() => import('./Inventory/InventoryItems')),
    },
    {
      path: '/inventory-items/accessory',
      component: React.lazy(() => import('./Inventory/AccessoryInventoryItems')),
    },
    {
      path: '/csv-order',
      component: React.lazy(() => import('./Inventory/CSVOrder')),
    },
    {
      path: '/orders',
      component: React.lazy(() => import('./Inventory/Orders')),
    },
    {
      path: '/accessory-orders',
      component: React.lazy(() => import('./Inventory/AccessoryOrders')),
    },
    {
      path: '/orders/edit/:id',
      component: React.lazy(() => import('./Inventory/EditOrder')),
    },
    {
      path: '/accessory-orders/edit/:id',
      component: React.lazy(() => import('./Inventory/EditAccessoryOrder')),
    },
    {
      path: '/fulfilled-orders',
      component: React.lazy(() => import('./Inventory/FulfilledOrders')),
    },
    {
      path: '/fulfilled-orders/accessory',
      component: React.lazy(() => import('./Inventory/FulfilledAccessoryOrders')),
    },
    {
      path: '/fulfill-orders/accessory/:id(\\d+)',
      component: React.lazy(() => import('./Inventory/FulfillAccessoryOrder')),
    },
    {
      path: '/fulfilled-orders/:id(\\d+)',
      component: React.lazy(() => import('./Inventory/ViewFulfilledOrder')),
    },
    {
      path: '/fulfilled-orders/accessory/:id(\\d+)',
      component: React.lazy(() => import('./Inventory/ViewFulfilledAccessoryOrder')),
    },
  ],
};

export default WarehouseInventoryAppConfig;
