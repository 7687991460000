const csoureData = [
  {
    // "id": 61,
    name: 'Reporting',
    // "company_id": 1,
    // "parent_id": null,
    // "created_at": "2020-01-28T08:06:00.000Z",
    // "updated_at": "2020-04-10T15:20:00.000Z",
    // "is_deleted": false,
    // "is_system_item": true,
    route: 'https://csbi.sisense.com/',
    embed_and_links_id: null,
    // "is_root": true,
    // "order": 98,
    icon: 'https://centraverse-notification-assets.s3.us-east-2.amazonaws.com/sideNavIcons/description_24px.svg',
    permissions: null,
    embedsAndLinks: null,
  }];

export default csoureData;
