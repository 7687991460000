/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
// /* eslint-disable */
import React, {useEffect} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { debounce } from 'lodash';
import { useGetCoordinates } from 'lib/useGetCoordinates';
import FaceModal from 'shared/components/FaceModal';

import clockIcon from '../../../assets/images/clock.png';
import {
  addTimecardForClockinAction, setWebClockInFaceAuthPendingAction, setWebClockInFaceAuthErrorAction, setWebClockInSuccessAction,
} from '../../../redux/actions/hrWebClockinAction';
import './HRWebClockIn.scss';
import { timecardActions } from '../../../constants/default';

const imageUrl = null;

function DiagBox({
  faceAuthPending,
  faceAuthError,
  faceAuthErrorMsg,
  clockinSuccess,
  ...props
}) {
  const timezone = localStorage.getItem('timezone');
  const storeTimeFormatter = ({ ...options }) =>
    new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      ...options,
    });

  const user = JSON.parse(localStorage.getItem('user'));
  const storeid = JSON.parse(localStorage.getItem('store_id'));
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const scheduleId = JSON.parse(localStorage.getItem('scheduleId'));
  const realTime = useSelector(state => (state.webclocks && state.webclocks.time) || []);
  const [open, setOpen] = React.useState(true);
  const [showdial, setShowdial] = React.useState(false);
  // const sidebar = useSelector(state => (state.sidebar_items && state.sidebar_items.sidebar_items) || []);
  // let val=(sidebar.map((item) => (item.name.includes('HR'))))
  // const found = sidebar.some(el => el.name ==='HR');
  const [user_id, setUser_id] = React.useState(user.id);
  const [store_id, setStore_id] = React.useState(storeid);
  const [user_comment, setUser_comment] = React.useState(null);
  const [diag, setDiag] = React.useState(true);
  const [faceAuthPayload, setFaceAuthPayload] = React.useState(null);
  const [faceAuthAction, setFaceAuthAction] = React.useState('');

  const getCoordinates = useGetCoordinates();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('clock_in', false);
  };
  const handleClockIn = async (data) => {
    const coordinates = await getCoordinates();
    const payload = {
      ...data,
      schedule_id: scheduleId !== 0 ? scheduleId : undefined,
      department_role_id: null,
      user_id,
      store_id,
      imageUrl,
      ...coordinates,
      user_comment,
    };
    setFaceAuthPayload(payload);
    setFaceAuthAction(timecardActions.CLOCK_IN);
    dispatch(addTimecardForClockinAction(payload));
  };
  const handleClickClockIn = debounce(async (e) => {
    e.preventDefault();
    await handleClockIn();
  }, 2000, { leading: true, trailing: false });

  const handleFaceSubmit = (faceImage) => {
    console.log('Submitted');
    console.log(faceImage);
    switch (faceAuthAction) {
      case timecardActions.CLOCK_IN:
        handleClockIn({ ...faceAuthPayload, ...faceImage });
        break;
      default:
        break;
    }
  };

  const handleFaceModalClose = () => {
    dispatch(setWebClockInFaceAuthPendingAction(false));
    dispatch(setWebClockInFaceAuthErrorAction(false));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = (e) => {
    e.preventDefault();
    setOpen(false);
    localStorage.setItem('clock_in', false);
  };

  //  const  handleClose = () => {
  //     clearTimeout(closetimer);
  //     this.setState({
  //        isopen: false,
  //        action: '',
  //        is_scheduled: null,
  //        has_break: null,
  //       });
  //   };

  const hour2 = realTime.hours;
  const minute2 = realTime.minutes;
  // if(Object.keys(realTime).length)
  // {
  //  let second2=((realTime.hours*3600)+(realTime.minutes*60))
  //  let second3=((realTime.hours*3600)+((realTime.minutes+.5)*60))
  //  let distance=second3-second2

  //  let x = setInterval(function() {
  //      distance = distance-1;
  //     if (distance === 0) {
  //       clearInterval(x);
  //       setDiag(true)
  //     }
  //     console.log('x',distance)

  //   }, 1000);
  // }


  useEffect(() => {
    if (clockinSuccess) {
      handleClose();
      dispatch(setWebClockInSuccessAction(false));
    }
  }, [clockinSuccess]);

  return (
    <div>
      {diag === true ? (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={handleClickClose}
          style={{ borderRadius: '20px' }}
        >
          <form className="form-container">
            <DialogContent>
              <Grid item xs={12}>
                <div style={{ fontSize: '20px', color: '#292C34' }}>
                  Hi ,{user.first_name}{' '}
                </div>
                <div style={{ position: 'relative' }}>
                  <img style={{ width: '30%' }} src={clockIcon} alt="Clock" />
                  <div
                    style={{ position: 'absolute', top: '40%', left: '43%' }}
                  >
                    <p style={{ color: '#FFFFFF' }}>
                      {moment().format('h:mm:ss a')}
                    </p>
                  </div>
                  <div
                    style={{ position: 'absolute', top: '55%', left: '44%' }}
                  >
                    <p style={{ color: '#FFFFFF', fontSize: '10px' }}>
                      {moment().format('MMM DD, YYYY')}
                    </p>
                  </div>
                </div>
                <p style={{ fontSize: '18px', color: '#292C34' }}>
                  {' '}
                  Would you like to Clock-in ?{' '}
                </p>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid item xs={12} className="text-center">
                {/* clockin button */}
                {/* <div className={this.state.action === 'clockin' ? 'div-visible' : 'div-invisible'}> */}
                <FormControl className="formControl clockinactionarea">
                  <div style={{ textAlign: 'center' }}>
                    <TextField
                      style={{
                        width: '350px',
                        marginTop: '0px',
                        marginBottom: '16px',
                      }}
                      id="txtusercomment"
                      placeholder="Add Notes/Comments"
                      width="500px"
                      margin="normal"
                      size="small"
                      variant="outlined"
                      onChange={e => setUser_comment(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>
                        <Button
                          onClick={handleClickClose}
                          disableElevation
                          variant="outlined"
                          size="large"
                          type="submit"
                          style={{
                            textTransform: 'none',
                            color: '#4EA50F',
                            borderColor: '#4EA50F',
                            height: 55,
                            width: 130,
                            margin: '5px',
                          }}
                        >
                          Not Now
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={handleClickClockIn}
                          disableElevation
                          variant="contained"
                          size="large"
                          type="submit"
                          style={{
                            textTransform: 'none',
                            backgroundColor: '#4EA50F',
                            color: '#FFFFFF',
                            height: 55,
                            width: 130,
                            margin: '5px',
                          }}
                        >
                          Clock In
                        </Button>
                      </div>
                    </div>
                  </div>
                </FormControl>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      ) : (
        ''
      )}
      {faceAuthPending && faceAuthAction &&
      <FaceModal
        open={faceAuthPending}
        onClose={handleFaceModalClose}
        onSubmit={handleFaceSubmit}
        error={faceAuthError}
        errorMsg={faceAuthErrorMsg}
      />
      }
    </div>
  );
}

export default connect(state => ({
  clockinSuccess: state.webclocks && state.webclocks.clockinSuccess,
  faceAuthPending: (state.webclocks && state.webclocks.faceAuthPending) || (state.timecardbreaks && state.timecardbreaks.faceAuthPending),
  faceAuthError: (state.webclocks && state.webclocks.faceAuthError) || (state.timecardbreaks && state.timecardbreaks.faceAuthError),
  faceAuthErrorMsg: (state.webclocks && state.webclocks.faceAuthErrorMsg) || (state.timecardbreaks && state.timecardbreaks.faceAuthErrorMsg),
}))(DiagBox);
