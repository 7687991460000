import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  taxLists: [],
  total: 0,
  loading: false,
  error: '',
  // users: [],
};

export default (state = initialState, action) => {
  // console.log('reached reducer');
  switch (action.type) {
    case types.FETCH_TAX_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          taxLists: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        taxLists: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_TAX_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_TAX_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_TAX_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_TAX_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_TAX_FAILED:
      return {
        ...state,
        taxLists: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_TAX_SUCCESS:
      return {
        ...state,
        taxLists: [...state.taxLists, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_TAX_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_TAX_SUCCESS:
      let taxLists = filter(state.taxLists, item => item.id !== action.res.data.id);
      return {
        ...state,
        taxLists: [...taxLists, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_TAX_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_TAX_SUCCESS:
      taxLists = filter(state.taxLists, item => item.id !== action.payload.id);
      return {
        ...state,
        taxLists: [...taxLists],
        status: true,
        loading: false,
      };
    case types.DELETE_TAX_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    default:
      return state;
  }
};
