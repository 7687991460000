import { createMuiTheme } from '@material-ui/core/styles';

const tabTextColor = '#13273C';
const arcGrey = '#868686';
const logoTextColor = '#003969';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0551A3',
      contrastText: '#fff',
      dark: '#002a74',
      light: '#527dd5',
    },
    secondary: {
      main: '#3AA50B',
      contrastText: '#fff',
      light: '#72d747',
      dark: '#007500',
      backdrop: '#3AA50B1A',
    },
    tertiary: {
      main: '#f49900',
      contrastText: '#fff',
      dark: '#bb6b00',
      light: '#ffca47',
    },
    highlight: {
      main: '#009ACA',
      contrastText: '#fff',
      dark: '#006c99',
      light: '#5dcbfd',
    },
    green: {
      main: '#3AA50B',
      contrastText: '#fff',
      dark: '#3AA50B',
      light: '#3AA50B',
    },
    blueGrey: {
      main: '#939fbf',
      contrastText: '#fff',
      dark: '#65718f',
      light: '#c4d0f2',
    },
    text: {
      primary: '#13273C',
      secondary: '#0551A3',
    },
    background: {
      paper: '#fff',
      default: '#F4F6F7',
    },
    // error: {
    //   main: red[500],
    //   contrastText: '#fff',
    //   dark: red[400],
    //   light: red[500],
    // },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    saleCenterTab: {
      color: `${tabTextColor}`,
      blueIndicator: {
        backgroundColor: '#4D9BCA',
      },
      greenIndicator: {
        backgroundColor: '#4EA50F',
      },
    },
    // Headline
    h1: {
      fontWeight: 400,
      fontSize: 24,
    },
    // Title
    h2: {
      fontWeight: 500,
      fontSize: 20,
    },
    // SubHeader
    h3: {
      fontWeight: 400,
      fontSize: 16,
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 12,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 12,
    },
    caption: {
      fontWeight: 'bold',
      fontSize: 12,
    },
  },
  topSideBarCustom: {
    iconColor: '#004995',
    buttonBg: 'linear-gradient(51deg, rgba(0, 154, 202, 1), rgba(0, 101, 209, 1) )',
    orangeColor: '#F49900',
  },
  topBarSelect: {
    fontWeight: 500,
    fontSize: 14,
  },
  dropDownlistItem: {
    padding: '8px 16px !important',
    borderBottom: '1px solid #ececec',
  },
  dropDownlistItemsWrapper: {
    padding: 0,
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  overflowScrollBar: {
    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(232, 232, 232, .35)',
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      outline: 0,
      backgroundColor: '#D2E2EA',
      borderRadius: 4,
    },
  },
  overflowXScrollBar: {
    '&::-webkit-scrollbar': {
      height: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(232, 232, 232, .35)',
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      outline: 0,
      backgroundColor: '#D2E2EA',
      borderRadius: 4,
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '5px',
        borderBottom: 'none',
      },
    },
  },
});

export default theme;
