import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addUserDistrict, deleteUserDistrict, getUserDistrictById, getUserDistricts, updateUserDistrict} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_USERDISTRICTS_REQUEST, fetchUserDistrictsSaga);
  yield takeLatest(types.FETCH_USERDISTRICT_REQUEST, fetchUserDistrictSaga);
  yield takeLatest(types.ADD_USERDISTRICT_REQUEST, addUserDistrictSaga, context);
  yield takeLatest(types.UPDATE_USERDISTRICT_REQUEST, updateUserDistrictSaga, context);
  yield takeLatest(types.DELETE_USERDISTRICT_REQUEST, deleteUserDistrictSaga);
}

export function* fetchUserDistrictsSaga({ payload }) {
  try {
    const res = yield call(getUserDistricts, payload);
    yield all([
      put({ type: types.FETCH_USERDISTRICTS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERDISTRICTS_FAILED, error });
  }
}

export function* fetchUserDistrictSaga({ payload }) {
  try {
    const res = yield call(getUserDistrictById, payload);
    yield all([
      put({ type: types.FETCH_USERDISTRICT_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERDISTRICT_FAILED, error });
  }
}

export function* addUserDistrictSaga({ history }, { payload }) {
  try {
    const res = yield call(addUserDistrict, payload);
    yield all([
      put({ type: types.ADD_USERDISTRICT_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserDistrict added' : res.message || 'UserDistrict not added',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/userstores');
    // }
  } catch (error) {
    yield all([
      put({ type: types.ADD_USERDISTRICT_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateUserDistrictSaga({ history }, { payload }) {
  try {
    const res = yield call(updateUserDistrict, payload);
    yield all([
      put({ type: types.UPDATE_USERDISTRICT_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserDistrict updated' : res.message || 'UserDistrict not updated',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/userstores');
    // }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_USERDISTRICT_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteUserDistrictSaga({ payload }) {
  try {
    const res = yield call(deleteUserDistrict, payload);
    yield all([
      put({ type: types.DELETE_USERDISTRICT_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserDistrict deleted' : res.message || 'UserDistrict not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_USERDISTRICT_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
