import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  serials: [],
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SERIALS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          serials: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        serials: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_SERIALS_REQUEST:
      return {
        ...state,
        serials: [],
        loading: true,
      };
    case types.FETCH_SERIALS_FAILED:
      return {
        ...state,
        serials: [],
        error: 'Bad Request',
        loading: false,
      };

    default:
      return state;
  }
};
