import { put, call, takeLatest, all } from 'redux-saga/effects';
import { fetchHRSettingsCompanies, updateHRSettingsCompany } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_HRSETTINGSCOMPANIES_REQUEST, fetchHRSettingsCompaniesSaga);
  yield takeLatest(types.UPDATE_HRSETTINGSCOMPANY_REQUEST, updateHRSettingsCompanySaga);
}

export function* fetchHRSettingsCompaniesSaga({ payload }) {
  try {
    const res = yield call(fetchHRSettingsCompanies, payload);
    yield all([
      put({ type: types.FETCH_HRSETTINGSCOMPANIES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_HRSETTINGSCOMPANIES_FAILED, error });
  }
}

export function* updateHRSettingsCompanySaga({ payload }) {
  try {
    const res = yield call(updateHRSettingsCompany, payload);
    yield all([
      put({ type: types.UPDATE_HRSETTINGSCOMPANY_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Company HR settings updated' : res.message || 'Company HR settings not updated',
        },
      }),
    ]);
  } catch (error) {
    yield put({ type: types.UPDATE_HRSETTINGSCOMPANY_FAILED, error });
  }
}
