import React from 'react';

export const HrAppConfig = { // eslint-disable-line
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'hrApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/hr/settings',
      component: React.lazy(() => import('./Newui/HRModule/HRSettings')),
    },
    {
      path: '/hr/dashboard/timeoff/:id?',
      component: React.lazy(() => import('./Newui/HRModule/ui/pages/TimeOffRequestsPage')),
    },
    {
      path: '/hr/dashboard/store_employees',
      component: React.lazy(() => import('./Newui/HRModule/ui/pages/StoreEmployeesPage')),
    },
    {
      path: '/hr/dashboard/:nestedRoute?',
      component: React.lazy(() => import('./Newui/HRModule/DashBoard')),
    },
    {
      path: '/hr/shift',
      component: React.lazy(() => import('./HrNewScheduleShift')),
    },
    {
      path: '/hr/schedule',
      component: React.lazy(() => import('./HrNewSchedule')),
    },
    {
      path: '/hr/schedule/delete',
      component: React.lazy(() => import('./HRScheduleDelete')),
    },
    {
      path: '/hr/team',
      component: React.lazy(() => import('./HRTeam')),
    },
    {
      path: '/hr/team/action/:id',
      component: React.lazy(() => import('./HRTeam/components/Actions')),
    },
    {
      path: '/hr/timesheet',
      component: React.lazy(() => import('./NewHrUi/TimeSheet')),
    },
    {
      path: '/hr/timesheet-details',
      component: React.lazy(() => import('./NewHrUi/TimeSheetDetails')),
    },
    {
      path: '/hr/reports',
      component: React.lazy(() => import('./HRReports/index')),
    },
    {
      path: '/subscriptions',
      component: React.lazy(() => import('./HRReports/components/subscriptions/index')),
    },
  ],
};
