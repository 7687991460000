import * as types from '../../constants/actionTypes';

export const fetchOneViewCompanyWidgetsAction = payload => ({
  type: types.FETCH_COMPANY_WIDGETS_REQUEST,
  payload,
});

export const storeCompanyWidgetsAction = payload => ({
  type: types.STORE_COMPANY_WIDGETS,
  payload,
});

export const fetchOneViewCompanyWidgetAction = payload => ({
  type: types.FETCH_COMPANY_WIDGET_REQUEST,
  payload,
});

export const addOneViewCompanyWidgetAction = payload => ({
  type: types.ADD_COMPANY_WIDGET_REQUEST,
  payload,
});

export const updateOneViewCompanyWidgetAction = payload => ({
  type: types.UPDATE_COMPANY_WIDGET_REQUEST,
  payload,
});

export const updateOneViewBgImageAction = payload => ({
  type: types.UPDATE_ONE_VIEW_BG_IMAGE_REQUEST,
  payload,
});

export const deleteOneViewCompanyWidgetAction = payload => ({
  type: types.DELETE_COMPANY_WIDGET_REQUEST,
  payload,
});
