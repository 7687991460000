import React, { useContext } from 'react';
import {Redirect} from 'react-router-dom';
import { Provider } from 'react-redux';

export const SalesAppConfig = { //eslint-disable-line
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'salesApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    { // Private routes
      path: '/sales-center',
      component: React.lazy(() => import('./SalesCenter')),
    },
    {
      path: '/activation',
      component: React.lazy(() => import('./NewUISaleCenter/ActivationActions/Activation')),
    },
    {
      path: '/activation/edit/:id',
      component: React.lazy(() => import('./Invoices/InvoiceActions/EditInvoice')),
    },
    {
      path: '/activation/view/:id',
      component: React.lazy(() => import('./Invoices/InvoiceActions/ViewInvoice')),
    },
    {
      path: '/ev-invoice/view/:id',
      component: React.lazy(() => import('./QuickSnapInvoice/evInvoiceActions/ViewEVInvoice')),
    },
    {
      path: '/simple-sale',
      component: React.lazy(() => import('./NewUISaleCenter/SimpleSaleActions/SimpleSale')),
    },
    {
      path: '/simple-sale/edit/:id',
      component: React.lazy(() => import('./Invoices/InvoiceActions/EditInvoice')),
    },
    {
      path: '/simple-sale/view/:id',
      component: React.lazy(() => import('./Invoices/InvoiceActions/ViewInvoice')),
    },
    {
      path: '/features-addons-sale',
      component: React.lazy(() => import('./NewUISaleCenter/FeatureAddonActions/FeatureAddon')),
    },
    {
      path: '/feature-add-on/edit/:id',
      component: React.lazy(() => import('./Invoices/InvoiceActions/EditInvoice')),
    },
    {
      path: '/feature-add-on/view/:id',
      component: React.lazy(() => import('./Invoices/InvoiceActions/ViewInvoice')),
    },
    {
      path: '/sale-confirmation/:id',
      component: React.lazy(() => import('./SalesCenter/SaleConfirmation')),
    },
    {
      path: '/returns-refunds',
      component: React.lazy(() => import('./ReturnAndRefund')),
    },
    {
      path: '/returns-refunds/:id',
      component: React.lazy(() => import('./ReturnAndRefund/ReturnAndRefundActions')),
    },
    {
      path: '/quicksnap-invoice',
      component: React.lazy(() => import('./QuickSnapInvoice')),
    },
    {
      path: '/invoice-bulk-upload',
      component: React.lazy(() => import('./InvoiceUpload')),
    },
    {
      path: '/invoice-update',
      component: React.lazy(() => import('./InvoiceUpdate')),
    },
    {
      path: '/invoice-item-bulk-delete',
      component: React.lazy(() => import('./InvoiceItemDelete')),
    },
    {
      path: '/accessory-item-bulk-delete',
      component: React.lazy(() => import('./AccessoryInvoiceItemDelete')),
    },
    {
      path: '/feature-items-bulk-delete',
      component: React.lazy(() => import('./FeatureItemDelete')),
    },
    {
      path: '/feature-items-bulk-update',
      component: React.lazy(() => import('./FeatureItemUpdate')),
    },
    {
      path: '/accessory-recon-upload',
      component: React.lazy(() => import('./AccessoryReconUpload')),
    },
    {
      path: '/accessory-item-bulk-edit',
      component: React.lazy(() => import('./AccessoryUpdateUpload')),
    },
    {
      path: '/sales-reports',
      component: React.lazy(() => import('./NewUISaleCenter/SalesReports')),
    },
    {
      path: '/sales-report/uw',
      component: React.lazy(() => import('./NewUISaleCenter/SalesReportUW')),
    },
    {
      path: '/sales-report/wecan',
      component: React.lazy(() => import('./NewUISaleCenter/SalesReportWeCan')),
    },
    {
      path: '/sales-details-reports',
      component: React.lazy(() => import('./NewUISaleCenter/SalesDetailsReports')),
    },
    {
      path: '/sales-details-reports-mc',
      component: React.lazy(() => import('./NewUISaleCenter/SalesDetailsReportsMC')),
    },
    {
      path: '/sales-metrics-reports',
      component: React.lazy(() => import('./NewUISaleCenter/SalesMetricsReports')),
    },
    {
      path: '/customers',
      component: React.lazy(() => import('./Customers')),
    },
    {
      path: '/billpay/new',
      component: React.lazy(() => import('./NewBillPay')),
    },
    {
      path: '/incomm',
      component: React.lazy(() => import('./InCommSSO')),
    },
    {
      path: '/incomm-thank-you/:tid',
      component: React.lazy(() => import('./InCommThankYou')),
    },
    {
      path: '/billpay/new/:tid',
      component: React.lazy(() => import('./NewBillPay/components/PaymentForm')),
    },
    {
      path: '/pos/invoices',
      component: React.lazy(() => import('./Invoices')),
    },
    {
      path: '/pos/invoices/:id',
      component: React.lazy(() => import('./PosInvoices')),
    },
    {
      path: '/admin/sale-dependencies',
      component: React.lazy(() => import('./Admin/SaleDependencies/SaleDependenciesListing')),
    },
    {
      path: '/admin/sale-dependencies/add',
      component: React.lazy(() => import('./Admin/SaleDependencies/AddSaleDependency')),
    },
    {
      path: '/feature-add-on',
      component: React.lazy(() => import('./Admin/FeatureAddons')),
    },
    {
      path: '/static-accessories',
      component: React.lazy(() => import('./Admin/StaticAccessories')),
    },
    {
      path: '/device-type-fields',
      component: React.lazy(() => import('./Admin/DevicetypeFields')),
    },
    {
      path: '/admin/sale-dependencies/existing',
      component: React.lazy(() => import('./Admin/SaleDependencies/AddSaleDependencyExisting')),
    },
    {
      path: '/customers/new',
      component: React.lazy(() => import('./AddEditCustomer')),
    },
    {
      path: '/customers/bulkupload',
      component: React.lazy(() => import('./CustomerBulkUpload')),
    },
    {
      path: '/customers/edit/:id',
      component: React.lazy(() => import('./AddEditCustomer')),
    },
    {
      path: '/customers/:id',
      component: React.lazy(() => import('./CustomerProfile')),
    },
    {
      path: '/b2b-sale',
      component: React.lazy(() => import('./B2BSales/B2BSale')),
    },
    {
      path: '/b2b-sales/invoices',
      component: React.lazy(() => import('./B2BSales/B2BSalesListing')),
    },
    {
      path: '/b2b-sales/invoices/bulk-upload',
      component: React.lazy(() => import('./B2BSales/B2BSalesUpload')),
    },
    {
      path: '/b2b-sales/invoices/bulk-update',
      component: React.lazy(() => import('./B2BSales/B2BSalesBulkUpdate')),
    },
    {
      path: '/b2b-sales/edit/:id',
      component: React.lazy(() => import('./B2BSales/B2BSaleActions/EditSale')),
    },
    {
      path: '/b2b-sales/view/:id',
      component: React.lazy(() => import('./B2BSales/B2BSaleActions/ViewSale')),
    },
    {
      path: '/sales-metric-report',
      component: React.lazy(() => import('./SalesMetricReports')),
    },
  ],
};
