import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addCompany, deleteCompany, getCompanyById, getCompanies, updateCompany,
  getCompaniesCount } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_COMPANIES_REQUEST, fetchCompaniesSaga);
  yield takeLatest(types.FETCH_COMPANY_REQUEST, fetchCompanySaga);
  yield takeLatest(types.ADD_COMPANY_REQUEST, addCompanySaga, context);
  yield takeLatest(types.UPDATE_COMPANY_REQUEST, updateCompanySaga, context);
  yield takeLatest(types.DELETE_COMPANY_REQUEST, deleteCompanySaga);
  yield takeLatest(types.FETCH_COMPANY_COUNTS, fetchCompanyCountSaga);
}

export function* fetchCompaniesSaga({ payload }) {
  try {
    const res = yield call(getCompanies, payload);
    yield all([
      put({ type: types.FETCH_COMPANIES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_COMPANIES_FAILED, error });
  }
}

export function* fetchCompanySaga({ payload }) {
  try {
    const res = yield call(getCompanyById, payload);
    yield all([
      put({ type: types.FETCH_COMPANY_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_COMPANY_FAILED, error });
  }
}

export function* addCompanySaga({ history }, { payload }) {
  try {
    const res = yield call(addCompany, payload);
    yield all([
      put({ type: types.ADD_COMPANY_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Company added' : res.message || 'Company not added',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/companies');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_COMPANY_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateCompanySaga({ history }, { payload }) {
  try {
    const res = yield call(updateCompany, payload);
    yield all([
      put({ type: types.UPDATE_COMPANY_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Company updated' : res.message || 'Company not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/admin-company-settings');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_COMPANY_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteCompanySaga({ payload }) {
  try {
    const res = yield call(deleteCompany, payload);
    yield all([
      put({ type: types.DELETE_COMPANY_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Company deleted' : res.message || 'Company not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_COMPANY_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchCompanyCountSaga({ payload }) {
  try {
    const res = yield call(getCompaniesCount, payload);
    yield all([
      put({ type: types.FETCH_COMPANY_COUNTS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_COMPANY_COUNTS_FAILED, error });
  }
}
