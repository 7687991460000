import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  FACEBOOK_SIGN_IN_SUCCESS,
  FACEBOOK_SIGN_IN_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  TWO_FACTOR_AUTH_SUCCESS,
  TWO_FACTOR_AUTH_FAILURE,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILED,
  SIGN_IN_REQUEST,
  MOBILE_PIN_CHANGE_SUCCESS,
  MOBILE_PIN_CHANGE_FAILURE,
  UPDATE_FACE_SUCCESS,
  UPDATE_FACE_FAILURE,
  UPDATE_FACE_REQUEST,
} from '../../constants/actionTypes';

const initialState = {
  login: {
    email: '',
    password: '',
    status: false,
    error: null,
    oneFactor: null,
    faceAuthPending: false,
    faceAuthError: false,
    loading: false,
    HomeScreen: null,
  },
  update_face_success: false,
};
/*eslint-disable*/
export default function (state = initialState, action) {

  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
        HomeScreen: null,
        loginStatus: false,
        twoFactor: false,
        user: null
      };
    case SIGN_IN_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          oneFactor: action.res,
          status: action.res.success,
          faceAuthPending: !!action.res.faceAuthPending,
          faceAuthError: !!action.res.faceAuthError,
          error: action.res.reason,
          loading: false
        };
      }
      return {
        ...state,
        twoFactor: action.res.twoFactor,
        loginStatus: action.res.loginStatus,
        faceAuthPending: !!action.res.faceAuthPending,
        faceAuthError: !!action.res.faceAuthError,
        HomeScreen: action.res.HomeScreen,
        status: action.res.success,
        user: action.res.user,
        loading: false
      };
    case SIGN_IN_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
        faceAuthPending: !!action.res.faceAuthPending,
        faceAuthError: !!action.res.faceAuthError,
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          error: action.res.reason,
        };
      }
      return {
        ...state,
        status: action.res.success,
      };
    case UPDATE_USER_PASSWORD_FAILED:
      return {
        ...state,
        error: 'Bad Request',
      };
    case FACEBOOK_SIGN_IN_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          error: action.res.reason,
        };
      }
      return {
        ...state,
        status: action.res.success,
      };
    case FACEBOOK_SIGN_IN_FAILED:
      return {
        ...state,
        error: 'Bad Request',
      };
    case FORGOT_PASSWORD_SUCCESS:
     
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          error: action.res.reason,
        };
      }
      return {
        ...state,
        status: action.res.success,
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        error: 'Bad Request',
      };

    case RESET_PASSWORD_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          resetPassword: true,
          error: action.res.reason,
        };
      }
      return {
        ...state,
        status: action.res.success,
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        error: 'Bad Request',
      };
    case TWO_FACTOR_AUTH_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          error: action.res.reason,
        };
      }
      return {
        ...state,
        status: action.res.success,
      };
    case TWO_FACTOR_AUTH_FAILURE:
      return {
        ...state,
        error: 'Bad Request',
      };
    
    case MOBILE_PIN_CHANGE_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          error: action.res.reason,
        };
      }
      return {
        ...state,
        status: action.res.success,
      };
    case MOBILE_PIN_CHANGE_FAILURE:
      return {
        ...state,
        error: 'Bad Request',
      };
    case UPDATE_FACE_REQUEST:
      return {
        ...state,
        loading: true, 
        update_face_success: (action && action.res && action.res.data && action.res.data.success),
      };
    case UPDATE_FACE_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          error: action.res.reason,
          loading: false, 
          update_face_success: (action && action.res && action.res.data && action.res.data.success),
        };
      }
      return {
        ...state,
        status: action.res.success,
        loading: false,
        update_face_success: (action && action.res && action.res.data && action.res.data.success),
      };
    case UPDATE_FACE_FAILURE:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
        update_face_success: (action && action.res && action.res.data && action.res.data.success),
      };

    default:
      return state;
  }
}
