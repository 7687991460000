import * as types from '../../constants/actionTypes';
/* eslint-disable no-case-declarations */

const initialState = {
  hruserautoclokoutdetails: [],
  is_success: false,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_HRUSERAUTOCLOCKEDOUT_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          hruserautoclokoutdetails: [],
          is_success: false,
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        hruserautoclokoutdetails: (action.res.data && action.res.data.results) || [],
        is_success: true,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_HRUSERAUTOCLOCKEDOUT_REQUEST:
      return {
        ...state,
        hruserautoclokoutdetails: [],
        is_success: false,
        loading: true,
      };
    case types.FETCH_HRUSERAUTOCLOCKEDOUT_FAILED:
      return {
        ...state,
        hruserautoclokoutdetails: [],
        is_success: false,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};

