import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addSupportTicket, deleteSupportTicket, getSupportTicketById, getSupportTickets, updateSupportTicket} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_SUPPORTTICKETS_REQUEST, fetchSupportTicketsSaga);
  yield takeLatest(types.FETCH_SUPPORTTICKET_REQUEST, fetchSupportTicketSaga);
  yield takeLatest(types.ADD_SUPPORTTICKET_REQUEST, addSupportTicketSaga, context);
  yield takeLatest(types.UPDATE_SUPPORTTICKET_REQUEST, updateSupportTicketSaga, context);
  yield takeLatest(types.DELETE_SUPPORTTICKET_REQUEST, deleteSupportTicketSaga);
}

export function* fetchSupportTicketsSaga({ payload }) {
  try {
    const res = yield call(getSupportTickets, payload);
    yield all([
      put({ type: types.FETCH_SUPPORTTICKETS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SUPPORTTICKETS_FAILED, error });
  }
}

export function* fetchSupportTicketSaga({ payload }) {
  try {
    const res = yield call(getSupportTicketById, payload);
    yield all([
      put({ type: types.FETCH_SUPPORTTICKET_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SUPPORTTICKET_FAILED, error });
  }
}

export function* addSupportTicketSaga({ history }, { payload }) {
  try {
    const res = yield call(addSupportTicket, payload);
    yield all([
      put({ type: types.ADD_SUPPORTTICKET_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SupportTicket added' : res.message || 'SupportTicket not added',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/supporttickets');
    // }
  } catch (error) {
    yield all([
      put({ type: types.ADD_SUPPORTTICKET_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateSupportTicketSaga({ history }, { payload }) {
  try {
    const res = yield call(updateSupportTicket, payload);
    yield all([
      put({ type: types.UPDATE_SUPPORTTICKET_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SupportTicket updated' : res.message || 'SupportTicket not updated',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/supporttickets');
    // }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_SUPPORTTICKET_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteSupportTicketSaga({ payload }) {
  try {
    const res = yield call(deleteSupportTicket, payload);
    yield all([
      put({ type: types.DELETE_SUPPORTTICKET_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SupportTicket deleted' : res.message || 'SupportTicket not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_SUPPORTTICKET_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
