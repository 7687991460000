import React from 'react';

export const ReportingAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'reportingApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/user-reporting/sales-scorecard ',
      component: React.lazy(() => import('./UserReporting/salesScorecard')),
    },
    {
      path: '/user-reporting/inventory-report-dashboard',
      component: React.lazy(() => import('./UserReporting/inventoryDashboard')),
    },
    {
      path: '/user-reporting/product-inventory',
      component: React.lazy(() => import('./UserReporting/productInventory')),
    },
    {
      path: '/user-reporting/transaction-dashboard',
      component: React.lazy(() => import('./UserReporting/transactionDashboard')),
    },
    {
      path: '/user-reporting/equipment-sales-dashboard',
      component: React.lazy(() => import('./UserReporting/equipmentSalesDashboard')),
    },
    {
      path: '/user-reporting/demo-report',
      component: React.lazy(() => import('./UserReporting/demoReport')),
    },
    {
      path: '/user-reporting/reverse-logistics-report',
      component: React.lazy(() => import('./UserReporting/reverseLogisticsReport')),
    },
    {
      path: '/user-reporting/mwg-useage-report',
      component: React.lazy(() => import('./UserReporting/mwgUsageReport')),
    },
    {
      path: '/reporting/charts/new',
      component: React.lazy(() => import('./Charts/ChartForm/index')),
    },
    {
      path: '/reporting/charts',
      component: React.lazy(() => import('./Charts/ChartsTable/index')),
    },
    {
      path: '/dashboard/some-cool-dashboard',
      component: React.lazy(() => import('./Charts/Dashboard/SingleDashboard')),
    },
    {
      path: '/reporting/dsr-employee',
      component: React.lazy(() => import('./UserReporting/dsrEmployee')),
    },
    {
      path: '/reporting/store-sales-station',
      component: React.lazy(() => import('./UserReporting/dsrStore')),
    },
    {
      path: '/reporting/base-report',
      component: React.lazy(() => import('./UserReporting/baseReporting')),
    },
    {
      path: '/reporting/um-sales-overview',
      component: React.lazy(() => import('./UserReporting/umSalesOverview')),
    },
    {
      path: '/reporting/company-reports',
      component: React.lazy(() => import('./UserReporting/companyReporting')),
    },
    {
      path: '/reporting/pagecomm-sales-overview',
      component: React.lazy(() => import('./UserReporting/pageCommSalesOverview')),
    },
    {
      path: '/sla-tracker',
      component: React.lazy(() => import('./UserReporting/slaTracker')),
    },
    {
      path: '/asset-tracker',
      component: React.lazy(() => import('./UserReporting/assetTracker')),
    },
    {
      path: '/commission-processing',
      component: React.lazy(() => import('./UserReporting/commissionProcessing')),
    },
    {
      path: '/sales-engagement-center',
      component: React.lazy(() => import('./SalesEngagementCenter/index')),
    },
    {
      path: '/sales-overview',
      component: React.lazy(() => import('./UserReporting/salesOverview')),
    },
    {
      path: '/sales-engagement',
      component: React.lazy(() => import('./SalesEngagementCenter')),
    },
    {
      path: '/daily-snapshot',
      component: React.lazy(() => import('./UserReporting/dailySnapshot')),
    },
    {
      path: '/yw-sales-overview',
      component: React.lazy(() => import('./UserReporting/ywSalesOverview')),
    },
    {
      path: '/reporting/pbi/:report_id',
      component: React.lazy(() => import('./PowerBI/Embed')),
    },
    {
      path: '/organization-chart',
      component: React.lazy(() => import('./OrganizationChart')),
    },
  ],
};

export default ReportingAppConfig;
