/* eslint-disable */
import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { ThemeProvider } from '@material-ui/styles';
// import '@fortawesome/fontawesome-pro/css/all.min.css';
import { Router } from 'react-router-dom';
import history from '@history';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import 'bootstrap/dist/css/bootstrap.css';
import CentraverseAuthorization from 'centraverse/components/CentraverseAuthorization';
import CentraverseStore from 'centraverse/components/CentraverseStore';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";

import '../../scss/app.scss';
import Router1 from './Router1';
import ScrollToTop from './ScrollToTop';
import ProviderWithRouter from './ProviderWithRouter';
import AppContext from './AppContext';
import routes from './CentraverseConfig/routesConfig';
import theme from './MuiGlobalStyles';
let remaining;

// const host = window.location.hostname;
// let theDsn;

// if (host.includes('centraverse.com')) {
//   theDsn = "https://c864fd7016a44263abd142f14ad132d9@o267725.ingest.sentry.io/5384670";
// } else if (host.includes('csourcedata')) {
//   theDsn = "https://7883d3a9f8e046fb9e25816a6a9f2c69@o267725.ingest.sentry.io/5502830"
// }

// if (theDsn) {
//   Sentry.init({
//     dsn: theDsn,
//     integrations: [
//       new Integrations.BrowserTracing(),
//     ],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }
const queryClient = new QueryClient();
class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  componentDidCatch(error, errorInfo) { // eslint-disable-line
    // You can also log the error to an error reporting service
    console.log('error', error, errorInfo);
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Router history={history}>
        <AppContext.Provider value={{routes}}>
        <ProviderWithRouter>
          <QueryClientProvider client={queryClient}>
            <ScrollToTop>
              {!loaded &&
                <div className={`load${loading ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              }
              <div>
                <ThemeProvider theme={theme}>
                  <CentraverseAuthorization>
                    <CentraverseStore>
                      <Router1 />
                    </CentraverseStore>
                  </CentraverseAuthorization>
                </ThemeProvider>
              </div>
            </ScrollToTop>
          </QueryClientProvider>
        </ProviderWithRouter>
        </AppContext.Provider>
      </Router>
    );
  }
}

export default hot(module)(App);
