import * as types from '../../constants/actionTypes';

export const addItemToCart = payload => ({
  type: types.ADD_ITEM_TO_CART,
  payload,
});

export const deleteItemFromCart = index => ({
  type: types.DELETE_ITEM_FROM_CART,
  index,
});

export const changeQtyItemCart = (index, qty) => ({
  type: types.CHANGE_QTY_FROM_CART,
  payload: {
    index,
    qty,
  },
});

export const setItemsCart = items => ({
  type: types.SET_ITEMS_CART,
  payload: {
    items,
  },
});

export const clearCart = items => ({
  type: types.CLEAR_CART,
  payload: {
    items,
  },
});
