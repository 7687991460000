import * as types from '../../constants/actionTypes';

export const fetchSupprtTicketTypesAction = payload => ({
  type: types.FETCH_SUPPORT_TICKET_TYPES_REQUEST,
  payload,
});

export const fetchSupprtTicketTypeAction = payload => ({
  type: types.FETCH_SUPPORT_TICKET_TYPE_REQUEST,
  payload,
});

export const addSupprtTicketTypeAction = payload => ({
  type: types.ADD_SUPPORT_TICKET_TYPE_REQUEST,
  payload,
});

export const updateSupprtTicketTypeAction = payload => ({
  type: types.UPDATE_SUPPORT_TICKET_TYPE_REQUEST,
  payload,
});


export const deleteSupprtTicketTypeAction = payload => ({
  type: types.DELETE_SUPPORT_TICKET_TYPE_REQUEST,
  payload,
});
