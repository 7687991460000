import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  district: {},
  districts: [],
  total: 0,
  loading: false,
  error: '',
  isB2BUser: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DISTRICTS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          districts: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        districts: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_DISTRICTS_REQUEST:
      return {
        ...state,
        districts: [],
        loading: true,
      };
    case types.FETCH_DISTRICT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_DISTRICTS_SEARCH_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          districts: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        districts: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_DISTRICTS_SEARCH_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_DISTRICT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_DISTRICT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACTIVATE_DISTRICT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_DISTRICT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_DISTRICTS_FAILED:
      return {
        ...state,
        districts: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_DISTRICTS_SEARCH_FAILED:
      return {
        ...state,
        districts: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.TOGGLE_B2B_REQUEST_DISTRICT:
      return {
        ...state,
        isB2BUser: !state.isB2BUser,
      };
    case types.FETCH_DISTRICT_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          district: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        district: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_DISTRICT_SUCCESS:
      return {
        ...state,
        // districts: [...state.districts, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_DISTRICT_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_DISTRICT_SUCCESS:
      let districts = filter(state.districts, item => item.id !== action.res.data.id);
      return {
        ...state,
        district: action.res.data || {},
        districts: [...districts, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_DISTRICT_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_DISTRICT_SUCCESS:
      districts = filter(state.districts, item => item.id !== action.payload.id);

      return {
        ...state,
        districts: [...districts],
        status: true,
        loading: false,
      };
    case types.ACTIVATE_DISTRICT_SUCCESS:
      // districts = filter(state.districts, item => item.id !== action.payload.id);

      return {
        ...state,
        // districts: [...districts],
        status: true,
        loading: false,
      };
    case types.DELETE_DISTRICT_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.ACTIVATE_DISTRICT_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.FETCH_DISTRICT_COUNTS_SUCCESS:
      return {
        ...state,
        theCount: action.res.data.count,
        status: action.res.success,
        loading: false,
      };
    case types.CLEAR_DISTRICT_FORM: {
      return {
        ...state,
        district: {},
      };
    }
    default:
      return state;
  }
};
