import axios from 'axios';
import history from '@history';
import getAccessToken from 'api/getAccessToken';
import { API_ENDPOINT_URL } from '../constants/default';

const api = axios.create({
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

api.interceptors.request.use(
  config =>
  // Do something before request is sent
    ({
      ...config,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  , error =>
  // Do something with request error
    Promise.reject(error),
);

api.interceptors.response.use(
  response =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
    response
  , (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
    if (error && error.response && error.response.status === 460) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

api.defaults.baseURL = API_ENDPOINT_URL;

export default api;
