import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as types from '../../constants/actionTypes';
import { getEmployees, terminationNotes, addUserEmployee, getEmployeeById, uploadProfilPic, deleteEmployeeById, updateEmployeeById, getSupervisorLists, reactivateEmployee, getHRTeamEmployee } from '../../api/Api';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_EMPLOYEES_REQUEST, fetchEmployeesSaga);
  yield takeLatest(types.FETCH_EMPLOYEE_BY_ID_REQUEST, fetchEmployeeSaga);
  yield takeLatest(types.ADD_EMPLOYEES_REQUEST, addEmployeeSaga, context);
  yield takeLatest(types.CHANGE_EMPLOYEE_PAGE_BEGIN, changeEmployeePageSaga);
  yield takeLatest(types.UPLOAD_PROFILE_PIC_REQUEST, uploadProfilePicSaga);
  yield takeLatest(types.DELETE_EMPLOYEE_REQUEST, deleteEmployeeSaga);
  yield takeLatest(types.UPDATE_EMPLOYEE_REQUEST, updateEmployeeSaga, context);
  yield takeLatest(types.FETCH_SUPERVISORS_REQUEST, fetchSupervisorsSaga);
  yield takeLatest(types.TERMINATION_USERS_REQUEST, terminationEmployeeSaga);
  yield takeLatest(types.REACTIVATE_EMPLOYEES_REQUEST, reactivateEmployeeSaga);
  yield takeLatest(types.GET_EMPLOYEES_BY_USERID_REQUEST, fetchEmployeeByUseridSaga);
}
export function* uploadProfilePicSaga({ payload }) {
  try {
    const res = yield call(uploadProfilPic, payload);
    yield all([
      put({ type: types.UPLOAD_PROFILE_PIC_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Click on save to attach image with the user' : res.message || 'Employee not created',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPLOAD_PROFILE_PIC_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchEmployeeSaga({ payload }) {
  try {
    const res = yield call(getEmployeeById, payload);
    yield all([
      put({ type: types.FETCH_EMPLOYEE_BY_ID_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_EMPLOYEE_BY_ID_FAILED, error });
  }
}

export function* fetchEmployeeByUseridSaga({ payload }) {
  try {
    const res = yield call(getHRTeamEmployee, payload);
    yield all([
      put({ type: types.GET_EMPLOYEES_BY_USERID_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.GET_EMPLOYEES_BY_USERID_FAILED, error });
  }
}

export function* changeEmployeePageSaga({ payload }) {
  try {
    yield all([
      put({ type: types.CHANGE_EMPLOYEE_PAGE_SUCCESS, payload }),
    ]);
  } catch (error) {
    console.log(error);
  }
}

export function* deleteEmployeeSaga({ payload }) {
  try {
    const res = yield call(deleteEmployeeById, payload);
    yield all([
      put({ type: types.DELETE_EMPLOYEE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Employee deleted' : res.message || 'Employee not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_EMPLOYEE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateEmployeeSaga({ history }, { payload }) {
  try {
    const res = yield call(updateEmployeeById, payload);
    yield all([
      put({ type: types.UPDATE_EMPLOYEE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Employee updated' : res.message || 'Employee not updated',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_EMP }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_EMPLOYEE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchEmployeesSaga({ payload }) {
  try {
    const res = yield call(getEmployees, payload);
    yield all([
      put({ type: types.FETCH_EMPLOYEES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_EMPLOYEES_FAILED, error });
  }
}

export function* addEmployeeSaga({ history }, { payload }) {
  try {
    const res = yield call(addUserEmployee, payload);
    yield all([
      put({ type: types.ADD_EMPLOYEES_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Employee created' : res.message || 'Employee not created',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_EMP }),
    ]);
    if (res && res.success) {
      history.push('/employees');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_EMPLOYEES_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchSupervisorsSaga({ payload }) {
  try {
    const res = yield call(getSupervisorLists, payload);
    yield all([
      put({ type: types.FETCH_SUPERVISORS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SUPERVISORS_FAILED, error });
  }
}

export function* terminationEmployeeSaga({ payload }) {
  try {
    const res = yield call(terminationNotes, payload);
    yield all([
      put({ type: types.TERMINATION_USERS_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'User termination notes updated' : res.message || 'User termination notes not updated',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_EMP }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.TERMINATION_USERS_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* reactivateEmployeeSaga({ payload }) {
  try {
    const res = yield call(reactivateEmployee, payload);
    yield all([
      put({ type: types.REACTIVATE_EMPLOYEES_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'User reactivated' : res.message || 'User reactivation unsuccessful',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_EMP }),
      put({ type: types.TOGGLE_REACTIVATION_MODAL_REQUEST, payload: !res.success }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.REACTIVATE_EMPLOYEES_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
