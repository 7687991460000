import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  defaultIcon: {
    color: '#333',
    width: '40px',
    height: '40px',
  },
  icon: {
    fill: '#F49900',
  },
  iconButton: {
    padding: '0px',
  },
  userName: { ...theme.topBarSelect },
  dropDownlistItem: { ...theme.dropDownlistItem },
  dropDownlistItemsWrapper: { ...theme.dropDownlistItemsWrapper },
}));
const TopUserProfile = ({ permission, handleLogout, userData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowSide, setArrowSide] = useState(false);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowSide(prev => !prev);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setArrowSide(prev => !prev);
  };

  const menuId = 'User avatar actions';
  const profilePhoto = JSON.parse(localStorage.getItem('user')).profile_photo;
  return (
    <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap">
      <Grid item>
        {profilePhoto === null ?
          <AccountCircle className={classes.defaultIcon} /> :
          <Avatar alt="No profile pic" src={profilePhoto} style={{ width: '40px', height: '40px' }} />}
      </Grid>
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
        {!matchesSM &&
          <Grid item style={{ paddingLeft: '8px' }}>
            <Typography noWrap className={classes.userName} style={{ color: '#13273C' }}>{`${userData.first_name ? userData.first_name : ''} ${userData.last_name ? userData.last_name : ''}`}</Typography>
          </Grid>
        }
        <Grid item >
          <IconButton
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            // className={classes.icon}
            // style={{ color: '#F49900' }}
            className={classes.iconButton}
          >
            {arrowSide ? <ExpandLessIcon style={{ color: '#F49900' }} /> : <ExpandMoreIcon style={{ color: '#F49900' }} />}
          </IconButton>
        </Grid>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        className={classes.dropDownlistItemsWrapper}
      >
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to="/account"
          className={classes.dropDownlistItem}
        >
          Edit Account
        </MenuItem>
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to="/user/control-notifications"
          className={classes.dropDownlistItem}
        >
          Control Notifications
        </MenuItem>
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to="/mobile-pin-update"
          className={classes.dropDownlistItem}
        >
          Mobile pin update
        </MenuItem>
        {permission ?
          <MenuItem
            onClick={handleMenuClose}
            component={Link}
            to="/my-company-settings"
            className={classes.dropDownlistItem}
          >Company Settings
          </MenuItem> : null}
        <MenuItem
          onClick={handleLogout}
          component={Link}
          to="/"
          className={classes.dropDownlistItem}
        >Logout
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default TopUserProfile;

TopUserProfile.propTypes = {
  permission: PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
  handleLogout: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
