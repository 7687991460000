import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import InlineSvg from '../Utils/InlineSvg';
import useStyles from './MenuItemStyles';


const CSReporting = ({ items, drawerOpen, setCurrentSidebar, currentSidebar, history }) => { /* eslint-disable-line */
  const classes = useStyles();
  return (
    items &&
    items.map((item) => {/* eslint-disable-line */
      return (
        <List key={item.name} className={currentSidebar === item.name ? classes.selectedBackgroud : classes.defaultBackround}>{/* eslint-disable-line */}
          <a rel="noopener noreferrer" href={item.route} target="_blank">
            <ListItem button className={drawerOpen ? classes.item0 : classes.item16} onClick={() => setCurrentSidebar(item.name)}>
              <ListItemIcon className={classes.iconRoot}>
                {item && item.icon ? <InlineSvg url={item.icon} selected={currentSidebar} location={item.name} /> : ''}
              </ListItemIcon>
              {drawerOpen && <ListItemText
                disableTypography
                primary={<Typography style={{ color: '#FFFFFF', opacity: currentSidebar === item.name ? '1' : '0.5' }}>{item.name}</Typography>}
              />}
            </ListItem>
          </a>
        </List >
      );
    })
  );
};

export default withRouter(CSReporting);
