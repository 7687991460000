import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Container, Row, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { fetchSupprtTicketTypesAction } from 'redux/actions/SupprtTicketTypeAction';
import { addSupportTicketAction, hideSupportTicketAction } from 'redux/actions/SupportTicketAction';
import './style.scss';

/* eslint-disable */

const renderRadionInput = (props) => (
  <FormControlLabel
    control={<Radio color="primary" onChange={props.input.onChange} />}
    label={props.label}
    checked={props.checked}
    labelPlacement="end"
  />
)

class SupportTicket extends Component {
  static propTypes = {
    // dispatch: PropTypes.func.isRequired,
    // stores: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    // total: PropTypes.number.isRequired, // eslint-disable-line react/forbid-prop-types
  };
  state = {};

  componentDidMount() {
    this.props.dispatch(fetchSupprtTicketTypesAction({}));
    this.props.dispatch(change('support_ticket_form', 'type_id', 1))
  }
  onChange = (e, id) => {
    this.props.dispatch(change('support_ticket_form', 'type_id', id))
  }
  handleSubmit = (e, data) => {
    e.preventDefault();
    const { company_id, email } = JSON.parse(localStorage.getItem('user'))
    const { type_id, detail } = this.props.formValues;
    this.props.dispatch(addSupportTicketAction({
      type_id,
      detail,
      email,
      company_id,
      store_id: parseInt(localStorage.getItem('store_id'), 10),
      sent_from_route: window.location.pathname,
    }));
  }
  onClose = () => {
    this.props.dispatch(hideSupportTicketAction({}));
  }
  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Modal isOpen={true} toggle={this.toggle} className="react-strap-modal imei-lookup-modal">
            <ModalHeader toggle={() => this.onClose()}>Support Ticket</ModalHeader>
            <ModalBody>
              <form className="form" onSubmit={this.handleSubmit}>
                <div className="form__form-group support-form">
                  {this.state.errorMessage && <Alert color="danger">
                    {this.state.errorMessage}
                  </Alert>
                  }
                  <span>Support Type</span>
                  <div className="types">
                    {this.props.support_ticket_types.map(type =>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                        </div>
                        <Field
                          name="support_type"
                          component={renderRadionInput}
                          label={type.title}
                          onChange={(e) => this.onChange(e, type.id)}
                          checked={type.id === this.props.supportTicketId}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form__form-group support-details">
                    <span>Details</span>
                    <div className="form__form-group-field">
                      <Field
                        name="detail"
                        component="textarea"
                        required
                      />
                    </div>
                  </div>
                  <Button className="btn btn-primary account__btn account__btn--small" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </Row>
      </Container>
    );
  }
}
SupportTicket = reduxForm({
  form: 'support_ticket_form',
})(SupportTicket);

const selector = formValueSelector('support_ticket_form'); // <-- same as form name

export default withRouter(connect(state => ({
  total: (state.stores && state.stores.total) || 0,
  stores: (state.stores && state.stores.stores) || [],
  support_ticket_types: (state.support_ticket_types && state.support_ticket_types.support_ticket_types) || [],
  showModal: (state.support_tickets && state.support_tickets.showModal),
  supportTicketId: selector(state, 'type_id'),
  formValues: (state.form && state.form.support_ticket_form && state.form.support_ticket_form.values) || {}
}))(SupportTicket));
