import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  is_leadSources_success: false,
  is_leadSources_loading: false,
  is_leadSources_error: '',
  leadSources: [],
  interests: [],
  loading: false,
  is_success: false,
  is_data_saved: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INTERESTS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          interests: [],
          error: '',
          is_success: false,
          loading: false,
        };
      }
      return {
        ...state,
        interests: (action.res.data && action.res.data.results) || [],
        status: action.res.success,
        is_success: true,
        loading: false,
      };
    case types.FETCH_INTERESTS_REQUEST:
      return {
        ...state,
        interests: [],
        is_success: false,
        loading: true,
      };
    case types.FETCH_INTERESTS_FAILED:
      return {
        ...state,
        interests: [],
        is_success: false,
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_LEAD_SOURCES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          leadSources: [],
          is_leadSources_error: '',
          is_leadSources_success: false,
          is_leadSources_loading: false,
        };
      }
      return {
        ...state,
        leadSources: (action.res.data && action.res.data.results) || [],
        status: action.res.success,
        is_leadSources_success: true,
        is_leadSources_loading: false,
      };
    case types.FETCH_LEAD_SOURCES_REQUEST:
      return {
        ...state,
        leadSources: [],
        is_leadSources_success: false,
        is_leadSources_loading: true,
      };
    case types.FETCH_LEAD_SOURCES_FAILED:
      return {
        ...state,
        leadSources: [],
        is_leadSources_success: false,
        is_leadSources_error: 'Bad Request',
        is_leadSources_loading: false,
      };
    case types.CONTACT_US_REQUEST:
      return {
        ...state,
        is_success: false,
        loading: true,
      };
    case types.CONTACT_US_SUCCESS:
      return {
        ...state,
        status: action.res.success,
        is_data_saved: true,
        loading: false,
      };
    case types.CONTACT_US_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        is_success: false,
        loading: false,
      };
    default:
      return state;
  }
};
