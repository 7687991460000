import React from 'react';

export const AuthAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    exact: true,
  },
  auth: false,
  routes: [
    {
      path: '/login',
      component: React.lazy(() => import('./LogIn')),
    },
    {
      path: '/locked',
      component: React.lazy(() => import('./LockScreen')),
    },
    {
      path: '/forgot-password',
      component: React.lazy(() => import('./ForgotPassword')),
    },
    {
      path: '/reset-password',
      component: React.lazy(() => import('./ResetPassword')),
    },
  ],
};

export default AuthAppConfig;
