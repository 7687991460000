import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  employee: {},
  employees: [],
  total: 0,
  loading: false,
  error: '',
  page: 'info',
  profilePicUrl: null,
  supervisors: [],
  isFailed: false,
  isSuccess: false,
  isB2BUser: false,
  isEmpUpdate: false,
  showReactivationModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          employees: [],
          error: '',
          loading: false,
          isFailed: true,
          isSuccess: false,
        };
      }
      return {
        ...state,
        employees: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
        isFailed: false,
        isSuccess: true,
      };
    case types.FETCH_EMPLOYEES_REQUEST:
      return {
        ...state,
        employees: [],
        loading: false,
        isFailed: false,
        isSuccess: false,
      };
    case types.TOGGLE_IS_UPDATE_EMP:
      return {
        ...state,
        isEmpUpdate: !state.isEmpUpdate,
      };
    case types.ADD_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.EMPLOYEE_BULKUPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.EMPLOYEE_BULKUPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.EMPLOYEE_BULKUPLOAD_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.GET_EMPLOYEES_BY_USERID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_EMPLOYEES_BY_USERID_SUCCESS:
      return {
        ...state,
        employee: (action.res && action.res.data[0]) || {},
        loading: false,
      };
    case types.GET_EMPLOYEES_BY_USERID_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.FETCH_EMPLOYEES_FAILED:
      return {
        ...state,
        employees: [],
        loading: false,
      };
    case types.ADD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        status: action.res.success,
        loading: false,
      };
    case types.ADD_EMPLOYEES_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.CHANGE_EMPLOYEE_PAGE_SUCCESS:
      return {
        ...state,
        page: action.payload,
      };
    case types.FETCH_EMPLOYEE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_EMPLOYEE_BY_ID_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          employee: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        employee: (action.res.data) || {},
        status: action.res.success,
        loading: false,
        profilePicUrl: null,
        page: 'info',
      };
    case types.FETCH_EMPLOYEE_BY_ID_FAILED:
      return {
        ...state,
        employee: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.UPLOAD_PROFILE_PIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPLOAD_PROFILE_PIC_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          profilePicUrl: null,
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        profilePicUrl: (action.res.data) || null,
        status: action.res.success,
        loading: false,
      };
    case types.UPLOAD_PROFILE_PIC_FAILED:
      return {
        ...state,
        profilePicUrl: null,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_EMPLOYEE_SUCCESS:
      const employees = state.employees.filter(item => item.empDetail_id !== action.res.data.empDetail_id);
      return {
        ...state,
        employees: [...employees],
        status: true,
        loading: false,
      };
    case types.DELETE_EMPLOYEE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_EMPLOYEE_SUCCESS:
      // console.log('from red', action.res.data);
      // const users = filter(state.users, item => item.id !== action.res.data.id);
      // const user = JSON.parse(localStorage.getItem('user'));
      // if (action.res.data.id === parseInt(localStorage.getItem('user_id'), 10)) {
      //   console.log('its coming inside');
      //   if (user.current_store !== action.res.data.current_store) {
      //     localStorage.setItem('user', JSON.stringify({ ...user, store: action.res.data.store, current_store: action.res.data.current_store }));
      //     localStorage.setItem('store_name', action.res.data.store && action.res.data.store.name);
      //     localStorage.setItem('store_id', action.res.data.store && action.res.data.store.id);
      //   }
      // }
      return {
        ...state,
        // employee: {},
        // employees: [],
        // total: 0,
        error: '',
        status: action.res.success,
        loading: false,
        profilePicUrl: null,
      };
    case types.UPDATE_EMPLOYEE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.CLEAR_EMPLOYEE_BY_ID_DATA: {
      return {
        ...state,
        employee: {},
        loading: false,
      };
    }
    case types.FETCH_SUPERVISORS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          supervisors: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        supervisors: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_SUPERVISORS_REQUEST:
      return {
        ...state,
        supervisors: [],
        loading: false,
      };
    case types.TERMINATION_USERS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.TERMINATION_USERS_SUCCESS:
      const updatedEmployees = state.employees.map(data => ({
        ...data,
        ...(data.user_id === action.payload.id && {
          user: {
            ...data.user,
            is_deleted: true,
          },
        }),
      }));
      return {
        ...state,
        employees: updatedEmployees,
        status: true,
        loading: false,
      };
    case types.FETCH_SUPERVISORS_FAILED:
      return {
        ...state,
        supervisors: [],
        loading: false,
      };
    case types.REACTIVATE_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REACTIVATE_EMPLOYEES_SUCCESS:
      const updatedEmployeesReAct = state.employees.map(data => ({
        ...data,
        ...(data.user_id === action.payload.User_Id && {
          user: {
            ...data.user,
          },
        }),
      }));
      return {
        ...state,
        employees: updatedEmployeesReAct,
        status: true,
        loading: false,
      };
    case types.REACTIVATE_EMPLOYEES_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.TOGGLE_B2B_REQUEST:
      return {
        ...state,
        isB2BUser: !state.isB2BUser,
      };
    case types.TOGGLE_REACTIVATION_MODAL_REQUEST:
      return {
        ...state,
        showReactivationModal: action.payload,
      };
    default:
      return state;
  }
};
