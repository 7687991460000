/* eslint-disable */

import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import DragComponent from '../../shared/components/drag/Drag';
import { updateSidebarItem } from '../../api/Api'
import './Drag.scss';
class Drag extends Component {
  static propTypes = {

  };

  state = {
    treeData: []
  };

  componentDidMount() {
    let treeData = JSON.parse(localStorage.getItem('sidebars')).map(s => ({ ...s, title: s.name }));
    this.setState({ treeData });
  }
  capturePositionChange = async (node, orderedIds) => {
    let update = await updateSidebarItem({ parent_id: node.parent_id, id: node.id, orderedIds })
  }
  canDrag = ({ node }) => {
    if (node.is_root && !node.parent_id) {
      return false
    }
    return true
  }
  setTreeData = (treeData) => {
    this.setState({ treeData })
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    return (
      <Container>
        <Row>
          <Col md={6}>
            <h3 className="page-title">Menu Management <i className="fal fa-expand-arrows" /></h3>
          </Col>
          <Col md={6}>
            <Button className="float-right" color="primary" onClick={() => this.handleClickOpen()}>Add New Menu Folder</Button>
          </Col>
        </Row>
        <Paper className="mt-4 p-4" elevation={2}>
          <>
            <Row>
              {this.state.treeData.length > 0 ? (
                <DragComponent
                  data={this.state.treeData}
                  parentKey="parent_id"
                  // onMoveNode={({ node }) => {this.capturePositionChange(node.id);}}
                  capturePositionChange={this.capturePositionChange}
                  canDrag={this.canDrag}
                  setTreeData={this.setTreeData}
                />
              ) : null}
            </Row>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth>
              <DialogTitle id="form-dialog-title">Add New Menu Folder</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Menu Name"
                  name="name"
                  type="text"
                  fullWidth
                  onChange={this.onChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
              </Button>
                <Button onClick={this.handleClose} color="primary">
                  Add
              </Button>
              </DialogActions>
            </Dialog>
          </>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(connect(state => ({
  user: state.users && state.users.user,
  companies: state.companies && state.companies.companies,
  stores: state.stores && state.stores.stores
}))(Drag));

function check(id, arr) {
  var found = arr.some(function (el) {
    return el.id === id;
  });

  if (!found)
    return false
  return true
}
