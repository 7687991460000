import { Grid } from '@material-ui/core';
import React from 'react';
import StepConnector from './StepConnector';

const Stepper = ({
  activeStep,
  children,
  Connector,
  ...props
}) => {
  const ConnectorComponent = Connector || StepConnector;
  const childrenArray = React.Children.toArray(children);
  return (
    <>
      <Grid alignItems="center" container {...props}>
        {childrenArray.map((child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {index !== 0 && (
              <Grid alignItems="center" style={{width: '18px'}} container item>
                <ConnectorComponent active={activeStep >= index} />
              </Grid>
            )}
            <Grid item>
              {child}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
};

export default Stepper;
