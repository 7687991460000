import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  iconRoot: {
    minWidth: '40px',
    justifyContent: 'center',
  },
  item0: {
    // paddingLeft: '5px',
  },
  item16: {
    // paddingLeft: '16px',
  },
  textColor: {
    color: '#FFFFFF',
  },
  selectedBackgroud: {
    backgroundColor: 'hsla(0,0%,100%,.2)',
    borderLeftStyle: 'solid',
    borderLeftColor: 'coral',
    padding: '10px 0',
  },
  defaultBackround: {
    backgroundColor: 'inherit',
    padding: '10px 0',
  },
  expandIcon: {
    color: '#F49900',
  },
  badgeHolder: {
    position: 'absolute',
    zIndex: 999,
    left: 0,
    top: 0,
    cursor: 'pointer',
  },
});

export default useStyles;
