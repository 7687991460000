import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import useStyles from './FaceModalStyles';

const FaceModal = ({
  open, onClose, onSubmit, errorMsg, error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputDevices, setInputDevices] = useState([]);
  const [selectedWebcam, setSelectedWebcam] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const webcamRef = useRef(null);
  const capture = useCallback(
    () => {
      if (
        typeof webcamRef.current !== 'undefined' &&
        webcamRef.current !== null &&
        webcamRef.current.video.readyState === 4
      ) {
        const imageSrc = webcamRef.current.getScreenshot();
        setPreviewImage(imageSrc);
      }
    },
    [webcamRef],
  );

  const handleSubmit = (data) => {
    const pinBody = {
      // uuid: uuidAuth,
      // new_pin: data.newPin,
      faceImage: previewImage.split(',')[1],
      extension: 'jpeg',
      has_unique_face: false,
    };
    onSubmit(pinBody);
    // onClose();
  };

  const handleSelectWebcam = (e) => {
    setSelectedWebcam(e.target.value);
  };
  const clear = (ev) => {
    setPreviewImage('');
  };
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(async (devices) => {
      console.log(devices);
      const inputDevice = await devices.filter(device => device.kind === 'videoinput');
      setInputDevices(inputDevice);
      if (inputDevice && inputDevice.length > 0) {
        setSelectedWebcam(inputDevice[0].deviceId);
      }
    });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{
        className: classes.faceModal,
      }}
      disableBackdropClick
    >
      <Grid container justify="space-between" alignItems="center" className={classes.ModalTopBar}>
        <Grid item xs={11} className={classes.modalHeading} style={{ textAlign: 'center', color: '#004995' }}>
          Verify Face
        </Grid>
        <Grid item xs={1}>
          <div onClick={() => onClose()} style={{ textAlign: 'end' }}>
            <CloseIcon style={{ color: '#004995', cursor: 'pointer' }} />
          </div>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={2}
          style={{
margin: '0', width: '100%', padding: '50px 50px 50px', minWidth: '600px',
}}
        >
          <Grid item xs={6}>

            {previewImage ?
              <Box style={{width: '316px' }}>
                <img src={previewImage} style={{maxWidth: '100%'}} alt="placeholder-profile" />
              </Box>
        :
              <Box style={{width: '316px', maxHeight: '296px', marginTop: '-58px'}}>
                <Webcam
                  audio={false}
                  height={353}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={316}
                  videoConstraints={{
                deviceId: selectedWebcam,
              }}
                  muted
                />
              </Box>
}
          </Grid>
          <Grid item xs={6} style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
            <Box style={{width: '100%'}}>

              <Box>
                <Select
                  name="device"
                  value={selectedWebcam}
                  onChange={handleSelectWebcam}
                  label="Webcam"
                  className={classes.buttonCustomize}
                >
                  {inputDevices && inputDevices.length > 0 && inputDevices.map(type => (
                    <MenuItem key={type.deviceId} value={type.deviceId}>
                      {console.log(type)}{type.label}
                    </MenuItem>
                ))}
                </Select>
              </Box>
              <Box>
                <Button variant="contained" className={classes.buttonCustomize} onClick={capture}>Capture</Button>
              </Box>
              {previewImage && (
              <Box>
                <Button
                  variant="contained"
                  onClick={clear}
                  className={classes.buttonCustomize}
                >
                  Clear
                </Button>
              </Box>
          )}
            </Box>
            <Box style={{position: 'absolute', bottom: '-26px', right: '0'}}>
              <Grid item>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs>

                    {/* <Button
                      fullWidth
                      variant="contained"
                      // onClick={() => selectedMode(MODES.Options)}
                    >
                Back
                    </Button> */}
                  </Grid>
                  <Grid item xs>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="secondary"
                      onClick={handleSubmit}
                      disabled={previewImage === ''}
                    >
                Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {error && errorMsg && <Grid>
          <Grid item xs={12}>
            <Typography color="error" align="center">{errorMsg}</Typography>
          </Grid>
        </Grid>}
      </DialogContent>
    </Dialog>
  );
};

export default FaceModal;
