import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as types from '../../constants/actionTypes';
import { signUpRequest } from '../../api/Api';
/* eslint-disable no-use-before-define */
// Responsible for searching media library, making calls to the API
// and instructing the redux-saga middle ware on the next line of action,
// for success or failure operation.

export default function* watchSignUpListener() {
  yield takeLatest(types.SIGN_UP_REQUEST, signUpRequestSaga);
}

export function* signUpRequestSaga({ payload }) {
  try {
    const res = yield call(signUpRequest, payload);
    yield all([
      put({ type: types.SIGN_UP_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.SIGN_UP_FAILED, error });
  }
}
