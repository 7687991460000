import {useState, useEffect} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getCoordinatesRequestAction, getCoordinatesSuccessAction, getCoordinatesFailedAction } from 'redux/actions/coordinatesAction';

export const getCoordinatesPromise = options => new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(resolve, reject, options);
});

export const useGetCoordinates = (options = {}) => {
  const dispatch = useDispatch();

  const getCoordinates = async () => {
    let res = {};
    try {
      dispatch(getCoordinatesRequestAction());
      const position = await getCoordinatesPromise(options);
      res = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      dispatch(getCoordinatesSuccessAction(res));
    } catch (err) {
      res = {geoLocationErrorCode: err && err.code};
      dispatch(getCoordinatesFailedAction(err));
    }
    return res;
  };

  return getCoordinates;
};
