import { AuthAppConfig } from 'containers/Auth/AuthAppConfig';
import DocumentCenterAppConfig from 'containers/DocumentCenter/DocumentCenterAppConfig';
import EventsAppConfig from 'containers/Events/EventsAppConfig';
import {SalesAppConfig} from 'containers/Sales/SalesAppConfig';
import { TasksAppConfig } from '../Tasks/TasksAppConfig';
import { HrAppConfig } from '../HR/HrAppConfig';
import { ReportingAppConfig } from '../Reporting/ReportingAppConfig';
import { OneViewAppConfig } from '../OneView/OneViewAppConfig';
import { ToolsAppConfig } from '../Tools/ToolsAppConfig';
import { EmbedsAppConfig } from '../Embeds/EmbedsAppConfig';
import { AdminAppConfig } from '../Admin/AdminAppConfig';
import { RegistersAppConfig } from '../Registers/RegistersAppConfig';
import { RmaRequestAppConfig } from '../RmaRequest/RmaRequestAppConfig';
import { AuditAppConfig } from '../Audit/AuditAppConfig';
import { SettingsAppConfig } from '../Settings/SettingsAppConfig';
import { NotificationAppConfig } from '../Notification/NotificationAppConfig';
import { WarehouseInventoryAppConfig } from '../WarehouseInventory/WarehouseInventoryAppConfig';
import {CommunicationCenterAppConfig} from '../CommunicationCenter/CommunicationCenterAppConfig';
import { LmsAppConfig } from '../Lms/LmsAppConfig';

const appsConfigs = [
  AuthAppConfig,
  SalesAppConfig,
  TasksAppConfig,
  HrAppConfig,
  ReportingAppConfig,
  OneViewAppConfig,
  ToolsAppConfig,
  EmbedsAppConfig,
  AdminAppConfig,
  RegistersAppConfig,
  RmaRequestAppConfig,
  AuditAppConfig,
  SettingsAppConfig,
  NotificationAppConfig,
  WarehouseInventoryAppConfig,
  CommunicationCenterAppConfig,
  LmsAppConfig,
  DocumentCenterAppConfig,
  LmsAppConfig,
  EventsAppConfig,
];

export default appsConfigs;
