import * as types from '../../constants/actionTypes';

const initialState = {
  latitude: null,
  longitude: null,
  loading: false,
  error: null,
  geoLocationErrorCode: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COORDINATES_REQUEST:
      return {
        ...state,
        latitude: null,
        longitude: null,
        loading: true,
      };
    case types.GET_COORDINATES_SUCCESS:
      return {
        ...state,
        latitude: (action && action.payload && action.payload.latitude) || null,
        longitude: (action && action.payload && action.payload.longitude) || null,
        error: null,
        geoLocationErrorCode: null,
        loading: false,
      };
    case types.GET_COORDINATES_FAILED:
      return {
        ...state,
        latitude: null,
        longitude: null,
        error: action.payload,
        geoLocationErrorCode: (action && action.payload && action.payload.code) || null,
        loading: false,
      };

    default:
      return state;
  }
};
