/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { withRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';

import { fetchRequiredFieldsAction } from 'redux/actions/requiredFieldsActions';
import { getCarriers } from 'api/Api';

import '../../scss/app.scss';
// import routes from './CentraverseConfig/routesConfig';
import RouteWithSubRoutes from './RouteWithSubRoutes';

const homeScreenComonent = {
  Sales: '/sales-center',
  HR: '/hr/dashboard',
  OneView: '/oneview',
  'Task/Ticket Center': '/ticket-center/dashboard',
  'Communication Center': '/communications/dashboard',
};

const PrivateRouter = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const HomeScreen =
    useSelector(state =>
      state.requiredFields &&
        state.requiredFields.required_fields &&
        state.requiredFields.required_fields[0] &&
        state.requiredFields.required_fields[0].title) || '';

  const HomeScreenLoading =
    useSelector(state =>
      state.requiredFields &&
        state.requiredFields.loading);

  useEffect(() => {
    const { company_id } = JSON.parse(localStorage.getItem('user')); // eslint-disable-line
    // fetch the company sidebars
    dispatch(fetchRequiredFieldsAction({
      params: {
        perPage: 1000,
        'filter[company_id]': company_id,
        'filter[type]': 'Home Screen',
      },
    }));
    if (!localStorage.getItem('carrier_types')) {
      getCarriers({
        params: {
          'filter[company_id]': company_id,
          related: 'carrier',
          perPage: 9999,
        },
      })
        .then((res) => {
          if (res.data && res.data.results.length) {
            const carriers = res.data.results.map(e => e.carrier);
            localStorage.setItem('carrier_types', JSON.stringify(carriers));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const findLoading = (val, key) => val[key] && val[key].loading;

  useEffect(() => {
    if (!HomeScreen || HomeScreen.length) setLoading(false);
    console.log(HomeScreen);
  }, [HomeScreen]);

  return (
    <>
      {loading || HomeScreenLoading ? (
        <div className="loading-overlay">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div>Loading...</div>
        </div>
      ) : (
        <>
          {/* {renderRoutes(routes)} */}
          <Switch>
            <Route exact path="/" render={() => <Redirect to={HomeScreenLoading ? '/' : !HomeScreen ? '/oneview' : homeScreenComonent[HomeScreen]} />} />
            {props.routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
          {/* <Router /> */}
          {Object.keys(props).find(prop => (prop.includes('App')
            ? Object.keys(props[prop]).find(nestedProp => findLoading(props[prop], nestedProp))
            : findLoading(props, prop))) && (
              <div className="loading-overlay">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
                <div>Loading...</div>
              </div>
          )}
        </>
      )}
    </>
  );
};
export default withRouter(connect(state => state)(PrivateRouter));
