import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addSupportTicketType, deleteSupportTicketType, getSupportTicketTypeById, getSupportTicketTypes, updateSupportTicketType} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_SUPPORT_TICKET_TYPES_REQUEST, fetchSupportTicketTypesSaga);
  yield takeLatest(types.FETCH_SUPPORT_TICKET_TYPE_REQUEST, fetchSupportTicketTypeSaga);
  yield takeLatest(types.ADD_SUPPORT_TICKET_TYPE_REQUEST, addSupportTicketTypeSaga, context);
  yield takeLatest(types.UPDATE_SUPPORT_TICKET_TYPE_REQUEST, updateSupportTicketTypeSaga, context);
  yield takeLatest(types.DELETE_SUPPORT_TICKET_TYPE_REQUEST, deleteSupportTicketTypeSaga);
}

export function* fetchSupportTicketTypesSaga({ payload }) {
  try {
    const res = yield call(getSupportTicketTypes, payload);
    yield all([
      put({ type: types.FETCH_SUPPORT_TICKET_TYPES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SUPPORT_TICKET_TYPES_FAILED, error });
  }
}

export function* fetchSupportTicketTypeSaga({ payload }) {
  try {
    const res = yield call(getSupportTicketTypeById, payload);
    yield all([
      put({ type: types.FETCH_SUPPORT_TICKET_TYPE_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SUPPORT_TICKET_TYPE_FAILED, error });
  }
}

export function* addSupportTicketTypeSaga({ history }, { payload }) {
  try {
    const res = yield call(addSupportTicketType, payload);
    yield all([
      put({ type: types.ADD_SUPPORT_TICKET_TYPE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SupportTicketType added' : res.message || 'SupportTicketType not added',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/supporttickettypes');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_SUPPORT_TICKET_TYPE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateSupportTicketTypeSaga({ history }, { payload }) {
  try {
    const res = yield call(updateSupportTicketType, payload);
    yield all([
      put({ type: types.UPDATE_SUPPORT_TICKET_TYPE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SupportTicketType updated' : res.message || 'SupportTicketType not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/supporttickettypes');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_SUPPORT_TICKET_TYPE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteSupportTicketTypeSaga({ payload }) {
  try {
    const res = yield call(deleteSupportTicketType, payload);
    yield all([
      put({ type: types.DELETE_SUPPORT_TICKET_TYPE_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SupportTicketType deleted' : res.message || 'SupportTicketType not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_SUPPORT_TICKET_TYPE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
