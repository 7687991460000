import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NotificationSystem from 'rc-notification';
import SupportTicket from 'containers/Tools/SupportTicket/index';
import { showSupportTicketAction } from 'redux/actions/SupportTicketAction';
import { clearNotificationAction } from 'redux/actions/notificationActions';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import { BasicNotification } from '../../shared/components/Notification';

import './style.scss';

let notificationR = null;
const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
class MainWrapper extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    children: PropTypes.element.isRequired,
    showModal: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired, // eslint-disable-line
  };

  componentDidMount() {
    NotificationSystem.newInstance({
      duration: 10,
    }, n => notificationR = n); // eslint-disable-line
  }

  componentDidUpdate(prevProps) {
    if (this.props.notification.message && (this.props.notification !== prevProps.notification)) {
      this.handleNotification(this.props.notification);
    }
  }

  componentWillUnmount() {
    notificationR.destroy();
  }

  showNotification = ({ notification }) => {
    notificationR.notice({
      content: notification,
      duration: 4,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 160%)', minWidth: 400 },
      className: 'left-up d-print-none',
    });
  }

  handleNotification = (notification) => {
    let color;
    if (notification.success === 'warning') {
      color = 'warning';
    } else if (notification.success === true) {
      color = 'success';
    } else if (notification.success === false) {
      color = 'danger';
    }
    // console.log('ClearNotification');
    // setTimeout(() => this.props.dispatch(clearNotificationAction()), 5000);
    this.props.dispatch(clearNotificationAction());
    this.showNotification({
      notification: <BasicNotification
        color={color}
        title={notification.title ? notification.title : 'Message'}
        message={notification.message}
      />,
    });
  }
  showSupportModal = () => {
    this.props.dispatch(showSupportTicketAction({}));
  }
  // classes = useStyles();
  render() {
    const { theme } = this.props;
    const user = localStorage.getItem('user');
    return (
      <div className={theme.className}>
        <div className="wrapper">
          {this.props.children}
        </div>
        {user ? (
          <div className="support-button">
            <Button variant="contained" color="primary" onClick={this.showSupportModal}>Get Support</Button>
          </div>
        ) : null}
        {
          this.props.showModal &&
          <SupportTicket />
        }
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
  notification: state.notification,
  showModal: (state.support_tickets && state.support_tickets.showModal) || false,
}))(MainWrapper);
