import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  addEmbedsAndLink, deleteEmbedsAndLink, getEmbedsAndLinkBySlug, getEmbedsAndLinks, updateEmbedsAndLink,
} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_EMBEDS_AND_LINKS_REQUEST, fetchEmbedsAndLinksSaga);
  yield takeLatest(types.FETCH_EMBEDS_AND_LINK_REQUEST, fetchEmbedsAndLinkSaga);
  yield takeLatest(types.ADD_EMBEDS_AND_LINK_REQUEST, addEmbedsAndLinkSaga, context);
  yield takeLatest(types.UPDATE_EMBEDS_AND_LINK_REQUEST, updateEmbedsAndLinkSaga, context);
  yield takeLatest(types.DELETE_EMBEDS_AND_LINK_REQUEST, deleteEmbedsAndLinkSaga);
}

export function* fetchEmbedsAndLinksSaga({ payload }) {
  try {
    const res = yield call(getEmbedsAndLinks, payload);
    yield all([
      put({ type: types.FETCH_EMBEDS_AND_LINKS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_EMBEDS_AND_LINKS_FAILED, error });
  }
}

export function* fetchEmbedsAndLinkSaga({ payload }) {
  try {
    const res = yield call(getEmbedsAndLinkBySlug, payload);
    yield all([
      put({ type: types.FETCH_EMBEDS_AND_LINK_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_EMBEDS_AND_LINK_FAILED, error });
  }
}

export function* addEmbedsAndLinkSaga({ history }, { payload }) {
  try {
    const res = yield call(addEmbedsAndLink, payload);
    yield all([
      put({ type: types.ADD_EMBEDS_AND_LINK_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'EmbedsAndLink added' : res.message || 'EmbedsAndLink not added',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/admin/embedded-links');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_EMBEDS_AND_LINK_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateEmbedsAndLinkSaga({ history }, { payload }) {
  try {
    const res = yield call(updateEmbedsAndLink, payload);
    yield all([
      put({ type: types.UPDATE_EMBEDS_AND_LINK_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'EmbedsAndLink updated' : res.message || 'EmbedsAndLink not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/admin/embedded-links');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_EMBEDS_AND_LINK_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteEmbedsAndLinkSaga({ payload }) {
  try {
    const res = yield call(deleteEmbedsAndLink, payload);
    yield all([
      put({ type: types.DELETE_EMBEDS_AND_LINK_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'EmbedsAndLink deleted' : res.message || 'EmbedsAndLink not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_EMBEDS_AND_LINK_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
