import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useGetCoordinates } from 'lib/useGetCoordinates';
import FaceModal from 'shared/components/FaceModal';
import moment from 'moment';
import SideDrawer from './sidebar/SideDrawer';
import TopAppBar from './topbar/TopAppBar';
import DiagBox from './HrDiag/DiagBox';
import AutoClockoutCardsPopup from './HrDiag/AutoClockoutCards';
import hexToHsl from '../../lib/changeColorFormat';

import { fetchSidebarItemsAction } from '../../redux/actions/sidebarItemAction';
import { updateTimecardBreakAction, setTimecardBreakFaceAuthPendingAction, setTimecardBreakFaceAuthErrorAction } from '../../redux/actions/hrTimecardBreakAction';
import { addTimecardForClockinAction, updateTimecardForClockoutAction, activeSessionAction, setClockinUserLoading, setWebClockInFaceAuthPendingAction, setWebClockInFaceAuthErrorAction } from '../../redux/actions/hrWebClockinAction';
import GeneralModal from '../../shared/components/Mui/GeneralModal';
import logout from '../../assets/images/logout.svg';
import { getActiveSession } from '../../api/Api';
import { parentWithRoute, parentWithoutRoute, setDefaultTopBar, setSettings, setSelectedTopBar, filterPermission } from './Utils/layoutUtils';
import Timer from './timer/Timer';
import BreakDiagBox from './BreakDialog/BreakDiagBox';
import { timecardActions } from '../../constants/default';

const drawerWidth = 260;

const useStyles = drawerOpen => makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px',
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: drawerOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${theme.spacing(9) + 1}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },
}));

const imageUrl = null;

export default function Layout(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const onClockInUserLoading = (data) => {
    dispatch(setClockinUserLoading(data));
  };
  // Drawer Open close
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [topbarHeight, setTopbarHeight] = React.useState(0);
  const classes = useStyles(open)();

  // sidebar permissions
  const [permissions, setPermissions] = useState({});

  // sidebar list
  const sidebarItems = useSelector(state => state.sidebar_items && state.sidebar_items.sidebar_items) || [];
  // let val=(sidebarItems.map((item) => (item.name.includes('HR'))))
  const faceAuthPending = useSelector(state => state.webclocks && state.webclocks.faceAuthPending);
  const faceAuthError = useSelector(state => state.webclocks && state.webclocks.faceAuthError);
  const faceAuthErrorMsg = useSelector(state => state.webclocks && state.webclocks.faceAuthErrorMsg);

  const found = sidebarItems.some(el => el.name === 'HR' && (!el.permissions || permissions[el.permissions.title]));
  const [currentTopbar, setCurrentTopbar] = useState('');
  const [openDiagBox, setOpenDiagBox] = useState(!JSON.parse(localStorage.getItem('showAutoClockoutTimeCards')));

  // filtered menu list for sidebar based on topbar selection
  const [filteredMenuItemSideBar, setFilteredMenuItemSideBar] = useState([]);
  const [currentSidebar, setCurrentSidebar] = useState('');
  const [showClockOutModal, setShowClockOutAlertModal] = useState(false);
  const getCoordinates = useGetCoordinates();
  const user = JSON.parse(localStorage.getItem('user'));
  const storeId = JSON.parse(localStorage.getItem('store_id'));
  const clockin = JSON.parse(localStorage.getItem('clock_in'));
  const showAutoClockoutTimeCards = JSON.parse(localStorage.getItem('showAutoClockoutTimeCards'));
  const activeSession = useSelector(state => state.webclocks && state.webclocks.activeSession) || {};
  const scheduleId = JSON.parse(localStorage.getItem('scheduleId'));
  const popupShow3 = JSON.parse(localStorage.getItem('breakPopUpShow')) || {};
  const [popupShow, setPopupShow] = useState(JSON.parse(localStorage.getItem('breakPopUpShow')) || {});
  const themeColor = localStorage.getItem('theme_color');
  const [faceAuthAction, setFaceAuthAction] = React.useState('');
  const [hue, setHue] = React.useState('');
  const [saturation, setSaturation] = React.useState('');
  const [luminance, setLuminance] = React.useState('');

  console.log('popupShow', popupShow);

  useEffect(() => {
    const {h, s, l} = hexToHsl(themeColor);
    if (h && s && l) {
      setHue(h);
      setSaturation(s);
      setLuminance(l);
    } else {
      setHue(211);
      setSaturation(100);
      setLuminance(29);
    }
  }, [themeColor]);
  // clockin state

  const findActiveSession = async () => {
    const userActiveSession = await getActiveSession({
      params: {
        related: 'store, breaks',
      },
    });
    if ((userActiveSession.data && userActiveSession.data.activeSession && userActiveSession.data.activeSession.length) && clockin) {
      if (userActiveSession.data.activeSession[0].store.id !== Number(storeId)) {
        dispatch(activeSessionAction({ activeSession: userActiveSession.data.activeSession[0] }));
        setTimeout(() => {
          setShowClockOutAlertModal(true);
        }, 500);
      }
    }
  };

  useEffect(async () => {
    const { company_id: companyId } = JSON.parse(localStorage.getItem('user'));
    // fetch the company sidebars
    dispatch(fetchSidebarItemsAction({
      params: {
        perPage: 1000,
        include_user_reports: true,
        // 'filterRelated[companies.id.=]': companyId,
        'relationFilter[companySidebar.company_id.=]': companyId,
        'relationFilter[companySidebar.is_deleted.=]': false,
        related: 'permissions,embedsAndLinks,powerbi_report',
      },
    }));
    onClockInUserLoading(true);
    await findActiveSession();
    onClockInUserLoading(false);
  }, []);


  useEffect(() => {
    const getPermissions = JSON.parse(localStorage.getItem('permissions'));
    setPermissions(getPermissions);
  }, []);

  useEffect(() => {
    // const popupShow2 =  JSON.parse(localStorage.getItem('breakPopUpShow')) || {};
    if (popupShow3.breakPopUpShowSchedule && Number(popupShow3.breakPopUpShowSchedule)) {
      localStorage.setItem('breakPopUpShowScheduleInitTime', moment().format());
      const breakPopUpShowScheduleTimeOut = setTimeout(() => {
        localStorage.setItem('breakPopUpShow', JSON.stringify({...popupShow3, breakPopUpShow: true, breakPopUpShowSchedule: undefined}));
        setPopupShow({...popupShow3, breakPopUpShow: true, breakPopUpShowSchedule: undefined});
      }, popupShow3.breakPopUpShowSchedule * 1000);
      return () => {
        clearTimeout(breakPopUpShowScheduleTimeOut);
        const popupShow1 = JSON.parse(localStorage.getItem('breakPopUpShow')) || {};
        const breakPopUpShowScheduleInitTime = localStorage.getItem('breakPopUpShowScheduleInitTime');
        const diffSeconds = moment().diff(breakPopUpShowScheduleInitTime, 'seconds');
        if (diffSeconds > 0) {
          const breakPopUpShowSchedule1 = popupShow1.breakPopUpShowSchedule - diffSeconds;
          localStorage.setItem('breakPopUpShow', JSON.stringify({...popupShow1, breakPopUpShowSchedule: breakPopUpShowSchedule1}));
        }
      };
    }
    return () => {};
  }, [popupShow3]);
  const localStorageUpdated = (e) => {
    console.log('Event', e);
    if (e.key === 'breakPopUpShow') {
      setPopupShow(JSON.parse(localStorage.getItem('breakPopUpShow')));
    }
  };
  useEffect(() => {
    console.debug();
    console.log('Hey');
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', localStorageUpdated);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('storage', localStorageUpdated);
      }
    };
  }, []);

  const HomeScreen = useSelector(state =>
    state.requiredFields
    && state.requiredFields.required_fields
    && state.requiredFields.required_fields[0]
    && state.requiredFields.required_fields[0].title) || '';

  const formattedData = filterPermission(sidebarItems, permissions) || [];
  const directParentRoutes = parentWithRoute(formattedData) || [];
  const childRoutes = parentWithoutRoute(formattedData) || [];
  const sct = setDefaultTopBar(formattedData, HomeScreen) || '';
  const filterdSettings = setSettings(formattedData) || [];

  useEffect(() => {
    if (currentTopbar === '') {
      if (sct && sct[0] && sct[0].name) {
        const name = HomeScreen || (sct && sct[0] && sct[0].name);
        setCurrentTopbar(name);
        setCurrentSidebar(name);
      }
    }
    if (!filteredMenuItemSideBar.length) {
      if (sct && sct[0] && sct[0].name) {
        const name = sct && sct[0] && sct[0].name;
        const menuItem = childRoutes && childRoutes.filter(childRoute => childRoute.name === name);
        setFilteredMenuItemSideBar(menuItem);
      }
    }
  });
  const setTopbarAndSidebarOptions = async (currentTopMenu) => {
    await setCurrentTopbar(currentTopMenu);
    // setCurrentSidebar(currentTopMenu);
    const filteredChildRoute = await setSelectedTopBar(childRoutes, currentTopMenu);
    await setFilteredMenuItemSideBar(filteredChildRoute);
    handleDrawerOpen();
  };

  const handleClose = () => {
    setShowClockOutAlertModal(false);
    localStorage.setItem('clock_in', false);
  };

  const handleClockOut = async (data) => {
    const coordinates = await getCoordinates();
    localStorage.setItem('clock_in', false);
    setFaceAuthAction(timecardActions.CLOCK_OUT_CLOCK_IN);
    if (activeSession.breaks && activeSession.breaks.length) {
      activeSession.breaks.forEach((b) => {
        if (!b.break_end) {
          dispatch(updateTimecardBreakAction({
            ...data,
            id: Number(b.id),
            user_id: Number(user.id),
            imageUrl,
            ...coordinates,
            user_comment: null,
          }));
        }
      });
    }
    dispatch(updateTimecardForClockoutAction({
      ...data,
      id: Number(activeSession.id),
      user_id: Number(user.id),
      imageUrl,
      ...coordinates,
      user_comment: null,
    }));
    setTimeout(() => {
      dispatch(addTimecardForClockinAction({
        ...data,
        schedule_id: scheduleId !== 0 ? scheduleId : undefined,
        department_role_id: null,
        user_id: Number(user.id),
        store_id: Number(storeId),
        imageUrl,
        ...coordinates,
        user_comment: null,
      }));
    }, 500);
  };

  const clockOutModalAction = async (stat) => {
    if (stat === 'Yes') {
      await handleClockOut();
    }
  };

  const handleFaceSubmit = (faceImage) => {
    console.log('Submitted');
    console.log(faceImage);
    switch (faceAuthAction) {
      case timecardActions.CLOCK_OUT_CLOCK_IN:
        handleClockOut({ ...faceImage});
        break;
      default:
        break;
    }
  };

  const handleFaceModalClose = () => {
    dispatch(setWebClockInFaceAuthPendingAction(false));
    dispatch(setWebClockInFaceAuthErrorAction(false));
    dispatch(setTimecardBreakFaceAuthPendingAction(false));
    dispatch(setTimecardBreakFaceAuthErrorAction(false));
  };

  return (
    <div className={classes.root}>

      {(showAutoClockoutTimeCards && found) && <AutoClockoutCardsPopup setOpenDiagBox={setOpenDiagBox} />}
      {(openDiagBox && found && clockin && !Object.keys(activeSession).length) && <DiagBox />}
      {openDiagBox && found && popupShow.breakPopUpShow && <BreakDiagBox />}

      <GeneralModal
        closeModal={() => handleClose()}
        open={showClockOutModal}
        message={`You clocked-in at ${(activeSession && activeSession.store && activeSession.store.name) ? activeSession.store.name : 'some other'} store do you want to clock-out there?`}
        cancelButtonText="No"
        okButtonText="Yes"
        actionStatus={clockOutModalAction}
        okButtonColor="#939FBF"
        cancelButtonColor="#3AA50B"
        imageSrc={logout}
      />
      <CssBaseline />
      <TopAppBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        childRoutes={childRoutes}
        setCurrentTopbar={setTopbarAndSidebarOptions}
        currentTopbar={currentTopbar}
        Sh={setTopbarHeight}
        hue={hue}
        saturation={saturation}
        luminance={luminance}
      />
      <SideDrawer
        drawerWidth={drawerWidth}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        filteredMenuItem={filteredMenuItemSideBar}
        directParentRoutes={directParentRoutes}
        filterdSettings={filterdSettings}
        formattedData={formattedData}
        currentTopbar={currentTopbar}
        setCurrentTopbar={setTopbarAndSidebarOptions}
        setCurrentSidebar={setCurrentSidebar}
        currentSidebar={currentSidebar}
        h={topbarHeight}
        hue={hue}
        saturation={saturation}
        luminance={luminance}
      />
      <main className={classes.content}>
        <div className={`${classes.toolbar} d-print-none`} />
        {props.children}
      </main>
      <div className="d-print-none">
        <Timer />
      </div>
      {faceAuthPending && faceAuthAction &&
      <FaceModal
        open={faceAuthPending}
        onClose={handleFaceModalClose}
        onSubmit={handleFaceSubmit}
        error={faceAuthError}
        errorMsg={faceAuthErrorMsg}
      />
      }
    </div>
  );
}
