import * as types from '../../constants/actionTypes';

export const fetchUserStoresAction = payload => ({
  type: types.FETCH_USERSTORES_REQUEST,
  payload,
});

export const fetchUserStoreAction = payload => ({
  type: types.FETCH_USERSTORE_REQUEST,
  payload,
});

export const fetchUserStorePrimaryByStoreIDAction = payload => ({
  type: types.FETCH_USERSTORE_PRIMARY_BY_STOREID_REQUEST,
  payload,
});

export const fetchUserStoreActionStore = payload => ({
  type: types.FETCH_USERSTORE_STOREID_REQUEST,
  payload,
});

export const addUserStoreAction = payload => ({
  type: types.ADD_USERSTORE_REQUEST,
  payload,
});

export const updateUserStoreAction = payload => ({
  type: types.UPDATE_USERSTORE_REQUEST,
  payload,
});


export const deleteUserStoreAction = payload => ({
  type: types.DELETE_USERSTORE_REQUEST,
  payload,
});
