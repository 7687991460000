import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  category: {},
  categories: [],
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_POS_CATEGORIES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          categories: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        categories: (action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_POS_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: [],
        loading: true,
      };
    case types.FETCH_POS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_POS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_POS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_POS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_POS_CATEGORIES_FAILED:
      return {
        ...state,
        categories: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_POS_CATEGORY_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          category: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        category: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_POS_CATEGORY_FAILED:
      return {
        ...state,
        category: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_POS_CATEGORY_SUCCESS:
      return {
        ...state,
        // categories: [...state.categories, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_POS_CATEGORY_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_POS_CATEGORY_SUCCESS:
      let categories = filter(state.categories, item => item.id !== action.res.data.id);

      return {
        ...state,
        // categories: [...categories, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_POS_CATEGORY_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_POS_CATEGORY_SUCCESS:
      categories = filter(state.categories, item => item.id !== action.payload.id);

      return {
        ...state,
        categories: [...categories],
        status: true,
        loading: false,
      };
    case types.DELETE_POS_CATEGORY_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.FETCH_POS_CATEGORY_COUNTS_SUCCESS:
      return {
        ...state,
        theCount: action.res.data.count,
        status: action.res.success,
        loading: false,
      };

    default:
      return state;
  }
};
