import React from 'react';
import { Route } from 'react-router-dom';
import RequireNoAuth from 'common/RequireNoAuth';

export default function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      {...(route.exact && {exact: route.exact})}
      {...(route.strict && {strict: route.strict})}
      {...(route.sensitive && {sensitive: route.sensitive})}
      render={(props) => {
        const Comp1 = RequireNoAuth(route.component);
        return (route.auth
        ? <route.component {...props} />
        : <Comp1 {...props} />);
      }
    }
    />
  );
}
