import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    padding: '4px',
  },
  topGrid: {
    marginBottom: '10px',
  },
  topHeading: {
    fontSize: '20px',
    fontWeight: 600,
  },
  addButton: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#4C98D3',
  },
  exportButton: {
    textTransform: 'none',
    width: '128px',
    marginLeft: '5px',
    position: 'relative',
  },
  Typo: {
    fontSize: 12,
    fontWeight: 400,
  },
  storeSelectFiled: {
    width: '234px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '370px',
    },
    height: '40px',
    backgroundColor: 'white',
    border: '1px solid #D2E2EA',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    justifyContent: 'space-between',
  },
  navButtons: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '25px',
    backgroundColor: 'white',
    border: '1px solid #D2E2EA',
    borderRadius: '4px',
    padding: '10px',
    margin: '10px',
    cursor: 'pointer',
  },
  startGridAligner: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  centerGridAligner: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  arrowButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  darkTypo: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#13273C',
  },
  endGridAligner: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  viewFilterSelectFiled: {
    width: '171px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '180px',
    },
    height: '40px',
    backgroundColor: 'white',
    border: '1px solid #D2E2EA',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'none',
    justifyContent: 'space-between',
    position: 'relative',
  },
  viewBox: {
    width: '327px',
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '2px 3px #0551A31A',
    marginTop: '5px',
    zIndex: 5,
  },
  boxEleStyle: {
    padding: '10px',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ececec',
      color: '#004995',
    },
  },
  filterBox: {
    width: '171px',
    height: '165px',
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '2px 3px #0551A31A',
    marginTop: '5px',
    zIndex: 10,
  },
  exportBox: {
    width: '127px',
    height: '80px',
    position: 'absolute',
    backgroundColor: 'white',
    boxShadow: '2px 3px #0551A31A',
    marginTop: '5px',
    zIndex: 3,
  },
  timeCardModal: {
    borderRadius: '20px',
    width: '535px',
    [theme.breakpoints.down('xs')]: {
      height: '580px',
      width: '400px',
      overflow: 'scroll',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  tcModalBreakAlign: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  tcModalTopBar: {
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    padding: '20px',
    backgroundColor: '#F4F6F7',
  },
  modalBodyWrapper: {
    padding: '20px',
  },
  modalSmallTxtBox: {
    height: '40px',
  },
  modalDatePickerBox: {
    height: '42px !important',
    fontSize: '14px !important',
    width: '100%',
    border: '1px solid #c5c5c5',
    borderRadius: '3px',
    paddingLeft: '11px',
  },
  dateSmallTxtBox: {
    height: '40px',
    width: '150px',
    border: '1px solid #c5c5c5',
    padding: '10px',
  },
  modalLargeTxtBox: {
    height: '40px',
  },
  modalSaveButton: {
    backgroundColor: '#3AA50B',
    color: 'white',
    textTransform: 'none',
  },
  tableRoot: {
    marginTop: '13px',
    [theme.breakpoints.up('xs')]: {
      minHeight: 390,
      maxHeight: 390,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 800,
      maxHeight: 800,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 564,
      maxHeight: 564,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 580,
      maxHeight: 580,
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: 680,
      maxHeight: 680,
    },
    overflowX: 'auto',
    ...theme.overflowScrollBar,
  },
  empProfile: {
    // objectFit: 'cover',
    // backgroundColor: 'aliceblue',
    // borderRadius: '50%',
    // height: '43px',
    // width: '43px',
    marginRight: '10px',
  },
  expandButton: {
    margin: '2px 20px',
    padding: 8,
    backgroundColor: '#009ACA',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#009ACA' },
  },
  editButton: {
    margin: '2px 2px',
    padding: 5,
    backgroundColor: '#009ACA',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#009ACA' },
  },
  deleteButton: {
    margin: '2px 2px',
    padding: 5,
    backgroundColor: '#DE3333',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#DE3333' },
  },
  logButton: {
    margin: '2px 2px',
    padding: 5,
    backgroundColor: '#687390',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#687390' },
  },
  msgIcon: {
    backgroundColor: '#004995',
    padding: '5px',
    borderRadius: '50%',
    color: '#FFFFFF',
  },
  listContainer: {
    maxHeight: 300,
    overflowY: 'auto',
    padding: '4px 10px 4px 0px',
    position: 'relative',
    WebkitOverflowScrolling: 'touch',
    boxSizing: 'border-box',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(232, 232, 232, .35)',
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      outline: 0,
      backgroundColor: '#D2E2EA',
      borderRadius: 4,
    },
  },
  checkBoxTypo: {
    margin: 0,
    width: '100%',
    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  activeRow: {
    // borderLeft: `4px solid ${theme.palette.secondary.main}`,
    // borderRadius: 4,
    // backgroundColor: '#F4F6F7',
    '& td': {
      backgroundColor: theme.palette.background.default,
    },
    '& td:first-child': {
      position: 'relative',
      '&::before': {
        'border-radius': 78,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 4,
        content: '""',
        width: '5px',
        height: 'calc(100% - 1px)',
        position: 'absolute',
        top: '0px',
        left: '0px',
      },
    },
  },
  trendingCell: {
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  viewButton: {
    margin: '2px 3px',
    padding: 5,
    backgroundColor: '#B4D302',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#B4D302' },
  },
  employeeName: {
    fontSize: '18px',
    fontWeight: 500,
  },
  timeCardLogModal: {
    borderRadius: '20px',
    minWidth: '1250px',
    maxWidth: '1250px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '650px',
      maxWidth: '650px',
      overflow: 'scroll',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '300px',
      maxWidth: '300px',
      overflow: 'scroll',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  logTable: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '1200px',
    },
  },
  logTableHead: {
    borderBottomStyle: 'none',
    fontSize: '14px',
    color: '#0551A3',
    backgroundColor: 'white',
    padding: '8px 5px',
    fontWeight: 700,
  },
  logTableCell: {
    backgroundColor: '#F4F6F7',
    padding: '12px 6px',
    borderBottom: '6px solid #fff',
    fontSize: '14px',
    fontWeight: 500,
    color: '#13273C',
  },
  avatarCell: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textRed: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
