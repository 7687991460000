import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addRegion, deleteRegion, getRegionById, getRegions, getRegionsSearch, updateRegion, activateRegion} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_REGIONS_REQUEST, fetchRegionsSaga);
  yield takeLatest(types.FETCH_REGIONS_SEARCH_REQUEST, fetchRegionsSearchSaga);
  yield takeLatest(types.FETCH_REGION_REQUEST, fetchRegionSaga);
  yield takeLatest(types.ADD_REGION_REQUEST, addRegionSaga, context);
  yield takeLatest(types.UPDATE_REGION_REQUEST, updateRegionSaga, context);
  yield takeLatest(types.DELETE_REGION_REQUEST, deleteRegionSaga);
  yield takeLatest(types.ACTIVATE_REGION_REQUEST, activateRegionSaga);
}

export function* fetchRegionsSaga({ payload }) {
  try {
    const res = yield call(getRegions, payload);
    yield all([
      put({ type: types.FETCH_REGIONS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_REGIONS_FAILED, error });
  }
}
export function* fetchRegionsSearchSaga({ payload }) {
  try {
    const res = yield call(getRegionsSearch, payload);
    yield all([
      put({ type: types.FETCH_REGIONS_SEARCH_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_REGIONS_SEARCH_FAILED, error });
  }
}
export function* fetchRegionSaga({ payload }) {
  try {
    const res = yield call(getRegionById, payload);
    yield all([
      put({ type: types.FETCH_REGION_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_REGION_FAILED, error });
  }
}

export function* addRegionSaga({ history }, { payload }) {
  try {
    const res = yield call(addRegion, payload);
    yield all([
      put({ type: types.ADD_REGION_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Region created' : res.message || 'Region not created',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/admin/regions');
    // }
  } catch (error) {
    yield all([
      put({ type: types.ADD_REGION_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateRegionSaga({ history }, { payload }) {
  try {
    const res = yield call(updateRegion, payload);
    yield all([
      put({ type: types.UPDATE_REGION_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Region updated' : res.message || 'Region not updated',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/admin/regions');
    // }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_REGION_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteRegionSaga({ payload }) {
  try {
    const res = yield call(deleteRegion, payload);
    yield all([
      put({ type: types.DELETE_REGION_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Region deleted' : res.message || 'Region not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_REGION_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* activateRegionSaga({ payload }) {
  try {
    const res = yield call(activateRegion, payload);
    yield all([
      put({ type: types.ACTIVATE_REGION_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Region activated' : res.message || 'Region not activated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.ACTIVATE_REGION_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
