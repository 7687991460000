import React, { useEffect, useState } from 'react';
import {Redirect} from 'react-router-dom';
import {CentraverseUtils} from 'centraverse'; //eslint-disable-line
import appsConfigs from 'containers/App/appConfigs';

import Drag from 'containers/Drag';
import Unauthorized from 'containers/Unauthorized';

const routeConfigs = [
  ...appsConfigs,
];

const routes = [
  ...CentraverseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/drag',
    exact: true,
    auth: true,
    component: () => import('containers/Drag'),
  },
  {
    path: '/unauth',
    exact: true,
    auth: true,
    component: () => import('containers/Unauthorized'),
  },
  // {
  //   path: '/404',
  //   exact: true,
  //   component: () => <h1 className="text-center">Page Not Found</h1>,
  // },
  // {
  //   component: () => <Redirect to="/404" />,
  // },
];

export default routes;
