import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable */

const initialState = {
  embeds_and_link: {},
  embeds_and_links: [],
  total: 0,
  loading: false,
  error: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.FETCH_EMBEDS_AND_LINKS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          embeds_and_links: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        embeds_and_links: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_EMBEDS_AND_LINKS_REQUEST:
      return {
        ...state,
        embeds_and_links: [],
        loading: true,
      };
    case types.FETCH_EMBEDS_AND_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_EMBEDS_AND_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_EMBEDS_AND_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_EMBEDS_AND_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_EMBEDS_AND_LINKS_FAILED:
      return {
        ...state,
        embeds_and_links: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_EMBEDS_AND_LINK_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          embeds_and_link: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        embeds_and_link: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_EMBEDS_AND_LINK_FAILED:
      return {
        ...state,
        embeds_and_link: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_EMBEDS_AND_LINK_SUCCESS:
      return {
        ...state,
        // embeds_and_links: [...state.embeds_and_links, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_EMBEDS_AND_LINK_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_EMBEDS_AND_LINK_SUCCESS:
      let embeds_and_links = filter(state.embeds_and_links, item => item.id !== action.res.data.id);
      return {
        ...state,
        embeds_and_link: action.res.data || {},
        embeds_and_links: [...embeds_and_links, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_EMBEDS_AND_LINK_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_EMBEDS_AND_LINK_SUCCESS:
      embeds_and_links = filter(state.embeds_and_links, item => item.id !== action.payload.id);

      return {
        ...state,
        embeds_and_links: [...embeds_and_links],
        status: true,
        loading: false,
      };
    case types.DELETE_EMBEDS_AND_LINK_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.CLEAR_EMBEDS_AND_LINK_FORM: {
      return {
        ...state,
        embeds_and_link: {},
      };
    }
    default:
      return state;
  }
}
