import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import ClearCache from 'react-clear-cache';

/* eslint-disable */

function getColorByValue(value) {
  let color = '';
  switch (true) {
    case (value <= 20 && value >= 0):
      color = '#da2020';
      break;
    case (value <= 40 && value > 20):
      color = 'rgba(237,32,32,0.65)';
      break;
    case (value <= 60 && value > 40):
      color = '#ed9820';
      break;
    case (value <= 80 && value > 60):
      color = 'rgba(5,81,163,0.65)';
      break;
    case (value <= 100 && value > 80):
      color = '#0551A4';
      break;
    default:
      break;
  }
  return color;
}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#4d637b',
    color: '#FFFFFF',
  },
}))(Tooltip);

const styles = theme => ({
  logoutStatus: {
    bottom: 20,
    right: 20,
    position: 'fixed',
    cursor: 'pointer',
    zIndex: 9999,
  },
});

const useStyles = makeStyles(theme => ({
  circular: {
    height: '55px !important',
    width: '55px !important',
    color: props => props.statusColor || '#0551A3',
  },
  circle: { strokeLinecap: 'round' },
  logoutBtn: {
    height: 40,
    width: 40,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: props => props.statusColor || '#0551A3',
      boxShadow: 'none',
      '& $logoutIcon': { color: '#FFFFFF' },
    },
  },
  logoutIcon: { color: props => props.statusColor || '#0551A3' },
}));

function CircularProgressWithButton(props) {
  const statusColor = getColorByValue(props.value);
  const classes = useStyles({ statusColor });
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" className={classes.circular} value={props.value} classes={{ circle: classes.circle }} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LightTooltip title={props.tooltipText} placement="top">
          <Fab onClick={props.onClick} className={classes.logoutBtn} color="primary" aria-label="logout">
            <LockIcon className={classes.logoutIcon} />
          </Fab>
        </LightTooltip>
      </Box>
    </Box>
  );
}

class Timer extends Component {
  constructor() {
    super();
    this.idleTimer = null;
    this.timeout = 3000;
    this.state = { remaining: this.timeout, timeout_limit: Number(localStorage.getItem('timeout_limit') || 10) };
    this.updateLockStatus = this.updateLockStatus.bind(this);
  }

  updateLockStatus(e) {
    if (e.key === 'resetRemaining') {
      this.idleTimer && this.idleTimer.reset();
    }
    if (e.key === 'locked' && JSON.parse(e.newValue)) {
      window.location.href = '/locked';
    }
  }

  componentDidMount() {
    if (!window.location.pathname.includes('login')) {
      this.setState({ remaining: this.idleTimer && this.idleTimer.getRemainingTime() });
      this.interval = setInterval(() => {
        this.setState({ remaining: this.idleTimer && this.idleTimer.getRemainingTime() });
      }, 1000);
    }
    window.addEventListener('storage', (e) => this.updateLockStatus(e));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('storage', (e) => this.updateLockStatus(e));
    localStorage.removeItem('resetRemaining');
  }

  handleOnIdle(event) {
    const id = localStorage.getItem('user_id');
    if (id) {
      window.location.href = '/login';
    } else {
      window.location.href = '/';
    }
    localStorage.clear();
  }

  formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(0);
    return (seconds == 60 ? (minutes + 1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
  }

  getRemainingTimePercentage = (time) => {
    const totalSeconds = (Math.floor(time / 60000) * 60) + Number(((time % 60000) / 1000).toFixed(0));
    return Math.ceil((totalSeconds / (this.state.timeout_limit * 60)) * 100);
  };

  handleLockClick = () => {
    const { email, profile_photo, first_name, last_name } = JSON.parse(localStorage.getItem('user')); 
    localStorage.clear();
    localStorage.setItem('locked_user', JSON.stringify({ email, profile_photo, name: `${first_name} ${last_name}` }));
    localStorage.setItem('locked', true);
    window.location.href = `/locked?redirect_url=${window.location.href}`;
  };

  handleAction = () => {
    localStorage.setItem('resetRemaining', this.idleTimer && this.idleTimer.getRemainingTime());
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {window.location.pathname.includes('login') ? null : (
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * this.state.timeout_limit}
            onIdle={this.handleOnIdle}
            debounce={250}
            onAction={this.handleAction.bind(this)}
          />
        )}
        {window.location.pathname.includes('login') ? null : (
          <React.Fragment>
            <div className={classes.logoutStatus}>
              <CircularProgressWithButton
                value={this.getRemainingTimePercentage(this.state.remaining)}
                tooltipText={`Auto Logout In ${this.formatTime(this.state.remaining)}`}
                onClick={this.handleLockClick}
              />
            </div>
          </React.Fragment>
          
          // <div className="remaining-time">
          //   Auto Logout In {this.formatTime(this.state.remaining)}
          // </div>
        )}

        {/* <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => (
            <>
              {!isLatestVersion && (
                <div className="outdated-version">
                  <p>Your app version is out of date, please click the button below to update to the latest version.</p>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      emptyCacheStorage();
                    }}
                  >
                    Update version
                  </a>
                </div>
              )}
            </>
          )}
        </ClearCache> */}
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Timer));