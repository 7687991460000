import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */
const initialState = {
  company_widget: {}, // eslint-disable-line
  company_widgets: [], // eslint-disable-line
  companyWidgets: [], // get data from direct api call
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_COMPANY_WIDGETS_REQUEST:
      return {
        ...state,
        company_widgets: [],
        loading: false,
      };
    case types.FETCH_COMPANY_WIDGETS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          company_widgets: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        company_widgets: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_COMPANY_WIDGETS_FAILED:
      return {
        ...state,
        company_widgets: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_COMPANY_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.STORE_COMPANY_WIDGETS:
      const companyWidgets = action.payload.widgets;
      return {
        ...state,
        companyWidgets,
      };
    case types.FETCH_COMPANY_WIDGET_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          company_widget: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        company_widget: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_COMPANY_WIDGET_FAILED:
      return {
        ...state,
        company_widget: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_COMPANY_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_COMPANY_WIDGET_SUCCESS:
      return {
        ...state,
        company_widgets: [...state.company_widgets, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_COMPANY_WIDGET_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_COMPANY_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_COMPANY_WIDGET_SUCCESS:
      // let company_widgets = filter(state.company_widgets, item => item.id !== action.res.data.id); // eslint-disable-line
      return {
        // ...state,
        // company_widget: action.res.data || {},
        // company_widgets: [...company_widgets, action.res.data], // eslint-disable-line
        // status: action.res.success,
        loading: false,
      };
    case types.UPDATE_COMPANY_WIDGET_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_ONE_VIEW_BG_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_ONE_VIEW_BG_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_ONE_VIEW_BG_IMAGE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_COMPANY_WIDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_COMPANY_WIDGET_SUCCESS:
      let company_widgets = filter(state.company_widgets, item => item.id !== action.payload.id);// eslint-disable-line
      return {
        ...state,
        company_widgets: [...company_widgets], // eslint-disable-line
        status: true,
        loading: false,
      };
    case types.DELETE_COMPANY_WIDGET_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
