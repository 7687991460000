let definedApiUrl;

if (window.location.origin === 'https://dealerportal.mywirelessgroup.com') {
  definedApiUrl = 'https://mwg-api.centraverse.com';
} else if (window.location.origin === 'https://centraverse.com') {
  definedApiUrl = 'https://api.centraverse.com';
} else if (window.location.origin === 'http://localhost:3000') {
  definedApiUrl = 'http://localhost:8000';
  // definedApiUrl = 'https://demoapi.centraverse.com';
  // definedApiUrl = 'https://api.centraverse.com';
  // definedApiUrl = 'https://stagingapi.centraverse.com';
  // definedApiUrl = 'https://apitesting.centraverse.com';
} else if (window.location.origin === 'https://demo.centraverse.com') {
  definedApiUrl = 'https://demoapi.centraverse.com';
} else if (window.location.origin === 'https://staging.centraverse.com') {
  definedApiUrl = 'https://stagingapi.centraverse.com';
} else if (window.location.origin === 'https://staging1.centraverse.com') {
  definedApiUrl = 'https://staging1api.centraverse.com';
} else if (window.location.origin === 'https://testing.centraverse.com') {
  definedApiUrl = 'https://apitesting.centraverse.com';
  // definedApiUrl = 'https://stagingapi.centraverse.com';
} else if (window.location.origin === 'https://csourcedata.com') {
  definedApiUrl = 'https://api.csourcedata.com';
} else if (window.location.origin === 'https://myarchstats.com') {
  definedApiUrl = 'https://api.myarchstats.com';
} else if (window.location.origin === 'https://thebusylion.com') {
  definedApiUrl = 'https://api.thebusylion.com';
} else {
  definedApiUrl = 'https://api.centraverse.com';
}

export const API_ENDPOINT_URL = (process.env.REACT_APP_API_ENDPOINT_URL) ? process.env.REACT_APP_API_ENDPOINT_URL : definedApiUrl;
export const LOTUS_API_ENDPOINT_URL = (process.env.LOTUS_POS_API_ENDPOINT_URL) ? process.env.LOTUS_POS_API_ENDPOINT_URL : definedApiUrl;
// export const LOTUS_API_ENDPOINT_URL = (process.env.LOTUS_POS_API_ENDPOINT_URL) ? process.env.LOTUS_POS_API_ENDPOINT_URL : 'http://localhost:5000';
export const SIGN_IN_API_ENDPOINT = `${API_ENDPOINT_URL}/auth/login`;

export const EMAIL_PATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;  // eslint-disable-line
export const TAX_FEE = 0.00;
export const DEFAULT_PAGE_SIZE = 20;
export const ROLE_LIST = [
  { value: 'super_admin', label: 'Super Admin' },
  { value: 'master_agent', label: 'Master Agent' },
  { value: 'sub_agent_admin', label: 'Sub Agent Admin' },
  { value: 'sub_agent_employee', label: 'Sub Agent Employee' },
  { value: 'warehouse_account', label: 'Warehouse Account' },
  { value: 'call_center_agent', label: 'Call Center Agent' },
  { value: 'base_user', label: 'Base User' },
];

export const TIME_ZONES = [
  { value: 'America/Chicago', label: 'Central Standard Time (CST)/Central Daylight Time(CDT)' },
  { value: 'America/New_York', label: 'Eastern Standard Time (EST)/Eastern Daylight Time(EDT)' },
  { value: 'America/Ojinaga', label: 'Mountain Standard Time (MST)/Mountain Daylight Time (MDT)' },
  { value: 'America/Los_Angeles', label: 'Pacific Standard Time (PST)/Pacific Daylight Time (PDT)' },
];


export const RETURN_TYPES = [
  { value: 'bre', label: 'Buyers Remorse (BRE)' },
  { value: 'doa', label: 'Dead on Arrival (DOA)' },
  { value: 'obf', label: 'Out of Box Failure (OBF)' },
  { value: 'sbr', label: 'Stock Balance Return (SBR)' },
];

export const ORDER_STATUS_OPTIONS = [
  { value: 'open', label: 'Open' },
  { value: 'submitted', label: 'Submitted' },
  { value: 'pending_shipment', label: 'Pending Shipment' },
  { value: 'under_review', label: 'Under Review' },
  { value: 'shipped', label: 'Shipped' },
  { value: 'received', label: 'Received' },
  { value: 'canceled', label: 'Canceled' },
];

export const PERMISSION_LIST = {
  write_invoices: 'Create Invoices',
  read_invoices: 'View Invoices',
  write_users: 'Create/Edit Users',
  write_users_lower_permission: 'Create/Edit Users Lower Permissions',
  read_users: 'View Users',
  create_activation_request: 'Create/Edit Activations',
  read_activation_request: 'View Activations',
  write_products: 'Create/Edit Products',
  order_products: 'Order Products',
  read_products: 'View Products',
  fulfill_orders: 'Fulfill Orders',
  read_orders: 'View Orders',
  write_orders: 'Create/Edit Orders',
  write_companies: 'Create/Edit Companies',
  read_companies: 'View Companies',
  manually_join_conversations: 'Join Conversations',
  write_settings: 'Edit Settings',
  write_stores: 'Create/Edit Stores',
  read_stores: 'View Stores',
  write_purchase_order: 'Write Purchase Orders',
  read_purchase_order: 'Read Purchase Orders',
  receive_purchase_order: 'Receive Purchase Orders',
  write_categories: 'Write Categories',
  access_comissions: 'Access Comissions',
  access_comissions_appeals: 'Access Comissions Appeals',
  access_aging_invoices: 'Access Aging Invoices',
  access_credit_line: 'Access Credit Line',
  access_iverify: 'Access iVerify',
  access_discrepancy_portal: 'Access Discrepancy Portal',
  access_lotus_drive: 'Access Lotus Drive',
  access_lotus_intelligence: 'Access Lotus Intelligence',
  access_ot_commission_tool: 'Access OT Commission Tool',
  access_real_time: 'Access Real Time',
  manage_districts: 'Manage Districts',
  write_refund_and_exchanges: 'Can Create Refund and Exchanges',
  read_refund_and_exchanges: 'Can View Refund and Exchanges',
  write_revenue: 'Write Revenue',
  write_raRequests: 'Write RaRequests',
  write_price_adjustments: 'Write Price Adjustments',
  write_notifications: 'Write Notifications',
  write_manufacturers: 'Write Manufacturers',
  write_leads: 'Write Leads',
  write_inventoryItems: 'Write InventoryItems',
  write_customers: 'Write Customers',
  read_revenue: 'Read Revenue',
  read_raRequests: 'Read RaRequests',
  read_price_adjustments: 'Read Price Adjustments',
  read_leads: 'Read Leads',
  read_inventoryItems: 'Read InventoryItems',
  read_districts: 'Read Districts',
  read_customers: 'Read Customers',
  process_raRequests: 'Process RaRequests',
  make_credit_payments: 'Make Credit Payments',
  can_view_user_reporting: 'Can View User Reporting',
  can_view_sales_report: 'Can View Sales Report',
  can_view_iverify_log_report: 'Can View Iverify Log Report',
  can_view_invoice_report: 'Can View Invoice Report',
  can_view_inventory_report: 'Can View Inventory Report',
  can_view_inventory_aging_report: 'Can View Inventory Aging Report',
  can_view_accessory_inventory_report: 'Can View Accessory Inventory Report',
  can_edit_resource: 'Can Edit Resource',
  can_delete_resource: 'Can Delete Resource',
  access_transaction_dashboard: 'Access Transaction Dashboard',
  access_sales_scorecard: 'Access Sales Scorecard',
  access_product_inventory: 'Access Product Inventory',
  access_inventory_dashboard: 'Access Inventory Dashboard',
  access_equipment_dashboard: 'Access Equipment Dashboard',
  access_comission_portal: 'Access Comission Portal',
};

export const SALE_TYPES = [
  { value: 'att_installment_plan_new_activation', label: 'AT&T Installment Plan (New Activation)' },
  { value: 'att_installment_plan_upgrade', label: 'AT&T Installment Plan (Upgrade)' },
  { value: 'att_installment_plan_with_next_up_new_activation', label: 'AT&T Installment Plan with Next Up (New Activation)' },
  { value: 'att_installment_plan_with_next_up_upgrade', label: 'AT&T Installment Plan with Next Up (Upgrade)' },
  { value: 'byod', label: 'BYOD (New Activation)' },
  { value: 'direct_fulfillment_new_activation', label: 'Direct Fulfillment (New Activation)' },
  { value: 'direct_fulfillment_upgrade', label: 'Direct Fulfillment (Upgrade)' },
  { value: 'entertainment_video', label: 'Entertainment - Video' },
  { value: 'entertainment_broadband', label: 'Entertainment - Broadband' },
  { value: 'mifi', label: 'MiFi' },
  { value: 'tablet_new_line', label: 'Tablet - New Line' },
  { value: 'tablet_upgrade', label: 'Tablet - Upgrade' },
  { value: 'wireless_home_phone', label: 'Wireless Home Phone' },
];

export const RATE_PLANS = [
  { value: 'unlimited_and_more_premium_unlimited_and_more', label: 'Unlimited&More Premium/Unlimited&More' },
  { value: 'mobileshare_plus', label: 'MobileShare Plus' },
  { value: 'firstnet_agency', label: 'FirstNet - Agency' },
  { value: 'firstnet_subscriber', label: 'FirstNet - Subscriber' },
  { value: 'cru', label: 'CRU' },
  { value: 'tv_dtv_u_verse', label: 'TV (DTV/U-Verse)' },
  { value: 'internet_u_verse_3rd_party', label: 'Internet (U-Verse/3rd Party)' },
  { value: 'legacy_unlimited', label: 'Legacy - Unlimited' },
  { value: 'legacy_mobileshare', label: 'Legacy - MobileShare' },
  { value: 'legacy_cru', label: 'Legacy - CRU' },
  { value: 'prepaid', label: 'Prepaid' },
  { value: 'N/A', label: 'Not Applicable' },
  { value: 'entertainment_video', label: 'Entertainment - Video' },
  { value: 'entertainment_broadband', label: 'Entertainment - Broadband' },
  { value: 'mifi', label: 'MiFi ' },
  { value: 'tablet_new_line', label: 'Tablet - New Line' },
  { value: 'tablet_upgrade', label: 'Tablet - Upgrade' },
  { value: 'wireless_home_phone', label: 'Wireless Home Phone' },
];

export const STATES = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyomi' },
];

export const NotificationStatuses = [
  { value: 'completed', label: 'Completed' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'deleted', label: 'Deleted' },
];

export const scheduleReqEnabledCompanies = [38, 52];

export const timecardActions = Object.freeze({
  CLOCK_IN: Symbol('CLOCK_IN'),
  CLOCK_OUT: Symbol('CLOCK_OUT'),
  CLOCK_OUT_CLOCK_IN: Symbol('CLOCK_OUT_CLOCK_IN'),
  BREAK_START: Symbol('BREAK_START'),
  BREAK_END: Symbol('BREAK_END'),
});

