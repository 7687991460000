import * as types from '../../constants/actionTypes';

export const enableEditMode = () => ({
  type: types.ENABLE_ONE_VIEW_EDIT,
});

export const disableEditMode = () => ({
  type: types.DISABLE_ONE_VIEW_EDIT,
});

export const storeOneViewPosition = payload => ({
  type: types.STORE_WIDGET_POSITION,
  payload,
});

export const storeBackgroundImage = payload => ({
  type: types.STORE_BACKGROUND_IMAGE,
  payload,
});

export const setNewWidgetAlertStatus = payload => ({
  type: types.NEW_WIDGET_ALERT,
  payload,
});

export const setBgImageUpdateStatus = payload => ({
  type: types.BG_IMAGE_UPDATE_STATUS,
  payload,
});

export const enableEmployeeEditMode = () => ({
  type: types.ENABLE_ONE_VIEW_EMPLOYEE_EDIT,
});

export const disableEmployeeEditMode = () => ({
  type: types.DISABLE_ONE_VIEW_EMPLOYEE_EDIT,
});

export const setCurrentEditMode = payload => ({
  type: types.SET_CURRENT_EDIT_MODE,
  payload,
});

export const setIsUpdate = payload => ({
  type: types.SET_IS_UPDATE,
  payload,
});
