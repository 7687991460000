import React from 'react';

export const LmsAppConfig = { // eslint-disable-line
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'lmsApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/lms/dashboard/:nestedRoute',
      component: React.lazy(() => import('./Dashboard/Components')),
    },
    {
      path: '/lms/create-material',
      component: React.lazy(() => import('./Dashboard/Components/CreateLms')),
    },
    {
      path: '/lms/update-material/:id',
      component: React.lazy(() => import('./Dashboard/Components/CreateLms')),
    },
    {
      path: '/lms/material/:id',
      component: React.lazy(() => import('./Dashboard/Components/Material/index')),
    },
    {
      path: '/lms/session/:id/:name',
      component: React.lazy(() => import('./Dashboard/Components/Material/SingleSession')),
    },
    {
      path: '/lms/settings',
      component: React.lazy(() => import('./Settings/Tabs/LmsTopics')),
    },
    {
      path: '/lms/settings/topics',
      component: React.lazy(() => import('./Settings/Tabs/LmsTopics')),
    },
    {
      path: '/lms/report',
      component: React.lazy(() => import('./LMSReport')),
    },

  ],
};
