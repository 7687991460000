/*eslint-disable */
import 'react-sortable-tree/style.css';
import React, { Component } from 'react';
import SortableTree, { getFlatDataFromTree, getTreeFromFlatData } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';

export default class Drag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: getTreeFromFlatData({
        flatData: props.data,
        getKey: node => node.id, // resolve a node's key
        getParentKey: node => node[props.parentKey], // resolve a node's parent_id's key
        rootKey: null, // The value of the parent_id key when there is no parent_id (i.e., at root level)
      })
    };
  }
  capturePositionChange = (node) => {
    let data = getFlatDataFromTree({
      treeData: this.state.treeData,
      getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }) => ({
      ...node,
      parent_id: path.length > 1 ? path[path.length - 2] : null,
    }))
    let index = data.findIndex(d => d.id === node.id)
    let parent = data.find(d => d.id === data[index].parent_id)
    let orderedIds
    if (parent) {
      orderedIds = parent.children.map(child => child.id)
    }
    this.props.capturePositionChange(data[index], orderedIds || [])
  }
  // static getDerivedStateFromProps(nextProps, prevState)   {
  //   return {
  //     treeData: getTreeFromFlatData({
  //       flatData: nextProps.data,
  //       getKey: node => node.id, // resolve a node's key
  //       getParentKey: node => node[nextProps.parentKey], // resolve a node's parent_id's key
  //       rootKey: null, // The value of the parent_id key when there is no parent_id (i.e., at root level)
  //     })
  //   }
  // }
  setTreeData = treeData => {
    this.setState({ treeData },
      () => {
        this.props.setTreeData(treeData);
      })
  }
  render() {
    const flatData = getFlatDataFromTree({
      treeData: this.state.treeData,
      getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }) => ({
      ...node,
      parent_id: path.length > 1 ? path[path.length - 2] : null,
    }));

    return (
      <div style={{ height: 1500, width: 1500 }}>
        <SortableTree
          treeData={this.state.treeData}
          onChange={treeData => this.setTreeData(treeData)}
          onMoveNode={({ node }) => { this.capturePositionChange(node); }}
          canDrag={this.props.canDrag}
          // theme={FileExplorerTheme}
        />
      </div>
    );
  }
}

