import React from 'react';
import { render } from 'react-dom';
// import 'semantic-ui-css/semantic.min.css';
import App from './containers/App/App';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

render(
  <App />,
  document.getElementById('root'),
);
