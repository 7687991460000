import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  company: {},
  companies: [],
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_COMPANIES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          companies: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        companies: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        companies: [],
        loading: true,
      };
    case types.FETCH_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_COMPANIES_FAILED:
      return {
        ...state,
        companies: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_COMPANY_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          company: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        company: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_COMPANY_FAILED:
      return {
        ...state,
        company: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        // companies: [...state.companies, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_COMPANY_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_COMPANY_SUCCESS:
      let companies = filter(state.companies, item => item.id !== action.res.data.id);
      return {
        ...state,
        company: action.res.data || {},
        companies: [...companies, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_COMPANY_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_COMPANY_SUCCESS:
      companies = filter(state.companies, item => item.id !== action.payload.id);

      return {
        ...state,
        companies: [...companies],
        status: true,
        loading: false,
      };
    case types.DELETE_COMPANY_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.FETCH_COMPANY_COUNTS_SUCCESS:
      return {
        ...state,
        theCount: action.res.data.count,
        status: action.res.success,
        loading: false,
      };

    default:
      return state;
  }
};
