import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  addInventoryLead,
  deleteInventoryLead,
  getInventoryLeadById,
  getInventoryLeads,
  updateInventoryLead,
  fetchInventoryLeadsSourcesByCompanyId,
  addInventoryLeadFromContactUs,
  updateInventoryLeadFromContactUs,
} from 'api/Api';
import * as types from 'constants/actionTypes';


/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_INVENTORY_LEADS_REQUEST, fetchInventoryLeadsSaga);
  yield takeLatest(types.FETCH_INVENTORY_LEADS_MORE_REQUEST, fetchInventoryLeadsMoreSaga);
  yield takeLatest(types.FETCH_INVENTORY_LEAD_REQUEST, fetchInventoryLeadSaga);
  yield takeLatest(types.ADD_INVENTORY_LEAD_REQUEST, addInventoryLeadSaga, context);
  yield takeLatest(types.UPDATE_INVENTORY_LEAD_REQUEST, updateInventoryLeadSaga, context);
  yield takeLatest(types.DELETE_INVENTORY_LEAD_REQUEST, deleteInventoryLeadSaga);
  yield takeLatest(types.FETCH_INVENTORY_LEAD_SOURCES_BY_COMPANY_ID_REQUEST, fetchInventoryLeadSourcesSaga);
  yield takeLatest(types.ADD_INVENTORY_LEAD_FROM_CONTACT_US_REQUEST, addInventoryLeadFromContactUsSaga, context);
  yield takeLatest(types.UPDATE_INVENTORY_LEAD_FROM_CONTACT_US_REQUEST, updateInventoryLeadFromContactUsSaga, context);
}

export function* fetchInventoryLeadsSaga({ payload }) {
  try {
    const res = yield call(getInventoryLeads, payload);
    yield all([
      put({ type: types.FETCH_INVENTORY_LEADS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.FETCH_INVENTORY_LEADS_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchInventoryLeadsMoreSaga({ payload }) {
  try {
    const res = yield call(getInventoryLeads, payload);
    yield all([
      put({ type: types.FETCH_INVENTORY_LEADS_MORE_SUCCESS, res }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.FETCH_INVENTORY_LEADS_MORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchInventoryLeadSaga({ payload }) {
  try {
    const res = yield call(getInventoryLeadById, payload);
    yield all([
      put({ type: types.FETCH_INVENTORY_LEAD_SUCCESS, res }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.FETCH_INVENTORY_LEAD_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* addInventoryLeadSaga({ history }, { payload }) {
  try {
    const res = yield call(addInventoryLead, payload);
    yield all([
      put({ type: types.ADD_INVENTORY_LEAD_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Inventory lead created' : res.message || 'Inventory lead not created',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/inventory-lead');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_INVENTORY_LEAD_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateInventoryLeadSaga({ history }, { payload }) {
  try {
    const res = yield call(updateInventoryLead, payload);
    yield all([
      put({ type: types.UPDATE_INVENTORY_LEAD_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Inventory lead updated' : res.message || 'Inventory lead not updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_INVENTORY_LEAD_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteInventoryLeadSaga({ payload }) {
  try {
    const res = yield call(deleteInventoryLead, payload);
    yield all([
      put({ type: types.DELETE_INVENTORY_LEAD_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Inventory lead deleted' : res.message || 'Inventory lead not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_INVENTORY_LEAD_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchInventoryLeadSourcesSaga({ payload }) {
  try {
    const res = yield call(fetchInventoryLeadsSourcesByCompanyId, payload);
    yield all([
      put({ type: types.FETCH_INVENTORY_LEAD_SOURCES_BY_COMPANY_ID_SUCCESS, res }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.FETCH_INVENTORY_LEAD_SOURCES_BY_COMPANY_ID_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* addInventoryLeadFromContactUsSaga({ history }, { payload }) {
  try {
    const res = yield call(addInventoryLeadFromContactUs, payload);
    yield all([
      put({ type: types.ADD_INVENTORY_LEAD_FROM_CONTACT_US_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Inventory lead created' : res.message || 'Inventory lead not created',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/inventory-leads/list');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_INVENTORY_LEAD_FROM_CONTACT_US_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateInventoryLeadFromContactUsSaga({ history }, { payload }) {
  try {
    const res = yield call(updateInventoryLeadFromContactUs, payload);
    yield all([
      put({ type: types.UPDATE_INVENTORY_LEAD_FROM_CONTACT_US_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Inventory lead updated' : res.message || 'Inventory lead not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/inventory-leads/list');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_INVENTORY_LEAD_FROM_CONTACT_US_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
