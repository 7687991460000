import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

const initialState = {
  loading: false,
  login: {
    email: '',
    password: '',
    status: false,
    error: null,
    loginStatus: null,
  },
  signUp: {
    status: false,
    error: null,
    loginStatus: null,
  },
  forgotPassword: {
    status: false,
    error: null,
    forgotStatus: null,
  },
  user: {
    data: {},
    status: false,
    error: null,
    userStatus: null,
  },
  is_success: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_REQUEST:
      return {
        ...state,
        loading: false,
        user: {
          data: action.res,
          status: true,
          error: null,
          userStatus: true,
        },
      };
    case types.SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_USERS_SUCCESS: {
      const users = filter(state.users, item => item.id !== action.res.data.id);
      return {
        ...state,
        user: {},
        users: [],
        total: 0,
        error: '',
        status: action.res.success,
        loading: false,
      };
    }
    case types.UPDATE_USERS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SIGN_UP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOG_OUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SIGN_IN_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          loading: false,
          login: {
            ...state.login,
            loginStatus: action.res.success,
            error: action.res.reason,
          },
        };
      }
      return {
        ...state,
        login: {
          ...state.login,
          loginStatus: action.res.success,
          error: null,
        },
        user: {
          data: action.res.data,
          status: true,
          error: null,
          userStatus: true,
        },
      };
    case types.SIGN_IN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          error: 'Bad Request',
        },
      };

    // FACEBOOK REDUCER
    case types.FACEBOOK_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        user: {
          data: action.res.data,
          status: true,
          error: null,
          userStatus: true,
        },
      };
    case types.FACEBOOK_FAILED:
      return {
        ...state,
      };

    // UPDATE USER REDUCER
    case types.UPDATE_USER_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          loading: false,
          is_success: false,
          login: {
            ...state.login,
            loginStatus: action.res.success,
            error: action.res.reason,
          },
        };
      }
      const { company_id } = JSON.parse(localStorage.getItem('user')); // eslint-disable-line
      return {
        ...state,
        login: {
          ...state.login,
          loginStatus: action.res.success,
          error: null,
        },
        user: {
          data: { ...action.res.data, company_id },
          status: true,
          error: null,
          userStatus: true,
        },
        is_success: true,
        loading: false,
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        is_success: false,
      };
    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          error: 'Bad Request',
        },
        is_success: false,
        error: 'Bad Request',
        loading: false,
      };

    // Requests for forgot password
    case types.FORGOT_PASSWORD_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          loading: false,
          forgotPassword: {
            ...state.forgotPassword,
            status: action.res.success,
            error: action.res.reason,
          },
        };
      }
      return {
        ...state,
        loading: false,
        forgotPassword: {
          ...state.forgotPassword,
          status: action.res.success,
        },
      };
    case types.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          status: false,
          error: 'Bad Request',
        },
      };

    // Requests for resetting the password
    case types.RESET_PASSWORD_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          error: action.res.reason,
        };
      }
      return {
        ...state,
        status: action.res.success,
        error: null,
      };
    case types.RESET_PASSWORD_FAILED:
      return {
        ...state,
        status: false,
        error: 'Bad Request',
      };

    // Requests for getting user data
    // case types.GET_USER_REQUEST:
    //   return {
    //     ...state,
    //       status: false,
    //       error: false,
    //       user: {}
    //   };
    case types.GET_USER_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          user: {
            ...state.forgotPassword,
            status: action.res.success,
            error: action.res.reason,
          },
        };
      }
      return {
        ...state,
        status: action.res.success,
        error: null,
        user: { ...action.res.data },
      };
    case types.GET_USER_FAILED:
      return {
        ...state,
        user: {
          ...state.forgotPassword,
          status: false,
          error: 'Bad Request',
        },
      };
    case types.SIGN_UP_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          signUp: {
            loginStatus: false,
            error: action.res.reason,
          },
        };
      }
      return {
        ...state,
        signUp: {
          loginStatus: action.res.success,
          error: null,
        },
      };
    case types.SIGN_UP_FAILED:
      return {
        ...state,
        signUp: {
          loginStatus: false,
          error: action.res.reason,
        },
      };
    case types.LOG_OUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
