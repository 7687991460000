import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  support_ticket: {}, // eslint-disable-line
  support_tickets: [], // eslint-disable-line
  total: 0,
  loading: false,
  showModal: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SUPPORTTICKETS:
      return {
        ...state,
        showModal: true,
      };
    case types.HIDE_SUPPORTTICKETS:
      return {
        ...state,
        showModal: false,
      };
    case types.FETCH_SUPPORTTICKETS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          support_tickets: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        support_tickets: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_SUPPORTTICKETS_REQUEST:
      return {
        ...state,
        support_tickets: [],
        loading: true,
      };
    case types.FETCH_SUPPORTTICKET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_SUPPORTTICKET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_SUPPORTTICKET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_SUPPORTTICKET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_SUPPORTTICKETS_FAILED:
      return {
        ...state,
        support_tickets: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_SUPPORTTICKET_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          support_ticket: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        support_ticket: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_SUPPORTTICKET_FAILED:
      return {
        ...state,
        support_ticket: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_SUPPORTTICKET_SUCCESS:
      return {
        ...state,
        // support_tickets: [...state.support_tickets, action.res.data],
        status: action.res.success,
        loading: false,
        showModal: false,
      };
    case types.ADD_SUPPORTTICKET_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_SUPPORTTICKET_SUCCESS:
      let support_tickets = filter(state.support_tickets, item => item.id !== action.res.data.id); // eslint-disable-line
      return {
        ...state,
        support_ticket: action.res.data || {},
        support_tickets: [...support_tickets, action.res.data], // eslint-disable-line
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_SUPPORTTICKET_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_SUPPORTTICKET_SUCCESS:
      support_tickets = filter(state.support_tickets, item => item.id !== action.payload.id);// eslint-disable-line

      return {
        ...state,
        support_tickets: [...support_tickets], // eslint-disable-line
        status: true,
        loading: false,
      };
    case types.DELETE_SUPPORTTICKET_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
