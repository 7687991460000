import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  store: {},
  storeReports: {},
  storetimestatus: {},
  stores: [],
  total: 0,
  loading: false,
  error: '',
  locations: [],
  companyStores: [],
  isB2BUser: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_STORES_SUCCESS:
      if (!action.res.data) {
        throw action.res;
      }
      if (!action.res.success) {
        return {
          ...state,
          stores: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        stores: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_STORES_FAILED:
      return {
        ...state,
        stores: [],
        error: action.error ? action.error.message : 'Bad Request',
        loading: false,
      };
    case types.FETCH_STORES_REQUEST:
      return {
        ...state,
        stores: [],
        loading: true,
      };
    case types.FETCH_COMPANY_STORES_SUCCESS:
      if (!action.res.data) {
        throw action.res;
      }
      if (!action.res.success) {
        return {
          ...state,
          companyStores: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        companyStores: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_COMPANY_STORES_FAILED:
      return {
        ...state,
        companyStores: [],
        error: action.error ? action.error.message : 'Bad Request',
        loading: false,
      };
    case types.FETCH_COMPANY_STORES_REQUEST:
      return {
        ...state,
        companyStores: [],
        loading: true,
      };
    case types.FETCH_STORES_SEARCH_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          stores: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        stores: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_STORES_SEARCH_FAILED:
      return {
        ...state,
        stores: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_STORE_REPORTS_REQUEST:
      return {
        ...state,
        storeReports: [],
        loading: true,
      };
    case types.FETCH_STORE_REPORTS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          storeReports: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        storeReports: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_STORE_REPORTS_FAILED:
      return {
        ...state,
        storeReports: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_STORES_SEARCH_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case types.FETCH_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACTIVATE_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_STORE_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          store: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        store: (action.res && action.res.data) || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_STORE_FAILED:
      return {
        ...state,
        store: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_STORE_SUCCESS:
      return {
        ...state,
        // stores: [...state.stores, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_STORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    // case types.UPDATE_STORE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    case types.UPDATE_STORE_SUCCESS:
      let stores = filter(state.stores, item => item.id !== action.res.data.id);

      return {
        ...state,
        // stores: [...stores, action.res.data],
        store: null,
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_STORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_STORE_SUCCESS:
      stores = filter(state.stores, item => item.id !== action.payload.id);

      return {
        ...state,
        stores: [...stores],
        status: true,
        loading: false,
      };

    case types.ACTIVATE_STORE_SUCCESS:
      // stores = filter(state.stores, item => item.id !== action.payload.id);

      return {
        ...state,
        // stores: [...stores],
        status: true,
        loading: false,
      };
    case types.DELETE_STORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.ACTIVATE_STORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.TOGGLE_B2B_REQUEST_STORE:
      return {
        ...state,
        isB2BUser: !state.isB2BUser,
      };

    case types.CLEAR_STORE_FORM:
      return {
        ...state,
        store: {},
      };
    case types.FETCH_STORES_LOCATIONS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          locations: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        locations: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_STORES_LOCATIONS_FAILED:
      return {
        ...state,
        locations: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_STORES_LOCATIONS_REQUEST:
      return {
        ...state,
        locations: [],
        loading: true,
      };

    case types.FETCH_STORE_TIME_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_STORE_TIME_STATUS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          storetimestatus: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        storetimestatus: (action.res && action.res.data.results) || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_STORE_TIME_STATUS_FAILED:
      return {
        ...state,
        storetimestatus: {},
        error: 'Bad Request',
        loading: false,
      };

    default:
      return state;
  }
};
