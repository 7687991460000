import { fork } from 'redux-saga/effects';
import watchLoginListener from './loginSaga';
import watchSignUpListener from './signupSaga';
import watchPosCategoriesListener from './posCategoriesSaga';
import watchOrdersListener from './ordersSaga';
import watchCompaniesSaga from './companiesSaga';
import watchTaxSaga from './taxSaga';
import watchUsersListener from './usersSaga';
import watchStoresListener from './storesSaga';
import watchRolesSaga from './rolesSaga';
import watchPermissionGroupsSaga from './permissionGroupsSaga';
import watchPhoneVerificationsListner from './phoneVerificationsSaga';
import watchDistrictsSaga from './districtsSaga';
import watchFetchSerials from './serialsSaga';
import watchPaymentAccountSaga from './paymentAccountSaga';
import watchSidebarItemSaga from './sidebarItemSaga';
import watchRegionSaga from './regionSaga';
import watchHRWebClockinSaga from './hrWebClockinSaga';
import watchHRTimecardBreakSaga from './hrTimecardBreakSaga';
import watchHRSettingsStoreSaga from './hrSettingsStoreSaga';
import watchHRSettingsCompaniesSaga from './hrSettingsCompaniesSaga';
import watchUserStoreSaga from './UserStoreSaga';
import watchUserDistrictSaga from './UserDistrictSaga';
import watchUserRegionSaga from './UserRegionSaga';
import watchSupportTicketSaga from './SupportTicketSaga';
import watchSupprtTicketTypeSaga from './SupprtTicketTypeSaga';
import watchEmbedsAndLinksSaga from './embedsAndLinksSaga';
import watchEmployeesListener from './employeesSaga';
import watchRequiredFieldsSaga from './requiredFieldsSaga';
import oneViewCompanyWidgetSaga from './oneViewCompanyWidgetSaga';
import watchHRUserAutoClockedDetailsSaga from './hrUserAutoClockedOutDetailsSaga';

export default function* startForman(context = {}) {
  yield fork(watchLoginListener, context);
  yield fork(watchSignUpListener, context);
  yield fork(watchPosCategoriesListener, context);
  yield fork(watchOrdersListener, context);
  yield fork(watchCompaniesSaga, context);
  yield fork(watchUsersListener, context);
  yield fork(watchTaxSaga, context);
  yield fork(watchStoresListener, context);
  yield fork(watchPermissionGroupsSaga, context);
  yield fork(watchPhoneVerificationsListner, context);
  yield fork(watchDistrictsSaga, context);
  yield fork(watchFetchSerials, context);
  yield fork(watchPaymentAccountSaga, context);
  yield fork(watchSidebarItemSaga, context);
  yield fork(watchRegionSaga, context);
  yield fork(watchHRWebClockinSaga, context);
  yield fork(watchHRTimecardBreakSaga, context);
  yield fork(watchHRSettingsStoreSaga, context);
  yield fork(watchHRSettingsCompaniesSaga, context);
  yield fork(watchUserStoreSaga, context);
  yield fork(watchUserDistrictSaga, context);
  yield fork(watchUserRegionSaga, context);
  yield fork(watchSupportTicketSaga, context);
  yield fork(watchRolesSaga, context);
  yield fork(watchSupprtTicketTypeSaga, context);
  yield fork(watchEmbedsAndLinksSaga, context);
  yield fork(watchEmployeesListener, context);
  yield fork(watchRequiredFieldsSaga, context);
  yield fork(oneViewCompanyWidgetSaga, context);
  yield fork(watchHRUserAutoClockedDetailsSaga, context);
}
