import React from 'react';

export const InventoryAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'inventoryApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/inventory/sale-order/new',
      component: React.lazy(() => import('./SaleOrder/Form')),
    },
    {
      path: '/inventory/sale-order/view/:id',
      component: React.lazy(() => import('./SaleOrder/List/components/ViewSingleSaleOrder')),
    },
    {
      path: '/inventory/sale-order/edit/:id',
      component: React.lazy(() => import('./SaleOrder/Form')),
    },
    {
      path: '/inventory/sale-order',
      component: React.lazy(() => import('./SaleOrder/List')),
    },
    {
      path: '/inventory-products',
      component: React.lazy(() => import('.//InventoryProducts/List')),
    },
    {
      path: '/inventory-products/create',
      component: React.lazy(() => import('.//InventoryProducts/UpdateData')),
    },
    {
      path: '/inventory-products/view/:id',
      component: React.lazy(() => import('.//InventoryProducts/View')),
    },
    {
      path: '/inventory-products/edit/:id',
      component: React.lazy(() => import('.//InventoryProducts/UpdateData')),
    },
    {
      path: '/inventory-brands',
      component: React.lazy(() => import('.//InventoryBrands/List')),
    },
    {
      path: '/inventory-brands/create',
      component: React.lazy(() => import('.//InventoryBrands/Create')),
    },
    {
      path: '/inventory-brands/edit/:id',
      component: React.lazy(() => import('.//InventoryBrands/Edit')),
    },
    {
      path: '/inventory-suppliers',
      component: React.lazy(() => import('.//InventorySuppliers/List')),
    },
    {
      path: '/inventory-suppliers/create',
      component: React.lazy(() => import('.//InventorySuppliers/UpdateData')),
    },
    {
      path: '/inventory-suppliers/edit/:id',
      component: React.lazy(() => import('.//InventorySuppliers/UpdateData')),
    },
    {
      path: '/inventory-suppliers/view/:id',
      component: React.lazy(() => import('.//InventorySuppliers/View')),
    },
    {
      path: '/inventory-warehouses',
      component: React.lazy(() => import('.//InventoryWarehouses/List')),
    },
    {
      path: '/inventory-warehouses/create',
      component: React.lazy(() => import('.//InventoryWarehouses/UpdateData')),
    },
    {
      path: '/inventory-warehouses/edit/:id',
      component: React.lazy(() => import('.//InventoryWarehouses/UpdateData')),
    },
    {
      path: '/inventory-warehouses/view/:id',
      component: React.lazy(() => import('.//InventoryWarehouses/View')),
    },
    {
      path: '/inventory-purchase-orders',
      component: React.lazy(() => import('./InventoryPurchaseOrders/List')),
    },
    {
      path: '/inventory-purchase-orders/create',
      component: React.lazy(() => import('./InventoryPurchaseOrders/UpdateData')),
    },
    {
      path: '/inventory-purchase-orders/edit/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/UpdateData')),
    },
    {
      path: '/inventory-purchase-orders/view/:id',
      component: React.lazy(() => import('./InventoryWarehouses/View')),
    },
    {
      path: '/inventory-purchase-order-receipt/form/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Receipt/index')),
    },
  ],
};

export default InventoryAppConfig;
