import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Locked from '../../assets/images/locked.svg';
import './Unauth.scss';

/* eslint-disable react/prop-types */

class Unauthorized extends Component {
  state = {

  };

  render() {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <div className="unauth-holder">
              <img src={Locked} alt="Page Locked" />
              <h1>Unauthorized</h1>
              <p>The page or feature that you are trying to access is not part of your current plan. Please contact your admin or Lotus for more information.</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Unauthorized;
