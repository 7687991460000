import React from 'react';

export const NotificationAppConfig = { //eslint-disable-line
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'notificationApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/notifications',
      component: React.lazy(() => import('./SingleNotificationAdmin')),
    },
    {
      path: '/notifications/column',
      component: React.lazy(() => import('./NotificationsColumnView')),
    },
    {
      path: '/notifications/reporting',
      component: React.lazy(() => import('./ManageNotificationList')),
    },
    {
      path: '/notifications/:id(\\d+)',
      component: React.lazy(() => import('./SingleNotification')),
    },
    {
      path: '/notifications/admin/:id(\\d+)',
      component: React.lazy(() => import('./SingleNotificationAdmin')),
    },
    {
      path: '/notifications/new',
      component: React.lazy(() => import('./NewEditNotifications')),
    },
    {
      path: '/notifications/status/new',
      component: React.lazy(() => import('./ManageNotificationStatuses')),
    },
    {
      path: '/notifications/type/new',
      component: React.lazy(() => import('./ManageNotificationTypes')),
    },
    {
      path: '/notifications/status/edit/:id(\\d+)',
      component: React.lazy(() => import('./ManageNotificationStatuses')),
    },
    {
      path: '/notifications/type/edit/:id(\\d+)',
      component: React.lazy(() => import('./ManageNotificationTypes')),
    },
    {
      path: '/notifications/settings',
      component: React.lazy(() => import('./NotificationSettings')),
    },
    {
      path: '/lists/new',
      component: React.lazy(() => import('./ManageList')),
    },
    {
      path: '/lists/edit/:id(\\d+)',
      component: React.lazy(() => import('./ManageList')),
    },
    {
      path: '/bare-text',
      component: React.lazy(() => import('./BareText')),
    },
    {
      path: '/lists',
      component: React.lazy(() => import('./Lists')),
    },
    {
      path: '/communications/settings/:nestedRoute?',
      component: React.lazy(() => import('./NewUI/Settings/index')),
    },
    {
      path: '/communications/new',
      component: React.lazy(() => import('./NewUI/AddCommunication')),
    },
    {
      path: '/communications/list-page',
      component: React.lazy(() => import('./NewUI/Listing/List')),
    },
    {
      path: '/communications/dashboard',
      component: React.lazy(() => import('./NewUI/Dashboard/Index')),
    },
    {
      path: '/communications/notifications',
      component: React.lazy(() => import('./NewUI/Dashboard/Notification/NotificationPage')),
    },
    {
      path: '/communications/bulletin-board/new',
      component: React.lazy(() => import('./NewUI/Dashboard/BulletinBoard/BulletinBoardForm')),
    },
    {
      path: '/communications/bulletin-board/:id',
      component: React.lazy(() => import('./NewUI/Dashboard/BulletinBoard/BulletinBoardForm')),
    },
    {
      path: '/communications/notification/new',
      component: React.lazy(() => import('./NewUI/Dashboard/Notification/NotificationForm')),
    },
    {
      path: '/communications/notification-history',
      component: React.lazy(() => import('./NewUI/Dashboard/Notification/NotificationHistory')),
    },
    {
      path: '/communications/notification/:id',
      component: React.lazy(() => import('./NewUI/Dashboard/Notification/NotificationForm')),
    },
    {
      path: '/communications/bulletin-board',
      component: React.lazy(() => import('./NewUI/Dashboard/BulletinBoard/BulletinListing')),
    },
    {
      path: '/communications/bulletin-board-history',
      component: React.lazy(() => import('./NewUI/Dashboard/BulletinBoard/BulletinBoardHistory')),
    },
    {
      path: '/communications/createTemplate',
      component: React.lazy(() => import('./NewUI/AddCommunication/components/MailModal/CreateTemplate')),
    },
    {
      path: '/communications/selectTemplate',
      component: React.lazy(() => import('./NewUI/AddCommunication/components/MailModal/SelectTemplate')),
    },
    {
      path: '/communications/master-template',
      component: React.lazy(() => import('./NewUI/MasterTemplate')),
    },

  ],
};
