import React, {useContext, useEffect} from 'react';
import { ReactReduxContext } from 'react-redux';
import AppContext from 'containers/App/AppContext';
import {matchRoutes} from 'react-router-config';
import {withRouter} from 'react-router-dom';

const withReducer = (props) => {
  const appContext = useContext(ReactReduxContext);
  const routesContext = useContext(AppContext);
  const { store } = appContext;
  const { routes } = routesContext;

  useEffect(async () => {
    const {location} = props;
    const {pathname} = location;

    const matched = matchRoutes(routes || [], pathname)[0];
    if (matched && matched.route && matched.route.moduleName) {
      const {moduleName, store: store1} = matched.route;
      const { reducers, sagas} = await store1;
      if (reducers) { store.injectReducer(moduleName, reducers); }
      if (sagas) { store.injectSaga(moduleName, sagas); }
    }
  }, [props]);

  return props.children;
};

export default withRouter(withReducer);
