import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setAccessoryItemsCart } from 'containers/WarehouseInventory/store/actions/accessoryCartAction';
import configureStore from './store';
import { getAccessToken, getUser } from '../../api/Api';
import { setUserAction } from '../../redux/actions/authActions';
import { setItemsCart } from '../../redux/actions/cartActions';

// import { initialize } from 'store/actions'

class ProviderWithRouter extends React.Component {
  static propTypes = {
    children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);
    this.store = configureStore({}, {
      history: props.history,
    });

    const data = localStorage.getItem('cart_items');
    if (data) {
      const items = JSON.parse(data);
      this.store.dispatch(setItemsCart(items || []));
    }
    const accessoryData = localStorage.getItem('accessory_cart_items');
    if (accessoryData) {
      const items = JSON.parse(accessoryData);
      this.store.dispatch(setAccessoryItemsCart(items || []));
    }
  }

  componentDidMount() {
    const user = getUser();
    const accessToken = getAccessToken();
    if (accessToken) {
      this.store.dispatch(setUserAction(user));
    }
  }

  render() {
    return (
      <Provider store={this.store}>
        {this.props.children}
      </Provider>
    );
  }
}

export default withRouter(ProviderWithRouter);
