import axios2 from 'axios';
import axios1 from '@axios';
import history from '@history';
import makeUrl from './makeUrl';
import { SIGN_IN_API_ENDPOINT, API_ENDPOINT_URL, LOTUS_API_ENDPOINT_URL } from '../constants/default';

/* eslint-disable no-use-before-define */
/* eslint-disable */
/**
 * Description [Access Shutterstock search endpoint for short videos]
 * @params { String } searchQuery
 * @return { Array }
 */
const axios = axios2.create();
axios.interceptors.response.use(
  response =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
    response
  , (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
    if (error.response.status === 460) {
      localStorage.clear();
      window.location.href = '/login';
      // return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export const signInRequest = (payload) => { // eslint-disable-line
  return axios.post(SIGN_IN_API_ENDPOINT, {
    ...payload,
  }, { withCredentials: true }).then((res) => {
    if (res.data.success && res.data.user) {
      console.log('asdf',res.data)
      setAccessToken(res.data.token); // no-use-before-define
      setUserID(res.data.user.id);
      let permissions = res.data.user.permissions; // eslint-disable-line
      delete res.data.user.permissions;
      setUser(res.data.user);
      setPermissions(permissions);
      setSidebars(res.data.sidebarItems);
      localStorage.setItem('theme_color', res.data.theme_color && res.data.theme_color || '#004995')
      localStorage.setItem('store_id', res.data.user.store && res.data.user.store.id)
      localStorage.setItem('store_unique_id', res.data.user.store && res.data.user.store.unique_id)
      localStorage.setItem('store_name', res.data.user.store && res.data.user.store.name)
      localStorage.setItem('logo', res.data.user.logo_url || 'https://cdn.centraverse.com/logos/centraverse.svg');
      localStorage.setItem('business_type', res.data.user.business_type || '');
      localStorage.setItem('geolocation_restriction_required', res.data.geolocation_restriction_required || false)
      localStorage.setItem('clock_in', res.data.clock_in || false)
      localStorage.setItem('scheduleId', res.data.scheduleId || 0)
      localStorage.setItem('breakPopUpShow', JSON.stringify(res.data.breakPopUpShow || {}))
      localStorage.setItem('timezone', res.data.timezone || 'America/Virgin')
      localStorage.setItem('timeout_limit', res.data.timeout_limit || 10)
      localStorage.setItem('isB2BUser', res.data.isB2BUser || false)
      localStorage.setItem('showAutoClockoutTimeCards', res.data.showAutoClockoutTimeCards || false)
      
      return {
        ...res.data,
        loginStatus: true,
        twoFactor: false
      }
    }
    return {
      ...res.data,
      loginStatus: false,
      twoFactor: true
    };
  }).catch(err => {
    console.error(err)
    return { 
      success: false,
      reason: err.response.data.message,
      faceAuthPending: err && err.response && err.response.data && err.response.data.faceAuthPending,
      faceAuthError: err && err.response && err.response.data && err.response.data.faceAuthError,
    }
  });
};

export const verify2Auth = payload => axios.post(`${API_ENDPOINT_URL}/auth/2factor/verify`, {
  ...payload
}).then((res) => {
  if (res.data) {
    setAccessToken(res.data.token); // no-use-before-define
    setUserID(res.data.user.id);
    let permissions = res.data.user.permissions; // eslint-disable-line
    delete res.data.user.permissions;
    setUser(res.data.user);
    setPermissions(permissions);
    setSidebars(res.data.user.sidebarItems);
    localStorage.setItem('logo', res.data.user.logo_url || 'https://cdn.centraverse.com/logos/centraverse.svg');
    localStorage.setItem('geolocation_restriction_required', res.data.geolocation_restriction_required || false)
    return res.data
  }
  return { success: false, reason: res.message };
}).catch(err => ({ success: false, reason: err.response.data.message }));

export const resend2FactorCode = payload => axios.post(`${API_ENDPOINT_URL}/auth/2factor/resend`, {
  ...payload
}).then((res) => {
  if (res.data) {
    return res.data
  }
  return { success: false, reason: res.message };
}).catch(err => ({ success: false, reason: err.response.data.message }));

export const signUpRequest = payload => axios.post(`${API_ENDPOINT_URL}/auth/signup`, {
  email: payload.email,
  password: payload.password_2,
  first_name: payload.first_name,
  last_name: payload.last_name,
  // username: payload.username
}).then((res) => {
  if (res.data.user) {
    setAccessToken(res.data.token);
    setUserID(res.data.user.id);
    setUser(res.data.user);
    return { success: true };
  }
  return { success: false, reason: res.message };
}).catch(err => ({ success: false, reason: err.response.data.message }));

export const forgotPasswordRequest = payload => axios.post(`${API_ENDPOINT_URL}/auth/forgot-password`, {
  email: payload.email,
}).then((res) => {
  if (res.data.success) {
    return { success: true };
  }
  return { success: false, reason: res.message };
}).catch(err => ({ success: false, reason: err.response.data.message }));

export const resetPasswordRequest = (payload) => {
  return axios.post(`${API_ENDPOINT_URL}/auth/reset_password/${payload.token}`, {
    password: payload.password,
  }).then((res) => {
    if (res.data.success) {
      // setAccessToken(res.data.token); // no-use-before-define
      // setUserID(res.data.user.id);
      // let permissions = res.data.user.permissions; // eslint-disable-line
      // delete res.data.user.permissions;
      // setUser(res.data.user);
      // setPermissions(permissions);
      // setSidebars(res.data.user.sidebarItems);
      // localStorage.setItem('store_id', res.data.user.store.id)
      // localStorage.setItem('store_unique_id', res.data.user.store.unique_id)
      // localStorage.setItem('store_name', res.data.user.store.name)
      // localStorage.setItem('logo', res.data.user.logo_url || 'https://cdn.centraverse.com/logos/centraverse.svg');
      // localStorage.setItem('geolocation_restriction_required', res.data.geolocation_restriction_required || false)
      return { success: true, user: res.data.user };
    }
    return { success: false, reason: res.message };
  }).catch(err => ({ success: false, reason: err.response.data.message }));
};

export const updateAccount = (payload) => {
  const { id, uuid, ...data } = payload;
  const url = `${API_ENDPOINT_URL}/users/${payload.id}`;
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then((res) => {
    setUser(res.data);
    return { success: true, data: res.data };
  }).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Phone Verification
export const overridePhoneVerification = (payload) => {
  const url = `${API_ENDPOINT_URL}/phone-verification/verify/override`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCarriers = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/company-carriers`, payload.params);
  return axios.get(url, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: (err && err.response && err.response.data && err.response.data.message) || 'Unknown/Network Error!',
  }));
};

export const employeeBulkupload = (payload) => {
  const url = `${API_ENDPOINT_URL}/employee-bulk-upload`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getPermissionGroups = (payload) => {
  const url = '/permission-groups';
  return axios1.get(url, {
    params: {
      ...payload.params,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getPermissionGroupById = (payload) => {
  const url = `/permission-groups/${payload.id}`;
  return axios1.get(url, {
    params: {
      ...payload.params,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addPermissionGroup = (payload) => {
  const url = '/permission-groups';
  return axios1.post(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updatePermissionGroup = (payload) => {
  const url = `/permission-groups/${payload.id}`;
  return axios1.patch(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deletePermissionGroup = (payload) => {
  const url = `/permission-groups/${payload.id}`;
  return axios1.delete(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserRoles = (payload) => {
  const url = '/user-roles';
  return axios1.get(url, {
    params: {
      ...payload.params,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRoles = (payload) => {
  const url = '/roles';
  return axios1.get(url, {
    params: {
      ...payload.params,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRoleById = (payload) => {
  const url = `/roles/${payload.id}`;
  return axios1.get(url, {
    params: {
      ...payload.params,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addRole = (payload) => {
  const url = '/roles';
  return axios1.post(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteRole = (payload) => {
  const url = `/roles/${payload.id}`;
  return axios1.delete(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const activateRole = (payload) => {
  const url = `/roles/activate/${payload.id}`;
  return axios1.delete(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateRole = (payload) => {
  const url = `/roles/${payload.id}`;
  return axios1.patch(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};



// POS Categories
export const getPosCategories = (payload) => {
  const url = makeUrl('/categories', payload.params);

  return axios1.get(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getPosCategoriesCount = (payload) => {
  const url = '/categories/count';

  return axios1.get(url, {

  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getPosCategoryById = (payload) => {
  const url = `/categories/${payload && payload.id}`;
  return axios1.get(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addPosCategory = (payload) => {
  const url = '/categories';
  return axios1.post(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updatePosCategory = (payload) => {
  const url = `/categories/${payload.id}`;
  return axios1.patch(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deletePosCategory = (payload) => {
  const url = `/categories/${payload.id}`;
  return axios1.delete(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Companies
export const getCompanies = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/companies/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCompaniesCount = () => {
  const url = `${API_ENDPOINT_URL}/companies/count`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCompanyById = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies/${payload && payload.id}${payload.params && payload.params.related ? "/?related=" + payload.params.related : ''}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addCompany = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateCompany = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteCompany = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Districts
export const getDistricts = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/districts/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getDistrictsCount = () => {
  const url = `${API_ENDPOINT_URL}/districts/count`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getDistrictById = (payload) => {
  const url = `${API_ENDPOINT_URL}/districts/${payload && payload.id}${payload.params && payload.params.related ? "/?related=" + payload.params.related : ''}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addDistrict = (payload) => {
  const url = `${API_ENDPOINT_URL}/districts`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateDistrict = (payload, id) => {
  const url = `${API_ENDPOINT_URL}/districts/${payload.id}`;
  delete payload.id;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteDistrict = (payload) => {
  const url = `${API_ENDPOINT_URL}/districts/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const activeDistrict = (payload) => {
  const url = `${API_ENDPOINT_URL}/districts/activate/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Manufacturers
export const getManufacturers = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/manufacturers/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getManufacturersCount = () => {
  const url = `${API_ENDPOINT_URL}/manufacturers/count`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getManufacturerById = (payload) => {
  const url = `${API_ENDPOINT_URL}/manufacturers/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addManufacturer = (payload) => {
  const url = `${API_ENDPOINT_URL}/manufacturers`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateManufacturer = (payload) => {
  const url = `${API_ENDPOINT_URL}/manufacturers/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteManufacturer = (payload) => {
  const url = `${API_ENDPOINT_URL}/manufacturers/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// Upload company document
export const uploadCompanyDocument = async (payload = {}) => {
  const body = { file_type: payload.selectedFile.type, file_name: payload.selectedFile.name };
  const url = `${API_ENDPOINT_URL}/companies/documents/upload`;
  try {
    const fileUploadResponse = await axios.post(url, body, {
      headers: { Authorization: `Bearer ${getAccessToken()}` }
    });
    const { signedUrl } = fileUploadResponse.data;
    const sURL = signedUrl.split('?')[0];
    await axios.put(signedUrl, payload.selectedFile, { headers: { 'Content-Type': payload.selectedFile.type } });
    return { success: true, data: sURL };
  } catch (err) {
    return {
      success: false,
      message: (err && err.response && err.response.data.message || 'Network/Unknown Error'),
    };
  };
};



// Orders
export const getOrders = (payload = {}) => {
  // const url = `${API_ENDPOINT_URL}/orders/?${(payload.company_id) ?
  //   `filter[company_id]=${payload.company_id}` : (null)}${(payload.related) ?
  //   `&related=${payload.related}` : (null)}`;
  const url = makeUrl(`${API_ENDPOINT_URL}/orders`, payload.params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const getAccessoryOrders = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/accessory-orders`, payload.params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};
export const getOrdersCount = () => {
  const url = `${API_ENDPOINT_URL}/orders/count?filter[status]=open`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getTodaysOrdersCount = () => {
  const url = `${API_ENDPOINT_URL}/orders/count/today`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Payments
export const getPayments = (payload = {}) => {
  let url = makeUrl(`${API_ENDPOINT_URL}/payments/`, { ...payload.params, ...payload.pagination });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getPaymentsCount = () => {
  const url = `${API_ENDPOINT_URL}/payments/count`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Get Company Documents
export const getCompanyDocuments = (payload) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/companies/documents/`, payload.params);
  return axios.get(url, {
    headers: { Authorization: `bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: (err && err.response && err.response.data.message || 'Network/Unknown Error'),
  }));
};

// Delete Company Document
export const deleteCompanyDocument = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies/documents/${payload.id}`;
  return axios.delete(url, {
    headers: { Authorization: `bearer ${getAccessToken()}`, 'Content-Type': 'application/json' },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: (err && err.response && err.response.data.message || 'Network/Unknown Error'),
  }));
};

// Add Company Document
export const addCompanyDocument = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies/documents/`;
  return axios.post(url, payload, {
    headers: { Authorization: `bearer ${getAccessToken()}` },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: (err && err.response && err.response.data.message || 'Network/Unknown Error'),
  }));
};

// Update Company Document
export const updateCompanyDocument = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies/documents/${payload.id}`;
  const body = {
    document_route: payload.document_route,
    document_title: payload.document_title,
    document_type: payload.document_type,
  };
  return axios.patch(url, body, {
    headers: { Authorization: `bearer ${getAccessToken()}` },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: (err && err.response && err.response.data.message || 'Network/Unknown Error'),
  }));
};

export const getPaymentById = (payload) => {
  const url = `${API_ENDPOINT_URL}/payments/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addPayment = (payload) => {
  const url = `${API_ENDPOINT_URL}/companies/${payload.id}/pay_balance`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updatePayment = (payload) => {
  const url = `${API_ENDPOINT_URL}/payments/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deletePayment = (payload) => {
  const url = `${API_ENDPOINT_URL}/payments/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};
export const getOrderById = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/orders/${payload && payload.id}`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addOrder = (payload) => {
  const url = `${API_ENDPOINT_URL}/orders`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const updateOrder = (payload) => {
  const url = `${API_ENDPOINT_URL}/orders/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const deleteOrder = (payload) => {
  const url = `${API_ENDPOINT_URL}/orders/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// Stores
export const getStores = (payload = {}) => {
  const params = payload.company_id ? { 'filterRelated[region.company_id]': payload.company_id, ...payload.pagination } : { ...payload.pagination };
  const url = makeUrl(`${API_ENDPOINT_URL}/stores`, payload.params ? { ...params, ...payload.params } :
    params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCompanyStore = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/stores/locations/all`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getAllStores = (payload = {}) => {
  const params = payload.company_id ? { 'filter[company_id]': payload.company_id, ...payload.pagination } : { ...payload.pagination };
  const url = makeUrl(`${API_ENDPOINT_URL}/stores/all`, payload.params ? { ...params, ...payload.params } :
    params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};
export const getStoreById = (payload) => {
  const url = `${API_ENDPOINT_URL}/stores/${payload && payload.id}${payload.params && payload.params.related ? "/?related=" + payload.params.related : ''}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addStore = (payload) => {
  const url = `${API_ENDPOINT_URL}/stores`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateStore = (payload = {}) => {
  const url = `${API_ENDPOINT_URL}/stores/${payload.id}`;
  delete payload.id;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteStore = (payload) => {
  const url = `${API_ENDPOINT_URL}/stores/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const activeStore = (payload) => {
  const url = `${API_ENDPOINT_URL}/stores/activate/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getStoreTimeStatus = (payload) => {
  const url = `${API_ENDPOINT_URL}/stores/time_status`;
  return axios.get(url, {
    params: payload,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Users
export const getUsers = (payload = {}) => {
  const params = payload.company_id ? { 'filter[company_id]': payload.company_id, ...payload.pagination } : { ...payload.pagination };
  const url = makeUrl(`${API_ENDPOINT_URL}/users`, payload.params ? { ...params, ...payload.params } :
    params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUsersCount = () => {
  const url = `${API_ENDPOINT_URL}/users/count`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserById = (payload = {}) => {
  const url = `${API_ENDPOINT_URL}/users/${payload && payload.id}/?related=${(payload.params && payload.params.related) || ''}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addUser = (payload) => {
  const url = `${API_ENDPOINT_URL}/users`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateUser = (payload) => {
  const { id, auth_uuid, ...data } = payload; // eslint-disable-line
  const url = `${API_ENDPOINT_URL}/users/${payload.id}`;
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteUser = (payload) => {
  const url = `${API_ENDPOINT_URL}/users/${payload}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => (
    {
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const lockUser = (payload) => {
  const url = `${API_ENDPOINT_URL}/lock-user`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
  }).then(res => (
    res.status === 200
      ? history.push('/employees')
      : null,
    {
      success: true,
      data: res.data,
    })).catch(err => ({
      success: false,
      message: err.response.data.message,
    }));
};

export const unlockUser = (payload) => {
  const url = `${API_ENDPOINT_URL}/unlock-user`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
  }).then(res => (
    console.log(res),
    res.status === 200
      ? history.push('/employees')
      : null,
    {
      success: true,
      data: res.data,
    })).catch(err => ({
      success: false,
      message: err.response.data.message,
    }));
};

export const reactivateEmployee = (payload) => {
  const url = `${API_ENDPOINT_URL}/employees/reactivate`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => (
    res.status === 200
      ? history.push('/employees')
      : null,
    {
      success: true,
      data: res.data,
    })).catch(err => ({
      success: false,
      message: err.response.data.message,
    }));
};

export const terminationNotes = (payload) => {
  const url = `${API_ENDPOINT_URL}/employee-termination`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const logPdfLink = (url) => {
  const requrl = `${API_ENDPOINT_URL}/user-link-clicks`;
  return axios.post(requrl, { url }, {
    headers: {
      Authorization: `bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const updateUserPassword = (payload) => {
  const url = `${API_ENDPOINT_URL}/users/change-password`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// update password by super admin

export const updateUserPasswordByAdmin = (payload) => {
  const url = `${API_ENDPOINT_URL}/users/change-password-admin`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};
// Get Serials
export const fetchSerials = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/product-serials`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export function setAccessToken(token) {
  localStorage.setItem('authentication_token', token);
}

export const getAccessToken = () => localStorage.getItem('authentication_token');
// export const getIncommToken = () => Cookies.get(''__incomm'');

export function setUserID(id) {
  localStorage.setItem('user_id', id);
}

export function setUser(user) {
  const localUser = JSON.parse(localStorage.getItem('user'));
  localStorage.setItem('user', JSON.stringify({...localUser, ...user}));
}

export const setUserlocalStorage = (user) => {
  localStorage.setItem('user', user);
};

export const setSidebars = (sidebars) => {
  localStorage.setItem('sidebars', JSON.stringify(sidebars));
};

export const setPermissions = (perms) => {
  localStorage.setItem('permissions', JSON.stringify(perms));
};

export const getPermissions = () => {
  if (!localStorage.getItem('permissions')) {
    return false;
  }
  return JSON.parse(localStorage.getItem('permissions'));
};

export const getUserID = () => {
  if (!localStorage.getItem('user_id')) {
    return false;
  }
  return localStorage.getItem('user_id');
};

export const getUser = () => {
  if (!localStorage.getItem('user')) {
    return false;
  }
  return JSON.parse(localStorage.getItem('user'));
};

export const logOut = () => {
  localStorage.setItem('access_token', null);
  localStorage.setItem('user_id', null);
  localStorage.setItem('user', null);
};

// create resources

export const createResources = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/resources`);
  return axios.post(url, { ...payload }, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// get resources 
export const getResources = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/resources`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// fetch Resources By ID

export const getResourceById = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/resources/${payload.params.id}`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// update resource

export const updateResource = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/resources/${payload.id}`);
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// delete resource
export const deleteResource = (payload) => {
  const url = `${LOTUS_API_ENDPOINT_URL}/resources/${payload.params.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// ImeiAudit company account
export const registerCompanyPaymentAccount = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/charge/account`);
  return axios.post(url, {}, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// ImeiAudit company account
export const getCompanyPaymentAccount = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/charge/account/${payload.store_id}`);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// ImeiAudit company account
export const updateCompanyPaymentAccount = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/charge/account/${payload.account_id}`);
  return axios.patch(url, { ...payload }, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Add a person to  company account
export const updatePersonforPaymentAccount = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/charge/account/person/${payload.account_id}`);
  return axios.post(url, { ...payload }, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Add a person to  company account
export const enableChargePaymentAccount = (payload) => {
  const url = `${LOTUS_API_ENDPOINT_URL}/charge/account/enable-charge`;
  return axios.post(url, { ...payload }, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Add a person to  company account
export const createInvoicePayment = (payload) => {
  const url = `${LOTUS_API_ENDPOINT_URL}/invoice-payments`;
  return axios.post(url, { ...payload }, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getPersonforPaymentAccount = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/charge/account/person/?store_id=${payload.store_id}`);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getPaymentTypes = (payload) => {
  const url = makeUrl(`${LOTUS_API_ENDPOINT_URL}/payment-types`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// permission categories

export const getNotificationTemplates = (payload) => {
  const url = `${API_ENDPOINT_URL}/notification-templates`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getNotificationTemplateById = (payload) => {
  const url = `${API_ENDPOINT_URL}/notification-templates/${payload.id}`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addNotificationTemplate = (payload) => {
  const url = `${API_ENDPOINT_URL}/notification-templates`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteNotificationTemplate = (payload) => {
  const url = `${API_ENDPOINT_URL}/notification-templates/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateNotificationTemplate = (payload) => {
  const url = `${API_ENDPOINT_URL}/notification-templates/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const getListSubscribers = (payload) => {
  const url = `${API_ENDPOINT_URL}/list-subscribers`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getListSubscriberById = (payload) => {
  const url = `${API_ENDPOINT_URL}/list-subscribers/${payload.id}`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addListSubscriber = (payload) => {
  const url = `${API_ENDPOINT_URL}/list-subscribers`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteListSubscriber = (payload) => {
  const url = `${API_ENDPOINT_URL}/list-subscribers/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateListSubscriber = (payload) => {
  const url = `${API_ENDPOINT_URL}/list-subscribers/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getMenuFolders = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folders`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getMenuFolderById = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folders/${payload.id}`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addMenuFolder = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folders`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteMenuFolder = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folders/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateMenuFolder = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folders/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getMenuFolderItems = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folder-items`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getMenuFolderItemById = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folder-items/${payload.id}`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addMenuFolderItem = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folder-items`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteMenuFolderItem = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folder-items/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateMenuFolderItem = (payload) => {
  const url = `${API_ENDPOINT_URL}/menu-folder-items/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getSidebarItems = (payload) => {
  const url = `${API_ENDPOINT_URL}/sidebar-items`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCompanyStaticAssets = (payload) => {
  const url = `${API_ENDPOINT_URL}/company-assets`;
  return axios.get(url, {
    params: { ...payload.params },
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err && err.response && err.response.data && err.response.data.message,
  }));
};

export const getSidebarItemById = (payload) => {
  const url = `${API_ENDPOINT_URL}/sidebar-items/${payload.id}`;
  return axios.get(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addSidebarItem = (payload) => {
  const url = `${API_ENDPOINT_URL}/sidebar-items`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteSidebarItem = (payload) => {
  const url = `${API_ENDPOINT_URL}/sidebar-items/${payload.id}`;
  return axios.delete(url, {
    params: {
      ...payload.params
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateSidebarItem = (payload) => {
  const url = `${API_ENDPOINT_URL}/sidebar-items/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const createStripeExpress = (payload) => {
  const url = `${API_ENDPOINT_URL}/charge/`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const removePaymentAccount = (payload) => {
  const url = `${API_ENDPOINT_URL}/charge/deauth/express`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};



export const addBillPayment = (payload) => {
  const url = `${API_ENDPOINT_URL}/billpay-transactions`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getBillPayments = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/billpay-transactions/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateBillPayment = (payload) => {
  const url = `${API_ENDPOINT_URL}/bill-payments/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteBillPayment = (payload) => {
  const url = `${API_ENDPOINT_URL}/bill-payments/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getBillPaymentById = (payload) => {
  const url = `${API_ENDPOINT_URL}/bill-payments/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addDrawer = (payload) => {
  const url = `${API_ENDPOINT_URL}/drawers`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getDrawers = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/drawers/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateDrawer = (payload) => {
  const url = `${API_ENDPOINT_URL}/drawers/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteDrawer = (payload) => {
  const url = `${API_ENDPOINT_URL}/drawers/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getDrawerById = (payload) => {
  const url = `${API_ENDPOINT_URL}/drawers/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addRegister = (payload) => {
  const url = `${API_ENDPOINT_URL}/registers`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRegisters = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/iregisters/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateRegister = (payload) => {
  const url = `${API_ENDPOINT_URL}/registers/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteRegister = (payload) => {
  const url = `${API_ENDPOINT_URL}/registers/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRegisterById = (payload) => {
  const url = `${API_ENDPOINT_URL}/registers/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCurrentStoreUsers = (payload) => {
  const url = `${API_ENDPOINT_URL}/users/store-token/?filterRelated[storesCanOrderFor.unique_id.]=${payload.id}&related=currentStore`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addImeiAudit = (payload) => {
  const url = `${API_ENDPOINT_URL}/imei-audits`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getImeiAudits = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/imei-audits/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateImeiAudit = (payload) => {
  const url = `${API_ENDPOINT_URL}/imei-audits/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteImeiAudit = (payload) => {
  const url = `${API_ENDPOINT_URL}/imei-audits/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getImeiAuditById = (payload) => {
  const url = `${API_ENDPOINT_URL}/imei-audits/${payload && payload.id}`;
  return axios.get(url, {
    params: {
      ...payload.params,
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addImeiAuditResult = (payload) => {
  const url = `${API_ENDPOINT_URL}/imei-audit-results`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getImeiAuditResults = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/imei-audit-results/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCustomImeiAuditResults = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/imei-audit-results/custom-reports`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Region
export const addRegion = (payload) => {
  const url = `${API_ENDPOINT_URL}/regions`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRegions = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/regions/notificaion-data`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRegionsSearch = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/regions`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateRegion = (payload) => {
  const url = `${API_ENDPOINT_URL}/regions/${payload.id}`;
  delete payload.id;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteRegion = (payload) => {
  const url = `${API_ENDPOINT_URL}/regions/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const activateRegion = (payload) => {
  const url = `${API_ENDPOINT_URL}/regions/activate/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRegionById = (payload) => {
  const url = `${API_ENDPOINT_URL}/regions/${payload && payload.id}${payload.params && payload.params.related ? "/?related=" + payload.params.related : ''}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Chart
export const addChart = (payload) => {
  const url = `${API_ENDPOINT_URL}/charts`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCharts = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/charts/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateChart = (payload) => {
  const url = `${API_ENDPOINT_URL}/charts/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteChart = (payload) => {
  const url = `${API_ENDPOINT_URL}/charts/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getChartById = (payload) => {
  const url = `${API_ENDPOINT_URL}/charts/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// ChartType
export const addChartType = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-types`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getChartTypes = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/chart-types/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateChartType = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-types/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteChartType = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-types/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getChartTypeById = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-types/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// ChartXAttribute
export const addChartXAttribute = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-x-attributes`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getChartXAttributes = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/chart-x-attributes/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateChartXAttribute = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-x-attributes/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteChartXAttribute = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-x-attributes/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getChartXAttributeById = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-x-attributes/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// ChartYAttribute
export const addChartYAttribute = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-y-attributes`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getChartYAttributes = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/chart-y-attributes/`, { ...payload.params, ...payload.pagination });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateChartYAttribute = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-y-attributes/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteChartYAttribute = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-y-attributes/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getChartYAttributeById = (payload) => {
  const url = `${API_ENDPOINT_URL}/chart-y-attributes/${payload && payload.id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// Departments
export const getDepartments = (payload) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/departments/${payload && payload.params && payload.params.company_id}`);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addDepartment = (payload) => {
  const url = `${API_ENDPOINT_URL}/departments`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateDepartment = (payload) => {
  const url = `${API_ENDPOINT_URL}/departments/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteDepartment = (payload) => {
  const url = `${API_ENDPOINT_URL}/departments/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// DepartmentRoles
export const getDepartmentRoles = (payload) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/department-roles/${payload && payload.params && payload.params.company_id}`);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addDepartmentRole = (payload) => {
  const url = `${API_ENDPOINT_URL}/department-roles/`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateDepartmentRole = (payload) => {
  const url = `${API_ENDPOINT_URL}/department-roles/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteDepartmentRole = (payload) => {
  const url = `${API_ENDPOINT_URL}/department-roles/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const getSchedulesByUser = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/hr-web-clockin/${payload && payload.params && payload.params.user_id}&${payload && payload.params && payload.params.store_id}`,
  );

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addTimecardForClockin = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-web-clockin/`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
    faceAuthPending: err && err.response && err.response.data && err.response.data.faceAuthPending,
    faceAuthError: err && err.response && err.response.data && err.response.data.faceAuthError,
  }));
};

export const updateTimecardForClockout = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-web-clockin/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
    faceAuthPending: err && err.response && err.response.data && err.response.data.faceAuthPending,
    faceAuthError: err && err.response && err.response.data && err.response.data.faceAuthError,
  }));
};

export const getTimeForStoreTimezone = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/hr-web-clockin/time/${payload && payload.params && payload.params.store_id}`,
  );

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getStatusForWebClockin = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/hr-web-clockin/status/${payload && payload.params && payload.params.user_id}&${payload && payload.params && payload.params.store_id}`,
  );

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getBreakSettingsForStore = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/hr-timecard-breaks/break_settings/${payload && payload.params && payload.params.store_id}&${payload && payload.params && payload.params.user_id}`,
  );

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const fetchHRSettingsStores = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-settings-stores/${payload && payload.store_id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateHRSettingsStore = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-settings-stores/`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const fetchHRSettingsCompanies = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-settings-companies/${payload && payload.company_id}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateHRSettingsCompany = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-settings-companies/`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const fetchTimecardBreaksForTimesheet = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-timecard-breaks/timesheet-breaks`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addTimecardBreak = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-timecard-breaks/`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
    faceAuthPending: err && err.response && err.response.data && err.response.data.faceAuthPending,
    faceAuthError: err && err.response && err.response.data && err.response.data.faceAuthError,
  }));
};

export const updateTimecardBreak = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-timecard-breaks/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
    faceAuthPending: err && err.response && err.response.data && err.response.data.faceAuthPending,
    faceAuthError: err && err.response && err.response.data && err.response.data.faceAuthError,
  }));
};

// selectlist
export const getSelectListScheduleShifts = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-selectlists/schedule-shift`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getSelectListEmployees = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-selectlists/employees`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response ? err.response.data.message : '',
  }));
};

export const getSelectListDepartmentRoles = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-selectlists/departmentroles`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};
export const pinLogInRequest = (payload) => {// eslint-disable-line
  return axios.post(`${API_ENDPOINT_URL}/hr-web-clockin/verifypin/`, payload).then((res) => {
    if (res.data.success) {
      if (res.data.data) {
        return {
          ...res.data
        }
      } else {
        return {
          success: false,
          reason: "Invalid Pin"
        };
      }
    }
    return {
      success: false,
      reason: "Invalid Pin"
    };
  }).catch(err => {
    console.error(err)
    return { success: false, reason: err.response.data.message }
  });
};


export const reqestStatusForUrlClockin = (payload) => {// eslint-disable-line
  return axios.post(`${API_ENDPOINT_URL}/hr-web-clockin/status/`, {
    user_id: payload.user_id,
    store_id: payload.store_id,
  }).then((res) => {
    if (res.data.success) {
      return {
        ...res.data
      }
    }
    return {
      ...res.data
    }
  }).catch(err => {
    console.error(err)
    return { success: false, reason: err.response.data.message }
  });
};

export const reqestTimeForStoreTimezone = (payload) => {// eslint-disable-line
  return axios.post(`${API_ENDPOINT_URL}/hr-web-clockin/time/`, {
    store_id: payload.store_id,
  }).then((res) => {
    if (res.data.success) {
      return {
        ...res.data
      }
    }
    return {
      ...res.data
    }
  }).catch(err => {
    console.error(err)
    return { success: false, reason: err.response.data.message }
  });
};

export const reqestSchedulesByUser = (payload) => {// eslint-disable-line
  return axios.post(`${API_ENDPOINT_URL}/hr-web-clockin/schedules/`, {
    user_id: payload.user_id,
    store_id: payload.store_id,
  }).then((res) => {
    if (res.data.success) {
      return {
        ...res.data
      }
    }
    return {
      ...res.data
    }
  }).catch(err => {
    console.error(err)
    return { success: false, reason: err.response.data.message }
  });
};

export const reqestBreakSettingsForStore = (payload) => {// eslint-disable-line
  return axios.post(`${API_ENDPOINT_URL}/hr-timecard-breaks/break_settings/`, {
    user_id: payload.user_id,
    store_id: payload.store_id,
  }).then((res) => {
    if (res.data.success) {
      return {
        ...res.data
      }
    }
    return {
      ...res.data
    }
  }).catch(err => {
    console.error(err)
    return { success: false, reason: err.response.data.message }
  });
};


export const getEmployeeRoster = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/hr-reports/employee-roster/`, { ...payload.params });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getSelectListStores = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/hr-reports/stores-list/${payload && payload.params && payload.params.company_id}`);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// UserStores
export const getUserStores = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/user-stores/`, { ...(payload.params || {}) });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserStoreById = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/user-stores/${id}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserStoreByStoreId = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/user-stores/users/${id}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserStorePrimaryByStoreId = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/user-stores/primary-locations/${id}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addUserStore = (payload) => {
  const url = `${API_ENDPOINT_URL}/user-stores`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateUserStore = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/user-stores/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteUserStore = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/user-stores/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// UserRegions
export const getUserRegions = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/user-regions/`, { ...(payload.params || {}) });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserRegionById = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/user-regions/${id}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addUserRegion = (payload) => {
  const url = `${API_ENDPOINT_URL}/user-regions`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateUserRegion = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/user-regions/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteUserRegion = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/user-regions/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// UserDistricts
export const getUserDistricts = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/user-districts/`, { ...(payload.params || {}) });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserDistrictById = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/user-districts/${id}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addUserDistrict = (payload) => {
  const url = `${API_ENDPOINT_URL}/user-districts`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateUserDistrict = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/user-districts/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteUserDistrict = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/user-districts/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};




export const getHrAvailability = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-availability/getavailability`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const saveHrAvailability = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-availability/saveavailability`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// EmbedsAndLinks
export const getEmbedsAndLinks = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/embeds/`, { ...(payload.params || {}) });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const deleteHrAvailability = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-availability/deleteavailability`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getEmbedsAndLinkBySlug = (payload) => {
  const slug = payload.slug
  const url = makeUrl(`${API_ENDPOINT_URL}/embeds/${slug}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const addEmbedsAndLink = (payload) => {
  const url = `${API_ENDPOINT_URL}/embeds`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getHrontimearrival = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-reports/hrontimearrival-report`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateEmbedsAndLink = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/embeds/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getHRschedulevsactualhrs = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-reports/hrschedulevsactualhrs-report`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteEmbedsAndLink = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/embeds/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const UsersUpload = (payload) => {
  const url = `${API_ENDPOINT_URL}/user-bulk-upload`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const getStoresReport = (payload = {}) => {
  const url = `${API_ENDPOINT_URL}/stores/report`
  
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// Employees
export const getEmployees = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/employees`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};



export const addUserEmployee = (payload) => {
  const url = `${API_ENDPOINT_URL}/employees/new`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getHRoverviewreport = (payload) => {
  const url = `${API_ENDPOINT_URL}/hr-reports/hroverview-report`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getEmployeeById = (payload = {}) => {
  const url = `${API_ENDPOINT_URL}/employees/${payload && payload.id}`;
  return axios.get(url, {
    params: {
      ...payload.params,
    },
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteEmployeeById = (payload = {}) => {
  const url = `${API_ENDPOINT_URL}/employees/${payload && payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateEmployeeById = (payload) => {
  const url = `${API_ENDPOINT_URL}/employees/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const uploadProfilPic = async (payload = {}) => {
  const body = {
    file_type: payload.selectedFile.type,
    file_name: payload.selectedFile.name,
  }
  const url = `${API_ENDPOINT_URL}/photos/sign`;
  try {
    let p1 = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      }
    })
    const { signedUrl } = p1.data;
    const imgUrl = signedUrl.split('?')[0];
    const options = {
      headers: {
        'Content-Type': payload.selectedFile.type,
      }
    }
    let p2 = await axios.put(signedUrl, payload.selectedFile, options)
    return {
      success: true,
      data: imgUrl,
    }

  } catch (err) {
    ({
      success: false,
      message: err.response.data.message,
    })

  };
}


// Supervisors List
export const getSupervisorLists = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/employees/supervisors`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const getAllStoresLocations = (payload = {}) => {
  // const url = makeUrl(`${API_ENDPOINT_URL}/stores`);
  const params = payload.company_id ? { 'filter[company_id]': payload.company_id, ...payload.pagination } : { ...payload.pagination };
  const url = makeUrl(`${API_ENDPOINT_URL}/stores/all`, payload.params ? { ...params, ...payload.params } :
    params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// SupportTicketTypes
export const getSupportTicketTypes = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/support-ticket-types/`, { ...(payload.params || {}) });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getSupportTicketTypeById = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/support-ticket-types/${id}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addSupportTicketType = (payload) => {
  const url = `${API_ENDPOINT_URL}/support-ticket-types`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateSupportTicketType = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/support-ticket-types/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteSupportTicketType = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/support-ticket-types/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

// SupportTickets
export const getSupportTickets = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/support-tickets/`, { ...(payload.params || {}) });

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getSupportTicketById = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/support-tickets/${id}`, { ...(payload.params || {}) });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addSupportTicket = (payload) => {
  const url = `${API_ENDPOINT_URL}/support-tickets`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateSupportTicket = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/support-tickets/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteSupportTicket = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/support-tickets/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const mobilePinChange = (payload) => {
  const id = payload.user_id;
  const url = `${API_ENDPOINT_URL}/users/mobile-pin-change/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const faceUpdate = (payload) => {
  const id = payload.user_id;
  const url = `${API_ENDPOINT_URL}/users/update-face/${id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


// Required Fields
export const getRequiredFields = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/module-fields-admin`, payload.params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getRequiredFieldById = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/module-fields-admin/${id}`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addRequiredFields = (payload) => {
  const url = `${API_ENDPOINT_URL}/module-fields-admin`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateRequiredFields = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/module-fields-admin/update/bulk`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data.results,
  })).catch(err => ({
    success: false,
    message: err.response ? err.response.data.message : 'Network Error!',
  }));
};

// OneView Company Widget
export const getCompanyWidgets = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/oneview-company-widgets`, payload.params);

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getCompanyWidget = (payload) => {
  const id = payload.id
  const url = makeUrl(`${API_ENDPOINT_URL}/oneview-company-widgets/${id}`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addCompanyWidget = (payload) => {
  const url = `${API_ENDPOINT_URL}/oneview-company-widgets`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateCompanyWidget = (payload) => {
  const url = `${API_ENDPOINT_URL}/oneview-company-widgets`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteCompanyWidget = (payload) => {
  const id = payload.id
  const url = `${API_ENDPOINT_URL}/oneview-company-widgets/${id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateOneViewBgImg = (payload) => {
  const url = `${API_ENDPOINT_URL}/oneview-company-widgets/bg-image`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getHRTeamEmployee = (payload) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/employees/userID/`);
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: (err && err.response && err.response.data.message) || 'Network/Unknown Error',
  }));
};

export const getActiveSession = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/hr-timecards/active-session`, payload.params);
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

//Tax Apis

export const getTax = (payload = {}) => {
  const url = makeUrl(`${API_ENDPOINT_URL}/taxInfo/`, { ...payload.params, });
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
  console.log('doneeee')
};
export const addTax = (payload) => {
  console.log('payload',payload)
  const url = `${API_ENDPOINT_URL}/taxInfo`;
  return axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateTax = (payload) => {
  const url = `${API_ENDPOINT_URL}/taxInfo/${payload.id}`;
  return axios.patch(url, payload, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteTax = (payload) => {
  const url = `${API_ENDPOINT_URL}/taxInfo/${payload.id}`;
  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getUserAutoClockedOutDetails = (payload = {}) => {
  const url = '/hr-schedules/user-autoclocked-out';
  return axios1.post(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const savePublicContactUsData = (payload) => {
  const url = `${API_ENDPOINT_URL}/public-contact-us-form`;
  return axios.post(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const fetchInterestsData = () => {
  const url = `${API_ENDPOINT_URL}/public-contact-us-form`;
  return axios.get(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const getInventoryLeads = (payload = {}) => {
  const url = makeUrl('/inventory-leads', payload.params);

  return axios1.get(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};
export const getInventoryLeadById = (payload = {}) => {
  const url = makeUrl(`/inventory-leads/${payload && payload.id}`, payload.params);
  return axios1.get(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const addInventoryLead = (payload) => {
  const url = '/inventory-leads';
  return axios1.post(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const updateInventoryLead = (payload) => {
  const url = `/inventory-leads/${payload.id}`;
  return axios1.patch(url, payload).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};

export const deleteInventoryLead = (payload) => {
  const url = `/inventory-leads/${payload.id}`;
  return axios1.delete(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};


export const fetchLeadSourcesData = (payload) => {
  const url = `${API_ENDPOINT_URL}/public-contact-us-form/${payload.id}`;
  return axios.get(url).then(res => ({
    success: true,
    data: res.data,
  })).catch(err => ({
    success: false,
    message: err.response.data.message,
  }));
};
