import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  region: {},
  regions: [],
  search_regions: [],
  total: 0,
  loading: false,
  error: '',
  isB2BUser: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REGIONS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          regions: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        regions: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_REGIONS_REQUEST:
      return {
        ...state,
        regions: [],
        loading: true,
      };
    case types.FETCH_REGIONS_SEARCH_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          search_regions: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        search_regions: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_REGIONS_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_REGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_REGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_REGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_REGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACTIVATE_REGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_REGIONS_FAILED:
      return {
        ...state,
        regions: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_REGIONS_SEARCH_FAILED:
      return {
        ...state,
        search_regions: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_REGION_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          region: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        region: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_REGION_FAILED:
      return {
        ...state,
        region: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_REGION_SUCCESS:
      return {
        ...state,
        // regions: [...state.regions, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_REGION_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_REGION_SUCCESS:
      let regions = filter(state.regions, item => item.id !== action.res.data.id);

      return {
        ...state,
        // regions: [...regions, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_REGION_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.TOGGLE_B2B_REQUEST_REGION:
      return {
        ...state,
        isB2BUser: !state.isB2BUser,
      };

    case types.DELETE_REGION_SUCCESS:
      regions = filter(state.regions, item => item.id !== action.payload.id);
      const searchRegions = filter(state.search_regions, item => item.id !== action.payload.id);
      return {
        ...state,
        regions: [...regions],
        search_regions: searchRegions,
        status: true,
        loading: false,
      };
    case types.ACTIVATE_REGION_SUCCESS:
      // regions = filter(state.regions, item => item.id !== action.payload.id);
      // const searchRegions = filter(state.search_regions, item => item.id !== action.payload.id);
      // console.log('regreducerstate', state);
      return {
        ...state,
        // regions: [...regions],
        // search_regions: [...searchRegions],
        status: true,
        loading: false,
      };
    case types.DELETE_REGION_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.ACTIVATE_REGION_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
