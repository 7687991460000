import queryString from 'query-string';

const makeUrl = (uri, params) => {
  if (params && Object.keys(params).length) {
    return `${uri}?${queryString.stringify(params)}`;
  }

  return uri;
};

export default makeUrl;
