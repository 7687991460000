import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as types1 from 'constants/actionTypes';
import {
  addRole, deleteRole, getRoleById, getRoles, updateRole, getUserRoles, activateRole,
} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_ROLES_REQUEST, fetchRolesSaga);
  yield takeLatest(types.FETCH_ROLES_SEARCH_REQUEST, fetchRolesSaga);
  yield takeLatest(types.FETCH_ROLE_REQUEST, fetchRoleSaga);
  yield takeLatest(types.ADD_ROLE_REQUEST, addRoleSaga, context);
  yield takeLatest(types.UPDATE_ROLE_REQUEST, updateRoleSaga, context);
  yield takeLatest(types.DELETE_ROLE_REQUEST, deleteRoleSaga);
  yield takeLatest(types.ACTIVATE_ROLE_REQUEST, activateRoleSaga);
  yield takeLatest(types.FETCH_USER_ROLES_REQUEST, fetchUserRolesSaga);
}

export function* fetchRolesSaga({ payload }) {
  try {
    const res = yield call(getRoles, payload);
    yield all([
      put({ type: types.FETCH_ROLES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_ROLES_FAILED, error });
  }
}


export function* fetchUserRolesSaga({ payload }) {
  try {
    const res = yield call(getUserRoles, payload);
    yield all([
      put({ type: types.FETCH_USER_ROLES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USER_ROLES_FAILED, error });
  }
}

export function* fetchRoleSaga({ payload }) {
  try {
    const res = yield call(getRoleById, payload);
    yield all([
      put({ type: types.FETCH_ROLE_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_ROLE_FAILED, error });
  }
}

export function* addRoleSaga({ history }, { payload }) {
  try {
    const res = yield call(addRole, payload);
    yield all([
      put({ type: types.ADD_ROLE_SUCCESS, res }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Role added' : res.message || 'Role not added',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/admin-role-settings');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_ROLE_FAILED, error }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateRoleSaga({ history }, { payload }) {
  try {
    const res = yield call(updateRole, payload);
    yield all([
      put({ type: types.UPDATE_ROLE_SUCCESS, res }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Role updated' : res.message || 'Role not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/admin-role-settings');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_ROLE_FAILED, error }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteRoleSaga({ payload }) {
  try {
    const res = yield call(deleteRole, payload);
    yield all([
      put({ type: types.DELETE_ROLE_SUCCESS, payload: res.data && res.data.user_exist_flag ? { ...payload, user_exist_flag: true } : payload }),
    ]);
    if (!(res.data && res.data.user_exist_flag)) {
      yield all([
        put({
          type: types1.SET_NOTIFICATION,
          payload: {
            success: res.success,
            message: res.success ? 'Role deleted' : res.message || 'Role not deleted',
          },
        }),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: types.DELETE_ROLE_FAILED, error }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

  export function* activateRoleSaga({ payload }) { // eslint-disable-line
  try {
    const res = yield call(activateRole, payload);
    yield all([
      put({ type: types.ACTIVATE_ROLE_SUCCESS, payload: res.data && res.data.user_exist_flag ? { ...payload, user_exist_flag: true } : payload }),
    ]);
    if (!(res.data && res.data.user_exist_flag)) {
      yield all([
        put({
          type: types1.SET_NOTIFICATION,
          payload: {
            success: res.success,
            message: res.success ? 'Role activated' : res.message || 'Role not activated',
          },
        }),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: types.DELETE_ROLE_FAILED, error }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
