import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  blueGreyButton: {
    backgroundColor: theme.palette.blueGrey.main,
    color: theme.palette.blueGrey.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.blueGrey.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.blueGrey.main,
      },
      '&$disabled': {
        backgroundColor: theme.palette.blueGrey.main,
      },
    },
    '&$disabled': {
      color: theme.palette.blueGrey.contrastText,
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.blueGrey.main,
    },
  },
  disabled: {},
}));

const BlueGreyButton = ({children, classes, ...props}) => {
  const $classes = useStyles();

  return (
    <Button {...props} classes={{ root: $classes.blueGreyButton, disabled: $classes.disabled, ...classes }}>
      {children}
    </Button>
  );
};

export default BlueGreyButton;
