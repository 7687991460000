import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

import { useGetCoordinates } from 'lib/useGetCoordinates';
import { getUserID } from '../../../api/Api';
import { fetchUserStoresAction } from '../../../redux/actions/UserStoreAction';
import { fetchUserAction } from '../../../redux/actions/usersActions';
import { fetchStoreAction } from '../../../redux/actions/storesActions';
import { updateUserAction } from '../../../redux/actions/authActions';

const useStyles = makeStyles(theme => ({
  icon: {
    fill: '#F49900',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cssLabel: {
    color: '#13273C',
    font: 'normal normal medium 18px/23px Helvetica Neue',
  },
  selectButton: {
    ...theme.topBarSelect,
    textTransform: 'none',
  },
  menuPropStyle: {
    opacity: 1,
    transform: 'none',
    transformOrigin: '93.5px 0px',
    borderRadius: '2%',
  },
  dropDownlistItem: { ...theme.dropDownlistItem },
  dropDownlistItemsWrapper: {
    maxHeight: 350,
    overflowY: 'auto',
    borderRadius: 4,
    ...theme.dropDownlistItemsWrapper,
    ...theme.overflowScrollBar,
  },
  accordionDetails: { padding: 0 },
  menuListWrapper: {
    width: '100%',
    margin: '5px 0 0',
  },
  selectedAccordion: { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
  accordionSummary: { minWidth: 200 },
}));

const TopBarChangeStore = ({ userData }) => {
  const classes = useStyles();
  const [mappedStores, setMappedStores] = useState({});
  const [selected, setSelected] = useState({ id: '', name: '' });
  const [changedStore, setChangedStore] = useState({ id: '', name: '' })
  const dispatch = useDispatch();
  const user = useSelector(state => state.users && state.users.user);
  const isSuccess = useSelector((state) => (state && state.auth && state.auth.is_success) || false);
  const userStores = useSelector(state => state.user_stores && state.user_stores.userstores) || [];
  const store = useSelector(state => state.stores && state.stores.store) || {};
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const getCoordinates = useGetCoordinates();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setSelected({ id: changedStore.id, name: changedStore.name });
       localStorage.setItem('store_name', changedStore.name);
       localStorage.setItem('store_id', parseInt(changedStore.id, 10));
       localStorage.setItem('timezone', changedStore.time_zone);
       dispatch(fetchStoreAction({ id: parseInt(changedStore.id, 10) }));
    }
  }, [isSuccess])

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const fetchData = async() => {
    dispatch(fetchUserStoresAction({ params: { related: 'district' }}));
    const localCurrentStoreID = localStorage.getItem('store_id');
    const localCurrentStoreName = localStorage.getItem('store_name');
    setSelected({ id: localCurrentStoreID, name: localCurrentStoreName });

    if (!localCurrentStoreID || localCurrentStoreID === 'undefined' || !localCurrentStoreName || localCurrentStoreName === 'undefined') { // If localStorage does not have anything, get value from DB
      dispatch(fetchUserAction({ id: getUserID(), params: { related: 'storesCanOrderFor' } }));

      if (user && user.current_store) { // Once props are set, store in localStorage
        localStorage.setItem('store_id', user.current_store);
        dispatch(fetchStoreAction({ id: user.current_store }));
        if (store) {
          localStorage.setItem('store_name', store.name);
          localStorage.setItem('timezone', store.time_zone);
        }
      }
    }
  };

  useEffect(() => {
    if (userStores.length) {
      const districtStoresMap = {};
      userStores.forEach((storeItem) => {
        if (storeItem.district) {
          districtStoresMap[storeItem.district.name] = !districtStoresMap[storeItem.district.name] ? [storeItem] : [...districtStoresMap[storeItem.district.name], storeItem];
        } else {
          districtStoresMap['unnamed district'] = !districtStoresMap['unnamed district'] ? [storeItem] : [...districtStoresMap['unnamed district'], storeItem];
        }
      });
      setMappedStores(districtStoresMap);
    }
  }, [userStores]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelect = async(handleStore) => {
    const selectedStore = userStores && userStores.filter(stores => stores.id === handleStore.target.value);
    // setSelected({ id: selectedStore[0].id, name: selectedStore[0].name });
    setChangedStore({ id: selectedStore[0].id, name: selectedStore[0].name , time_zone: selectedStore[0].time_zone});
    const coordinates = await getCoordinates();

    const payload = {
      id: parseInt(getUserID(), 10),
      current_store: parseInt(selectedStore[0].id, 10),
      company_id: JSON.parse(localStorage.getItem('user')).company_id,
      ...coordinates
    };

    // localStorage.setItem('store_id', parseInt(selectedStore[0].id, 10));
    // localStorage.setItem('timezone', selectedStore[0].time_zone);
    dispatch(updateUserAction(payload));
    // localStorage.setItem('store_name', selectedStore[0].name);
    // dispatch(fetchStoreAction({ id: parseInt(selectedStore[0].id, 10) }));
  };

  const checkStoreExists = (stores) => {
    const storeIds = stores.map(e => e.id);
    return storeIds.includes(Number(selected.id));
  };

  return (
    <Grid container direction="column">
      {/* <Grid item>
        <Typography style={{ color: '#13273C' }}>{`${userData.first_name ? userData.first_name : ''} ${userData.last_name ? userData.last_name : ''}`}</Typography>
      </Grid> */}
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <LocationOnIcon style={{ color: '#F49900', fontSize: 24 }} />
        <FormControl >
          <InputLabel
            id="store_select"
            formlabelclasses={{
              root: classes.cssLabel,
            }}
          >
            {userData.user && userData.current_store ? userData.store.name : null}
          </InputLabel>
          <Button ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} className={classes.selectButton} style={{whiteSpace: 'nowrap'}}>
            {selected.name}
            { open ? <ExpandLessIcon style={{ color: 'rgb(244, 153, 0)' }} /> : <ExpandMoreIcon style={{ color: 'rgb(244, 153, 0)' }} /> }
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                <Paper elevation={8}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <div className={classes.dropDownlistItemsWrapper}>
                      {Object.entries(mappedStores).map(([key, stores]) => {
                        const storeExists = checkStoreExists(stores);
                        return (
                          <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)}>
                            <AccordionSummary
                              className={`${storeExists ? classes.selectedAccordion : ''} ${classes.accordionSummary}`}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`${key.replace(' ', '-')}-content`}
                              id={`${key.replace(' ', '-')}-header`}
                            >
                              <Typography>{key}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                              <MenuList className={classes.menuListWrapper} disablePadding autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {stores.map(userStore => (
                                  <MenuItem
                                    disabled={Number(selected.id) === userStore.id}
                                    className={classes.dropDownlistItem}
                                    selected={Number(selected.id) === userStore.id}
                                    onClick={handleSelect}
                                    style={{ color: '#13273C', opacity: 1 }}
                                    value={userStore.id}
                                    key={`${userStore.id}-${userStore.name}`}
                                  >
                                    {userStore.name}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </div>
                    {/* <MenuList className={classes.dropDownlistItemsWrapper} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      { map(userStores, userStore => (
                        <MenuItem disabled={Number(selected.id) === userStore.id} className={classes.dropDownlistItem} selected={Number(selected.id) === userStore.id} onClick={handleSelect} style={{ color: '#13273C', opacity: 1 }} value={userStore.id} key={`${userStore.id}-${userStore.name}`}>{userStore.name}</MenuItem>
                      ))}
                    </MenuList> */}

                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {/* <Select
            IconComponent={ExpandMoreIcon}
            style={{ marginTop: '0px', fontWeight: '500' }}
            labelId="simple-store-select"
            id="simple-store-select"
            value={selected}
            onChange={handleSelect}
            label="Store"
            renderValue={selected => selected.name} // eslint-disable-line
            disableUnderline={true} // eslint-disable-line
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              // getContentAnchorEl: null,
              classes: { paper: classes.menuPropStyle },
            }}
          >
            {map(userStores, userStore => (
              <div key={userStore.id}>
                <MenuItem style={{ color: '#13273C' }} value={userStore.id} key={`${userStore.id}-${userStore.name}`}>{userStore.name}</MenuItem>
                <div style={{ borderBottom: '1px solid #E9ECF2', paddingTop: 7 }} />
              </div>
            ))}
          </Select> */}
        </FormControl>
      </Grid>
    </Grid >
  );
};

export default TopBarChangeStore;

TopBarChangeStore.propTypes = {
  userData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

