import React from 'react';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


const CartIcon = ({ onClick, cart }) => {
  const useStyles = makeStyles({
    // root: {
    //   maxWidth: 345,
    // },
    customBadge: {
      display: (cart.items && cart.items.length) ? 'flex' : 'none',
      backgroundColor: 'rgba(244, 153, 0, 1)',
      color: 'rgba(255, 255, 255, 1)',
    },
  });
  const classes = useStyles();

  return (
    <IconButton
      disableTouchRipple
      disableFocusRipple
      disableRipple
      // className={classes.iconBackgroud}
      onClick={onClick}
      style={{
        padding: '0',
        width: '2.0em',
        height: '1.75em',
        borderRadius: '8px',
        background: 'linear-gradient(51deg, rgba(0, 154, 202, 1), rgba(0, 101, 209, 1) )',
        border: '3px solid rgba(255, 255, 255, 1)',
      }}
    >
      <Badge
        classes={{ badge: classes.customBadge }}
        style={{}}
        badgeContent={(cart.items && cart.items.length) || 0}
      >
        <ShoppingCartOutlinedIcon
          style={{
            color: 'rgba(255, 255, 255, 1)',
            width: '1em',
            height: '1em',
          }}
        />
      </Badge>
    </IconButton>
  );
};

export default connect(state => ({
  cart: (state.cart) || {},
}))(CartIcon);

CartIcon.propTypes = {
  cart: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
};
