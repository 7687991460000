const data = companyId => [
  {
    id: 1,
    name: 'All Lotus Resources',
    route: '',
    embed_and_links_id: null,
    is_root: true,
    icon: 'https://centraverse-notification-assets.s3.us-east-2.amazonaws.com/sideNavIcons/lotuslogo.svg',
    children: [
      // {
      //   id: 2,
      //   name: 'Insights',
      //   route: '/insights',
      //   embed_and_links_id: null,
      // },
      // {
      //   id: 3,
      //   name: 'Trace',
      //   route: '/trace',
      //   embed_and_links_id: null,
      // },
      // {
      //   id: 4,
      //   name: 'My Portal',
      //   route: '/my-portal',
      //   embed_and_links_id: null,
      // },
      {
        id: 5,
        name: 'Engage',
        route: '/engage',
        embed_and_links_id: null,
      },
      {
        id: 6,
        name: 'Leasing Pro',
        route: '/real-estate',
        embed_and_links_id: null,
      },
      ...((companyId !== 37) ? [
        {
          id: 7,
          name: 'Lotus Drive',
          route: '/lotus-drive',
          embed_and_links_id: null,
        },
        {
          id: 8,
          name: 'Real Time',
          route: '/real-time',
          embed_and_links_id: null,
        },
      ] : []),
      ...((companyId === 37) ? [
        {
          id: 8,
          name: 'iVerify',
          route: '/iverify',
          embed_and_links_id: null,
        },
      ] : []),
      ((companyId === 25 || companyId === 36 || companyId === 37) && {
        id: 9,
        name: 'Membership Services',
        route: '/membership-services',
        embed_and_links_id: null,
      }),
      // ((companyId === 25 || companyId === 36) && {
      //   id: 10,
      //   name: 'LMS',
      //   route: '/lms',
      //   embed_and_links_id: null,
      // }),
    ].filter(Boolean),
  },
];

export default data;
