import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  support_ticket_type: {},
  support_ticket_types: [],
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SUPPORT_TICKET_TYPES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          support_ticket_types: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        support_ticket_types: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_SUPPORT_TICKET_TYPES_REQUEST:
      return {
        ...state,
        support_ticket_types: [],
        loading: true,
      };
    case types.FETCH_SUPPORT_TICKET_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_SUPPORT_TICKET_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_SUPPORT_TICKET_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_SUPPORT_TICKET_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_SUPPORT_TICKET_TYPES_FAILED:
      return {
        ...state,
        support_ticket_types: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_SUPPORT_TICKET_TYPE_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          support_ticket_type: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        support_ticket_type: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_SUPPORT_TICKET_TYPE_FAILED:
      return {
        ...state,
        support_ticket_type: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_SUPPORT_TICKET_TYPE_SUCCESS:
      return {
        ...state,
        // support_ticket_types: [...state.support_ticket_types, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_SUPPORT_TICKET_TYPE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_SUPPORT_TICKET_TYPE_SUCCESS:
      let support_ticket_types = filter(state.support_ticket_types, item => item.id !== action.res.data.id); //eslint-disable-line
      return {
        ...state,
        support_ticket_type: action.res.data || {},
        support_ticket_types: [...support_ticket_types, action.res.data], //eslint-disable-line
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_SUPPORT_TICKET_TYPE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_SUPPORT_TICKET_TYPE_SUCCESS:
      support_ticket_types = filter(state.support_ticket_types, item => item.id !== action.payload.id); //eslint-disable-line

      return {
        ...state,
        support_ticket_types: [...support_ticket_types], //eslint-disable-line
        status: true,
        loading: false,
      };
    case types.DELETE_SUPPORT_TICKET_TYPE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
