import * as types from '../../constants/actionTypes';

export const fetchUsersAction = payload => ({
  type: types.FETCH_USERS_REQUEST,
  payload,
});

export const fetchUserAction = payload => ({
  type: types.FETCH_USER_BY_ID_REQUEST,
  payload,
});

export const addUserAction = payload => ({
  type: types.ADD_USERS_REQUEST,
  payload,
});

export const updateUserAction = payload => ({
  type: types.UPDATE_USERS_REQUEST,
  payload,
});

export const deleteUserAction = payload => ({
  type: types.DELETE_USERS_REQUEST,
  payload,
});

export const lockUserAction = payload => ({
  type: types.LOCK_USERS_REQUEST,
  payload,
});

export const unlockUserAction = payload => ({
  type: types.UNLOCK_USERS_REQUEST,
  payload,
});
