import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */
const initialState = {
  hrsettingsstores: [],
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_HRSETTINGSSTORES_REQUEST:
      return {
        ...state,
        hrsettingsstores: [],
        loading: true,
      };
    case types.FETCH_HRSETTINGSSTORES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          hrsettingsstores: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        hrsettingsstores: (action.res.data && action.res.data.results) || [],
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_HRSETTINGSSTORES_FAILED:
      return {
        ...state,
        hrsettingsstores: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_HRSETTINGSSTORE_REQUEST:
      return {
        ...state,
        hrsettingsstores: [],
        loading: true,
      };
    case types.UPDATE_HRSETTINGSSTORE_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          hrsettingsstores: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        hrsettingsstores: (action.res.data && action.res.data.results) || [],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_HRSETTINGSSTORE_FAILED:
      return {
        ...state,
        hrsettingsstores: [],
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
