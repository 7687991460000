import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addSidebarItem, deleteSidebarItem, getSidebarItemById, getSidebarItems, updateSidebarItem } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_SIDEBAR_ITEMS_REQUEST, fetchSidebarItemsSaga);
  yield takeLatest(types.FETCH_SIDEBAR_ITEM_REQUEST, fetchSidebarItemSaga);
  yield takeLatest(types.ADD_SIDEBAR_ITEM_REQUEST, addSidebarItemSaga, context);
  yield takeLatest(types.UPDATE_SIDEBAR_ITEM_REQUEST, updateSidebarItemSaga, context);
  yield takeLatest(types.DELETE_SIDEBAR_ITEM_REQUEST, deleteSidebarItemSaga);
}

export function* fetchSidebarItemsSaga({ payload }) {
  try {
    const res = yield call(getSidebarItems, payload);
    yield all([
      put({ type: types.FETCH_SIDEBAR_ITEMS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SIDEBAR_ITEMS_FAILED, error });
  }
}

export function* fetchSidebarItemSaga({ payload }) {
  try {
    const res = yield call(getSidebarItemById, payload);
    yield all([
      put({ type: types.FETCH_SIDEBAR_ITEM_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SIDEBAR_ITEM_FAILED, error });
  }
}

export function* addSidebarItemSaga({ history }, { payload }) {
  try {
    const res = yield call(addSidebarItem, payload);
    yield all([
      put({ type: types.ADD_SIDEBAR_ITEM_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SidebarItem added' : res.message || 'SidebarItem not added',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/admin/sidebar-items');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_SIDEBAR_ITEM_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateSidebarItemSaga({ history }, { payload }) {
  try {
    const res = yield call(updateSidebarItem, payload);
    yield all([
      put({ type: types.UPDATE_SIDEBAR_ITEM_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SidebarItem updated' : res.message || 'SidebarItem not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/admin/sidebar-items');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_SIDEBAR_ITEM_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteSidebarItemSaga({ payload }) {
  try {
    const res = yield call(deleteSidebarItem, payload);
    yield all([
      put({ type: types.DELETE_SIDEBAR_ITEM_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'SidebarItem deleted' : res.message || 'SidebarItem not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_SIDEBAR_ITEM_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
