import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  userstore: {},
  userstoreid: {},
  userstores: [],
  userstoresPrimary: [],
  userstoresPrimaryTotal: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USERSTORES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          userstores: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        userstores: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_USERSTORES_REQUEST:
      return {
        ...state,
        userstores: [],
        loading: true,
      };
    case types.FETCH_USERSTORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_USERSTORE_STOREID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_USERSTORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_USERSTORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_USERSTORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_USERSTORES_FAILED:
      return {
        ...state,
        userstores: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_USERSTORE_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          userstore: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        userstore: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_USERSTORE_STOREID_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          userstoreid: {},
          error: '',
          loading: false,
        };
      }
      return {
        userstoreid: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_USERSTORE_FAILED:
      return {
        ...state,
        userstore: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_USERSTORE_STOREID_FAILED:
      return {
        ...state,
        userstoreid: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_USERSTORE_SUCCESS:
      return {
        ...state,
        // userstores: [...state.userstores, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_USERSTORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_USERSTORE_SUCCESS:
      let userstores = filter(state.userstores, item => item.id !== action.res.data.id);
      return {
        ...state,
        userstore: action.res.data || {},
        userstores: [...userstores, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_USERSTORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_USERSTORE_SUCCESS:
      userstores = filter(state.userstores, item => item.id !== action.payload.id);

      return {
        ...state,
        userstores: [...userstores],
        status: true,
        loading: false,
      };
    case types.DELETE_USERSTORE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.FETCH_USERSTORE_PRIMARY_BY_STOREID_REQUEST:
      return {
        ...state,
        userstoresPrimary: [],
        userstoresPrimaryTotal: 0,
        loading: true,
      };
    case types.FETCH_USERSTORE_PRIMARY_BY_STOREID_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          userstoresPrimary: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        userstoresPrimary: (action.res.data && action.res.data.results) || [],
        userstoresPrimaryTotal: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_USERSTORE_PRIMARY_BY_STOREID_FAILED:
      return {
        ...state,
        userstoresPrimary: [],
        error: 'Bad Request',
        loading: false,
      };

    default:
      return state;
  }
};
