import * as types from '../../constants/actionTypes';

export const getCoordinatesRequestAction = payload => ({
  type: types.GET_COORDINATES_REQUEST,
  payload,
});

export const getCoordinatesSuccessAction = payload => ({
  type: types.GET_COORDINATES_SUCCESS,
  payload,
});

export const getCoordinatesFailedAction = payload => ({
  type: types.GET_COORDINATES_FAILED,
  payload,
});
