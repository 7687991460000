import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

/*eslint-disable */
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    // margin: theme.spacing(1)
    width:'100%'
  }
}));

export default function TopbarMenuSelect({ childRoutes, setCurrentTopbar, currentTopbar }) {
  const classes = useStyles();
  const handleChange = (event) => {
    setCurrentTopbar(event.target.value);
  };
  return (
    <FormControl className={classes.margin}>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={currentTopbar && currentTopbar}
        onChange={handleChange}
        input={<BootstrapInput />}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >

        {childRoutes &&
          childRoutes.filter(removeSetting => removeSetting.name !== "Centraverse Settings")
            .map((route, key) => (<MenuItem key={`${route.name}${key}`} value={route.name}>{route.name}</MenuItem>))}

      </Select>
    </FormControl>
  );
}