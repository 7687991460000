
// Accessory Orders
export const FETCH_ACCESSORY_ORDERS_REQUEST = 'FETCH_ACCESSORY_ORDERS_REQUEST';
export const FETCH_ACCESSORY_ORDERS_SUCCESS = 'FETCH_ACCESSORY_ORDERS_SUCCESS';
export const FETCH_ACCESSORY_ORDERS_FAILED = 'FETCH_ACCESSORY_ORDERS_FAILED';

export const FETCH_ACCESSORY_ORDER_REQUEST = 'FETCH_ACCESSORY_ORDER_REQUEST';
export const FETCH_ACCESSORY_ORDER_SUCCESS = 'FETCH_ACCESSORY_ORDER_SUCCESS';
export const FETCH_ACCESSORY_ORDER_FAILED = 'FETCH_ACCESSORY_ORDER_FAILED';

export const ADD_ACCESSORY_ORDER_REQUEST = 'ADD_ACCESSORY_ORDER_REQUEST';
export const ADD_ACCESSORY_ORDER_SUCCESS = 'ADD_ACCESSORY_ORDER_SUCCESS';
export const ADD_ACCESSORY_ORDER_FAILED = 'ADD_ACCESSORY_ORDER_FAILED';

export const UPDATE_ACCESSORY_ORDER_REQUEST = 'UPDATE_ACCESSORY_ORDER_REQUEST';
export const UPDATE_ACCESSORY_ORDER_SUCCESS = 'UPDATE_ACCESSORY_ORDER_SUCCESS';
export const UPDATE_ACCESSORY_ORDER_FAILED = 'UPDATE_ACCESSORY_ORDER_FAILED';
export const UPDATE_ACCESSORY_ORDER_STATE = 'UPDATE_ACCESSORY_ORDER_STATE';

export const DELETE_ACCESSORY_ORDER_REQUEST = 'DELETE_ACCESSORY_ORDER_REQUEST';
export const DELETE_ACCESSORY_ORDER_SUCCESS = 'DELETE_ACCESSORY_ORDER_SUCCESS';
export const DELETE_ACCESSORY_ORDER_FAILED = 'DELETE_ACCESSORY_ORDER_FAILED';

export const FETCH_ACCESSORY_ORDER_COUNTS = 'FETCH_ACCESSORY_ORDER_COUNTS';
export const FETCH_ACCESSORY_ORDER_COUNTS_FAILED = 'FETCH_ACCESSORY_ORDER_COUNTS_FAILED';
export const FETCH_ACCESSORY_ORDER_COUNTS_SUCCESS = 'FETCH_ACCESSORY_ORDER_COUNTS_SUCCESS';

// Fulfillments
export const FETCH_FULFILLMENTS_REQUEST = 'FETCH_FULFILLMENTS_REQUEST';
export const FETCH_FULFILLMENTS_SUCCESS = 'FETCH_FULFILLMENTS_SUCCESS';
export const FETCH_FULFILLMENTS_FAILED = 'FETCH_FULFILLMENTS_FAILED';

export const FETCH_FULFILLMENT_REQUEST = 'FETCH_FULFILLMENT_REQUEST';
export const FETCH_FULFILLMENT_SUCCESS = 'FETCH_FULFILLMENT_SUCCESS';
export const FETCH_FULFILLMENT_FAILED = 'FETCH_FULFILLMENT_FAILED';

export const ADD_FULFILLMENT_REQUEST = 'ADD_FULFILLMENT_REQUEST';
export const ADD_FULFILLMENT_SUCCESS = 'ADD_FULFILLMENT_SUCCESS';
export const ADD_FULFILLMENT_FAILED = 'ADD_FULFILLMENT_FAILED';

export const UPDATE_FULFILLMENT_REQUEST = 'UPDATE_FULFILLMENT_REQUEST';
export const UPDATE_FULFILLMENT_SUCCESS = 'UPDATE_FULFILLMENT_SUCCESS';
export const UPDATE_FULFILLMENT_FAILED = 'UPDATE_FULFILLMENT_FAILED';

export const DELETE_FULFILLMENT_REQUEST = 'DELETE_FULFILLMENT_REQUEST';
export const DELETE_FULFILLMENT_SUCCESS = 'DELETE_FULFILLMENT_SUCCESS';
export const DELETE_FULFILLMENT_FAILED = 'DELETE_FULFILLMENT_FAILED';

// Accessory Fulfillments
export const FETCH_ACCESSORY_FULFILLMENTS_REQUEST = 'FETCH_ACCESSORY_FULFILLMENTS_REQUEST';
export const FETCH_ACCESSORY_FULFILLMENTS_SUCCESS = 'FETCH_ACCESSORY_FULFILLMENTS_SUCCESS';
export const FETCH_ACCESSORY_FULFILLMENTS_FAILED = 'FETCH_ACCESSORY_FULFILLMENTS_FAILED';

export const FETCH_ACCESSORY_FULFILLMENT_REQUEST = 'FETCH_ACCESSORY_FULFILLMENT_REQUEST';
export const FETCH_ACCESSORY_FULFILLMENT_SUCCESS = 'FETCH_ACCESSORY_FULFILLMENT_SUCCESS';
export const FETCH_ACCESSORY_FULFILLMENT_FAILED = 'FETCH_ACCESSORY_FULFILLMENT_FAILED';

export const ADD_ACCESSORY_FULFILLMENT_REQUEST = 'ADD_ACCESSORY_FULFILLMENT_REQUEST';
export const ADD_ACCESSORY_FULFILLMENT_SUCCESS = 'ADD_ACCESSORY_FULFILLMENT_SUCCESS';
export const ADD_ACCESSORY_FULFILLMENT_FAILED = 'ADD_ACCESSORY_FULFILLMENT_FAILED';

export const UPDATE_ACCESSORY_FULFILLMENT_REQUEST = 'UPDATE_ACCESSORY_FULFILLMENT_REQUEST';
export const UPDATE_ACCESSORY_FULFILLMENT_SUCCESS = 'UPDATE_ACCESSORY_FULFILLMENT_SUCCESS';
export const UPDATE_ACCESSORY_FULFILLMENT_FAILED = 'UPDATE_ACCESSORY_FULFILLMENT_FAILED';

export const DELETE_ACCESSORY_FULFILLMENT_REQUEST = 'DELETE_ACCESSORY_FULFILLMENT_REQUEST';
export const DELETE_ACCESSORY_FULFILLMENT_SUCCESS = 'DELETE_ACCESSORY_FULFILLMENT_SUCCESS';
export const DELETE_ACCESSORY_FULFILLMENT_FAILED = 'DELETE_ACCESSORY_FULFILLMENT_FAILED';


// Products
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED';

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILED = 'FETCH_PRODUCT_FAILED';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const FETCH_PRODUCT_COUNTS = 'FETCH_PRODUCT_COUNTS';
export const FETCH_PRODUCT_COUNTS_FAILED = 'FETCH_PRODUCT_COUNTS_FAILED';
export const FETCH_PRODUCT_COUNTS_SUCCESS = 'FETCH_PRODUCT_COUNTS_SUCCESS';


// Accessories

export const FETCH_ACCESSORIES_REQUEST = 'FETCH_ACCESSORIES_REQUEST';
export const FETCH_ACCESSORIES_SUCCESS = 'FETCH_ACCESSORIES_SUCCESS';
export const FETCH_ACCESSORIES_FAILED = 'FETCH_ACCESSORIES_FAILED';

export const FETCH_ACCESSORY_REQUEST = 'FETCH_ACCESSORY_REQUEST';
export const FETCH_ACCESSORY_SUCCESS = 'FETCH_ACCESSORY_SUCCESS';
export const FETCH_ACCESSORY_FAILED = 'FETCH_ACCESSORY_FAILED';

export const ADD_ACCESSORY_REQUEST = 'ADD_ACCESSORY_REQUEST';
export const ADD_ACCESSORY_SUCCESS = 'ADD_ACCESSORY_SUCCESS';
export const ADD_ACCESSORY_FAILED = 'ADD_ACCESSORY_FAILED';

export const UPDATE_ACCESSORY_REQUEST = 'UPDATE_ACCESSORY_REQUEST';
export const UPDATE_ACCESSORY_SUCCESS = 'UPDATE_ACCESSORY_SUCCESS';
export const UPDATE_ACCESSORY_FAILED = 'UPDATE_ACCESSORY_FAILED';

export const DELETE_ACCESSORY_REQUEST = 'DELETE_ACCESSORY_REQUEST';
export const DELETE_ACCESSORY_SUCCESS = 'DELETE_ACCESSORY_SUCCESS';
export const DELETE_ACCESSORY_FAILED = 'DELETE_ACCESSORY_FAILED';


// Inventory Items
export const ADD_INVENTORY_ITEM_REQUEST = 'ADD_INVENTORY_ITEM_REQUEST';
export const ADD_INVENTORY_ITEM_SUCCESS = 'ADD_INVENTORY_ITEM_SUCCESS';
export const ADD_INVENTORY_ITEM_FAILED = 'ADD_INVENTORY_ITEM_FAILED';

export const DELETE_INVENTORY_ITEMS_REQUEST = 'DELETE_INVENTORY_ITEMS_REQUEST';
export const DELETE_INVENTORY_ITEMS_SUCCESS = 'DELETE_INVENTORY_ITEMS_SUCCESS';
export const DELETE_INVENTORY_ITEMS_FAILED = 'DELETE_INVENTORY_ITEMS_FAILED';

export const FETCH_INVENTORY_ITEMS_REQUEST = 'FETCH_INVENTORY_ITEMS_REQUEST';
export const FETCH_INVENTORY_ITEMS_SUCCESS = 'FETCH_INVENTORY_ITEMS_SUCCESS';
export const FETCH_INVENTORY_ITEMS_FAILED = 'FETCH_INVENTORY_ITEMS_FAILED';

export const FETCH_INVENTORY_ITEM_REQUEST = 'FETCH_INVENTORY_ITEM_REQUEST';
export const FETCH_INVENTORY_ITEM_SUCCESS = 'FETCH_INVENTORY_ITEM_SUCCESS';
export const FETCH_INVENTORY_ITEM_FAILED = 'FETCH_INVENTORY_ITEM_FAILED';

export const UPDATE_INVENTORY_ITEM_REQUEST = 'UPDATE_INVENTORY_ITEM_REQUEST';
export const UPDATE_INVENTORY_ITEM_SUCCESS = 'UPDATE_INVENTORY_ITEM_SUCCESS';
export const UPDATE_INVENTORY_ITEM_FAILED = 'UPDATE_INVENTORY_ITEM_FAILED';

export const DELETE_INVENTORY_ITEM_REQUEST = 'DELETE_INVENTORY_ITEM_REQUEST';
export const DELETE_INVENTORY_ITEM_SUCCESS = 'DELETE_INVENTORY_ITEM_SUCCESS';
export const DELETE_INVENTORY_ITEM_FAILED = 'DELETE_INVENTORY_ITEM_FAILED';

export const FETCH_INVENTORY_ITEMS_COUNT = 'FETCH_INVENTORY_ITEMs_COUNT';
export const FETCH_INVENTORY_ITEMS_COUNT_FAILED = 'FETCH_INVENTORY_ITEMS_COUNT_FAILED';
export const FETCH_INVENTORY_ITEMS_COUNT_SUCCESS = 'FETCH_INVENTORY_ITEMS_COUNT_SUCCESS';


// Accessory Inventory Items
export const ADD_ACCESSORY_INVENTORY_ITEM_REQUEST = 'ADD_ACCESSORY_INVENTORY_ITEM_REQUEST';
export const ADD_ACCESSORY_INVENTORY_ITEM_SUCCESS = 'ADD_ACCESSORY_INVENTORY_ITEM_SUCCESS';
export const ADD_ACCESSORY_INVENTORY_ITEM_FAILED = 'ADD_ACCESSORY_INVENTORY_ITEM_FAILED';

export const DELETE_ACCESSORY_INVENTORY_ITEMS_REQUEST = 'DELETE_ACCESSORY_INVENTORY_ITEMS_REQUEST';
export const DELETE_ACCESSORY_INVENTORY_ITEMS_SUCCESS = 'DELETE_ACCESSORY_INVENTORY_ITEMS_SUCCESS';
export const DELETE_ACCESSORY_INVENTORY_ITEMS_FAILED = 'DELETE_ACCESSORY_INVENTORY_ITEMS_FAILED';

export const FETCH_ACCESSORY_INVENTORY_ITEMS_REQUEST = 'FETCH_ACCESSORY_INVENTORY_ITEMS_REQUEST';
export const FETCH_ACCESSORY_INVENTORY_ITEMS_SUCCESS = 'FETCH_ACCESSORY_INVENTORY_ITEMS_SUCCESS';
export const FETCH_ACCESSORY_INVENTORY_ITEMS_FAILED = 'FETCH_ACCESSORY_INVENTORY_ITEMS_FAILED';

export const FETCH_ACCESSORY_INVENTORY_ITEM_REQUEST = 'FETCH_ACCESSORY_INVENTORY_ITEM_REQUEST';
export const FETCH_ACCESSORY_INVENTORY_ITEM_SUCCESS = 'FETCH_ACCESSORY_INVENTORY_ITEM_SUCCESS';
export const FETCH_ACCESSORY_INVENTORY_ITEM_FAILED = 'FETCH_ACCESSORY_INVENTORY_ITEM_FAILED';

export const UPDATE_ACCESSORY_INVENTORY_ITEM_REQUEST = 'UPDATE_ACCESSORY_INVENTORY_ITEM_REQUEST';
export const UPDATE_ACCESSORY_INVENTORY_ITEM_SUCCESS = 'UPDATE_ACCESSORY_INVENTORY_ITEM_SUCCESS';
export const UPDATE_ACCESSORY_INVENTORY_ITEM_FAILED = 'UPDATE_ACCESSORY_INVENTORY_ITEM_FAILED';

export const DELETE_ACCESSORY_INVENTORY_ITEM_REQUEST = 'DELETE_ACCESSORY_INVENTORY_ITEM_REQUEST';
export const DELETE_ACCESSORY_INVENTORY_ITEM_SUCCESS = 'DELETE_ACCESSORY_INVENTORY_ITEM_SUCCESS';
export const DELETE_ACCESSORY_INVENTORY_ITEM_FAILED = 'DELETE_ACCESSORY_INVENTORY_ITEM_FAILED';

export const FETCH_ACCESSORY_INVENTORY_ITEMS_COUNT = 'FETCH_ACCESSORY_INVENTORY_ITEMS_COUNT';
export const FETCH_ACCESSORY_INVENTORY_ITEMS_COUNT_FAILED = 'FETCH_ACCESSORY_INVENTORY_ITEMS_COUNT_FAILED';
export const FETCH_ACCESSORY_INVENTORY_ITEMS_COUNT_SUCCESS = 'FETCH_ACCESSORY_INVENTORY_ITEMS_COUNT_SUCCESS';

// Accessory Cart
export const ADD_ACCESSORY_ITEM_TO_CART = 'ADD_ACCESSORY_ITEM_TO_CART';
export const DELETE_ACCESSORY_ITEM_FROM_CART = 'DELETE_ACCESSORY_ITEM_FROM_CART';
export const CHANGE_ACCESSORY_QTY_FROM_CART = 'CHANGE_ACCESSORY_QTY_FROM_CART';
export const SET_ACCESSORY_ITEMS_CART = 'SET_ACCESSORY_ITEMS_CART';
export const CLEAR_ACCESSORY_CART = 'CLEAR_ACCESSORY_CART';

// fetch Resources
export const FETCH_ADJUSTMENT_REASONS_REQUEST = 'FETCH_ADJUSTMENT_REASONS_REQUEST';
export const FETCH_ADJUSTMENT_REASONS_SUCCESS = 'FETCH_ADJUSTMENT_REASONS_SUCCESS';
export const FETCH_ADJUSTMENT_REASONS_FAILED = 'FETCH_ADJUSTMENT_REASONS_FAILED';

// fetch Resource
export const FETCH_ADJUSTMENT_REASON_REQUEST = 'FETCH_ADJUSTMENT_REASON_REQUEST';
export const FETCH_ADJUSTMENT_REASON_SUCCESS = 'FETCH_ADJUSTMENT_REASON_SUCCESS';
export const FETCH_ADJUSTMENT_REASON_FAILED = 'FETCH_ADJUSTMENT_REASON_FAILED';

// Update Resource
export const UPDATE_ADJUSTMENT_REASON_REQUEST = 'UPDATE_ADJUSTMENT_REASON_REQUEST';
export const UPDATE_ADJUSTMENT_REASON_SUCCESS = 'UPDATE_ADJUSTMENT_REASON_SUCCESS';
export const UPDATE_ADJUSTMENT_REASON_FAILED = 'UPDATE_ADJUSTMENT_REASON_FAILED';

// Delete Resource
export const DELETE_ADJUSTMENT_REASON_REQUEST = 'DELETE_ADJUSTMENT_REASON_REQUEST';
export const DELETE_ADJUSTMENT_REASON_SUCCESS = 'DELETE_ADJUSTMENT_REASON_SUCCESS';
export const DELETE_ADJUSTMENT_REASON_FAILED = 'DELETE_ADJUSTMENT_REASON_FAILED';

// Add Resources
export const ADD_ADJUSTMENT_REASON_REQUEST = 'ADD_ADJUSTMENT_REASON_REQUEST';
export const ADD_ADJUSTMENT_REASON_SUCCESS = 'ADD_ADJUSTMENT_REASON_SUCCESS';
export const ADD_ADJUSTMENT_REASON_FAILED = 'ADD_ADJUSTMENT_REASON_FAILED';

// clear resource

export const CLEAR_ADJUSTMENT_REASON_REQUEST = 'CLEAR_ADJUSTMENT_REASON_REQUEST';
export const CLEAR_ADJUSTMENT_REASON_SUCCESS = 'CLEAR_ADJUSTMENT_REASON_SUCCESS';
export const CLEAR_ADJUSTMENT_REASON_FAILED = 'CLEAR_ADJUSTMENT_REASON_FAILED';


// Manufacturers
export const FETCH_MANUFACTURERS_REQUEST = 'FETCH_MANUFACTURERS_REQUEST';
export const FETCH_MANUFACTURERS_SUCCESS = 'FETCH_MANUFACTURERS_SUCCESS';
export const FETCH_MANUFACTURERS_FAILED = 'FETCH_MANUFACTURERS_FAILED';

export const FETCH_MANUFACTURER_REQUEST = 'FETCH_MANUFACTURER_REQUEST';
export const FETCH_MANUFACTURER_SUCCESS = 'FETCH_MANUFACTURER_SUCCESS';
export const FETCH_MANUFACTURER_FAILED = 'FETCH_MANUFACTURER_FAILED';

export const ADD_MANUFACTURER_REQUEST = 'ADD_MANUFACTURER_REQUEST';
export const ADD_MANUFACTURER_SUCCESS = 'ADD_MANUFACTURER_SUCCESS';
export const ADD_MANUFACTURER_FAILED = 'ADD_MANUFACTURER_FAILED';

export const UPDATE_MANUFACTURER_REQUEST = 'UPDATE_MANUFACTURER_REQUEST';
export const UPDATE_MANUFACTURER_SUCCESS = 'UPDATE_MANUFACTURER_SUCCESS';
export const UPDATE_MANUFACTURER_FAILED = 'UPDATE_MANUFACTURER_FAILED';

export const DELETE_MANUFACTURER_REQUEST = 'DELETE_MANUFACTURER_REQUEST';
export const DELETE_MANUFACTURER_SUCCESS = 'DELETE_MANUFACTURER_SUCCESS';
export const DELETE_MANUFACTURER_FAILED = 'DELETE_MANUFACTURER_FAILED';

export const FETCH_MANUFACTURER_COUNTS = 'FETCH_MANUFACTURER_COUNTS';
export const FETCH_MANUFACTURER_COUNTS_FAILED = 'FETCH_MANUFACTURER_COUNTS_FAILED';
export const FETCH_MANUFACTURER_COUNTS_SUCCESS = 'FETCH_MANUFACTURER_COUNTS_SUCCESS';
