import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */
const initialState = {
  required_fields: [], // eslint-disable-line
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REQUIRED_FIELDS_REQUEST:
      return {
        ...state,
        required_fields: [],
        loading: true,
      };
    case types.FETCH_REQUIRED_FIELDS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          required_fields: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        required_fields: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_REQUIRED_FIELDS_FAILED:
      return {
        ...state,
        static_accessory: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_REQUIRED_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_REQUIRED_FIELDS_SUCCESS:
      return {
        ...state,
        required_fields: [...state.required_fields, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_REQUIRED_FIELDS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_REQUIRED_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_REQUIRED_FIELDS_SUCCESS:
      let required_fields = filter(state.required_fields, item => item.id !== action.res.data.id); // eslint-disable-line
      return {
        ...state,
        required_fields: [...required_fields, action.res.data], // eslint-disable-line
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_REQUIRED_FIELDS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
