import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';

export default (ComposedComponent) => {
  class Authentication extends Component {
    componentWillMount() {
      const params = new URL(window.location.href).searchParams;
      const pathName = window.location.pathname;
      const lockScreenChecks = [
        JSON.parse(localStorage.getItem('locked')),
        !pathName.includes('forgot-password'),
        !pathName.includes('reset-password'),
        !pathName.includes('locked'),
      ];
      if (!lockScreenChecks.includes(false) && JSON.parse(localStorage.getItem('locked_user'))) {
        this.props.history.push(!params.get('redirect_url') ? '/locked' : `/locked?redirect_url=${params.get('redirect_url')}`);
      } else if (localStorage.getItem('user_id')) {
        this.props.history.push('/');
      }
    }

    render() {
      return (<ComposedComponent {...this.props} />);
    }
  }
  return withRouter(Authentication);
};
