import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  faceModal: {
    borderRadius: '20px',
    width: '800px',
    maxWidth: '800px',
    [theme.breakpoints.down('xs')]: {
      height: '580px',
      width: '400px',
      overflow: 'scroll',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  darkTypo: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#13273C',
  },
  modalButtonWrapper: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-around',
    marginTop: '40px',
    marginBottom: '15px',
  },
  modalSaveButton: {
    backgroundColor: '#3AA50B',
    color: 'white',
    textTransform: 'none',
    width: '170px',
  },
  modalContinueButton: {
    backgroundColor: '#6AC371',
    color: 'white',
    textTransform: 'none',
    width: '170px',
  },
  modalCancelButton: {
    backgroundColor: '#B8B8B8',
    color: 'white',
    textTransform: 'none',
    width: '170px',
  },
  ModalTopBar: {
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    padding: '20px',
    backgroundColor: '#F4F6F7',
  },
  modalHeading: {
    fontSize: '18px',
    fontWeight: 500,
  },
  buttonCustomize: {
    width: '100%',
    height: '55px',
    marginBottom: '10px',
    backgroundColor: '#F8F5F5',
    boxShadow: 'none',
    textTransform: 'none',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0px',
    color: ' #25284E',
    opacity: '1',
    fontWeight: 'bold',
  },
  buttonWrapper: {
    margin: 'auto 50px',
  },
}));

export default useStyles;
