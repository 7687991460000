import React from 'react';

export const CommunicationCenterAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'communicationCenterApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/ticket-center/dashboard',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketCenter')),
    },
    {
      path: '/external/ticket-center/dashboard',
      component: React.lazy(() => import('./CommunicationCenter/Pages/InventoryTicketCenter/InventoryTicketCenter')),
    },
    {
      path: '/ticket-center/create-ticket',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketCenter/CreateTicket')),
    },
    {
      path: '/ticket-center/create-ticket/:id',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketCenter/CreateTicket')),
    },
    {
      path: '/external/ticket-center/update-ticket/:id',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketCenter/UpdateExternalTicket')),
    },
    {
      path: '/ticket-center/create-system-ticket',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketCenter/CreateSystemTicket')),
    },
    {
      path: '/ticket-center/create-system-ticket/:id',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketCenter/CreateSystemTicket')),
    },
    {
      path: '/ticket-center/ticket-view/:id',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketViewPage')),
    },
    {
      path: '/external/ticket-center/ticket-view/:id',
      component: React.lazy(() => import('./CommunicationCenter/Pages/ExternalTicketViewPage')),
    },
    {
      path: '/ticket-center/settings/:nestedRoute?',
      component: React.lazy(() => import('./CommunicationCenter/Pages/Settings')),
    },
    {
      path: '/ticket-center/tickets',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketIsuue/TicketIssueList')),
    },
    {
      path: '/ticket-center/system-tickets',
      component: React.lazy(() => import('./CommunicationCenter/Pages/SystemTickets/SystemTickets')),
    },
    {
      path: '/ticket-center/ticket-categories-overview',
      component: React.lazy(() => import('./CommunicationCenter/Pages/TicketCenter/components/AllTicketIssueType')),
    },
    {
      path: '/ticket-center/help-support',
      component: React.lazy(() => import('./CommunicationCenter/Pages/HelpSupport')),
    },
  ],
};

export default CommunicationCenterAppConfig;
