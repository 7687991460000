export default function hasChildren(item) {
  const { children: subChildren } = item;

  if (subChildren === undefined) {
    return false;
  }

  if (subChildren.constructor !== Array) {
    return false;
  }

  if (subChildren.length === 0) {
    return false;
  }

  return true;
}
