import * as types from '../../constants/actionTypes';

const initialState = {
  title: '',
  message: '',
  success: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_NOTIFICATION:
      return {
        title: action.payload.title,
        message: action.payload.message,
        success: action.payload.success,
      };
    case types.CLEAR_NOTIFICATION:
      return {
        title: '',
        message: '',
        success: '',
      };
    default:
      return state;
  }
};
