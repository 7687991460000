import * as types from '../../constants/actionTypes';

/* eslint-disable */
export const fetchSchedulesByUserAction = payload => ({
  type: types.FETCH_SCHEDULESBYUSER_REQUEST,
  payload,
});

export const fetchTimeForStoreTimezoneAction = payload => ({
  type: types.FETCH_TIMEFORSTORETIMEZONE_REQUEST,
  payload,
});

export const fetchStatusForWebClockinAction = payload => ({
  type: types.FETCH_STATUSFORWEBCLOCKIN_REQUEST,
  payload,
});

export const setClockinUserComment = payload => ({
  type: types.SET_CLOCKIN_USER_COMMENT,
  payload,
});

export const setClockinUserLoading = payload => ({
  type: types.SET_CLOCKIN_USER_LOADING,
  payload,
});

export const addTimecardForClockinAction = payload => ({
  type: types.ADD_TIMECARDFORCLOCKIN_REQUEST,
  payload,
});

export const updateTimecardForClockoutAction = payload => ({
  type: types.UPDATE_TIMECARDFORCLOCKOUT_REQUEST,
  payload,
});

export const activeSessionAction = payload => ({
  type: types.HR_ACTIVE_SESSION,
  payload,
});

export const setWebClockInFaceAuthPendingAction = payload => ({
  type: types.SET_WEBCLOCKIN_FACE_AUTH_PENDING,
  payload,
});

export const setWebClockInFaceAuthErrorAction = payload => ({
  type: types.SET_WEBCLOCKIN_FACE_AUTH_ERROR,
  payload,
});

export const setWebClockInSuccessAction = payload => ({
  type: types.SET_WEBCLOCKIN_CLOCK_IN_SUCEESS,
  payload,
});

export const setWebClockOutSuccessAction = payload => ({
  type: types.SET_WEBCLOCKIN_CLOCK_OUT_SUCEESS,
  payload,
});
