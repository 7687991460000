import React, { useState } from 'react';
import history from '@history';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import CartIcon from '../../../shared/components/Topbar/CartIcon';
import TopUserProfile from '../../../shared/components/Topbar/TopUserProfile';
import TopBarChangeStore from '../../../shared/components/Topbar/TopBarChangeStore';
import NotificationIcon from '../../../shared/components/Topbar/NotificationIcon';

// import CartDropdown from './CartDropdown';
const Ava = `${process.env.PUBLIC_URL}/img/default_avatar.png`;

const TopbarProfile = () => {
  const [hidden, setHidden] = useState(false);
  const user = useSelector(state => state.auth && state.auth.user) || {};
  const cart = useSelector(state => state && state.cart) || {};
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = (event) => {
    event.preventDefault();
    const id = localStorage.getItem('user_id');
    if (id) {
      window.location.href = '/login';
    } else {
      window.location.href = '/';
    }
    localStorage.clear();
  };
  const handleOpenClose = () => {
    setHidden(!hidden);
    // if (props.cart) {
    //   if (props.cart.hidden) {
    //     props.dispatch(showCart());
    //   } else {
    //     props.dispatch(hideCart());
    //   }
    // }
  };
  const data = JSON.parse(localStorage.getItem('user'));
  let img;
  if (data.profile_photo && data.profile_photo[0] && data.profile_photo[0].preview) {
    img = data.profile_photo[0].preview;
  }
  if (typeof data.profile_photo === typeof '') {
    img = data.profile_photo;
  }
  if (!data.profile_photo) {
    img = Ava;
  }
  const companyID = JSON.parse(localStorage.getItem('user')).company_id;
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const isB2BUser = JSON.parse(localStorage.getItem('isB2BUser'));
  const link = `/companies/${companyID}`;

  return (
    <Grid container justify="flex-end" alignItems="center" spacing={matchesSM ? 0 : 2} wrap="nowrap" >
      <Grid item>
        <NotificationIcon onClick={handleOpenClose} />
      </Grid>
      <Grid item>
        <CartIcon onClick={handleOpenClose} />
      </Grid>
      {!isB2BUser ?
        <Grid item>
          <TopBarChangeStore userData={data} />
        </Grid>
      : null}
      <Grid item style={{ paddingRight: '-20px' }}>
        <TopUserProfile
          permission={!!(permissions && permissions.can_edit_own_company_settings)}
          handleLogout={handleLogout}
          src={img}
          userData={data}
        />
      </Grid>
      {/* <Grid item>
         { !props.cart.hidden ? <CartDropdown /> : null }
      </Grid> */}
    </Grid>
  );
};

export default TopbarProfile;
