import React, { useContext } from 'react';
import {Redirect} from 'react-router-dom';
import { Provider } from 'react-redux';

export default {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'documentCenterApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    { // Private routes
      path: '/document-center',
      component: React.lazy(() => import('./DocumentCenter')),
    },
  ],
};
