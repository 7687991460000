import { put, call, takeLatest, all } from 'redux-saga/effects';
import { getCompanyPaymentAccount, registerCompanyPaymentAccount, updateCompanyPaymentAccount, updatePersonforPaymentAccount, getPersonforPaymentAccount, removePaymentAccount } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable  */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_PAYMENT_ACCOUNT_REQUEST, fetchPaymentAccountSaga);
  yield takeLatest(types.ADD_PAYMENT_ACCOUNT_REQUEST, addPaymentAccountSaga, context);
  yield takeLatest(types.UPDATE_PAYMENT_ACCOUNT_REQUEST, updateCompanyPaymentAccountSaga);
  yield takeLatest(types.UPDATE_PERSON_FOR_PAYMENT_REQUEST, updatePersonForPaymentSaga);
  yield takeLatest(types.FETCH_PERSON_FOR_PAYMENT_REQUEST, fetchPersonForPaymentSaga);
  yield takeLatest(types.REMOVE_PAYMENT_ACCOUNT_REQUEST, removePaymentAccountSaga);
  // yield takeLatest(types.DELETE_PAYMENT_ACCOUNT_REQUEST, deletePaymentAccountSaga);
  // yield takeLatest(types.FETCH_PAYMENT_ACCOUNT_COUNTS, fetchPaymentAccountCountSaga);
}

export function* fetchPaymentAccountSaga({ payload }) {
  try {
    const res = yield call(getCompanyPaymentAccount, payload);
    yield all([
      put({ type: types.FETCH_PAYMENT_ACCOUNT_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_PAYMENT_ACCOUNT_FAILED, error });
  }
}

export function* addPaymentAccountSaga({ history }, { payload }) {
  try {
    const res = yield call(registerCompanyPaymentAccount, payload);
    yield all([
      put({ type: types.ADD_PAYMENT_ACCOUNT_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Payment Account added' : res.message || 'PaymentAccount not added',
        },
      }),
    ]);
  } catch (error) {
    console.log(error)
    yield all([
      put({ type: types.ADD_PAYMENT_ACCOUNT_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateCompanyPaymentAccountSaga({ payload }) {
  try {
    const res = yield call(updateCompanyPaymentAccount, payload);
    yield all([
      put({ type: types.UPDATE_PAYMENT_ACCOUNT_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Payment Account updated' : res.message || 'PaymentAccount not updated',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //     history.push('/payments');
    // }
  } catch (error) {
    console.log(error)
    yield all([
      put({ type: types.UPDATE_PAYMENT_ACCOUNT_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updatePersonForPaymentSaga({ payload }) {
  try {
    const res = yield call(updatePersonforPaymentAccount, payload);
    yield all([
      put({ type: types.UPDATE_PERSON_FOR_PAYMENT_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Payment Account updated' : res.message || 'PaymentAccount not updated',
        },
      }),
    ]);
  } catch (error) {
    console.log(error)
    yield all([
      put({ type: types.UPDATE_PERSON_FOR_PAYMENT_SUCCESS, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* removePaymentAccountSaga({ payload }) {
  try {
    const res = yield call(removePaymentAccount, payload);
    yield all([
      put({ type: types.REMOVE_PAYMENT_ACCOUNT_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Payment Account removed' : res.message || 'PaymentAccount not removed',
        },
      }),
    ]);
  } catch (error) {
    console.log(error)
    yield all([
      put({ type: types.REMOVE_PAYMENT_ACCOUNT_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}


export function* fetchPersonForPaymentSaga({ payload }) {
  try {
    const res = yield call(getPersonforPaymentAccount, payload);
    yield all([
      put({ type: types.FETCH_PERSON_FOR_PAYMENT_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_PERSON_FOR_PAYMENT_FAILED, error });
  }
}

// export function* deletePaymentAccountSaga({ payload }) {
//     try {
//         const res = yield call(deletePaymentAccount, payload);
//         yield all([
//             put({ type: types.DELETE_PAYMENT_ACCOUNT_SUCCESS, payload }),
//             put({
//                 type: types.SET_NOTIFICATION,
//                 payload: {
//                     success: res.success,
//                     message: res.success ? 'PaymentAccount deleted' : res.message || 'PaymentAccount not deleted',
//                 },
//             }),
//         ]);
//     } catch (error) {
//         yield all([
//             put({ type: types.DELETE_PAYMENT_ACCOUNT_FAILED, error }),
//             put({
//                 type: types.SET_NOTIFICATION,
//                 payload: {
//                     success: false,
//                     message: error && error.message ? error.message : 'Server error',
//                 },
//             }),
//         ]);
//     }
// }

// export function* fetchPaymentAccountCountSaga({ payload }) {
//     try {
//         const res = yield call(getPaymentAccountsCount, payload);
//         yield all([
//             put({ type: types.FETCH_PAYMENT_ACCOUNT_COUNTS_SUCCESS, res }),
//         ]);
//     } catch (error) {
//         yield put({ type: types.FETCH_PAYMENT_ACCOUNT_COUNTS_FAILED, error });
//     }
// }
