/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import {matchRoutes} from 'react-router-config';
import {withRouter} from 'react-router-dom';
import AppContext from 'containers/App/AppContext';
import CentraverseUtils from '../../CentraverseUtils';

class CentraverseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const {routes} = context;
    this.state = {
      accessGranted: true,
      routes,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  componentDidUpdate() { // eslint-disable-line
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {location} = props;
    const {pathname} = location;

    const matched = matchRoutes(state.routes || [], pathname)[0];

    return {
      accessGranted: matched ? CentraverseUtils.hasPermission(matched.route.auth) : true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  redirectRoute() {
    const {location, history} = this.props;
    const {pathname, state} = location;
    const redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';

    if (!localStorage.getItem('user_id')) {
      window.location.href = '/login';
      // history.push({
      //   pathname: '/login',
      //   state: {redirectUrl: pathname},
      // });
    } else {
      window.location.href = '/';
      // history.push({
      //   pathname: redirectUrl,
      // });
    }
  }

  render() {
    // console.info('Fuse Authorization rendered', accessGranted);
    return this.state.accessGranted ? <React.Fragment>{this.props.children}</React.Fragment> : null;
  }
}

CentraverseAuthorization.contextType = AppContext;

export default withRouter(CentraverseAuthorization);
