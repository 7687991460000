/* eslint-disable */
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    maxWidth: 500,
    width: '90%',
    height: 280,
    backgroundColor: theme.palette.background.paper,
    border: '',
    borderRadius: '10px',
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center'
  },
}));

export default function GeneralModal({
  message,
  addMessage,
  closeModal,
  open,
  actionStatus,
  cancelButtonText,
  okButtonText,
  cancelButtonColor,
  okButtonColor,
  imageSrc,
}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <img
        style={{ width: '20%', marginTop: '30px' }}
        src={imageSrc}
      />
      <p
        id='simple-modal-description'
        style={{ paddingTop: '20px', textAlign: 'center' }}
      >
        <b>{message}</b>
      </p>
      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        {cancelButtonText ?
          (<Button
            onClick={() => {
              closeModal();
              actionStatus('No');
            }}
            style={{
              fontSize: '15px',
              textTransform: 'none',
              backgroundColor: cancelButtonColor ? cancelButtonColor : '#939FBF',
              color: 'white',
            }}
            variant='outlined'
            size='large'
            className={classes.margin}
          >
            {cancelButtonText}
        </Button>)
        : null}
        <Button
          onClick={() => {
            closeModal();
            actionStatus('Yes');
          }}
          style={{
            textTransform: 'none',
            marginLeft: '20px',
            backgroundColor: okButtonColor ? okButtonColor : '#3AA50B',
            color: 'white',
          }}
          variant='outlined'
          size='large'
          className={classes.margin}
        >
          {okButtonText}
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      {body}
    </Modal>
  );
}
