/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
// /* eslint-disable */
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Paper,
  Button,
  Box,
  Icon,
  Typography,
  IconButton,
  SvgIcon,
  ButtonGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import _, { filter, debounce } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { useGetCoordinates } from 'lib/useGetCoordinates';
import CommentIcon from '@material-ui/icons/Comment';
import AddCommentIcon from '@material-ui/icons/AddComment';
import {ReactComponent as CoffeeIcon} from 'assets/images/coffee1.svg';
import {ReactComponent as LunchIcon} from 'assets/images/lunch.svg';
import RedButton from 'shared/components/Mui/button/RedButton';
import { fetchBreakSettingsAction, addTimecardBreakAction, updateTimecardBreakAction, setTimecardBreakFaceAuthPendingAction, setTimecardBreakFaceAuthErrorAction, setBreakEndSuccessAction, setBreakStartSuccessAction } from 'redux/actions/hrTimecardBreakAction';
import { fetchStatusForWebClockinAction } from 'redux/actions/hrWebClockinAction';
import FaceModal from 'shared/components/FaceModal';

import './HRWebClockIn.scss';
import Break from './Break';
import { timecardActions } from '../../../constants/default';

const imageUrl = null;
const useStyles = makeStyles(theme => ({
  breakIcons: props => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    padding: 7,
    'border-radius': '5px',
    '&.active': {
      borderColor: '#D2E2EA',
    },
  }),
  highlightColor: {
    color: theme.palette.highlight.main,
  },
  highlightBackground: {
    background: theme.palette.highlight.main,
    color: theme.palette.highlight.contrastText,
  },
  blueGreyColor: {
    color: theme.palette.blueGrey.main,
  },
}));
function BreakDiagBox({
  breaksettings,
  timecardbreak,
  webclock,
  faceAuthPending,
  faceAuthError,
  faceAuthErrorMsg,
  breakStartSuccess,
  breakEndSuccess,
  ...props
}) {
  const classes = useStyles();
  const breakPopUpShow = JSON.parse(localStorage.getItem('breakPopUpShow')) || {};
  const timezone = localStorage.getItem('timezone');

  const storeTimeFormatter = ({ ...options }) =>
    new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      ...options,
    });

  const user_id = JSON.parse(localStorage.getItem('user')).id;
  const store_id = JSON.parse(localStorage.getItem('store_id'));
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const scheduleId = JSON.parse(localStorage.getItem('scheduleId'));
  const [open, setOpen] = React.useState(true);
  // const sidebar = useSelector(state => (state.sidebar_items && state.sidebar_items.sidebar_items) || []);
  // let val=(sidebar.map((item) => (item.name.includes('HR'))))
  // const found = sidebar.some(el => el.name ==='HR');
  const [user_comment, setUser_comment] = React.useState(null);
  const [activeBreak, setActiveBreak] = React.useState((breaksettings &&
    breaksettings.length > 0 &&
    breaksettings[0]) ||
    {});
  const [faceAuthPayload, setFaceAuthPayload] = React.useState(null);
  const [faceAuthAction, setFaceAuthAction] = React.useState('');

  const getCoordinates = useGetCoordinates();
  const dispatch = useDispatch();
  const handleClose = (e) => {
    setOpen(false);
    localStorage.setItem('breakPopUpShow', JSON.stringify({...breakPopUpShow, breakPopUpShow: false}));
  };

  const handleClickClose = (e) => {
    e.preventDefault();
    handleClose();
  };

  useEffect(() => {
    if (webclock.timecard_break_id && breaksettings && breaksettings.length > 0) {
      setActiveBreak((
        (_.find(breaksettings, ['settings_store_id', webclock.settings_store_id]) ||
        breaksettings[0])) ||
        {});
    } else {
      const selBreak = _.chain(breaksettings)
        .orderBy(['is_mandatory', 'is_paid'], ['desc', 'desc'])
        .head()
        .value();
      if (selBreak) {
        setActiveBreak(selBreak || {});
      }
    }
  }, [breaksettings, webclock.timecard_break_id]);
  const onStartBreak = debounce(async (data) => {
    if (permissions && permissions.write_hr_clockin && permissions.write_hr_clockin_web && webclock.status === 2 && activeBreak.settings_store_id && webclock.timecard_id) {
      const coordinates = await getCoordinates();
      const payload = {
        ...data,
        timecard_id: webclock.timecard_id,
        user_id,
        imageUrl,
        ...coordinates,
        user_comment,
        settings_store_id: activeBreak.settings_store_id,
      };
      setFaceAuthPayload(payload);
      setFaceAuthAction(timecardActions.BREAK_START);
      dispatch(addTimecardBreakAction(payload));
    }
  }, 2000, {leading: true, trailing: false});
  const onEndBreak = debounce(async (data) => {
    if (permissions && permissions.write_hr_clockin && permissions.write_hr_clockin_web && webclock.status === 3 && webclock.timecard_break_id) {
      const coordinates = await getCoordinates();
      const payload = {
        ...data,
        id: webclock.timecard_break_id,
        user_id,
        imageUrl,
        ...coordinates,
        user_comment,
      };
      setFaceAuthPayload(payload);
      setFaceAuthAction(timecardActions.BREAK_END);
      dispatch(updateTimecardBreakAction(payload));
    }
  }, 2000, {leading: true, trailing: false});

  const handleFaceSubmit = (faceImage) => {
    console.log('Submitted');
    console.log(faceImage);
    switch (faceAuthAction) {
      case timecardActions.BREAK_START:
        onStartBreak({...faceAuthPayload, ...faceImage});
        break;
      case timecardActions.BREAK_END:
        onEndBreak({...faceAuthPayload, ...faceImage});
        break;
      default:
        break;
    }
  };

  const handleFaceModalClose = () => {
    dispatch(setTimecardBreakFaceAuthPendingAction(false));
    dispatch(setTimecardBreakFaceAuthErrorAction(false));
  };

  useEffect(() => {
    console.log('timecardbreak', timecardbreak);
    dispatch(fetchStatusForWebClockinAction({
      params: {
        user_id,
        store_id,
      },
    }));
    dispatch(fetchBreakSettingsAction({
      params: {
        store_id,
        user_id,
      },
    }));
  }, []);

  useEffect(() => {
    if (breakStartSuccess) {
      handleClose();
      dispatch(setBreakStartSuccessAction(false));
    }
  }, [breakStartSuccess]);

  useEffect(() => {
    if (breakEndSuccess) {
      handleClose();
      dispatch(setBreakEndSuccessAction(false));
    }
  }, [breakEndSuccess]);

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClickClose}
        style={{ borderRadius: '20px' }}
      >
        <div className="form-container1">
          <DialogContent>
            <Grid item xs={12}>
              <h3>Break</h3>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} className="text-center">
              {permissions.write_hr_clockin && permissions.write_hr_clockin_web ? (
                (webclock.status === 2 || webclock.status === 3) ?
                  <Paper style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: 'none',
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      style={{ width: '177px', height: '180px', padding: '8px' }}
                      alignItems="center"
                      justify="space-evenly"
                    >
                      <Grid container spacing={2} justify="center" item>
                        {breaksettings &&
                            breaksettings.length > 0 &&
                            filter(breaksettings, { short_description: 'Lunch Break' }).map(breakSetting => (
                              <Grid item key={breakSetting.settings_store_id}>
                                <IconButton
                                  className={`${activeBreak.settings_store_id ===
                                    breakSetting.settings_store_id && 'active'} ${classes.breakIcons
                                    }`}
                                  onClick={() => !webclock.timecard_break_id && setActiveBreak(breakSetting)}
                                  title="Lunch Break"
                                >
                                  <SvgIcon
                                    component={LunchIcon}
                                    color={
                                      activeBreak.settings_store_id !== breakSetting.settings_store_id
                                        ? 'disabled'
                                        : undefined
                                    }
                                  />
                                </IconButton>
                              </Grid>
                            ))}
                        {breaksettings &&
                            breaksettings.length > 0 &&
                            _.chain(breaksettings)
                              .filter({ short_description: 'Coffee Break' })
                              .orderBy(['is_mandatory', 'is_paid'], ['desc', 'desc'])
                              .take()
                              .value()
                              .map(breakSetting => (
                                <Grid item key={breakSetting.settings_store_id}>
                                  <IconButton
                                    className={`${activeBreak.settings_store_id === breakSetting.settings_store_id &&
                                      'active'} ${classes.breakIcons}`}
                                    onClick={() => !webclock.timecard_break_id && setActiveBreak(breakSetting)}
                                    title="Coffee Break"

                                  >
                                    <SvgIcon
                                      component={CoffeeIcon}
                                      color={
                                        activeBreak.settings_store_id !== breakSetting.settings_store_id
                                          ? 'disabled'
                                          : undefined
                                      }
                                    />
                                  </IconButton>
                                </Grid>
                              ))}
                      </Grid>
                      <Grid item>
                        <Typography className={classes.highlightColor} variant="h2" component="span">
                          {(activeBreak.duration) ||
                              0}
                        </Typography>
                        <Typography className={classes.highlightColor} variant="caption">
                            min
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueGreyColor} variant="subtitle2" component="span">
                          {activeBreak.short_description === 'Lunch Break'
                              ? 'Lunch - '
                              : activeBreak.short_description === 'Coffee Break' && 'Coffee - '}
                          {activeBreak.is_paid
                              ? 'Paid Break'
                              : 'Unpaid Break'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper> :
                      <>
                        <Grid item>
                          <Typography variant="body1" component="span">
                            You are not clocked in, you can't take breaks now.
                          </Typography>
                        </Grid>
                        <Box my={3} />
                      </>
                ) : null
                }
              <div style={{ textAlign: 'center' }}>
                {(webclock.status === 2 || webclock.status === 3) &&
                <TextField
                  style={{
                        width: '350px',
                        marginTop: '0px',
                        marginBottom: '16px',
                      }}
                  id="txtusercomment"
                  placeholder="Add Notes/Comments"
                  width="500px"
                  margin="normal"
                  size="small"
                  variant="outlined"
                  onChange={e => setUser_comment(e.target.value)}
                  InputLabelProps={{
                        shrink: true,
                      }}
                />}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <Button
                      onClick={handleClickClose}
                      disableElevation
                      variant="outlined"
                      size="large"
                      type="submit"
                      style={{
                            textTransform: 'none',
                            color: '#4EA50F',
                            borderColor: '#4EA50F',
                            height: 55,
                            width: 130,
                            margin: '5px',
                          }}
                    >
                      {(webclock.status === 2 || webclock.status === 3) ? 'Cancel' : 'Close'}
                    </Button>
                  </div>
                  {(webclock.status === 2 || webclock.status === 3) &&
                  <div>
                    <Button
                      onClick={() => (!webclock.timecard_break_id && webclock.status === 2 ? onStartBreak() : onEndBreak())}
                      disableElevation
                      variant="contained"
                      size="large"
                      type="submit"
                      style={{
                            textTransform: 'none',
                            backgroundColor: !webclock.timecard_break_id && webclock.status === 2 ? '#4EA50F' : '#f44336',
                            color: '#FFFFFF',
                            height: 55,
                            width: 130,
                            margin: '5px',
                          }}
                    >
                      {!webclock.timecard_break_id && webclock.status === 2 ? 'Start Break' : 'End Break' }
                    </Button>
                  </div>}
                </div>
              </div>
            </Grid>
          </DialogActions>
        </div>
      </Dialog>
      {faceAuthPending && faceAuthAction &&
      <FaceModal
        open={faceAuthPending}
        onClose={handleFaceModalClose}
        onSubmit={handleFaceSubmit}
        error={faceAuthError}
        errorMsg={faceAuthErrorMsg}
      />
      }
    </div>
  );
}

export default connect(state => ({
  breaksettings: state.timecardbreaks && state.timecardbreaks.breaksettings,
  timecardbreak: state.timecardbreaks && state.timecardbreaks.timecardbreak,
  webclock: state.webclocks && state.webclocks.webclock,
  faceAuthPending: (state.timecardbreaks && state.timecardbreaks.faceAuthPending),
  faceAuthError: (state.timecardbreaks && state.timecardbreaks.faceAuthError),
  faceAuthErrorMsg: (state.timecardbreaks && state.timecardbreaks.faceAuthErrorMsg),
  breakStartSuccess: state.timecardbreaks && state.timecardbreaks.breakStartSuccess,
  breakEndSuccess: state.timecardbreaks && state.timecardbreaks.breakEndSuccess,
}))(BreakDiagBox);
