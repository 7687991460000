import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import loginReducer from './loginReducer';
import authReducer from './authReducer';
import posCategoriesReducer from './posCategoriesReducer';
import ordersReducer from './ordersReducer';
import companiesReducer from './companiesReducer';
import usersReducer from './usersReducer';
import taxReducer from './taxReducer';
import roleReducer from './roleReducer';
import permissionGroupReducer from './permissionGroupReducer';
import storesReducer from './storesReducer';
import cartReducer from './cartReducer';
import notificationReducer from './notificationReducer';
import phoneVerificationsReducer from './phoneVerificationsReducer';
import districtsReducer from './districtsReducer';
import serialsReducer from './serialsReducer';
import paymentAccountReducers from './paymentAccountReducer';
import sidebarItemReducer from './sidebarItemReducer';
import embedsAndLinksReducer from './embedsAndLinksReducer';
import regionReducer from './regionReducer';
import hrWebClockinReducer from './hrWebClockinReducer';
import hrTimecardBreakReducer from './hrTimecardBreakReducer';
import hrSettingsStoreReducer from './hrSettingsStoreReducer';
import hrSettingsCompaniesReducer from './hrSettingsCompaniesReducer';
import user_stores from './UserStoreReducer'; // eslint-disable-line
import user_districts from './UserDistrictReducer'; // eslint-disable-line
import user_regions from './UserRegionReducer'; // eslint-disable-line
import support_tickets from './SupportTicketReducer'; // eslint-disable-line
import support_ticket_types from './SupprtTicketTypeReducer'; // eslint-disable-line
import globalLoader from './globalLoaderReducer';
import employeesReducer from './employeesReducer';
import requiredFieldsReducer from './requiredFieldsReducer';
import oneViewCompanyWidgetReducer from './oneViewCompanyWidgetReducer';
import oneViewReducer from './oneViewReducer';
import coordinatesReducer from './coordinatesReducer';
import hrUserAutoClockedDetailsReducer from './hrUserAutoClockedDetailsReducer';

export {
  themeReducer,
  sidebarReducer,
  loginReducer,
  authReducer,
  posCategoriesReducer,
  ordersReducer,
  companiesReducer,
  usersReducer,
  taxReducer,
  permissionGroupReducer,
  storesReducer,
  cartReducer,
  notificationReducer,
  phoneVerificationsReducer,
  districtsReducer,
  serialsReducer,
  paymentAccountReducers,
  sidebarItemReducer,
  embedsAndLinksReducer,
  regionReducer,
  hrWebClockinReducer,
  hrTimecardBreakReducer,
  hrSettingsStoreReducer,
  hrSettingsCompaniesReducer,
  user_stores, // eslint-disable-line
  user_districts,// eslint-disable-line
  user_regions,// eslint-disable-line
  support_tickets,// eslint-disable-line
  support_ticket_types, // eslint-disable-line
  globalLoader,
  employeesReducer,
  requiredFieldsReducer,
  oneViewCompanyWidgetReducer,
  oneViewReducer,
  roleReducer,
  coordinatesReducer,
  hrUserAutoClockedDetailsReducer,
};
