import React from 'react';

export const TasksAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'tasksApp',
    store: import('./store'),
  },
  auth: true,
  routes: [
    {
      path: '/task-tickets/settings',
      component: React.lazy(() => import('./TaskTicketSetting')),
    },
    {
      path: '/task-center',
      component: React.lazy(() => import('./SingleTaskTicketAdmin')),
    },
    {
      path: '/task-tickets/status/new',
      component: React.lazy(() => import('./ManageTaskTicketStatuses')),
    },
    {
      path: '/task-tickets/status/edit/:id(\\d+)',
      component: React.lazy(() => import('./ManageTaskTicketStatuses')),
    },
    {
      path: '/task-tickets/priority/new',
      component: React.lazy(() => import('./ManageTaskTicketPriorities')),
    },
    {
      path: '/task-tickets/priority/edit/:id(\\d+)',
      component: React.lazy(() => import('./ManageTaskTicketPriorities')),
    },
    {
      path: '/task-tickets/type/new',
      component: React.lazy(() => import('./ManageTaskTicketTypes')),
    },
    {
      path: '/task-tickets/type/edit/:id(\\d+)',
      component: React.lazy(() => import('./ManageTaskTicketTypes')),
    },
    {
      path: '/task-tickets/new-from-template',
      component: React.lazy(() => import('./NewTicketViaTemplate')),
    },
    {
      path: '/task-tickets/custom',
      component: React.lazy(() => import('./NewTicketTemplate')),
    },
    {
      path: '/ticket-templates/new',
      component: React.lazy(() => import('./NewTicketTemplate')),
    },
    {
      path: '/ticket-templates/edit/:id(\\d+)',
      component: React.lazy(() => import('./NewTicketTemplate')),
    },
    {
      path: '/task-tickets/new/:id',
      component: React.lazy(() => import('./NewTicketTemplate')),
    },
    {
      path: '/ticket-templates',
      component: React.lazy(() => import('./TicketTemplates')),
    },
  ],
};

export default TasksAppConfig;
