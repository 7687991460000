export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';

export const changeSidebarVisibility = () => ({
  type: CHANGE_SIDEBAR_VISIBILITY,
});

export const changeMobileSidebarVisibility = () => ({
  type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
});
