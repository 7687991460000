import * as types from '../../constants/actionTypes';

export const fetchRequiredFieldsAction = payload => ({
  type: types.FETCH_REQUIRED_FIELDS_REQUEST,
  payload,
});

export const addRequiredFieldsAction = payload => ({
  type: types.ADD_REQUIRED_FIELDS_REQUEST,
  payload,
});

export const updateRequiredFieldsAction = payload => ({
  type: types.UPDATE_REQUIRED_FIELDS_REQUEST,
  payload,
});
