export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';

export const changeThemeToDark = () => ({
  type: CHANGE_THEME_TO_DARK,
});

export const changeThemeToLight = () => ({
  type: CHANGE_THEME_TO_LIGHT,
});
