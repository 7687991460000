
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { getUserAutoClockedOutDetails } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_HRUSERAUTOCLOCKEDOUT_REQUEST, fetchHRUserAutoClockedOutUserDetailsSaga);
}

export function* fetchHRUserAutoClockedOutUserDetailsSaga({ payload }) {
  try {
    const res = yield call(getUserAutoClockedOutDetails, payload);
    yield all([
      put({ type: types.FETCH_HRUSERAUTOCLOCKEDOUT_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_HRUSERAUTOCLOCKEDOUT_FAILED, error });
  }
}
