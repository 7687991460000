import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addCompanyWidget, deleteCompanyWidget, getCompanyWidget, getCompanyWidgets, updateCompanyWidget, updateOneViewBgImg } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_COMPANY_WIDGETS_REQUEST, fetchCompanyWidgetsSaga);
  yield takeLatest(types.FETCH_COMPANY_WIDGET_REQUEST, fetchCompanyWidgetSaga);
  yield takeLatest(types.ADD_COMPANY_WIDGET_REQUEST, addCompanyWidgetSaga, context);
  yield takeLatest(types.UPDATE_COMPANY_WIDGET_REQUEST, updateCompanyWidgetSaga, context);
  yield takeLatest(types.UPDATE_ONE_VIEW_BG_IMAGE_REQUEST, updateOneViewBgImgSaga, context);
  yield takeLatest(types.DELETE_COMPANY_WIDGET_REQUEST, deleteCompanyWidgetSaga);
}

export function* fetchCompanyWidgetsSaga({ payload }) {
  try {
    const res = yield call(getCompanyWidgets, payload);
    yield all([
      put({ type: types.FETCH_COMPANY_WIDGETS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_COMPANY_WIDGETS_FAILED, error });
  }
}

export function* fetchCompanyWidgetSaga({ payload }) {
  try {
    const res = yield call(getCompanyWidget, payload);
    yield all([
      put({ type: types.FETCH_COMPANY_WIDGET_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_COMPANY_WIDGET_FAILED, error });
  }
}

export function* addCompanyWidgetSaga({ history }, { payload }) {
  try {
    const res = yield call(addCompanyWidget, payload);
    yield all([
      put({ type: types.ADD_COMPANY_WIDGET_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Widget added' : res.message || 'Widget not added',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.ADD_COMPANY_WIDGET_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateCompanyWidgetSaga({ history }, { payload }) {
  try {
    const res = yield call(updateCompanyWidget, payload);
    yield all([
      put({ type: types.UPDATE_COMPANY_WIDGET_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Widget updated' : res.message || 'Widget not updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_COMPANY_WIDGET_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateOneViewBgImgSaga({ history }, { payload }) {
  try {
    const res = yield call(updateOneViewBgImg, payload);
    yield all([
      put({ type: types.UPDATE_ONE_VIEW_BG_IMAGE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Background Image Updated' : res.message || 'Background Image Not Updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_ONE_VIEW_BG_IMAGE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteCompanyWidgetSaga({ payload }) {
  try {
    const res = yield call(deleteCompanyWidget, payload);
    yield all([
      put({ type: types.DELETE_COMPANY_WIDGET_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Widget deleted' : res.message || 'Widget not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_COMPANY_WIDGET_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
