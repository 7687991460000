import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */
const initialState = {
  isEdit: false,
  isEmployeeEdit: false,
  widgetPositions: [],
  backgroundImage: {},
  isNew: false,
  bgImageStatus: false,
  editMode: 1,
  isUpdate: {
    mgr: false, eom: false, devAcc: false, textEditor: false, empDetail: false, upAct: false, storeInfo: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ENABLE_ONE_VIEW_EDIT:
      return {
        ...state,
        isEdit: true,
      };
    case types.DISABLE_ONE_VIEW_EDIT:
      return {
        ...state,
        isEdit: false,
      };
    case types.ENABLE_ONE_VIEW_EMPLOYEE_EDIT:
      return {
        ...state,
        isEmployeeEdit: true,
      };
    case types.DISABLE_ONE_VIEW_EMPLOYEE_EDIT:
      return {
        ...state,
        isEmployeeEdit: false,
      };
    case types.STORE_WIDGET_POSITION:
      const widgetPositions = action.payload.layout;
      return {
        ...state,
        widgetPositions,
      };
    case types.STORE_BACKGROUND_IMAGE:
      const backgroundImage = action.payload.imageBody;
      return {
        ...state,
        backgroundImage,
      };
    case types.NEW_WIDGET_ALERT:
      const isNew = action.payload.status;
      return {
        ...state,
        isNew,
      };
    case types.BG_IMAGE_UPDATE_STATUS:
      const bgImageStatus = action.payload.status;
      return {
        ...state,
        bgImageStatus,
      };
    case types.SET_CURRENT_EDIT_MODE:
      const {editMode} = action.payload;
      return {
        ...state,
        editMode,
      };
    case types.SET_IS_UPDATE:
      return {
        ...state,
        isUpdate: {...state.isUpdate, ...action.payload},
      };
    default:
      return state;
  }
};
