import React from 'react';

export const RegistersAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    // moduleName: 'adminApp',
    // store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/registers',
      component: React.lazy(() => import('./Registers')),
    },
    {
      path: '/textract-processor',
      component: React.lazy(() => import('./TextractProcessor')),
    },
  ],
};

export default RegistersAppConfig;
