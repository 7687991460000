import React from 'react';
import { withRouter, Link as RouterLink, useHistory  } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import NotFound from '../../../assets/images/not-found.svg';


const useStyles = makeStyles({
  contentWrapper: {
    width: '100%',
    display: 'block',
    padding: '20px 0',
    textAlign: 'center',
  },
  noResultImg: {
    width: props => props.imageWidth || 100,
    height: props => props.imageHeight || 100,
  },
  noResultHeader: {
    fontSize: props => props.textSize || 16,
    margin: '10px 0',
    color: '#707070',
  },
  noResultSubHeader: {
    fontSize: 12,
    color: 'grey !important',
    margin: '10px 0',
  },
});

/* eslint-disable react/prop-types */

function NoResultsFound(props) {
  const classes = useStyles(props.styles);
  return (
    <div className={classes.contentWrapper}>
      <img
        src={props.image ? props.image : NotFound}
        alt="No Results Found"
        className={classes.noResultImg}
      />
      <div className={classes.noResultHeader}>
        {props.message ? props.message : 'Sorry we could not find any results'}
      </div>
      <div className={classes.noResultHeader}>
        {props.link && (
          <Link
            className={classes.backLink}
            underline="none"
            component={RouterLink}
            to={props.link}
          >
            {props.pageChangeMessage ? props.pageChangeMessage : null}
          </Link>
        )}
      </div>
      {/* <p className={classes.noResultSubHeader}>Please try searching with another term</p> */}
    </div>
  );
}

export default NoResultsFound;
