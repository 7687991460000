import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addUserRegion, deleteUserRegion, getUserRegionById, getUserRegions, updateUserRegion} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_USERREGIONS_REQUEST, fetchUserRegionsSaga);
  yield takeLatest(types.FETCH_USERREGION_REQUEST, fetchUserRegionSaga);
  yield takeLatest(types.ADD_USERREGION_REQUEST, addUserRegionSaga, context);
  yield takeLatest(types.UPDATE_USERREGION_REQUEST, updateUserRegionSaga, context);
  yield takeLatest(types.DELETE_USERREGION_REQUEST, deleteUserRegionSaga);
}

export function* fetchUserRegionsSaga({ payload }) {
  try {
    const res = yield call(getUserRegions, payload);
    yield all([
      put({ type: types.FETCH_USERREGIONS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERREGIONS_FAILED, error });
  }
}

export function* fetchUserRegionSaga({ payload }) {
  try {
    const res = yield call(getUserRegionById, payload);
    yield all([
      put({ type: types.FETCH_USERREGION_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERREGION_FAILED, error });
  }
}

export function* addUserRegionSaga({ history }, { payload }) {
  try {
    const res = yield call(addUserRegion, payload);
    yield all([
      put({ type: types.ADD_USERREGION_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserRegion added' : res.message || 'UserRegion not added',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/userstores');
    // }
  } catch (error) {
    yield all([
      put({ type: types.ADD_USERREGION_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateUserRegionSaga({ history }, { payload }) {
  try {
    const res = yield call(updateUserRegion, payload);
    yield all([
      put({ type: types.UPDATE_USERREGION_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserRegion updated' : res.message || 'UserRegion not updated',
        },
      }),
    ]);
    // if (res && res.success && res.data && res.data.id && history) {
    //   history.push('/userstores');
    // }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_USERREGION_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteUserRegionSaga({ payload }) {
  try {
    const res = yield call(deleteUserRegion, payload);
    yield all([
      put({ type: types.DELETE_USERREGION_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserRegion deleted' : res.message || 'UserRegion not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_USERREGION_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
