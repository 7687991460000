import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTable, useFilters, useSortBy, useGroupBy, useExpanded, useRowSelect, useGlobalFilter, usePagination } from 'react-table';
import { useExportData } from 'react-table-plugins';
import JsPDF from 'jspdf';
import Papa from 'papaparse';
import moment from 'moment';
import 'jspdf-autotable';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import CloseIcon from '@material-ui/icons/Close';
import { Search, ArrowDropDown, ExpandLess, ExpandMore, UnfoldMore } from '@material-ui/icons';
import { Checkbox, Grid, Popper, IconButton, Typography, InputLabel, FormControl, Select, ClickAwayListener } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';


const GreenCheckbox = withStyles({
  root: {
    color: '#939FBF',
    '&$checked': {
      color: '#4EA50F',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '1400px',
    },
    '& tfoot, & tfoot tr, & tfoot td': {
      position: 'sticky',
      bottom: 0,
      zIndex: 1,
    },
    '& thead, & thead tr, & thead th': {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  },
  tBody: {
    ...theme.overflowScrollBar,
  },
  tableCell: {
    backgroundColor: '#fff',
    padding: '12px 6px',
    borderBottom: '6px solid #F4F6F7',
    borderRight: '1px solid #e3e4e4',
  },
  tableCellNoBorderRight: {
    backgroundColor: '#fff',
    padding: '12px 6px',
    borderBottom: '6px solid #F4F6F7',
  },
  viewButton: {
    margin: '2px 5px',
    padding: 8,
    backgroundColor: '#B4D302',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#B4D302' },
  },
  rowLimit: {
    padding: 8,
    display: 'flex',
  },
  editButton: {
    margin: '2px 5px',
    padding: 8,
    backgroundColor: '#009ACA',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#009ACA' },
  },
  deleteButton: {
    margin: '2px 5px',
    padding: 8,
    backgroundColor: '#DE3333',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#DE3333' },
  },
  paginationButton: {
    display: 'flex',
  },
  paginationText: {
    display: 'flex',
    fontSize: '14px',
    color: '#0551A3',
    backgroundColor: '#F4F6F7',
    padding: '15px 5px',
    textAlign: 'center',
    fontWeight: 500,
  },
  paginationText2: {
    fontSize: '14px',
    color: '#0551A3',
    backgroundColor: '#F4F6F7',
    padding: '8px 5px',
    textAlign: 'center',
    fontWeight: 500,
  },
  tableRow: {
    '&:last-child': {
      '& td, th': {
        borderBottom: 'none',
      },
    },
  },
  headerButton: {
    fontSize: '0.532rem',
  },
  head: {
    borderBottomStyle: 'none',
    fontSize: '14px',
    color: '#0551A3',
    backgroundColor: '#F4F6F7',
    padding: '8px 5px',
    textAlign: 'center',
    fontWeight: 500,
  },
  footerStyle: {
    backgroundColor: '#687390',
    color: '#FFFFFF',
    fontSize: '14px',
    textAlign: 'center',
    padding: '8px',
  },
  filterHolder: {
    zIndex: 99,
    position: 'absolute',
    background: 'white',
    boxShadow: '1px 1px rgba(0,0,0,0.2)',
    marginTop: 4,
    borderRadius: 6,
  },
  customizeColumns: {
    width: 'auto',
    // display: 'flex',
    overflow: 'scroll',
    overflowY: 'hidden',
    ...theme.overflowScrollBar,
  },
  customizeColumnCheckbox: {
    padding: '10px',
    display: 'flex',
  },
  tableContainer: {
    maxHeight: 300,
    overflow: 'auto',
    padding: '0 2px',
    minHeight: 100,
    ...theme.overflowScrollBar,
  },
  TypoHeading: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#939FBF',
    padding: '10px 6px',
    backgroundColor: '#FFFFFF',
  },
  TypoSubHeading: {
    fontSize: 14,
    fontWeight: 'normal',
    color: 'black',
    padding: '16px 6px',
  },
  infoBoxWrapper: {
    borderRadius: 4,
    backgroundColor: '#F4F6F7',
    padding: '5px 15px',
    border: '1px solid #D2E2EA',
  },
  btn: {
    textTransform: 'none',
    backgroundColor: '#4EA50F',
    color: '#FFFFFF',
    height: 55,
    minWidth: 130,
    '&:hover': { backgroundColor: '#4EA50F' },
  },
  summary: {
    borderRadius: 4,
    alignContent: 'flex-end',
    backgroundColor: '#F4F6F7',
    border: '1px solid #D2E2EA',
  },
  infoHeader: {
    color: '#0551A3 !important',
    fontWeight: 600,
    textAlign: 'center',
    position: 'relative',
    fontSize: 14,
    '&:before': {
      content: '""',
      position: 'absolute',
      border: '1px dashed #D2E2EA',
      top: '50%',
      left: 0,
      width: '45%',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      border: '1px dashed #D2E2EA',
      top: '50%',
      right: 0,
      width: '45%',
    },
  },
  productDataHeading: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#939FBF',
    padding: '5px',
  },
  deletedText: { color: 'red', marginLeft: 10 },
  backBtn: { textTransform: 'none', height: 55, width: 130 },
  infoBox: { backgroundColor: '#F7F9FA', paddingBottom: 0, paddingTop: 0 },
  table: props => ({ 
    borderCollapse: 'collapse',
    ...(props.fullWidth && { width: '100%'}), maxWidth: '100%', 
    ...theme.overflowScrollBar,
  }),
}));

export default function ReactTable({
  inputData,
  inputColumns,
  sendColumn,
  fromDate,
  hideCols,
  startDate,
  endDate,
  reportName,
  toDate,
  makeGroup,
  closeColumns,
  groupColumnNames,
  enableCheckbox,
  checkBoxRowSpan,
  exportFileData,
  fileName,
  startExport,
  globalFilterValue,
  enableFooter,
  pagination,
  customizeColumns,
  exportFileType, exportAll, setStartExport, customExport,
  headerAlignment,
  getRowProps = (row) => {},
  filtersState = [],
  setFiltersState,
  expandedState = [],
  setExpandedState,
  // selectedFlatRows,
  handleSelectedFlatRows,
  startSelected,
  renderRowSubComponent,
  showSubComponent,
  subComponentProps={},
  columnDependencies,
  pageIndex: controlledPageIndex,
  setPageIndex,
  pageSize: controlledPageSize,
  setControlledPageSize,
  fullWidth = false,
}) {
  const classes = useStyles({ fullWidth });
  const wrapperRef = useRef(null);
  const [activeHeader, setActiveHeader] = useState('');
  const [selectedGlobalFilter, setSelectedGlobalFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const columns = useMemo(() => inputColumns, columnDependencies || []);
  const data = useMemo(() => inputData, [inputData]);
  const [closeCol, setCloseCol] = useState(false);

  function getExportFileBlob({ columns, data, fileType }) { // eslint-disable-line
    // CSV Export
    if (fileType === 'CSV') {
      let csvfileName;
      if (reportName) {
        reportName === 'Employee Leaves Report' ? csvfileName = `${reportName} For the year ${moment().format('YYYY')}.csv` : csvfileName = `${reportName} From ${moment(fromDate).format('MMM DD, YYYY')} To ${moment(toDate).format('MMM DD, YYYY')}.csv`; // eslint-disable-line
        const headerNames = columns.map(col => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data });
        const blob = new Blob([csvString], { type: 'text/csv' });
        const blobURL = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = csvfileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
        anchor.click();
      } else {
        csvfileName = `${fileName}.csv`;
        const headerNames = columns.map(col => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data });
        const blob = new Blob([csvString], { type: 'text/csv' });
        const blobURL = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.download = csvfileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
        anchor.click();
      }
    } else if (fileType === 'PDF') { // PDF Export
      const headerNames = columns.map(column => column.exportValue);
      if (reportName) {
        const doc = new JsPDF();
        let primaryHeaderText;
        reportName === 'Employee Leaves Report' ? primaryHeaderText = `${reportName} For the year ${moment().format('YYYY')}` : primaryHeaderText = `${reportName} From ${moment(fromDate).format('MMM DD, YYYY')} To ${moment(toDate).format('MMM DD, YYYY')}`;; // eslint-disable-line
        const secondaryHeaderText = `Store: ${localStorage.getItem('store_name')}`;
        doc.cell(12, 10, 186, 10, primaryHeaderText, undefined, 'center');
        doc.setFontSize(12);
        doc.cell(12, 20, 186, 10, secondaryHeaderText, undefined, 'center');
        // doc.text(`Timesheet Data From ${moment(fromDate).format('MMM DD, YYYY')} To ${moment(toDate).format('MMM DD, YYYY')}`, 14, 20);
        doc.autoTable({
          head: [headerNames],
          body: data,
          startY: 35,
          rowPageBreak: 'auto',
          bodyStyles: { valign: 'top' },
          columnStyles: { text: { cellWidth: 'auto' } },
        });
        doc.save(reportName === 'Employee Leaves Report' ? `${primaryHeaderText}.pdf` : `${fileName}.pdf`);
        return false;
      }
      const doc = new JsPDF();
      const primaryHeaderText = `${fileName}`;
      const secondaryHeaderText = `Store: ${localStorage.getItem('store_name')}`;
      doc.cell(12, 10, 186, 10, primaryHeaderText, undefined, 'center');
      doc.setFontSize(12);
      doc.cell(12, 20, 186, 10, secondaryHeaderText, undefined, 'center');
      // doc.text(`Timesheet Data From ${moment(fromDate).format('MMM DD, YYYY')} To ${moment(toDate).format('MMM DD, YYYY')}`, 14, 20);
      doc.autoTable({
        head: [headerNames],
        body: data,
        startY: 35,
        rowPageBreak: 'auto',
        bodyStyles: { valign: 'top' },
        columnStyles: { text: { cellWidth: 'auto' } },
      });
      doc.save(`${fileName}.pdf`);
      return false;
    }
    // Other formats goes here
    return false;
  }

  const {
    getTableProps, getTableBodyProps, headerGroups, footerGroups, page, rows, prepareRow, setGlobalFilter, exportData, setGroupBy, visibleColumns,
    state: {
      groupBy, globalFilter, pageIndex, pageSize, filters, expanded
    },
    selectedFlatRows,
    flatRows,
    // page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable({ // eslint-disable-line
    columns,
    data,
    hideCols,
    initialState: {
      hiddenColumns: columns
        .filter(col => col.show === false)
        .map(col => typeof col.accessor === 'string' ? col.accessor : col.id),
      filters: filtersState,
      expanded: expandedState,
      pageSize: controlledPageSize || 50,
      pageIndex: controlledPageIndex || 0,
    },
    getExportFileBlob,
    autoResetExpanded: false,
    isMultiSortEvent : () => true,
  },
  useGlobalFilter,
  useFilters,
  useGroupBy,
  useSortBy,
  useExpanded,
  usePagination,
  useRowSelect,
  useExportData,
  (hooks) => {
    if (enableCheckbox) {
      hooks.visibleColumns.push(columns => [ // eslint-disable-line
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableFilters: true,
          disableSortBy: true,
          disableBorder: true,
          rowSpan: checkBoxRowSpan,
          width: '2%',
          Footer: info =>
            (
              <div className={classes.footerStyle}>&nbsp;</div>
            ),
        },
        ...columns,
      ]);
    }
  }); // eslint-disable-line

  const handleHeaderClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setActiveHeader(v => (v === id ? 0 : id));
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setActiveHeader(0);
  };

  const sendColumnsToIndex = () => {
    sendColumn(allColumns);
  };

  const getIcon = (icon) => {
    switch (icon) {
      case 'search':
        return (<Search style={{ color: '#0551A3', cursor: 'pointer' }} />);
      case 'filter':
        return (<ArrowDropDown style={{ color: '#0551A3', cursor: 'pointer' }} />);
      default:
        return ('');
    }
  };

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setActiveHeader('');
    }
  };

  const IndeterminateCheckbox = forwardRef ( // eslint-disable-line
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <GreenCheckbox inputRef={resolvedRef} indeterminate={indeterminate} name="checkedG" {...rest} />
        </>
      );
    });

  useEffect(() => {
    if (startExport && exportFileData) {
      if (startExport && exportFileData && customExport) {
        exportFileData(flatRows);
      } else {
      exportFileData(selectedFlatRows);
      }
    }
    if (startExport && exportFileType !== '') {
      exportData(exportFileType, exportAll);
      setStartExport();
    }
  }, [startExport]);

  useEffect(() => {
    if (handleSelectedFlatRows) {
      handleSelectedFlatRows(selectedFlatRows.map((row) => row.original));
    }
  }, [selectedFlatRows]);

  // useEffect(() => {
  //   if (startSelected && handleSelectedFlatRows) {
  //     handleSelectedFlatRows(selectedFlatRows);
  //   }
  // }, [startSelected]);

  useEffect(() => {
    setSelectedGlobalFilter(globalFilterValue);
  }, [globalFilterValue]);

  useEffect(() => {
    setFiltersState && setFiltersState(filters);
  }, [filters]);

  useEffect(() => {
    setExpandedState && setExpandedState(expanded);
  }, [expanded]);

  useEffect(() => {
    setPageIndex && setPageIndex(pageIndex);
    setControlledPageSize && setControlledPageSize(pageSize);
  }, [setPageIndex, setControlledPageSize, pageIndex, pageSize]);

  useEffect(() => {
    setGlobalFilter(selectedGlobalFilter);
  }, [selectedGlobalFilter]);

  useEffect(() => {
    if (makeGroup && groupBy.length === 0) {
      setGroupBy(groupColumnNames); // columnNames is an accessor
    }
  }, [makeGroup, groupBy]);

  useEffect(() => {
    // sendColumnsToIndex();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (closeCol === true) {
      closeColumns(); // eslint-disable-line
      setCloseCol(false);
    }
  }, [closeCol]);

  return (
    <>
      {
        customizeColumns &&
        <>
          <div>
            <Grid container direction="row" className={classes.topGrid}>
              <Grid item md={11} sm={6} xs={6} justify="flex-end" />
              <Grid item md={1} sm={6} xs={6} justify="flex-end">
                <IconButton aria-label="close" onClick={e => setCloseCol(true)} style={{ padding: '8px', marginLeft: '50px' }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <div className={classes.customizeColumns} >
            <Grid container direction="row" className={classes.topGrid}>
              <Grid item md={12} sm={6} xs={6} style={{ display: 'flex' }}>
                <div style={{ display: 'flex', padding: '10px' }}>
                  <Checkbox {...getToggleHideAllColumnsProps()} style={{ marginRight: '5px' }} />
                  <Typography component="h5" style={{ marginTop: '9px', width: '70px' }}>Select all</Typography>
                </div>
                <ul style={{ display: 'flex', listStyle: 'none' }}>
                  {
                    allColumns.map(column => (
                      <li key={column.id} >
                        <div style={{ display: 'flex', padding: '10px' }}> {/*eslint-disable-line */}
                          <input type="checkbox" {...column.getToggleHiddenProps()} style={{ marginTop: '13px', marginRight: '10px' }} />
                          {column.Header.length > 9 ? <Typography component="h5" style={{ marginTop: '9px', width: '135px' }}>{column.Header}</Typography> : <Typography component="h5" style={{ marginTop: '9px', width: '70px' }}>{column.Header}</Typography>}
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </Grid>
            </Grid>
          </div>
        </>
      }
      <table {...getTableProps()} className={classes.table}>
        <thead style={{ backgroundColor: '#F4F6F7' }}>
          {headerGroups && headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers && headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} width={column.width} className={classes.head + ` ${column.thClassName || ''}`} key={column.Header}>
                  <Grid container justify={headerAlignment} wrap="nowrap" spacing={0} alignItems={'center'}>
                    <Grid item>
                      {column.render('Header')}
                    </Grid>
                    <Grid item>
                      {column.icon &&
                        <span onClick={e => handleHeaderClick(e, column.id)} style={{ padding: '5px' }}>
                          {getIcon(column.icon)}
                        </span>
                      }
                      {
                        !column.disableSortBy &&
                        <span  {...column.getSortByToggleProps()}>
                          {column.isSorted // eslint-disable-line
                            ? (column.isSortedDesc
                              ? <ExpandMore style={{ color: '#0551A3' }} />
                              : <ExpandLess style={{ color: '#0551A3' }} />
                            )
                            : <UnfoldMore style={{ color: '#0551A3' }} />
                          }
                        </span>
                      }
                    </Grid>
                  </Grid>
                  {
                    activeHeader === column.id
                    ? (
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <Popper
                          className={classes.filterHolder}
                          anchorEl={anchorEl}
                          open={activeHeader === column.id}
                          modifiers={{
                          offset: {
                            offset: '-25px',
                          },
                        }}
                        // anchorOrigin={{
                        //   vertical: 'bottom',
                        //   horizontal: 'left',
                        // }}
                        // transformOrigin={{
                        //   vertical: 'top',
                        //   horizontal: 'center',
                        // }}
                          placement="bottom"
                        >
                          {column.canFilter ? column.render('Filter') : null }
                        </Popper>
                      </ClickAwayListener>
                    )
                    : null
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {pagination === true ?
          <tbody {...getTableBodyProps()} className={classes.tBody}>
            {page && page.map((row) => { // eslint-disable-line
              prepareRow(row);
              const rowProps = row.getRowProps(getRowProps(row));
              return (
                <React.Fragment key={rowProps.key}>
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells && row.cells.map(cell =>
                      <td {...cell.getCellProps()} rowSpan={cell.column.rowSpan} className={ cell.column.disableBorder ? classes.tableCellNoBorderRight : classes.tableCell}> {/* eslint-disable-line */}
                        {cell.isGrouped ? ( // eslint-disable-line
                          // If it's a grouped cell, add an expander and row count
                          <>
                            {cell.render('Cell')}
                          </>
                        ) : cell.isAggregated ? ( // eslint-disable-line
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? cell.render('Placeholder') : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                      </td>)}
                  </tr>
                  <tr>
                    <td colSpan={visibleColumns.length}>
                    {(row.isExpanded || showSubComponent) &&
                      renderRowSubComponent && typeof renderRowSubComponent === 'function' &&
                      renderRowSubComponent({ row, rowProps, visibleColumns, ...subComponentProps})}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
            }
          </tbody>
          :
          <tbody {...getTableBodyProps()} className={classes.tBody}>
            {rows && rows.map((row) => { // eslint-disable-line
              prepareRow(row);
              const rowProps = row.getRowProps(getRowProps(row));
              return (
                <React.Fragment key={rowProps.key}>
                  <tr {...rowProps}>
                    {row.cells && row.cells.map(cell =>
                      <td {...cell.getCellProps()} className={ cell.column.disableBorder ? classes.tableCellNoBorderRight : classes.tableCell}> {/* eslint-disable-line */}
                        {cell.isGrouped ? ( // eslint-disable-line
                          // If it's a grouped cell, add an expander and row count
                          <>
                            {cell.render('Cell')}
                          </>
                        ) : cell.isAggregated ? ( // eslint-disable-line
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? cell.render('Placeholder') : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                      </td>)}
                  </tr>
                  <tr>
                    <td colSpan={visibleColumns.length}>
                    {(row.isExpanded || showSubComponent) &&
                      renderRowSubComponent && typeof renderRowSubComponent === 'function' &&
                        renderRowSubComponent({ row, rowProps, visibleColumns, ...subComponentProps })}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
            }
          </tbody>
        }
        {enableFooter &&
          <tfoot>
            {footerGroups && footerGroups.map(footerGroup => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers && footerGroup.headers.map(column => (
                  <td {...column.getFooterGroupProps} className={classes.footerStyle}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        }
      </table>
      {pagination === true ?
        (
          <div className="pagination" style={{ justifyContent: 'center' }}>
            <div className={classes.paginationButton}>
              <IconButton onClick={() => gotoPage(0)}>
                {!canPreviousPage ? <FirstPageIcon style={{ color: 'grey' }} /> : <FirstPageIcon color="primary" />}
              </IconButton>{' '}
              <IconButton onClick={() => previousPage()}>
                {!canPreviousPage ? <ArrowBackIosIcon style={{ color: 'grey' }} fontSize="small" /> : <ArrowBackIosIcon color="primary" fontSize="small" />}
              </IconButton>{' '}
              <IconButton onClick={() => nextPage()}>
                {!canNextPage ? <ArrowForwardIosIcon style={{ color: 'grey' }} fontSize="small" /> : <ArrowForwardIosIcon color="primary" fontSize="small" />}
              </IconButton>{' '}
              <IconButton onClick={() => gotoPage(pageCount - 1)}>
                {!canNextPage ? <LastPageIcon style={{ color: 'grey' }} fontSize="medium" /> : <LastPageIcon color="primary" fontSize="medium" />}
              </IconButton>{' '}
            </div>
            <div className={classes.paginationText}>
              <InputLabel htmlFor="demo-customized-select-native">Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </InputLabel>
            </div>
            <div className={classes.rowLimit}>
              <InputLabel htmlFor="demo-customized-select-native" className={classes.paginationText2}>Show</InputLabel>
              <FormControl>
                <Select
                  id="demo-customized-select-native"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSizes => (
                    <option key={pageSizes} value={pageSizes} style={{ cursor: 'pointer' }}>
                      {pageSizes} rows
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        )
        :
        null
      }
    </>
  );
}

ReactTable.propTypes = {
  headerAlignment: PropTypes.string,
};

ReactTable.defaultProps = {
  headerAlignment: 'center',
};
