import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  connector: props => ({
    display: 'block',
    borderBottom: props.active ? '3px dashed #3AA50B' : '3px dashed #939FBF',
  }),
}));

function StepConnector({
  active,
}) {
  const classes = useStyles({active});
  return (
    <>
      <Grid item xs className={classes.connector} />
    </>
  );
}

export default StepConnector;
