import * as types from '../../constants/actionTypes';

export const fetchBreakSettingsAction = payload => ({
  type: types.FETCH_BREAKSETTINGS_REQUEST,
  payload,
});

export const fetchTimecardBreakForTimesheetAction = payload => ({
  type: types.FETCH_TIMECARDBREAK_TIMESHEET_REQUEST,
  payload,
});

export const addTimecardBreakAction = payload => ({
  type: types.ADD_TIMECARDBREAK_REQUEST,
  payload,
});

export const updateTimecardBreakAction = payload => ({
  type: types.UPDATE_TIMECARDBREAK_REQUEST,
  payload,
});

export const setTimecardBreakFaceAuthPendingAction = payload => ({
  type: types.SET_TIMECARDBREAK_FACE_AUTH_PENDING,
  payload,
});

export const setTimecardBreakFaceAuthErrorAction = payload => ({
  type: types.SET_TIMECARDBREAK_FACE_AUTH_ERROR,
  payload,
});

export const setBreakStartSuccessAction = payload => ({
  type: types.SET_TIMECARDBREAK_START_SUCEESS,
  payload,
});

export const setBreakEndSuccessAction = payload => ({
  type: types.SET_TIMECARDBREAK_END_SUCEESS,
  payload,
});
