import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  user: {},
  users: [],
  total: 0,
  loading: false,
  error: '',
  isUserUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USERS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          users: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        users: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        // user: {},
        status: action.res.success,
        loading: false,
      };
    case types.TOGGLE_IS_UPDATE_USER:
      return {
        ...state,
        isUserUpdate: !state.isUserUpdate,
      };
    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        users: [],
        loading: false,
      };
    case types.FETCH_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_USERS_FAILED:
      return {
        ...state,
        users: [],
        loading: false,
      };
    case types.FETCH_USER_BY_ID_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          user: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        user: (action.res.data) || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_USER_BY_ID_FAILED:
      return {
        ...state,
        user: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_USERS_SUCCESS:
      return {
        ...state,
        // users: [...state.users, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_USERS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_USERS_SUCCESS:
      let users = filter(state.users, item => item.id !== action.res.data.id);
      const user = JSON.parse(localStorage.getItem('user'));
      if (action.res.data.id === parseInt(localStorage.getItem('user_id'), 10)) {
        if (user.current_store !== action.res.data.current_store) {
          localStorage.setItem('user', JSON.stringify({ ...user, store: action.res.data.store, current_store: action.res.data.current_store }));
          localStorage.setItem('store_name', action.res.data.store && action.res.data.store.name);
          localStorage.setItem('store_id', action.res.data.store && action.res.data.store.id);
        }
      }
      return {
        ...state,
        // user: {},
        users: [],
        total: 0,
        error: '',
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_USERS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_USERS_SUCCESS:
      users = filter(state.users, item => item.id !== action.payload);

      return {
        ...state,
        users: [...users],
        status: true,
        loading: false,
      };
    case types.DELETE_USERS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.CLEAR_USER_BY_ID_DATA: {
      return {
        ...state,
        user: {},
        loading: false,
      };
    }
    case types.LOCK_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOCK_USERS_SUCCESS:
      return {
        ...state,
        status: true,
        loading: false,
      };
    case types.LOCK_USERS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UNLOCK_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UNLOCK_USERS_SUCCESS:
      return {
        ...state,
        status: true,
        loading: false,
      };
    case types.UNLOCK_USERS_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
