import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as types1 from 'constants/actionTypes';
import {
  addPermissionGroup, deletePermissionGroup, getPermissionGroupById, getPermissionGroups, updatePermissionGroup,
} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_PERMISSION_GROUPS_REQUEST, fetchPermissionGroupsSaga);
  yield takeLatest(types.FETCH_PERMISSION_GROUP_REQUEST, fetchPermissionGroupSaga);
  yield takeLatest(types.ADD_PERMISSION_GROUP_REQUEST, addPermissionGroupSaga, context);
  yield takeLatest(types.UPDATE_PERMISSION_GROUP_REQUEST, updatePermissionGroupSaga, context);
  yield takeLatest(types.DELETE_PERMISSION_GROUP_REQUEST, deletePermissionGroupSaga);
}

export function* fetchPermissionGroupsSaga({ payload }) {
  try {
    const res = yield call(getPermissionGroups, payload);
    yield all([
      put({ type: types.FETCH_PERMISSION_GROUPS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_PERMISSION_GROUPS_FAILED, error });
  }
}

export function* fetchPermissionGroupSaga({ payload }) {
  try {
    const res = yield call(getPermissionGroupById, payload);
    yield all([
      put({ type: types.FETCH_PERMISSION_GROUP_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_PERMISSION_GROUP_FAILED, error });
  }
}

export function* addPermissionGroupSaga({ history }, { payload }) {
  try {
    const res = yield call(addPermissionGroup, payload);
    yield all([
      put({ type: types.ADD_PERMISSION_GROUP_SUCCESS, res }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Permission Group added' : res.message || 'Permission Group not added',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/districts');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_PERMISSION_GROUP_FAILED, error }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updatePermissionGroupSaga({ history }, { payload }) {
  try {
    const res = yield call(updatePermissionGroup, payload);
    yield all([
      put({ type: types.UPDATE_PERMISSION_GROUP_SUCCESS, res }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Permission Group updated' : res.message || 'Permission Group not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/districts');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_PERMISSION_GROUP_FAILED, error }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deletePermissionGroupSaga({ payload }) {
  try {
    const res = yield call(deletePermissionGroup, payload);
    yield all([
      put({ type: types.DELETE_PERMISSION_GROUP_SUCCESS, payload }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Permission Group deleted' : res.message || 'Permission Group not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_PERMISSION_GROUP_FAILED, error }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
