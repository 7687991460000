/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useContext } from 'react';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';
import CentraverseSuspense from 'centraverse/components/CentraverseSuspense';
import 'bootstrap/dist/css/bootstrap.css';
import _ from 'lodash';

import '../../scss/app.scss';
// import routes from './CentraverseConfig/routesConfig';
import MainWrapper from './MainWrapper';
import AppContext from './AppContext';
import PrivateRouter from './PrivateRouter';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import Layout from '../Layout/index';
import ConditionalWrapper from './ConditionalWrapper';


const Router1 = (props) => {
  const appContext = useContext(AppContext);
  const { routes } = appContext;

  const [publicRoutes, setPublicRoutes] = useState([]);
  const [privateRoutes, setPrivateRoutes] = useState([]);

  useEffect(() => {
    const routesPartitionedByAuth = _.partition(routes, {auth: true});
    setPrivateRoutes(routesPartitionedByAuth[0]);
    setPublicRoutes(routesPartitionedByAuth[1]);
  }, [routes]);

  return (
    <MainWrapper>
      <ConditionalWrapper
        condition={!!localStorage.getItem('user_id')}
        wrapper={children => <Layout>{children}</Layout>}
      >
        <CentraverseSuspense>
          {/* {renderRoutes(routes)} */}
          <Switch>
            {publicRoutes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
              ))}
            { localStorage.getItem('user_id')
            ? <PrivateRouter routes={privateRoutes} />
            : <Route exact path="/" render={({ location }) => <Redirect to={{ pathname: '/login', state: { from: location } }} />} />}
          </Switch>
          {/* <Router /> */}
        </CentraverseSuspense>
      </ConditionalWrapper>
    </MainWrapper>
  );
};
export default withRouter(Router1);
