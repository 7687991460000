import React from 'react';

export const AuditAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    // moduleName: 'adminApp',
    // store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/audits/new',
      component: React.lazy(() => import('./NewAudit/index')),
    },
    {
      path: '/audits/new/:id',
      component: React.lazy(() => import('./NewAudit/index')),
    },
    {
      path: '/audits/results',
      component: React.lazy(() => import('./AuditResultLists/index')),
    },
    {
      path: '/audits/results/:id',
      component: React.lazy(() => import('./AuditResults/index')),
    },
    {
      path: '/rolling-audits',
      component: React.lazy(() => import('./RollingAudit/index')),
    },
    {
      path: '/audits/reports',
      component: React.lazy(() => import('./AuditReports/index')),
    },
  ],
};

export default AuditAppConfig;
