import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as types from 'constants/actionTypes';
import { addTimecardBreak, updateTimecardBreak, getBreakSettingsForStore, fetchTimecardBreaksForTimesheet } from '../../api/Api';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_BREAKSETTINGS_REQUEST, fetchBreakSettingsSaga);
  yield takeLatest(types.ADD_TIMECARDBREAK_REQUEST, addTimecardBreakSaga, context);
  yield takeLatest(types.UPDATE_TIMECARDBREAK_REQUEST, modifyTimecardBreak, context);
  yield takeLatest(types.FETCH_TIMECARDBREAK_TIMESHEET_REQUEST, fetchTimecardBreakForTimesheetSaga);
}

/* eslint-disable */
export function* fetchBreakSettingsSaga({ payload }) {
  try {
    const res = yield call(getBreakSettingsForStore, payload);
    yield all([
      put({ type: types.FETCH_BREAKSETTINGS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_BREAKSETTINGS_FAILED, error });
  }
}

export function* fetchTimecardBreakForTimesheetSaga({ payload }) {
  try {
    const res = yield call(fetchTimecardBreaksForTimesheet, payload);
    yield all([
      put({ type: types.FETCH_TIMECARDBREAK_TIMESHEET_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_TIMECARDBREAK_TIMESHEET_FAILED, error });
  }
}

export function* addTimecardBreakSaga({ history }, { payload }) {
  try {
    const res = yield call(addTimecardBreak, payload);
    yield all([
      put({ type: types.ADD_TIMECARDBREAK_SUCCESS, res }),
      ...(!res.faceAuthPending ? [put({ type: types.SET_CLOCKIN_USER_COMMENT, payload: {start_break: ''} }),] : []),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Break started successfully' : res.message || 'Clocked In',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.ADD_TIMECARDBREAK_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* modifyTimecardBreak({ history }, { payload }) {
  try {
    const res = yield call(updateTimecardBreak, payload);
    yield all([
      put({ type: types.UPDATE_TIMECARDBREAK_SUCCESS, res }),
      ...(!res.faceAuthPending ? [put({ type: types.SET_CLOCKIN_USER_COMMENT, payload: {end_break: ''} }),] : []),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Break ended successfully' : res.message || 'Clocked In',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_TIMECARDBREAK_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

