// seprate parent sidebar having direct route
/* eslint-disable */

import { treeData } from '../../../constants/utils';

export const parentWithRoute = formattedTree =>

  formattedTree.filter(tree => tree.is_root && (tree.route));


export const parentWithoutRoute = formattedTree => (
  formattedTree.filter(tree => tree.is_root).sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  })
);

export const setDefaultTopBar = (formattedTree, HomeScreen) => {
  let defaultOptions;
  if(HomeScreen && HomeScreen.length){
    defaultOptions = formattedTree.filter(tree => tree.is_root && tree.name === HomeScreen);
  }
  else{
    defaultOptions = formattedTree.filter(tree => tree.is_root && tree.name === 'OneView');
  }
  return defaultOptions;
};


export const setSelectedTopBar = async (childRoutes, currentTopMenu) => {
  const selected = await childRoutes && childRoutes.filter(childRoute => childRoute.name === currentTopMenu);
  return selected;
};


/* Filtering settings */

export const setSettings = formattedTree =>

  formattedTree.filter(tree => tree.is_root && tree.name === 'Centraverse Settings');

// filter sidebar based on user assigned permissions
export const filterPermission = (data, permissions) => {
  const permissionKeys = Object.keys(permissions);
  const withoutPermission = data && data.filter(noPer => !noPer.permissions);
  const withPermission = data && data.filter((elem) => elem && elem.permissions && elem.permissions.title);
  const matched = withPermission.filter(elems => permissionKeys && permissionKeys.includes(elems.permissions.title));
  const combined = [...withoutPermission, ...matched];
  return treeData(combined);
}

