import { put, call, takeLatest, all } from 'redux-saga/effects';
import * as types1 from 'constants/actionTypes';
import { getSchedulesByUser, addTimecardForClockin, updateTimecardForClockout, getTimeForStoreTimezone, getStatusForWebClockin } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_SCHEDULESBYUSER_REQUEST, fetchSchedulesByUserSaga);
  yield takeLatest(types.ADD_TIMECARDFORCLOCKIN_REQUEST, addTimecardForClockinSaga, context);
  yield takeLatest(types.UPDATE_TIMECARDFORCLOCKOUT_REQUEST, modifyTimecardForClockout, context);
  yield takeLatest(types.FETCH_TIMEFORSTORETIMEZONE_REQUEST, fetchTimeForStoreTimezoneSaga);
  yield takeLatest(types.FETCH_STATUSFORWEBCLOCKIN_REQUEST, fetchStatusForWebClockinSaga);
}

/* eslint-disable */
export function* fetchSchedulesByUserSaga({ payload }) {
  try {
    const res = yield call(getSchedulesByUser, payload);
    yield all([
      put({ type: types.FETCH_SCHEDULESBYUSER_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_SCHEDULESBYUSER_FAILED, error });
  }
}

export function* addTimecardForClockinSaga({ history }, { payload }) {
  try {
    const res = yield call(addTimecardForClockin, payload);
    yield all([
      put({ type: types.ADD_TIMECARDFORCLOCKIN_SUCCESS, res }),
      ...(!res.faceAuthPending ? [put({ type: types.SET_CLOCKIN_USER_COMMENT, payload: {clock_in: ''} }),
      put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Clocked In' : res.message || 'Server error',
        },
      }),] : []),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.ADD_TIMECARDFORCLOCKIN_FAILED, error }),
      ...(!res.faceAuthPending ? [put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      })] : []),
    ]);
  }
}

export function* modifyTimecardForClockout({ history }, { payload }) {
  try {
    const res = yield call(updateTimecardForClockout, payload);
    yield all([
      put({ type: types.UPDATE_TIMECARDFORCLOCKOUT_SUCCESS, res }),
      ...(!res.faceAuthPending ? [put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Clocked Out' : res.message || 'Server error',
        },
      }),
      put({ type: types.SET_CLOCKIN_USER_COMMENT, payload: {clock_out: ''} }),
    ] : []),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_TIMECARDFORCLOCKOUT_FAILED, error }),
      ...(!res.faceAuthPending ? [put({
        type: types1.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      })] : []),
    ]);
  }
}

export function* fetchTimeForStoreTimezoneSaga({ payload }) {
  try {
    const res = yield call(getTimeForStoreTimezone, payload);
    yield all([
      put({ type: types.FETCH_TIMEFORSTORETIMEZONE_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_TIMEFORSTORETIMEZONE_FAILED, error });
  }
}

export function* fetchStatusForWebClockinSaga({ payload }) {
  try {
    const res = yield call(getStatusForWebClockin, payload);
    yield all([
      put({ type: types.FETCH_STATUSFORWEBCLOCKIN_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_STATUSFORWEBCLOCKIN_FAILED, error });
  }
}