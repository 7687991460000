import { put, call, takeLatest, all } from 'redux-saga/effects';
import { fetchHRSettingsStores, updateHRSettingsStore } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_HRSETTINGSSTORES_REQUEST, fetchHRSettingsStoresSaga);
  yield takeLatest(types.UPDATE_HRSETTINGSSTORE_REQUEST, updateHRSettingsStoreSaga);
}

export function* fetchHRSettingsStoresSaga({ payload }) {
  try {
    const res = yield call(fetchHRSettingsStores, payload);
    yield all([
      put({ type: types.FETCH_HRSETTINGSSTORES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_HRSETTINGSSTORES_FAILED, error });
  }
}

export function* updateHRSettingsStoreSaga({ payload }) {
  try {
    const res = yield call(updateHRSettingsStore, payload);
    yield all([
      put({ type: types.UPDATE_HRSETTINGSSTORE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Store settings updated' : res.message || 'Store settings not updated',
        },
      }),
    ]);
  } catch (error) {
    yield put({ type: types.UPDATE_HRSETTINGSSTORE_FAILED, error });
  }
}
