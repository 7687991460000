import React from 'react';
import PropTypes from 'prop-types';
import CentraverseLoading from '../CentraverseLoading';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */function CentraverseSuspense(props) {
  return (
    <React.Suspense fallback={<CentraverseLoading {...props.loadingProps} />}>
      {props.children}
    </React.Suspense>
  );
}

CentraverseSuspense.propTypes = {
  loadingProps: PropTypes.object, //eslint-disable-line
};

CentraverseSuspense.defaultProps = {
  loadingProps: {
    delay: 300,
  },
};

export default CentraverseSuspense;
