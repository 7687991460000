import * as types from '../../constants/actionTypes';

export const fetchSupportTicketsAction = payload => ({
  type: types.FETCH_SUPPORTTICKETS_REQUEST,
  payload,
});

export const fetchSupportTicketAction = payload => ({
  type: types.FETCH_SUPPORTTICKET_REQUEST,
  payload,
});

export const addSupportTicketAction = payload => ({
  type: types.ADD_SUPPORTTICKET_REQUEST,
  payload,
});

export const updateSupportTicketAction = payload => ({
  type: types.UPDATE_SUPPORTTICKET_REQUEST,
  payload,
});


export const deleteSupportTicketAction = payload => ({
  type: types.DELETE_SUPPORTTICKET_REQUEST,
  payload,
});

export const showSupportTicketAction = payload => ({
  type: types.SHOW_SUPPORTTICKETS,
  payload,
});

export const hideSupportTicketAction = payload => ({
  type: types.HIDE_SUPPORTTICKETS,
  payload,
});
