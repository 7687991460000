import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  role: {},
  roles: [],
  total: 0,
  loading: false,
  error: '',
  user_roles: [],
  user_exist_flag: false,
  isB2BUser: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ROLES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          roles: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        roles: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_ROLES_SEARCH_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          roles: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        roles: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_ROLES_REQUEST:
      return {
        ...state,
        roles: [],
        loading: true,
      };
    case types.FETCH_ROLES_SEARCH_REQUEST:
      return {
        ...state,
        // roles: [],
        loading: false,
      };
    case types.FETCH_USER_ROLES_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          roles: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        user_roles: (action.res.data && action.res.data.results) || [],
        loading: false,
      };
    case types.FETCH_USER_ROLES_REQUEST:
      return {
        ...state,
        user_roles: [],
        loading: true,
      };
    case types.FETCH_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACTIVATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.TOGGLE_B2B_ROLE_REQUEST:
      return {
        ...state,
        isB2BUser: !state.isB2BUser,
      };
    case types.FETCH_ROLES_FAILED:
      return {
        ...state,
        roles: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_ROLES_SEARCH_FAILED:
      return {
        ...state,
        roles: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_ROLE_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          role: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        role: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_ROLE_FAILED:
      return {
        ...state,
        role: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_USER_ROLES_FAILED:
      return {
        ...state,
        user_roles: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_ROLE_SUCCESS:
      return {
        ...state,
        // roles: [...state.roles, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_ROLE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_ROLE_SUCCESS:
      let roles = filter(state.roles, item => item.id !== action.res.data.id);
      return {
        ...state,
        role: action.res.data || {},
        roles: [...roles, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_ROLE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_ROLE_SUCCESS:
      if (!action.payload.user_exist_flag) {
        roles = filter(state.roles, item => item.id !== action.payload.id);
      } else {
        roles = state.roles; //eslint-disable-line
      }

      return {
        ...state,
        roles: [...roles],
        status: true,
        loading: false,
        user_exist_flag: action.payload.user_exist_flag || false,
      };
    case types.ACTIVATE_ROLE_SUCCESS:
      // if (!action.payload.user_exist_flag) {
      //   roles = filter(state.roles, item => item.id !== action.payload.id);
      // } else {
      //   roles = state.roles; //eslint-disable-line
      // }

      return {
        ...state,
        // roles: [...roles],
        status: true,
        loading: false,
        // user_exist_flag: action.payload.user_exist_flag || false,
      };
    case types.DELETE_ROLE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.ACTIVATE_ROLE_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.CLEAR_ROLE_FORM: {
      return {
        ...state,
        role: {},
      };
    }
    case types.CLEAR_USER_EXIST: {
      return {
        ...state,
        user_exist_flag: false,
      };
    }
    default:
      return state;
  }
};
