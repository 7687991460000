import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */
const initialState = {
  schedulelist: [],
  timecard: {},
  time: {},
  webclock: {},
  webclockstatus: '',
  clockinusercomment: {},
  closckinLoading: false,
  faceAuthPending: false,
  faceAuthError: false,
  faceAuthErrorMsg: false,
  clockinSuccess: false,
  clockoutSuccess: false,
  // total: 0,
  loading: false,
  error: '',
  activeSession: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SCHEDULESBYUSER_REQUEST:
      return {
        ...state,
        schedulelist: [],
        loading: true,
      };
    case types.FETCH_SCHEDULESBYUSER_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          schedulelist: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        schedulelist: (action.res.data && action.res.data.results) || [],
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_SCHEDULESBYUSER_FAILED:
      return {
        ...state,
        schedulelist: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_TIMECARDFORCLOCKIN_REQUEST:
      return {
        ...state,
        timecard: {},
        loading: true,
        closckinLoading: true,
        clockinSuccess: false,
      };
    case types.ADD_TIMECARDFORCLOCKIN_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          timecard: {},
          error: '',
          faceAuthPending: !!action.res.faceAuthPending,
          faceAuthError: !!action.res.faceAuthError,
          faceAuthErrorMsg: action.res.message,
          loading: false,
          closckinLoading: false,
          clockinSuccess: false,
        };
      }
      return {
        ...state,
        timecard: (action.res.data && action.res.data.results) || {},
        faceAuthPending: !!action.res.faceAuthPending,
        faceAuthError: !!action.res.faceAuthError,
        faceAuthErrorMsg: action.res.message,
        status: action.res.success,
        loading: false,
        closckinLoading: false,
        clockinSuccess: true,
      };
    case types.ADD_TIMECARDFORCLOCKIN_FAILED:
      return {
        ...state,
        timecard: {},
        error: 'Bad Request',
        faceAuthPending: !!action.res.faceAuthPending,
        faceAuthError: !!action.res.faceAuthError,
        faceAuthErrorMsg: action.res.message,
        loading: false,
        closckinLoading: false,
        clockinSuccess: false,
      };
    case types.UPDATE_TIMECARDFORCLOCKOUT_REQUEST:
      return {
        ...state,
        timecard: {},
        loading: true,
        clockoutSuccess: false,
      };
    case types.UPDATE_TIMECARDFORCLOCKOUT_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          timecard: {},
          faceAuthPending: !!action.res.faceAuthPending,
          faceAuthError: !!action.res.faceAuthError,
          faceAuthErrorMsg: action.res.message,
          clockoutSuccess: false,
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        timecard: (action.res.data && action.res.data.results) || {},
        status: action.res.success,
        faceAuthPending: !!action.res.faceAuthPending,
        faceAuthError: !!action.res.faceAuthError,
        faceAuthErrorMsg: action.res.message,
        clockoutSuccess: true,
        loading: false,
      };
    case types.UPDATE_TIMECARDFORCLOCKOUT_FAILED:
      return {
        ...state,
        timecard: {},
        faceAuthPending: !!action.res.faceAuthPending,
        faceAuthError: !!action.res.faceAuthError,
        faceAuthErrorMsg: action.res.message,
        clockoutSuccess: false,
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_TIMEFORSTORETIMEZONE_REQUEST:
      return {
        ...state,
        time: {},
        loading: true,
      };
    case types.FETCH_TIMEFORSTORETIMEZONE_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          time: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        time: (action.res.data && action.res.data.results) || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_TIMEFORSTORETIMEZONE_FAILED:
      return {
        ...state,
        time: {},
        error: 'Bad Request',
        loading: false,
      };

    case types.FETCH_STATUSFORWEBCLOCKIN_REQUEST:
      return {
        ...state,
        webclock: {},
        webclockstatus: 'request',
        loading: true,
      };
    case types.FETCH_STATUSFORWEBCLOCKIN_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          webclock: {},
          webclockstatus: 'fail',
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        webclock: (action.res.data && action.res.data.results) || {},
        webclockstatus: 'success',
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_STATUSFORWEBCLOCKIN_FAILED:
      return {
        ...state,
        webclock: {},
        webclockstatus: 'fail',
        error: 'Bad Request',
        loading: false,
      };
    case types.SET_CLOCKIN_USER_COMMENT:
      return {
        ...state,
        clockinusercomment: {...state.clockinusercomment, ...action.payload},
      };
    case types.SET_CLOCKIN_USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.HR_ACTIVE_SESSION:
      const {activeSession} = action.payload;
      return {
        ...state,
        activeSession,
      };
    case types.SET_WEBCLOCKIN_FACE_AUTH_PENDING:
      return {
        ...state,
        faceAuthPending: action.payload,
      };
    case types.SET_WEBCLOCKIN_FACE_AUTH_ERROR:
      return {
        ...state,
        faceAuthError: action.payload,
      };
    case types.SET_WEBCLOCKIN_CLOCK_IN_SUCEESS:
      return {
        ...state,
        clockinSuccess: action.payload,
      };
    case types.SET_WEBCLOCKIN_CLOCK_OUT_SUCEESS:
      return {
        ...state,
        clockoutSuccess: action.payload,
      };
    default:
      return state;
  }
};
