import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  payment_account: {},
  payment_account_persons: [],
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        payment_account: {},
        loading: true,
      };
    case types.FETCH_PAYMENT_ACCOUNT_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          payment_account: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        payment_account: action.res.data || {},
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_PAYMENT_ACCOUNT_FAILED:
      return {
        ...state,
        payment_account: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_PAYMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        payment_account: {},
        loading: true,
      };
    case types.ADD_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        payment_account: action.res.data,
        status: action.res.success,
        loading: false,
      };
    case types.ADD_PAYMENT_ACCOUNT_FAILED:
      return {
        ...state,
        payment_account: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_PAYMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        // payment_account: {},
        loading: true,
      };
    case types.UPDATE_PAYMENT_ACCOUNT_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          loading: false,
        };
      }
      return {
        ...state,
        payment_account: action.res.data || {},
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_PAYMENT_ACCOUNT_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_PERSON_FOR_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_PERSON_FOR_PAYMENT_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          loading: false,
        };
      }
      return {
        ...state,
        payment_account_persons: action.res.data || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_PERSON_FOR_PAYMENT_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          status: action.res.success,
          loading: false,
        };
      }
      return {
        ...state,
        payment_account_persons: action.res.data || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_PERSON_FOR_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_PERSON_FOR_PAYMENT_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_PERSON_FOR_PAYMENT_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.REMOVE_PAYMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        payment_account: {},
      };
    case types.REMOVE_PAYMENT_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
