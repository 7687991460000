import { put, call, takeLatest, all } from 'redux-saga/effects';
import { signInRequest, forgotPasswordRequest, resetPasswordRequest, updateAccount, verify2Auth, updateUserPassword, updateUserPasswordByAdmin, mobilePinChange, faceUpdate } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchLoginListener() {
  yield takeLatest(types.SIGN_IN_REQUEST, signInRequestSaga);
  yield takeLatest(types.FORGOT_PASSWORD_REQUEST, forgotPasswordRequestSaga);
  yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPasswordRequestSaga);
  yield takeLatest(types.UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLatest(types.TWO_FACTOR_AUTH_REQUEST, verify2AuthSaga);
  yield takeLatest(types.UPDATE_USER_PASSWORD_REQUEST, updateUserPasswordSaga);
  yield takeLatest(types.UPDATE_USER_PASSWORD_ADMIN_REQUEST, updateUserPasswordByAdminSaga);
  yield takeLatest(types.MOBILE_PIN_CHANGE_REQUEST, mobilePinChangeRequestSaga);
  yield takeLatest(types.UPDATE_FACE_REQUEST, updateFaceSaga);
}

export function* signInRequestSaga({ payload }) {
  try {
    const res = yield call(signInRequest, payload);
    yield all([
      put({ type: types.SIGN_IN_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.SIGN_IN_FAILED, error });
  }
}
export function* verify2AuthSaga({ payload }) {
  try {
    const res = yield call(verify2Auth, payload);
    yield all([
      put({ type: types.TWO_FACTOR_AUTH_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.TWO_FACTOR_AUTH_FAILURE, error });
  }
}
export function* forgotPasswordRequestSaga({ payload }) {
  try {
    const res = yield call(forgotPasswordRequest, payload);
    yield all([
      put({ type: types.FORGOT_PASSWORD_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Reset email sent' : res.message || res.reason || 'Password Reset Failed',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      yield put({ type: types.FORGOT_PASSWORD_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: 'Server error',
        },
      }),
    ]);
  }
}

export function* resetPasswordRequestSaga({ payload }) {
  try {
    const res = yield call(resetPasswordRequest, payload);
    yield all([
      put({ type: types.RESET_PASSWORD_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_FAILED, error });
  }
}

export function* updateUserSaga({ payload }) {
  try {
    const res = yield call(updateAccount, payload);

    yield all([
      put({ type: types.UPDATE_USER_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Account updated' : res.message || 'Account not updated',
        },
      }),
    ]);
    window.location.reload();
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_USER_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateUserPasswordSaga({ payload }) {
  try {
    const res = yield call(updateUserPassword, payload);

    yield all([
      put({ type: types.UPDATE_USER_PASSWORD_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Password updated' : res.message || 'password not updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_USER_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateUserPasswordByAdminSaga({ payload }) {
  try {
    const res = yield call(updateUserPasswordByAdmin, payload);

    yield all([
      put({ type: types.UPDATE_USER_PASSWORD_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Password updated' : res.message || 'password not updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_USER_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* mobilePinChangeRequestSaga({ payload }) {
  try {
    const res = yield call(mobilePinChange, payload);
    yield all([
      put({ type: types.MOBILE_PIN_CHANGE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Mobile Pin updated' : res.message || 'Mobile Pin not updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.MOBILE_PIN_CHANGE_FAILURE, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateFaceSaga({ payload }) {
  try {
    const res = yield call(faceUpdate, payload);
    yield all([
      put({ type: types.UPDATE_FACE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Face updated' : res.message || 'Face not updated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_FACE_FAILURE, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
