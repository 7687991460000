import React from 'react';

export default {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'eventsApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    { // Private routes
      path: '/events',
      component: React.lazy(() => import('./Events')),
    },
    { // Private routes
      path: '/events/calendar',
      component: React.lazy(() => import('./Calendar')),
    },
  ],
};
