import React from 'react';

export const SettingsAppConfig = { //eslint-disable-line
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'settingsApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/companies',
      component: React.lazy(() => import('./Companies')),
    },
    // {
    //   path: '/newcompanies',
    //   component: React.lazy(() => import('./newCompanies')),
    // },
    // {
    //   path: '/admin-company/edit',
    //   component: React.lazy(() => import('./newCompaniesEdit')),
    // },
    {
      path: '/companies/edit/:id',
      component: React.lazy(() => import('./EditCompany')),
    },
    {
      path: '/new-company',
      component: React.lazy(() => import('./NewCompany')),
    },
    {
      path: '/users',
      component: React.lazy(() => import('./Users')),
    },
    {
      path: '/users/new',
      component: React.lazy(() => import('./NewUser')),
    },
    {
      path: '/users/edit/:id',
      component: React.lazy(() => import('./NewUser')),
    },
    {
      path: '/companies/payments',
      component: React.lazy(() => import('./Payments')),
    },
    {
      path: '/companies/payments/new',
      component: React.lazy(() => import('./ManagePayments')),
    },
    {
      path: '/my-company-settings/',
      component: React.lazy(() => import('./CompanyProfile')),
    },
    {
      path: '/roles',
      component: React.lazy(() => import('./Roles')),
    },
    {
      path: '/admin-roles',
      component: React.lazy(() => import('./NewRoles')),
    },
    {
      path: '/admin-roles/add',
      component: React.lazy(() => import('./NewRoles/components/RoleAdd')),
    },
    {
      path: '/admin-roles/edit/:id',
      component: React.lazy(() => import('./NewRoles/components/RoleAdd')),
    },
    {
      path: '/employees',
      component: React.lazy(() => import('./EmployeeModule/Employees')),
    },
    {
      path: '/employees/new',
      component: React.lazy(() => import('./EmployeeModule/AddEditEmployee')),
    },
    {
      path: '/employees/edit/:id',
      component: React.lazy(() => import('./EmployeeModule/AddEditEmployee')),
    },
    {
      path: '/employees/bulkupload',
      component: React.lazy(() => import('./EmployeeBulkUpload')),
    },
    {
      path: '/employees/compensation/:id',
      component: React.lazy(() => import('./EmployeeModule/EmployeeCompensation/index')),
    },
    {
      path: '/employees/compensations/edit/:id/:empId',
      component: React.lazy(() => import('./EmployeeModule/EmployeeCompensation/components/EmployeeCompensationForm')),
    },
    {
      path: '/employees/compensations/new/:empId',
      component: React.lazy(() => import('./EmployeeModule/EmployeeCompensation/components/EmployeeCompensationForm')),
    },
    {
      path: '/employees/store-temp-access/:id/:userId',
      component: React.lazy(() => import('./EmployeeModule/UserStoreTempAccess/index')),
    },
    {
      path: '/employees/store-temp-access/edit/:id/:empId/:userId',
      component: React.lazy(() => import('./EmployeeModule/UserStoreTempAccess/components/UserStoreTempAccessForm')),
    },
    {
      path: '/employees/store-temp-access/new/:empId/:userId',
      component: React.lazy(() => import('./EmployeeModule/UserStoreTempAccess/components/UserStoreTempAccessForm')),
    },
    {
      path: '/employees/documents/:id',
      component: React.lazy(() => import('./EmployeeModule/EmployeeDocuments')),
    },
    {
      path: '/employees/document/new',
      component: React.lazy(() => import('./NewDocuments')),
    },
    {
      path: '/settings/required-fields',
      component: React.lazy(() => import('./ManageRequiredFields')),
    },
    {
      path: '/settings/role-based-fields',
      component: React.lazy(() => import('./RoleBasedModuleFields')),
    },
    {
      path: '/mobile-pin-update/',
      component: React.lazy(() => import('./MobilePinUpdate')),
    },
    {
      path: '/account',
      component: React.lazy(() => import('./Account')),
    },
    {
      path: '/new-role',
      component: React.lazy(() => import('./NewAndEditRole')),
    },
    {
      path: '/roles/new',
      component: React.lazy(() => import('./NewAdminRole')),
    },
    {
      path: '/edit-role/:id',
      component: React.lazy(() => import('./NewAdminRole')),
    },
    {
      path: '/centraverse-pay-settings',
      component: React.lazy(() => import('./PaymentSettings')),
    },
    {
      path: '/stripe/auth',
      component: React.lazy(() => import('./StripeAuth')),
    },
    {
      path: '/company-documents',
      component: React.lazy(() => import('./Companies/ComanyDocuments')),
    },
    {
      path: '/companies/:id',
      component: React.lazy(() => import('./CompanyProfile')),
    },
    {
      path: '/user-bulk-upload',
      component: React.lazy(() => import('./UserBulkUpload')),
    },
    {
      path: '/user/control-notifications',
      component: React.lazy(() => import('./ControlNotifications')),
    },
  ],
};
