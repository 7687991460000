import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  phoneVerification: {},
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.OVERRRIDE_OTP_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          phoneVerification: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        phoneVerification: action.res.data,
        status: action.res.success,
        loading: false,
      };
    case types.OVERRRIDE_OTP_FAILED:
      return {
        ...state,
        phoneVerification: [],
        error: 'Bad Request',
        loading: false,
      };

    default:
      return state;
  }
};
