import { put, call, takeLatest, all } from 'redux-saga/effects';
import { addUserStore, deleteUserStore, getUserStoreById, getUserStores, updateUserStore, getUserStoreByStoreId, getUserStorePrimaryByStoreId} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_USERSTORES_REQUEST, fetchUserStoresSaga);
  yield takeLatest(types.FETCH_USERSTORE_PRIMARY_BY_STOREID_REQUEST, fetchUserStorePrimaryByStoreIDSaga);
  yield takeLatest(types.FETCH_USERSTORE_REQUEST, fetchUserStoreSaga);
  yield takeLatest(types.FETCH_USERSTORE_STOREID_REQUEST, fetchUserStoreSagaStoreId);
  yield takeLatest(types.ADD_USERSTORE_REQUEST, addUserStoreSaga, context);
  yield takeLatest(types.UPDATE_USERSTORE_REQUEST, updateUserStoreSaga, context);
  yield takeLatest(types.DELETE_USERSTORE_REQUEST, deleteUserStoreSaga);
}

export function* fetchUserStorePrimaryByStoreIDSaga({ payload }) {
  try {
    const res = yield call(getUserStorePrimaryByStoreId, payload);
    yield all([
      put({ type: types.FETCH_USERSTORE_PRIMARY_BY_STOREID_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERSTORE_PRIMARY_BY_STOREID_FAILED, error });
  }
}

export function* fetchUserStoresSaga({ payload }) {
  try {
    const res = yield call(getUserStores, payload);
    yield all([
      put({ type: types.FETCH_USERSTORES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERSTORES_FAILED, error });
  }
}

export function* fetchUserStoreSaga({ payload }) {
  try {
    const res = yield call(getUserStoreById, payload);
    yield all([
      put({ type: types.FETCH_USERSTORE_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERSTORE_FAILED, error });
  }
}

export function* fetchUserStoreSagaStoreId({ payload }) {
  try {
    const res = yield call(getUserStoreByStoreId, payload);
    yield all([
      put({ type: types.FETCH_USERSTORE_STOREID_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERSTORE_STOREID_FAILED, error });
  }
}

export function* addUserStoreSaga({ history }, { payload }) {
  try {
    const res = yield call(addUserStore, payload);
    yield all([
      put({ type: types.ADD_USERSTORE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserStore added' : res.message || 'UserStore not added',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/userstores');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_USERSTORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateUserStoreSaga({ history }, { payload }) {
  try {
    const res = yield call(updateUserStore, payload);
    yield all([
      put({ type: types.UPDATE_USERSTORE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserStore updated' : res.message || 'UserStore not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/userstores');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_USERSTORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteUserStoreSaga({ payload }) {
  try {
    const res = yield call(deleteUserStore, payload);
    yield all([
      put({ type: types.DELETE_USERSTORE_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'UserStore deleted' : res.message || 'UserStore not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_USERSTORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
