import * as types from '../../constants/actionTypes';

export const fetchStoresAction = payload => ({
  type: types.FETCH_STORES_REQUEST,
  payload,
});

export const fetchStoresSearchAction = payload => ({
  type: types.FETCH_STORES_SEARCH_REQUEST,
  payload,
});

export const fetchStoreAction = payload => ({
  type: types.FETCH_STORE_REQUEST,
  payload,
});

export const fetchStoresReportAction = payload => ({
  type: types.FETCH_STORE_REPORTS_REQUEST,
  payload,
});

export const addStoreAction = payload => ({
  type: types.ADD_STORE_REQUEST,
  payload,
});

export const updateStoreAction = payload => ({
  type: types.UPDATE_STORE_REQUEST,
  payload,
});

export const deleteStoreAction = payload => ({
  type: types.DELETE_STORE_REQUEST,
  payload,
});

export const activeStoreAction = payload => ({
  type: types.ACTIVATE_STORE_REQUEST,
  payload,
});

export const fetchStoreTimeStatusAction = payload => ({
  type: types.FETCH_STORE_TIME_STATUS_REQUEST,
  payload,
});

export const clearStoreForm = () => ({
  type: types.CLEAR_STORE_FORM,
  payload: null,
});

export const fetchStoresLocationsAction = payload => ({
  type: types.FETCH_STORES_LOCATIONS_REQUEST,
  payload,
});

export const toggleB2BActionStore = () => ({
  type: types.TOGGLE_B2B_REQUEST_STORE,
});

export const fetchStoresByCompany = payload => ({
  type: types.FETCH_COMPANY_STORES_REQUEST,
  payload,
});

