import * as types from '../../constants/actionTypes';

export const fetchSidebarItemsAction = payload => ({
  type: types.FETCH_SIDEBAR_ITEMS_REQUEST,
  payload,
});

export const fetchSidebarItemAction = payload => ({
  type: types.FETCH_SIDEBAR_ITEM_REQUEST,
  payload,
});

export const addSidebarItemAction = payload => ({
  type: types.ADD_SIDEBAR_ITEM_REQUEST,
  payload,
});

export const updateSidebarItemAction = payload => ({
  type: types.UPDATE_SIDEBAR_ITEM_REQUEST,
  payload,
});


export const deleteSidebarItemAction = payload => ({
  type: types.DELETE_SIDEBAR_ITEM_REQUEST,
  payload,
});
