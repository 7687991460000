import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Tooltip, Button } from '@material-ui/core';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';

import useStyles from './MenuItemStyles';
import { enableEditMode, disableEditMode, setBgImageUpdateStatus, enableEmployeeEditMode, disableEmployeeEditMode, setCurrentEditMode } from '../../../redux/actions/oneViewActions';
import { updateOneViewCompanyWidgetAction, updateOneViewBgImageAction } from '../../../redux/actions/oneViewCompanyWidgets';
import { sendNotificationAction } from '../../../redux/actions/notificationActions';
import gridSVG from '../../../assets/images/grid.svg';
import editSVG from '../../../assets/images/edit.svg';
import checkSVG from '../../../assets/images/check.svg';
import smallBadge from '../../../assets/images/smallBadge.svg';
import { getAccessToken } from '../../../api/Api';
import { API_ENDPOINT_URL } from '../../../constants/default';

const StyledButton = withStyles({
  root: {
    color: 'white',
    borderRadius: '50%',
    backgroundColor: '#004995',
    fontSize: '16px',
    boxShadow: '3px 6px 20px #00000029',
    minWidth: '32px',
    lineHeight: 1,
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

/* eslint-disable */
const OneViewMenuItem = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const editModes = [
    {id: 1, mode: 'A', toolTip: 'Admin'},
    {id: 2, mode: 'M', toolTip: 'Manager'},
    {id: 3, mode: 'E', toolTip: 'Employee'},
  ]

  const editMode = useSelector(state => (state.oneView && state.oneView.isEdit) || false);
  const employeeEditMode = useSelector(state => (state.oneView && state.oneView.isEmployeeEdit) || false);
  const updatedLayouts = useSelector(state => (state.oneView && state.oneView.widgetPositions) || []);
  const isNew = useSelector(state => (state.oneView && state.oneView.isNew) || false);
  const bgImgData = useSelector(state => (state.oneView && state.oneView.backgroundImage) || {});
  const status = useSelector(state => (state.oneView && state.oneView.bgImageStatus) || false);
  const companyWidgets = useSelector(state => (state.oneViewCompanyWidget && state.oneViewCompanyWidget.companyWidgets) || []);
  const currentEditMode = useSelector(state => (state.oneView && state.oneView.editMode) || 1);
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  const handleEditClick = () => {
    dispatch(enableEditMode());
  };

  const handleGridClick = () => {
    dispatch(disableEditMode());
    dispatch(disableEmployeeEditMode());
  };

  const submitUpdatedLayout = () => {
    const { company_id } = JSON.parse(localStorage.getItem('user'));
    const updatePayload = {
      company_id,
      widgetsData: updatedLayouts,
    };
    dispatch(updateOneViewCompanyWidgetAction(updatePayload));
    if (bgImgData.file_type) {
      const imageFile = bgImgData.image
      delete bgImgData.image;
      bgImgData.folder = 'oneViewBgImg';
      const url = `${API_ENDPOINT_URL}/photos/upload`;
      axios.post(url, bgImgData, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }).then((res) => {
        const { signedUrl } = res.data;
        const imgUrl = signedUrl.split('?')[0]; // https://centraverse-notification-assets.s3.us-east-2.amazonaws.com/user-photos/1611334330991/test1.jpeg
        const options = {
          headers: {
            'Content-Type': bgImgData.file_type,
          },
        };
        axios.put(signedUrl, imageFile, options)
          .then((patchRes) => {
            const payloadBody = {
              company_id,
              url: imgUrl,
            };
            dispatch(updateOneViewBgImageAction(payloadBody));
            setTimeout(() => {
              dispatch(setBgImageUpdateStatus({ status: !status }))
            }, 500)
          });
      }).catch((error) => {
        const errorBody = {
          success: false,
          message: 'Image size must be less than 2MB',
          title: 'Error',
        };
        dispatch(sendNotificationAction(errorBody));
      });
    }
    dispatch(disableEditMode());
    dispatch(setCurrentEditMode({ editMode: 1 }));
  };

  const handleMode = (id) => {
    dispatch(setCurrentEditMode({ editMode: id }))
  };

  return (
    <React.Fragment>
      <Tooltip title="OneView" placement="right-start">
        <List className={(!editMode && !employeeEditMode) ? classes.selectedBackgroud : classes.defaultBackround}>
          <ListItem button className={props.drawerOpen ? classes.item0 : classes.item16} onClick={() => handleGridClick()}>
            <ListItemIcon className={classes.iconRoot}>
              <div>
                <img src={gridSVG} alt="grid" />
              </div>
            </ListItemIcon>
            {props.drawerOpen && <ListItemText
              disableTypography
              primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: !editMode ? '1' : '0.5' }}>OneView</Typography>}
            />}
          </ListItem>
        </List>
      </Tooltip>
      {permissions && permissions.write_oneview === true
        ? (<Tooltip title="Edit" placement="right-start">
            <List className={editMode ? classes.selectedBackgroud : classes.defaultBackround}>
              <ListItem button className={props.drawerOpen ? classes.item0 : classes.item16} onClick={() => handleEditClick()}>
                <ListItemIcon className={classes.iconRoot}>
                  {/* <BorderColorIcon className={classes.iconRoot} style={{ color: editMode ? '#D4E0ED' : '#99BCE0', cursor: 'pointer' }} /> */}
                  <div>
                    <img src={editSVG} alt="edit" />
                  </div>
                </ListItemIcon>
                {props.drawerOpen && <ListItemText
                  disableTypography
                  primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: editMode ? '1' : '0.5' }}>Edit</Typography>}
                />}
              </ListItem>
              {
                (!editMode && isNew) &&
                <div className={classes.badgeHolder} onClick={() => handleEditClick()}>
                  <img src={smallBadge} alt="new" />
                </div>
              }
            </List>
          </Tooltip>)
          : null
      }
      {editMode ? (
        <>
          {
            editModes.map(mode => (
              <Tooltip title={mode.toolTip} placement="right-start" key={mode.id}>
                <List className={classes.defaultBackround} style={{ backgroundColor: 'hsla(0,0%,100%,.2)' }}>
                <ListItem button className={props.drawerOpen ? classes.item0 : classes.item16} onClick={() => handleMode(mode.id)}>
                  <ListItemIcon className={classes.iconRoot}>
                    <StyledButton name="modeButton" type="button" style={{ border: currentEditMode === mode.id ? '3px solid #F49900' : '1px solid #004995'}}>
                      {mode.mode}
                    </StyledButton>
                  </ListItemIcon>
                  {props.drawerOpen && <ListItemText
                    disableTypography
                    primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: '0.5' }}></Typography>}
                  />}
                </ListItem>
              </List>
            </Tooltip>
            ))
          }
          <Tooltip title="Save" placement="right-start">
            <List className={classes.defaultBackround} style={{ backgroundColor: 'hsla(0,0%,100%,.2)' }}>
            <ListItem button className={props.drawerOpen ? classes.item0 : classes.item16} onClick={() => submitUpdatedLayout()}>
              <ListItemIcon className={classes.iconRoot}>
                <div>
                  <img src={checkSVG} alt="save" />
                </div>
              </ListItemIcon>
              {props.drawerOpen && <ListItemText
                disableTypography
                primary={<Typography style={{ color: '#FFFFFF', whiteSpace: 'normal', opacity: '0.5' }}></Typography>}
              />}
            </ListItem>
          </List>
        </Tooltip>
      </>
      ) : null}
    </React.Fragment>
  );
};

export default withRouter(OneViewMenuItem);
