import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  addOrder,
  deleteOrder,
  getOrderById,
  getOrders, updateOrder, getOrdersCount, getTodaysOrdersCount,
} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_ORDERS_REQUEST, fetchOrdersSaga);
  yield takeLatest(types.FETCH_ORDER_REQUEST, fetchOrderSaga);
  yield takeLatest(types.ADD_ORDER_REQUEST, addOrderSaga, context);
  yield takeLatest(types.UPDATE_ORDER_REQUEST, updateOrderSaga, context);
  yield takeLatest(types.DELETE_ORDER_REQUEST, deleteOrderSaga);
  yield takeLatest(types.FETCH_OPEN_ORDER_COUNTS, fetchOrdersCountSaga);
  yield takeLatest(types.FETCH_TODAY_OPEN_ORDER_COUNTS, fetchTodayOrdersCountSaga);
}

export function* fetchOrdersSaga({ payload }) {
  try {
    const res = yield call(getOrders, payload);
    yield all([
      put({ type: types.FETCH_ORDERS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_ORDERS_FAILED, error });
  }
}

export function* fetchOrderSaga({ payload }) {
  try {
    const res = yield call(getOrderById, payload);
    yield all([
      put({ type: types.FETCH_ORDER_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_ORDER_FAILED, error });
  }
}

export function* addOrderSaga({ history }, { payload }) {
  try {
    const res = yield call(addOrder, payload);
    yield all([
      put({ type: types.ADD_ORDER_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Order created' : res.message || 'Order not created',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      yield put({ type: types.CLEAR_CART });
      history.push('/orders');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_ORDER_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateOrderSaga({ history }, { payload }) {
  try {
    const res = yield call(updateOrder, payload);
    yield all([
      put({ type: types.UPDATE_ORDER_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Order updated' : res.message || 'Order not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && res.data.id) {
      history.push('/orders');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_ORDER_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteOrderSaga({ payload }) {
  try {
    const res = yield call(deleteOrder, payload);
    yield all([
      put({ type: types.DELETE_ORDER_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Order deleted' : res.message || 'Order not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_ORDER_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchOrdersCountSaga({ payload }) {
  try {
    const res = yield call(getOrdersCount, payload);
    yield all([
      put({ type: types.FETCH_OPEN_ORDER_COUNTS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_OPEN_ORDER_COUNTS_FAILED, error });
  }
}


export function* fetchTodayOrdersCountSaga({ payload }) {
  try {
    const res = yield call(getTodaysOrdersCount, payload);
    yield all([
      put({ type: types.FETCH_TODAY_OPEN_ORDER_COUNTS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_TODAY_OPEN_ORDER_COUNTS_FAILED, error });
  }
}
