import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
/* eslint-disable */


// notification need to be implemented 

const useStyles = makeStyles({
  // root: {
  //   maxWidth: 345,
  // },
  customBadge: {
    // display: props => props.noti.items && props.noti.items.length ? 'flex' : 'none' || 'none',
    backgroundColor: 'rgba(244, 153, 0, 1)',
    color: 'rgba(255, 255, 255, 1)',
  },
});

const NotificationIcon = (props) => {
  const classes = useStyles(props);
  // const noti = useSelector(state => state.noti) || {};
  return (
    <IconButton
      disableTouchRipple
      disableFocusRipple
      disableRipple
      // className={classes.iconBackgroud}
      onClick={props.onClick}
      style={{
        padding: '0',
        width: '2.0em',
        height: '1.7em',
        // borderRadius: '8px',
        // background: 'linear-gradient(51deg, rgba(0, 154, 202, 1), rgba(0, 101, 209, 1) )',
        // border: '3px solid rgba(255, 255, 255, 1)',
      }}
    >
      <Badge
        classes={{ badge: classes.customBadge }}
        style={{}}
      // badgeContent={(noti.items && noti.items.length) || 0}
      >
        <NotificationsIcon
          style={{
            color: 'silver',
            width: '1.3em',
            height: '1.3em',
          }}
        />
      </Badge>
    </IconButton>
  );
};

export default NotificationIcon;

NotificationIcon.propTypes = {
  // noti: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
};
