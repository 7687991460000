import React from 'react';

export const InventoryAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'inventoryApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/inventory/sale-order/new',
      component: React.lazy(() => import('./SaleOrder/Form')),
    },
    {
      path: '/inventory/sale-order/view/:id',
      component: React.lazy(() => import('./SaleOrder/List/components/ViewSingleSaleOrder')),
    },
    {
      path: '/inventory/sale-order/:id/invoice',
      component: React.lazy(() => import('./SaleOrder/InvoiceForm')),
    },
    {
      path: '/inventory/sale-order/edit/:id',
      component: React.lazy(() => import('./SaleOrder/Form')),
    },
    {
      path: '/inventory/sale-order',
      component: React.lazy(() => import('./SaleOrder/List')),
    },
    {
      path: '/inventory/sale-order/:id(\\d+)/documents/new',
      component: React.lazy(() => import('./SaleOrder/NewDocuments')),
    },
    {
      path: '/inventory/sale-order/:id(\\d+)/documents',
      component: React.lazy(() => import('./SaleOrder/Documents')),
    },
    {
      path: '/inventory-products',
      component: React.lazy(() => import('.//InventoryProducts/List')),
    },
    {
      path: '/inventory-products/create',
      component: React.lazy(() => import('.//InventoryProducts/UpdateData')),
    },
    {
      path: '/inventory-products/view/:id',
      component: React.lazy(() => import('.//InventoryProducts/View')),
    },
    {
      path: '/inventory-products/edit/:id',
      component: React.lazy(() => import('.//InventoryProducts/UpdateData')),
    },
    {
      path: '/inventory-brands',
      component: React.lazy(() => import('.//InventoryBrands/List')),
    },
    {
      path: '/inventory-brands/create',
      component: React.lazy(() => import('.//InventoryBrands/Create')),
    },
    {
      path: '/inventory-brands/edit/:id',
      component: React.lazy(() => import('.//InventoryBrands/Edit')),
    },
    {
      path: '/inventory-suppliers',
      component: React.lazy(() => import('.//InventorySuppliers/List')),
    },
    {
      path: '/inventory-suppliers/create',
      component: React.lazy(() => import('.//InventorySuppliers/UpdateData')),
    },
    {
      path: '/inventory-suppliers/edit/:id',
      component: React.lazy(() => import('.//InventorySuppliers/UpdateData')),
    },
    {
      path: '/inventory-suppliers/view/:id',
      component: React.lazy(() => import('.//InventorySuppliers/View')),
    },
    {
      path: '/inventory-warehouses',
      component: React.lazy(() => import('.//InventoryWarehouses/List')),
    },
    {
      path: '/inventory-warehouses/create',
      component: React.lazy(() => import('.//InventoryWarehouses/UpdateData')),
    },
    {
      path: '/inventory-warehouses/edit/:id',
      component: React.lazy(() => import('.//InventoryWarehouses/UpdateData')),
    },
    {
      path: '/inventory-warehouses/view/:id',
      component: React.lazy(() => import('.//InventoryWarehouses/View')),
    },
    {
      path: '/inventory-purchase-orders',
      component: React.lazy(() => import('./InventoryPurchaseOrders/List')),
    },
    {
      path: '/inventory-purchase-orders/create',
      component: React.lazy(() => import('./InventoryPurchaseOrders/UpdateData')),
    },
    {
      path: '/inventory-purchase-orders/edit/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/UpdateData')),
    },
    {
      path: '/inventory-purchase-orders/view/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/View')),
    },
    {
      path: '/inventory-purchase-order-receipt/create',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Receipt/index')),
    },
    {
      path: '/inventory-purchase-order-receipt/edit/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Receipt/index')),
    },
    {
      path: '/inventory-purchase-order-receipt/view/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Receipt/View')),
    },
    {
      path: '/inventory-purchase-order-receipt/list',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Receipt/List')),
    },
    {
      path: '/inventory/finished-product/create',
      component: React.lazy(() => import('./InventoryFinishedProducts/UpdateData')),
    },
    {
      path: '/inventory/finished-product/edit/:id',
      component: React.lazy(() => import('./InventoryFinishedProducts/UpdateData')),
    },
    {
      path: '/inventory/finished-product/view/:id',
      component: React.lazy(() => import('./InventoryFinishedProducts/View')),
    },
    {
      path: '/inventory/finished-products',
      component: React.lazy(() => import('./InventoryFinishedProducts/List')),
    },
    {
      path: '/inventory-leads/list',
      component: React.lazy(() => import('./InventoryLeads/List')),
    },
    {
      path: '/inventory-leads/create',
      component: React.lazy(() => import('./InventoryLeads/ContactUsForm')),
    },
    {
      path: '/inventory-purchase-order/payment/list',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Payments/List')),
    },
    {
      path: '/inventory-purchase-order/payment/create',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Payments/UpdateData')),
    },
    {
      path: '/inventory-purchase-order/payment/edit/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Payments/UpdateData')),
    },
    {
      path: '/inventory-purchase-order/payment/view/:id',
      component: React.lazy(() => import('./InventoryPurchaseOrders/Payments/View')),
    },
    {
      path: '/inventory/sale-order/payment/list',
      component: React.lazy(() => import('./SaleOrder/Payments/List')),
    },
    {
      path: '/inventory/sale-order/payment/create',
      component: React.lazy(() => import('./SaleOrder/Payments/UpdateData')),
    },
    {
      path: '/inventory/sale-order/payment/edit/:id',
      component: React.lazy(() => import('./SaleOrder/Payments/UpdateData')),
    },
    {
      path: '/inventory/sale-order/payment/view/:id',
      component: React.lazy(() => import('./SaleOrder/Payments/View')),
    },
    {
      path: '/inventory/finished-product/duplicate/:id',
      component: React.lazy(() => import('./InventoryFinishedProducts/UpdateData')),
    },
    {
      path: '/inventory/dealer/create',
      component: React.lazy(() => import('./InventoryDealers/UpdateData')),
    },
    {
      path: '/inventory/dealer/edit/:id',
      component: React.lazy(() => import('./InventoryDealers/UpdateData')),
    },
    {
      path: '/inventory/dealer/view/:id',
      component: React.lazy(() => import('./InventoryDealers/View')),
    },
    {
      path: '/inventory/dealers',
      component: React.lazy(() => import('./InventoryDealers/List')),
    },
    {
      path: '/inventory/sales-installations/calendar',
      component: React.lazy(() => import('./SaleOrder/InstallationCalendar')),
    },
    {
      path: '/inventory-leads/update/:id',
      component: React.lazy(() => import('./InventoryLeads/ContactUsForm')),
    },
    {
      path: '/inventory-leads/view/:id',
      component: React.lazy(() => import('./InventoryLeads/View')),
    },
    {
      path: '/inventory/sale-order/invoices/list',
      component: React.lazy(() => import('./SaleOrder/CustomerInvoices/List')),
    },
    {
      path: '/inventory/sale-order/invoices/create',
      component: React.lazy(() => import('./SaleOrder/CustomerInvoices/UpdateData')),
    },
    {
      path: '/inventory/sale-order/invoices/edit/:id',
      component: React.lazy(() => import('./SaleOrder/CustomerInvoices/UpdateData')),
    },
  ],
};

export default InventoryAppConfig;
