import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  userregion: {},
  userregions: [],
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USERREGIONS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          userregions: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        userregions: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_USERREGIONS_REQUEST:
      return {
        ...state,
        userregion: [],
        loading: true,
      };
    case types.FETCH_USERREGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_USERREGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_USERREGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_USERREGION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_USERREGIONS_FAILED:
      return {
        ...state,
        userregions: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_USERREGION_SUCCESS:
      if (!action.res.data.success) {
        return {
          ...state,
          userregion: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        userregion: action.res.data || {},
        total: (action.res.data && action.res.data.length) || 0,
        status: action.res.data.success,
        loading: false,
      };
    case types.FETCH_USERREGION_FAILED:
      return {
        ...state,
        userregion: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_USERREGION_SUCCESS:
      return {
        ...state,
        userregions: [...state.userregions, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_USERREGION_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_USERREGION_SUCCESS:
      let userregions = filter(state.userregions, item => item.id !== action.res.data.id);
      return {
        ...state,
        userregion: action.res.data || {},
        userregions: [...userregions, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_USERREGION_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.DELETE_USERREGION_SUCCESS:
      userregions = filter(state.userregions, item => item.id !== action.payload.id);

      return {
        ...state,
        userregions: [...userregions],
        status: true,
        loading: false,
      };
    case types.DELETE_USERREGION_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
