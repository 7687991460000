import React from 'react';

export const AdminAppConfig = { // eslint-disable-line
  settings: {
    layout: {},
  },
  routeConfig: {
    moduleName: 'adminApp',
    store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/admin/sidebar-items',
      component: React.lazy(() => import('./SidebarItems')),
    },
    {
      path: '/admin/sidebar-items/add',
      component: React.lazy(() => import('./SidebarItems/AddSidebarItem')),
    },
    {
      path: '/admin/sidebar-items/edit/:id',
      component: React.lazy(() => import('./SidebarItems/EditSidebarItem')),
    },
    {
      path: '/admin/embedded-links',
      component: React.lazy(() => import('./EmbeddedLinks')),
    },
    {
      path: '/admin/embedded-links/add',
      component: React.lazy(() => import('./EmbeddedLinks/AddEmbeddedLink')),
    },
    {
      path: '/admin/embedded-links/edit/:id',
      component: React.lazy(() => import('./EmbeddedLinks/EditEmbeddedLink')),
    },
    {
      path: '/admin/districts/add',
      exact: true,
      component: React.lazy(() => import('./Districts/AddDistrict')),
    },
    {
      path: '/admin/districts/edit/:id',
      exact: true,
      component: React.lazy(() => import('./Districts/EditDistrict')),
    },
    {
      path: '/admin/districts',
      exact: true,
      component: React.lazy(() => import('./Districts')),
    },
    {
      path: '/admin/regions',
      component: React.lazy(() => import('./Regions')),
    },
    {
      path: '/admin-districts',
      component: React.lazy(() => import('./newDistricts')),
    },
    {
      path: '/admin-regions',
      component: React.lazy(() => import('./newRegions')),
    },
    {
      path: '/admin-stores',
      component: React.lazy(() => import('./newStores')),
    },
    {
      path: '/admin-stores/edit/:id',
      component: React.lazy(() => import('./newStores/components/StoreEdit')),
    },
    {
      path: '/admin-location',
      component: React.lazy(() => import('./newLocation')),
    },
    {
      path: '/admin-role',
      component: React.lazy(() => import('../Settings/NewRoles')),
    },
    // {
    //   path: '/admin-roles',
    //   component: React.lazy(() => import('./NewRoles')),
    // },
    // {
    //   path: '/admin-roles/add',
    //   component: React.lazy(() => import('./NewRoles/components/RoleAdd')),
    // },
    {
      path: '/admin-stores/add',
      component: React.lazy(() => import('./newStores/components/StoreTax')),
    },
    {
      path: '/admin-company-settings',
      component: React.lazy(() => import('./CompanySettings')),
    },
    {
      path: '/admin-role-settings',
      component: React.lazy(() => import('./CompanyRoleSettings')),
    },
    {
      path: '/admin-location-settings',
      component: React.lazy(() => import('./CompanyLocationSettings')),
    },
    {
      path: '/admin-company/edit',
      component: React.lazy(() => import('./newCompaniesEdit')),
    },
    {
      path: '/admin/regions/add',
      component: React.lazy(() => import('./Regions/AddRegion')),
    },
    {
      path: '/admin/regions/edit/:id',
      component: React.lazy(() => import('./Regions/EditRegion')),
    },
    {
      path: '/admin/stores',
      component: React.lazy(() => import('./Stores')),
    },
    {
      path: '/admin/stores/add',
      component: React.lazy(() => import('./Stores/AddStore')),
    },
    {
      path: '/admin/stores/edit/:id',
      component: React.lazy(() => import('./Stores/EditStore')),
    },
    {
      path: '/csv-upload',
      component: React.lazy(() => import('./CsvUpload')),
    },
    {
      path: '/mail-reports',
      component: React.lazy(() => import('./MailReports')),
    },
    {
      path: '/sms-reports',
      component: React.lazy(() => import('./SMSReports')),
    },
  ],
};
