import React from 'react';

export const EmbedsAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    // moduleName: 'adminApp',
    // store: import('./store'),
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/e/:slug',
      component: React.lazy(() => import('./SingleEmbed')),
    },
    {
      path: '/embed/new',
      component: React.lazy(() => import('./ManageEmbed')),
    },
    {
      path: '/real-estate',
      component: React.lazy(() => import('./RealEstate')),
    },
    {
      path: '/insights',
      component: React.lazy(() => import('./Insights')),
    },
    {
      path: '/mobile-experts-report',
      component: React.lazy(() => import('./MobileExportsReport')),
    },
    {
      path: '/ow-emp-onboard-form',
      component: React.lazy(() => import('./EmpOnBoardingForm')),
    },
    {
      path: '/ow-emp-update-form',
      component: React.lazy(() => import('./EmployeeUpdate')),
    },
    {
      path: '/ow-emp-departure-form',
      component: React.lazy(() => import('./EmployeeDeparture')),
    },
    {
      path: '/device-tracker',
      component: React.lazy(() => import('./DeviceTracker')),
    },
    // Finance module
    {
      path: '/commission',
      component: React.lazy(() => import('./Commission')),
    },
    {
      path: '/ow-brisk-safe-checklist',
      component: React.lazy(() => import('./BriskSafeChecklist')),
    },
    {
      path: '/engage',
      component: React.lazy(() => import('./Engage')),
    },
    {
      path: '/inventory-management',
      component: React.lazy(() => import('./InventoryManagement')),
    },
    {
      path: '/simple-sale-pos',
      component: React.lazy(() => import('./SimpleSalePOS')),
    },
    {
      path: '/cleaning-supplies',
      component: React.lazy(() => import('./VividCleaningSupplies')),
    },
    {
      path: '/work-order',
      component: React.lazy(() => import('./WorkOrder')),
    },
    {
      path: '/salesforce-report',
      component: React.lazy(() => import('./SalesforceReport')),
    },
  ],
};

export default EmbedsAppConfig;
