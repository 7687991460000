/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter, useHistory } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@material-ui/core';
import hexToHsl from '../../../lib/changeColorFormat';

// const seletectedRoute = {
//   background: 'linear-gradient(51deg, rgba(0, 154, 202, 1), rgba(0, 101, 209, 1) )',
//   borderRadius: '5px 8px',
//   color: '#FFFFFF',
//   textTransform: 'initial',
// };

const normalRoute = {
  color: '#13273C',
  textTransform: 'initial',
  opacity: 0.8,
};

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  topbarButton: {
    '&:hover': {
      background: props => `linear-gradient(51deg, hsl(${props.hue}, ${100}%, ${40}%), hsl(${props.hue}, ${100}%, ${41}%) )`,
      color: '#FFFFFF',
    },
  },
  selected: {
    background: props => `linear-gradient(51deg, hsl(${props.hue}, ${100}%, ${40}%), hsl(${props.hue}, ${100}%, ${41}%) )`,
    // borderRadius: '5px 8px',
    color: '#FFFFFF',
    textTransform: 'initial',
    minWidth: 'auto',
    minHeight: 'auto',
    borderRadius: '5px 8px',
    '&:hover': {
      background: props => `linear-gradient(51deg, hsl(${props.hue}, ${100}%, ${40}%), hsl(${props.h}, ${100}%, ${41}%) )`,
      color: '#FFFFFF',
    },
  },
  normal: {
    ...normalRoute,
    minWidth: 'auto',
    minHeight: 'auto',
    borderRadius: 4,
    '&:hover': {
      background: props => `linear-gradient(51deg, hsl(${props.hue}, ${100}%, ${40}%), hsl(${props.hue}, ${100}%, ${41}%) )`,
      color: '#FFFFFF',
    },
  },
  tabsRoot: {
    // width: '600px',
    alignItems: 'center',
    '& .MuiTabs-flexContainer': {
      '& button:not(:first-child)': {
        marginLeft: 16,
      },
    },
    '& svg': { fontSize: '2.1875rem' },
  },
}));

const Links = (props) => {
  const [hue, setHue] = React.useState('');
  const [saturation, setSaturation] = React.useState('');
  const [luminance, setLuminance] = React.useState('');
  const themeColor = localStorage.getItem('theme_color');

  React.useEffect(() => {
    const {h, s, l} = hexToHsl(themeColor);
    if (h && s && l) {
      setHue(h);
      setSaturation(s);
      setLuminance(l);
    } else {
      setHue(211);
      setSaturation(100);
      setLuminance(29);
    }
  }, [themeColor]);
  const classes = useStyles({hue, saturation, luminance});
  const { childRoutes, setCurrentTopbar, currentTopbar } = props;
  const history = useHistory();
  return (
    <>
      {/* <Typography className={classes.root} > */}
      <Tabs textColor="inherit" value={false} TabScrollButtonProps={{ style: { color: 'rgb(244, 153, 0)' }}} variant="scrollable" className={classes.tabsRoot}>
        {childRoutes && childRoutes.filter(removeSettings => removeSettings.name !== 'Centraverse Settings').map(route => (
          <Tab
            disableRipple
            key={route.name}
            onClick={() => {
              if (route.name === 'OneView') history.push('/oneview');
              setCurrentTopbar(route.name);
            }}
            className={currentTopbar === route.name ? classes.selected : classes.normal}
            label={route.name}
          />
        ))}
      </Tabs>
      {/* </Typography> */}
    </>
  );
};
const LinkWrapper = withRouter(props => <Links {...props} />);
export default LinkWrapper;
