import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  addUser,
  deleteUser,
  getUserById,
  getUsers, updateUser, lockUser, unlockUser,
} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_USERS_REQUEST, fetchUsersSaga);
  yield takeLatest(types.FETCH_USER_BY_ID_REQUEST, fetchUserSaga);
  yield takeLatest(types.ADD_USERS_REQUEST, addUserSaga, context);
  yield takeLatest(types.UPDATE_USERS_REQUEST, updateUserSaga, context);
  yield takeLatest(types.DELETE_USERS_REQUEST, deleteUserSaga);
  yield takeLatest(types.LOCK_USERS_REQUEST, lockUserSaga);
  yield takeLatest(types.UNLOCK_USERS_REQUEST, unlockUserSaga);
}

export function* fetchUsersSaga({ payload }) {
  try {
    const res = yield call(getUsers, payload);
    yield all([
      put({ type: types.FETCH_USERS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USERS_FAILED, error });
  }
}

export function* fetchUserSaga({ payload }) {
  try {
    const res = yield call(getUserById, payload);
    yield all([
      put({ type: types.FETCH_USER_BY_ID_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_USER_BY_ID_FAILED, error });
  }
}

export function* addUserSaga({ history }, { payload }) {
  try {
    const res = yield call(addUser, payload);
    yield all([
      put({ type: types.ADD_USERS_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'User created' : res.message || 'User not created',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_USER }),
    ]);
    if (res && res.success) {
      history.push('/users');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_USERS_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateUserSaga({ history }, { payload }) {
  try {
    const res = yield call(updateUser, payload);

    yield all([
      put({ type: types.UPDATE_USERS_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'User updated' : res.message || 'User not updated',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_USER }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/users');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_USERS_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteUserSaga({ payload }) {
  try {
    const res = yield call(deleteUser, payload);
    yield all([
      put({ type: types.DELETE_USERS_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'User Deactivated' : res.message || 'User not deactivated',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_USER }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_USERS_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* lockUserSaga({ payload }) {
  try {
    const res = yield call(lockUser, payload);
    yield all([
      put({ type: types.LOCK_USERS_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'User disabled' : res.message || 'User not disabled',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_EMP }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.LOCK_USERS_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* unlockUserSaga({ payload }) {
  try {
    const res = yield call(unlockUser, payload);
    yield all([
      put({ type: types.UNLOCK_USERS_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'User enabled' : res.message || 'User not enabled',
        },
      }),
      put({ type: types.TOGGLE_IS_UPDATE_EMP }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.UNLOCK_USERS_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}
