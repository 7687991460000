import React, { Component } from 'react';

const styles = {
  bigImg: {
    fontFamily: 'Courier New, Courier, monospace',
    fontSize: 16,
  },
  middle: {
    maxWidth: '70%',
    margin: 'auto',
    textAlign: 'center',
  },
};

class AccessDenied extends Component {
  render() {
    return (
      <div style={styles.bigImg}>
        <div style={styles.middle}>
          <h3>Access denied</h3>
          <hr />
          <p>Sorry you don't have a valid access.</p>
        </div>
      </div>
    );
  }
}

export default AccessDenied;
