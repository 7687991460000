import React from 'react';

export const ToolsAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    exact: true,
  },
  auth: true,
  routes: [
    {
      path: '/iverify',
      component: React.lazy(() => import('./iVerify')),
    },
    {
      path: '/lotus-drive',
      component: React.lazy(() => import('./LotusDrive')),
    },
    {
      path: '/real-time',
      component: React.lazy(() => import('./RealTime')),
    },
    {
      path: '/trace',
      component: React.lazy(() => import('./Trace')),
    },
    {
      path: '/my-portal',
      component: React.lazy(() => import('./MyPortal')),
    },
    {
      path: '/stripe-dashboard',
      component: React.lazy(() => import('./StripeDashboard')),
    },
    {
      path: '/sandbox',
      component: React.lazy(() => import('./Sandbox')),
    },
    {
      path: '/support',
      component: React.lazy(() => import('./SupportTicket')),
    },
    {
      path: '/membership-services',
      component: React.lazy(() => import('./MembershipServices')),
    },
    {
      path: '/lms',
      component: React.lazy(() => import('./LMS')),
    },
  ],
};

export default ToolsAppConfig;
