import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import TopbarRightBlock from './TopbarRightBlock';
import TopbarMenu from './TopbarMenu';
import TopbarMenuSelect from '../Utils/TopbarMenuSelect';
import useDimension from '../Utils/observer';


const drawerWidth = 280;
const mobDrawerWidth = 0;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.contrastText,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // // Related for modal drawer for small screen
    // [theme.breakpoints.down('sm')]: {
    //   width: `calc(100% - ${mobDrawerWidth}px)`,
    // },
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    // marginRight: 36,
    color: '#fff',
    background: '#fff',
  },
  hide: {
    display: 'none',
  },
  iconStyle: {
    color: props => `hsl(${props.hue}, ${props.saturation}%, ${props.luminance}%)`,
  },
  // toolbar: {
  //   paddingRight: '0',
  // },
  topbarItems: {
    [theme.breakpoints.up('md')]: {
    },
  },
}));

export default function TopAppBar(props) {
  const classes = useStyles(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const [logo, setLogo] = useState(null);
  const logoWidth = '100px';
  const logoHeight = '56px';

  const { childRoutes, setCurrentTopbar, currentTopbar } = props;

  const divRef = useRef(null);
  const { height, width } = useDimension(divRef);

  useEffect(() => {
    props.Sh(height);
  }, [height]);

  useEffect(() => {
    const logoFromStorage = localStorage.getItem('logo');
    if (logoFromStorage) {
      setLogo(logoFromStorage);
    }
  }, [logo]);
  return (
    <AppBar
      id="topbar"
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open,
      })}
      elevation={0}
      // style={{ boxShadow: 'none' }}
      ref={divRef}
    >
      <Toolbar
        className={classes.toolbar}
      // ref={divRef}
      >
        <IconButton
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: props.open,
          })}
        >
          <MenuIcon className={classes.iconStyle} />
        </IconButton>
        <IconButton
          aria-label="close drawer"
          onClick={props.handleDrawerClose}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: !props.open,
          })}
        >
          <CloseOutlinedIcon className={classes.iconStyle} />
        </IconButton>
        <div style={{ maxWidth: '175px' }}>
          <Link to="/">
            <img
              src={logo}
              alt="Main Logo"
              className="logo"
              width={logoWidth}
              maxheight={logoHeight}
              style={{ maxHeight: logoHeight, objectFit: 'contain' }}
            />
          </Link>
        </div>
        {matches ?
          (
            <Grid container justify="space-between" alignItems="center" direction="row" className={classes.topbarItems} wrap="nowrap" >
              {/* <Grid item container direction="row" lg={7} alignItems="center" className={classes.topbarItems}> */}
              <Grid>
                <TopbarMenu childRoutes={childRoutes} setCurrentTopbar={setCurrentTopbar} currentTopbar={currentTopbar} />
              </Grid>
              {/* </Grid> */}
              {/* <Grid item lg={5}> */}
              <Grid>
                <TopbarRightBlock />
              </Grid>
              {/* </Grid> */}
            </Grid>
          ) :
          (
            <Grid container justify="space-between" alignItems="center" direction="row" className={classes.topbarItems}>
              {/* <Grid item md={3} sm={4} alignItems="center" container direction="row" justify={props.open ? 'flex-start' : 'space-between'}> */}
              <Grid item>
                <TopbarMenuSelect childRoutes={childRoutes} setCurrentTopbar={setCurrentTopbar} currentTopbar={currentTopbar} />
              </Grid>
              {/* </Grid> */}
              {/* <Grid item md={9} sm={8}> */}
              <Grid item >
                <TopbarRightBlock />
                {/* </Grid> */}
              </Grid>
            </Grid>
          )
        }
      </Toolbar>
    </AppBar >
  );
}


TopAppBar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  childRoutes: PropTypes.array.isRequired, /* eslint react/forbid-prop-types: 0 */
  setCurrentTopbar: PropTypes.func.isRequired,
  currentTopbar: PropTypes.string.isRequired,
  Sh: PropTypes.func.isRequired,
};
