import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  addStore,
  deleteStore,
  activeStore,
  getStoreById,
  getStores, updateStore,
  getAllStores,
  getAllStoresLocations,
  getStoreTimeStatus,
  getStoresReport,
  getCompanyStore,
} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_STORES_REQUEST, fetchStoresSaga);
  yield takeLatest(types.FETCH_COMPANY_STORES_REQUEST, fetchCompanyStoresSaga);
  yield takeLatest(types.FETCH_STORES_SEARCH_REQUEST, fetchStoresSearchSaga);
  yield takeLatest(types.FETCH_STORE_REQUEST, fetchStoreSaga);
  yield takeLatest(types.ADD_STORE_REQUEST, addStoreSaga, context);
  yield takeLatest(types.UPDATE_STORE_REQUEST, updateStoreSaga, context);
  yield takeLatest(types.DELETE_STORE_REQUEST, deleteStoreSaga);
  yield takeLatest(types.ACTIVATE_STORE_REQUEST, activeStoreSaga);
  yield takeLatest(types.FETCH_STORE_TIME_STATUS_REQUEST, fetchStoreTimeStatusSaga);
  yield takeLatest(types.FETCH_STORES_LOCATIONS_REQUEST, fetchStoresLocationsSaga);
  yield takeLatest(types.FETCH_STORE_REPORTS_REQUEST, fetchStoreReportsSaga);
}

export function* fetchStoresSaga({ payload }) {
  try {
    const res = yield call(getStores, payload);
    yield all([
      put({ type: types.FETCH_STORES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.FETCH_STORES_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchCompanyStoresSaga({ payload }) {
  try {
    const res = yield call(getCompanyStore, payload);
    yield all([
      put({ type: types.FETCH_COMPANY_STORES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_COMPANY_STORES_FAILED, error });
  }
}

export function* fetchStoresSearchSaga({ payload }) {
  try {
    const res = yield call(getAllStores, payload);
    yield all([
      put({ type: types.FETCH_STORES_SEARCH_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_STORES_SEARCH_FAILED, error });
  }
}

export function* fetchStoreSaga({ payload }) {
  try {
    const res = yield call(getStoreById, payload);
    yield all([
      put({ type: types.FETCH_STORE_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_STORE_FAILED, error });
  }
}

export function* addStoreSaga({ history }, { payload }) {
  try {
    const res = yield call(addStore, payload);
    yield all([
      put({ type: types.ADD_STORE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Store created' : res.message || 'Store not created',
        },
      }),
    ]);
    if (res && res.success && res.data && history) {
      history.push('/admin-location-settings');
    }
  } catch (error) {
    yield all([
      put({ type: types.ADD_STORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* updateStoreSaga({ history }, { payload }) {
  try {
    const res = yield call(updateStore, payload);
    yield all([
      put({ type: types.UPDATE_STORE_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Store updated' : res.message || 'Store not updated',
        },
      }),
    ]);
    if (res && res.success && res.data && history) {
      history.push('/admin-location-settings');
    }
  } catch (error) {
    yield all([
      put({ type: types.UPDATE_STORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* deleteStoreSaga({ payload }) {
  try {
    const res = yield call(deleteStore, payload);
    yield all([
      put({ type: types.DELETE_STORE_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Store deleted' : res.message || 'Store not deleted',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_STORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* activeStoreSaga({ payload }) {
  try {
    const res = yield call(activeStore, payload);
    yield all([
      put({ type: types.ACTIVATE_STORE_SUCCESS, payload }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Store activated' : res.message || 'Store not activated',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.DELETE_STORE_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchStoreTimeStatusSaga({ payload }) {
  try {
    const res = yield call(getStoreTimeStatus, payload);
    yield all([
      put({ type: types.FETCH_STORE_TIME_STATUS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_STORE_TIME_STATUS_FAILED, error });
  }
}

export function* fetchStoresLocationsSaga({ payload }) {
  try {
    const res = yield call(getAllStoresLocations, payload);
    yield all([
      put({ type: types.FETCH_STORES_LOCATIONS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_STORES_LOCATIONS_FAILED, error });
  }
}

export function* fetchStoreReportsSaga({ payload }) {
  try {
    const res = yield call(getStoresReport, payload);
    yield all([
      put({ type: types.FETCH_STORE_REPORTS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_STORE_REPORTS_FAILED, error });
  }
}
