export const SET_USER_REQUEST = 'SET_USER_REQUEST';

// Login page
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILED = 'LOG_OUT_FAILED';

// roles

export const CLEAR_ROLE_FORM = 'CLEAR_ROLE_FORM';

export const FETCH_ROLE_REQUEST = 'FETCH_ROLE_REQUEST';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_FAILED = 'FETCH_ROLE_FAILED';

export const TOGGLE_B2B_ROLE_REQUEST = 'TOGGLE_B2B_ROLE_REQUEST';

export const FETCH_ROLES_SEARCH_REQUEST = 'FETCH_ROLES_SEARCH_REQUEST';
export const FETCH_ROLES_SEARCH_SUCCESS = 'FETCH_ROLES_SEARCH_SUCCESS';
export const FETCH_ROLES_SEARCH_FAILED = 'FETCH_ROLES_SEARCH_FAILED';

export const FETCH_USER_ROLES_REQUEST = 'FETCH_USER_ROLES_REQUEST';
export const FETCH_USER_ROLES_SUCCESS = 'FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAILED = 'FETCH_USER_ROLES_FAILED';

export const CLEAR_USER_EXIST = 'CLEAR_USER_EXIST';
export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILED = 'ADD_ROLE_FAILED';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';

export const ACTIVATE_ROLE_REQUEST = 'ACTIVATE_ROLE_REQUEST';
export const ACTIVATE_ROLE_SUCCESS = 'ACTIVATE_ROLE_SUCCESS';
export const ACTIVATE_ROLE_FAILED = 'ACTIVATE_ROLE_FAILED';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

export const FETCH_PERMISSION_GROUP_REQUEST = 'FETCH_PERMISSION_GROUP_REQUEST';
export const FETCH_PERMISSION_GROUP_SUCCESS = 'FETCH_PERMISSION_GROUP_SUCCESS';
export const FETCH_PERMISSION_GROUP_FAILED = 'FETCH_PERMISSION_GROUP_FAILED';

export const FETCH_PERMISSION_GROUPS_REQUEST = 'FETCH_PERMISSION_GROUPS_REQUEST';
export const FETCH_PERMISSION_GROUPS_SUCCESS = 'FETCH_PERMISSION_GROUPS_SUCCESS';
export const FETCH_PERMISSION_GROUPS_FAILED = 'FETCH_PERMISSION_GROUPS_FAILED';

export const FETCH_PERMISSION_GROUP_BY_ID_REQUEST = 'FETCH_PERMISSION_GROUP_BY_ID_REQUEST';
export const FETCH_PERMISSION_GROUP_BY_ID_SUCCESS = 'FETCH_PERMISSION_GROUP_BY_ID_SUCCESS';
export const FETCH_PERMISSION_GROUP_BY_ID_FAILED = 'FETCH_PERMISSION_GROUP_BY_ID_FAILED';

export const ADD_PERMISSION_GROUP_REQUEST = 'ADD_PERMISSION_GROUP_REQUEST';
export const ADD_PERMISSION_GROUP_SUCCESS = 'ADD_PERMISSION_GROUP_SUCCESS';
export const ADD_PERMISSION_GROUP_FAILED = 'ADD_PERMISSION_GROUP_FAILED';

export const UPDATE_PERMISSION_GROUP_REQUEST = 'UPDATE_PERMISSION_GROUP_REQUEST';
export const UPDATE_PERMISSION_GROUP_SUCCESS = 'UPDATE_PERMISSION_GROUP_SUCCESS';
export const UPDATE_PERMISSION_GROUP_FAILED = 'UPDATE_PERMISSION_GROUP_FAILED';

export const DELETE_PERMISSION_GROUP_FAILED = 'DELETE_PERMISSION_GROUP_FAILED';
export const DELETE_PERMISSION_GROUP_SUCCESS = 'DELETE_PERMISSION_GROUP_SUCCESS';
export const DELETE_PERMISSION_GROUP_REQUEST = 'DELETE_PERMISSION_GROUP_REQUEST';

export const CLEAR_PERMISSION_GROUP_FORM = 'CLEAR_PERMISSION_GROUP_FORM';

// facebook
export const FACEBOOK_SIGN_IN_REQUEST = 'FACEBOOK_SIGN_IN_REQUEST';
export const FACEBOOK_SIGN_IN_SUCCESS = 'FACEBOOK_SIGN_IN_SUCCESS';
export const FACEBOOK_SIGN_IN_FAILED = 'FACEBOOK_SIGN_IN_FAILED';

export const FACEBOOK_REQUEST = 'FACEBOOK_REQUEST';
export const FACEBOOK_SUCCESS = 'FACEBOOK_SUCCESS';
export const FACEBOOK_FAILED = 'FACEBOOK_FAILED';

// Home page
export const FETCH_HOME_PAGE_ORDERS_REQUEST = 'FETCH_HOME_PAGE_ORDERS_REQUEST';
export const FETCH_HOME_PAGE_ORDERS_SUCCESS = 'FETCH_HOME_PAGE_ORDERS_SUCCESS';
export const FETCH_HOME_PAGE_ORDERS_FAILED = 'FETCH_HOME_PAGE_ORDERS_FAILED';

export const FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAILED = 'FETCH_USER_INFO_FAILED';

// POS Categories
export const FETCH_POS_CATEGORIES_REQUEST = 'FETCH_POS_CATEGORIES_REQUEST';
export const FETCH_POS_CATEGORIES_SUCCESS = 'FETCH_POS_CATEGORIES_SUCCESS';
export const FETCH_POS_CATEGORIES_FAILED = 'FETCH_POS_CATEGORIES_FAILED';

export const FETCH_POS_CATEGORY_REQUEST = 'FETCH_POS_CATEGORY_REQUEST';
export const FETCH_POS_CATEGORY_SUCCESS = 'FETCH_POS_CATEGORY_SUCCESS';
export const FETCH_POS_CATEGORY_FAILED = 'FETCH_POS_CATEGORY_FAILED';

export const ADD_POS_CATEGORY_REQUEST = 'ADD_POS_CATEGORY_REQUEST';
export const ADD_POS_CATEGORY_SUCCESS = 'ADD_POS_CATEGORY_SUCCESS';
export const ADD_POS_CATEGORY_FAILED = 'ADD_POS_CATEGORY_FAILED';

export const UPDATE_POS_CATEGORY_REQUEST = 'UPDATE_POS_CATEGORY_REQUEST';
export const UPDATE_POS_CATEGORY_SUCCESS = 'UPDATE_POS_CATEGORY_SUCCESS';
export const UPDATE_POS_CATEGORY_FAILED = 'UPDATE_POS_CATEGORY_FAILED';

export const DELETE_POS_CATEGORY_REQUEST = 'DELETE_POS_CATEGORY_REQUEST';
export const DELETE_POS_CATEGORY_SUCCESS = 'DELETE_POS_CATEGORY_SUCCESS';
export const DELETE_POS_CATEGORY_FAILED = 'DELETE_POS_CATEGORY_FAILED';

export const FETCH_POS_CATEGORY_COUNTS = 'FETCH_CATEGORY_COUNTS';
export const FETCH_POS_CATEGORY_COUNTS_FAILED = 'FETCH_CATEGORY_COUNTS_FAILED';
export const FETCH_POS_CATEGORY_COUNTS_SUCCESS = 'FETCH_CATEGORY_COUNTS_SUCCESS';

// Companies
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILED = 'FETCH_COMPANIES_FAILED';

export const FETCH_COMPANY_REQUEST = 'FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILED = 'FETCH_COMPANY_FAILED';

export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILED = 'ADD_COMPANY_FAILED';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED';


// Districts
export const FETCH_DISTRICTS_REQUEST = 'FETCH_DISTRICTS_REQUEST';
export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS';
export const FETCH_DISTRICTS_FAILED = 'FETCH_DISTRICTS_FAILED';

export const FETCH_DISTRICTS_SEARCH_REQUEST = 'FETCH_DISTRICTS_SEARCH_REQUEST';
export const FETCH_DISTRICTS_SEARCH_SUCCESS = 'FETCH_DISTRICTS_SEARCH_SUCCESS';
export const FETCH_DISTRICTS_SEARCH_FAILED = 'FETCH_DISTRICTS_SEARCH_FAILED';

export const FETCH_DISTRICT_REQUEST = 'FETCH_DISTRICT_REQUEST';
export const FETCH_DISTRICT_SUCCESS = 'FETCH_DISTRICT_SUCCESS';
export const FETCH_DISTRICT_FAILED = 'FETCH_DISTRICT_FAILED';

export const ADD_DISTRICT_REQUEST = 'ADD_DISTRICT_REQUEST';
export const ADD_DISTRICT_SUCCESS = 'ADD_DISTRICT_SUCCESS';
export const ADD_DISTRICT_FAILED = 'ADD_DISTRICT_FAILED';

export const UPDATE_DISTRICT_REQUEST = 'UPDATE_DISTRICT_REQUEST';
export const UPDATE_DISTRICT_SUCCESS = 'UPDATE_DISTRICT_SUCCESS';
export const UPDATE_DISTRICT_FAILED = 'UPDATE_DISTRICT_FAILED';

export const DELETE_DISTRICT_REQUEST = 'DELETE_DISTRICT_REQUEST';
export const DELETE_DISTRICT_SUCCESS = 'DELETE_DISTRICT_SUCCESS';
export const DELETE_DISTRICT_FAILED = 'DELETE_DISTRICT_FAILED';

export const DELETE_USERDISTRICT_REQUEST = 'DELETE_USERDISTRICT_REQUEST';
export const DELETE_USERDISTRICT_SUCCESS = 'DELETE_USERDISTRICT_SUCCESS';
export const DELETE_USERDISTRICT_FAILED = 'DELETE_USERDISTRICT_FAILED';

export const FETCH_USERDISTRICT_REQUEST = 'FETCH_USERDISTRICT_REQUEST';
export const FETCH_USERDISTRICT_SUCCESS = 'FETCH_USERDISTRICT_SUCCESS';
export const FETCH_USERDISTRICT_FAILED = 'FETCH_USERDISTRICT_FAILED';

export const FETCH_USERDISTRICTS_REQUEST = 'FETCH_USERDISTRICTS_REQUEST';
export const FETCH_USERDISTRICTS_SUCCESS = 'FETCH_USERDISTRICTS_SUCCESS';
export const FETCH_USERDISTRICTS_FAILED = 'FETCH_USERDISTRICTS_FAILED';

export const ADD_USERDISTRICT_REQUEST = 'ADD_USERDISTRICT_REQUEST';
export const ADD_USERDISTRICT_SUCCESS = 'ADD_USERDISTRICT_SUCCESS';
export const ADD_USERDISTRICT_FAILED = 'ADD_USERDISTRICT_FAILED';

export const UPDATE_USERDISTRICT_REQUEST = 'UPDATE_USERDISTRICT_REQUEST';
export const UPDATE_USERDISTRICT_SUCCESS = 'UPDATE_USERDISTRICT_SUCCESS';
export const UPDATE_USERDISTRICT_FAILED = 'UPDATE_USERDISTRICT_FAILED';

export const ACTIVATE_DISTRICT_REQUEST = 'ACTIVATE_DISTRICT_REQUEST';
export const ACTIVATE_DISTRICT_SUCCESS = 'ACTIVATE_DISTRICT_SUCCESS';
export const ACTIVATE_DISTRICT_FAILED = 'ACTIVATE_DISTRICT_FAILED';

export const FETCH_DISTRICT_COUNTS = 'FETCH_DISTRICT_COUNTS';
export const FETCH_DISTRICT_COUNTS_FAILED = 'FETCH_DISTRICT_COUNTS_FAILED';
export const FETCH_DISTRICT_COUNTS_SUCCESS = 'FETCH_DISTRICT_COUNTS_SUCCESS';

export const TOGGLE_B2B_REQUEST_DISTRICT = 'TOGGLE_B2B_REQUEST_DISTRICT';

export const CLEAR_DISTRICT_FORM = 'CLEAR_DISTRICT_FORM';


// Orders
export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILED = 'FETCH_ORDER_FAILED';

export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILED = 'ADD_ORDER_FAILED';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILED = 'UPDATE_ORDER_FAILED';
export const UPDATE_ORDER_STATE = 'UPDATE_ORDER_STATE';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILED = 'DELETE_ORDER_FAILED';

export const FETCH_ORDER_COUNTS = 'FETCH_ORDER_COUNTS';
export const FETCH_ORDER_COUNTS_FAILED = 'FETCH_ORDER_COUNTS_FAILED';
export const FETCH_ORDER_COUNTS_SUCCESS = 'FETCH_ORDER_COUNTS_SUCCESS';


// Stores
export const FETCH_STORES_REQUEST = 'FETCH_STORES_REQUEST';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAILED = 'FETCH_STORES_FAILED';

export const FETCH_STORES_SEARCH_REQUEST = 'FETCH_STORES_SEARCH_REQUEST';
export const FETCH_STORES_SEARCH_SUCCESS = 'FETCH_STORES_SEARCH_SUCCESS';
export const FETCH_STORES_SEARCH_FAILED = 'FETCH_STORES_SEARCH_FAILED';

export const TOGGLE_B2B_REQUEST_STORE = 'TOGGLE_B2B_REQUEST_STORE';

export const FETCH_STORE_REQUEST = 'FETCH_STORE_REQUEST';
export const FETCH_STORE_SUCCESS = 'FETCH_STORE_SUCCESS';
export const FETCH_STORE_FAILED = 'FETCH_STORE_FAILED';

export const ADD_STORE_REQUEST = 'ADD_STORE_REQUEST';
export const ADD_STORE_SUCCESS = 'ADD_STORE_SUCCESS';
export const ADD_STORE_FAILED = 'ADD_STORE_FAILED';

export const UPDATE_STORE_REQUEST = 'UPDATE_STORE_REQUEST';
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILED = 'UPDATE_STORE_FAILED';

export const DELETE_STORE_REQUEST = 'DELETE_STORE_REQUEST';
export const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS';
export const DELETE_STORE_FAILED = 'DELETE_STORE_FAILED';

export const ACTIVATE_STORE_REQUEST = 'ACTIVATE_STORE_REQUEST';
export const ACTIVATE_STORE_SUCCESS = 'ACTIVATE_STORE_SUCCESS';
export const ACTIVATE_STORE_FAILED = 'ACTIVATE_STORE_FAILED';

export const FETCH_STORE_TIME_STATUS_REQUEST = 'FETCH_STORE_TIME_STATUS_REQUEST';
export const FETCH_STORE_TIME_STATUS_SUCCESS = 'FETCH_STORE_TIME_STATUS_SUCCESS';
export const FETCH_STORE_TIME_STATUS_FAILED = 'FETCH_STORE_TIME_STATUS_FAILED';

export const CLEAR_STORE_FORM = 'CLEAR_STORE_FORM';

// Tax

export const FETCH_TAX_REQUEST = 'FETCH_TAX_REQUEST';
export const FETCH_TAX_SUCCESS = 'FETCH_TAX_SUCCESS';
export const FETCH_TAX_FAILED = 'FETCH_TAX_FAILED';

export const ADD_TAX_REQUEST = 'ADD_TAX_REQUEST';
export const ADD_TAX_SUCCESS = 'ADD_TAX_SUCCESS';
export const ADD_TAX_FAILED = 'ADD_TAX_FAILED';

export const UPDATE_TAX_REQUEST = 'UPDATE_TAX_REQUEST';
export const UPDATE_TAX_SUCCESS = 'UPDATE_TAX_SUCCESS';
export const UPDATE_TAX_FAILED = 'UPDATE_TAX_FAILED';

export const DELETE_TAX_REQUEST = 'DELETE_TAX_REQUEST';
export const DELETE_TAX_SUCCESS = 'DELETE_TAX_SUCCESS';
export const DELETE_TAX_FAILED = 'DELETE_TAX_FAILED';

// Users
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

export const FETCH_USER_BY_ID_REQUEST = 'FETCH_USER_BY_ID_REQUEST';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAILED = 'FETCH_USER_BY_ID_FAILED';
export const CLEAR_USER_BY_ID_DATA = 'CLEAR_USER_BY_ID_DATA';


export const ADD_USERS_REQUEST = 'ADD_USERS_REQUEST';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_FAILED = 'ADD_USERS_FAILED';

export const UPDATE_USERS_REQUEST = 'UPDATE_USERS_REQUEST';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const UPDATE_USERS_FAILED = 'UPDATE_USERS_FAILED';

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED';

export const TERMINATION_USERS_REQUEST = 'TERMINATION_USERS_REQUEST';
export const TERMINATION_USERS_SUCCESS = 'TERMINATION_USERS_SUCCESS';
export const TERMINATION_USERS_FAILED = 'TERMINATION_USERS_FAILED';

// update account
export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_REQUEST_SUCCESS = 'UPDATE_USER_INFO_REQUEST_SUCCESS';
export const UPDATE_USER_INFO_REQUEST_FAILED = 'UPDATE_USER_INFO_REQUEST_FAILED';

// User Details
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const LOCK_USERS_REQUEST = 'LOCK_USERS_REQUEST';
export const LOCK_USERS_SUCCESS = 'LOCK_USERS_SUCCESS';
export const LOCK_USERS_FAILED = 'LOCK_USERS_FAILED';

export const UNLOCK_USERS_REQUEST = 'UNLOCK_USERS_REQUEST';
export const UNLOCK_USERS_SUCCESS = 'UNLOCK_USERS_SUCCESS';
export const UNLOCK_USERS_FAILED = 'UNLOCK_USERS_FAILED';

export const REACTIVATE_EMPLOYEES_REQUEST = 'REACTIVATE_EMPLOYEES_REQUEST';
export const REACTIVATE_EMPLOYEES_SUCCESS = 'REACTIVATE_EMPLOYEES_SUCCESS';
export const REACTIVATE_EMPLOYEES_FAILED = 'REACTIVATE_EMPLOYEES_FAILED';


// Password Reset
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const FETCH_USER_PHOTO_PRE_SIGNED_URL_REQUEST = 'FETCH_USER_PHOTO_PRE_SIGNED_URL_REQUEST';
export const FETCH_USER_PHOTO_PRE_SIGNED_URL_SUCCESS = 'FETCH_USER_PHOTO_PRE_SIGNED_URL_SUCCESS';
export const FETCH_USER_PHOTO_PRE_SIGNED_URL_FAILED = 'FETCH_USER_PHOTO_PRE_SIGNED_URL_FAILED';

// Sign Up
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED';

export const FETCH_COMPANY_COUNTS = 'FETCH_COMPANY_COUNTS';
export const FETCH_COMPANY_COUNTS_FAILED = 'FETCH_COMPANY_COUNTS_FAILED';
export const FETCH_COMPANY_COUNTS_SUCCESS = 'FETCH_COMPANY_COUNTS_SUCCESS';

export const FETCH_OPEN_ORDER_COUNTS = 'FETCH_OPEN_ORDER_COUNTS';
export const FETCH_OPEN_ORDER_COUNTS_FAILED = 'FETCH_OPEN_ORDER_COUNTS_FAILED';
export const FETCH_OPEN_ORDER_COUNTS_SUCCESS = 'FETCH_OPEN_ORDER_COUNTS_SUCCESS';

export const FETCH_TODAY_OPEN_ORDER_COUNTS = 'FETCH_TODAY_OPEN_ORDER_COUNTS';
export const FETCH_TODAY_OPEN_ORDER_COUNTS_FAILED = 'FETCH_TODAY_OPEN_ORDER_COUNTS_FAILED';
export const FETCH_TODAY_OPEN_ORDER_COUNTS_SUCCESS = 'FETCH_TODAY_OPEN_ORDER_COUNTS_SUCCESS';

// Cart
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const DELETE_ITEM_FROM_CART = 'DELETE_ITEM_FROM_CART';
export const CHANGE_QTY_FROM_CART = 'CHANGE_QTY_FROM_CART';
export const SET_ITEMS_CART = 'SET_ITEMS_CART';
export const CLEAR_CART = 'CLEAR_CART';


// Notifications
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';


// Phone Verification
export const OVERRRIDE_OTP_REQUEST = 'OVERRRIDE_OTP_REQUEST';
export const OVERRRIDE_OTP_SUCCESS = 'OVERRRIDE_OTP_SUCCESS';
export const OVERRRIDE_OTP_FAILED = 'OVERRRIDE_OTP_FAILED';

// Company History
export const FETCH_COMPANY_HISTORY_REQUEST = 'FETCH_COMPANY_HISTORY_REQUEST';
export const FETCH_COMPANY_HISTORY_SUCCESS = 'FETCH_COMPANY_HISTORY_SUCCESS';
export const FETCH_COMPANY_HISTORY_FAILED = 'FETCH_COMPANY_HISTORY_FAILED';

// 2Factor Auth
export const TWO_FACTOR_AUTH_REQUEST = 'TWO_FACTOR_AUTH_REQUEST';
export const TWO_FACTOR_AUTH_SUCCESS = 'TWO_FACTOR_AUTH_SUCCESS';
export const TWO_FACTOR_AUTH_FAILURE = 'TWO_FACTOR_AUTH_FAILURE';


// User Password Update
export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_ADMIN_REQUEST = 'UPDATE_USER_PASSWORD_ADMIN_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILED = 'UPDATE_USER_PASSWORD_FAILED';

// Serials
export const FETCH_SERIALS_REQUEST = 'FETCH_SERIALS_REQUEST';
export const FETCH_SERIALS_SUCCESS = 'FETCH_SERIALS_SUCCESS';
export const FETCH_SERIALS_FAILED = 'FETCH_SERIALS_FAILED';

// Get Company Payment Account

export const FETCH_PAYMENT_ACCOUNT_REQUEST = 'FETCH_PAYMENT_ACCOUNT_REQUEST';
export const FETCH_PAYMENT_ACCOUNT_SUCCESS = 'FETCH_PAYMENT_ACCOUNT_SUCCESS';
export const FETCH_PAYMENT_ACCOUNT_FAILED = 'FETCH_PAYMENT_ACCOUNT_FAILED';

// Update Company Payment Account

export const UPDATE_PAYMENT_ACCOUNT_REQUEST = 'UPDATE_PAYMENT_ACCOUNT_REQUEST';
export const UPDATE_PAYMENT_ACCOUNT_SUCCESS = 'UPDATE_PAYMENT_ACCOUNT_SUCCESS';
export const UPDATE_PAYMENT_ACCOUNT_FAILED = 'UPDATE_PAYMENT_ACCOUNT_FAILED';

export const UPDATE_PERSON_FOR_PAYMENT_REQUEST = 'UPDATE_PERSON_FOR_PAYMENT_REQUEST';
export const UPDATE_PERSON_FOR_PAYMENT_SUCCESS = 'UPDATE_PERSON_FOR_PAYMENT_SUCCESS';
export const UPDATE_PERSON_FOR_PAYMENT_FAILED = 'UPDATE_PERSON_FOR_PAYMENT_FAILED';

// Create Company Payment Account

export const ADD_PAYMENT_ACCOUNT_REQUEST = 'ADD_PAYMENT_ACCOUNT_REQUEST';
export const ADD_PAYMENT_ACCOUNT_SUCCESS = 'ADD_PAYMENT_ACCOUNT_SUCCESS';
export const ADD_PAYMENT_ACCOUNT_FAILED = 'ADD_PAYMENT_ACCOUNT_FAILED';

export const FETCH_PERSON_FOR_PAYMENT_REQUEST = 'FETCH_PERSON_FOR_PAYMENT_REQUEST';
export const FETCH_PERSON_FOR_PAYMENT_SUCCESS = 'FETCH_PERSON_FOR_PAYMENT_SUCCESS';
export const FETCH_PERSON_FOR_PAYMENT_FAILED = 'FETCH_PERSON_FOR_PAYMENT_FAILED';


//  add ticket response
export const ADD_TICKET_RESPONSE_REQUEST = 'ADD_TICKET_RESPONSE_REQUEST';
export const ADD_TICKET_RESPONSE_SUCCESS = 'ADD_TICKET_RESPONSE_SUCCESS';
export const ADD_TICKET_RESPONSE_FAILED = 'ADD_TICKET_RESPONSE_FAILED';
export const CLEAR_USERS_WITHOUT_PHONE = 'CLEAR_USERS_WITHOUT_PHONE';
export const CLEAR_NO_PHONE_FLAG = 'CLEAR_NO_PHONE_FLAG';
export const CLEAR_STATUS_EXIST = 'CLEAR_STATUS_EXIST';
export const CLEAR_TYPE_EXIST = 'CLEAR_TYPE_EXIST';
//  fetch siodebar item
export const FETCH_SIDEBAR_ITEM_REQUEST = 'FETCH_SIDEBAR_ITEM_REQUEST';
export const FETCH_SIDEBAR_ITEM_SUCCESS = 'FETCH_SIDEBAR_ITEM_SUCCESS';
export const FETCH_SIDEBAR_ITEM_FAILED = 'FETCH_SIDEBAR_ITEM_FAILED';

export const FETCH_SIDEBAR_ITEMS_REQUEST = 'FETCH_SIDEBAR_ITEMS_REQUEST';
export const FETCH_SIDEBAR_ITEMS_SUCCESS = 'FETCH_SIDEBAR_ITEMS_SUCCESS';
export const FETCH_SIDEBAR_ITEMS_FAILED = 'FETCH_SIDEBAR_ITEMS_FAILED';

export const FETCH_SIDEBAR_ITEM_BY_ID_REQUEST = 'FETCH_SIDEBAR_ITEM_BY_ID_REQUEST';
export const FETCH_SIDEBAR_ITEM_BY_ID_SUCCESS = 'FETCH_SIDEBAR_ITEM_BY_ID_SUCCESS';
export const FETCH_SIDEBAR_ITEM_BY_ID_FAILED = 'FETCH_SIDEBAR_ITEM_BY_ID_FAILED';

export const ADD_SIDEBAR_ITEM_REQUEST = 'ADD_SIDEBAR_ITEM_REQUEST';
export const ADD_SIDEBAR_ITEM_SUCCESS = 'ADD_SIDEBAR_ITEM_SUCCESS';
export const ADD_SIDEBAR_ITEM_FAILED = 'ADD_SIDEBAR_ITEM_FAILED';

export const UPDATE_SIDEBAR_ITEM_REQUEST = 'UPDATE_SIDEBAR_ITEM_REQUEST';
export const UPDATE_SIDEBAR_ITEM_SUCCESS = 'UPDATE_SIDEBAR_ITEM_SUCCESS';
export const UPDATE_SIDEBAR_ITEM_FAILED = 'UPDATE_SIDEBAR_ITEM_FAILED';


// TICKETS TEMPLATES


//  fetch ticket responses


export const DELETE_SIDEBAR_ITEM_FAILED = 'DELETE_SIDEBAR_ITEM_FAILED';
export const DELETE_SIDEBAR_ITEM_SUCCESS = 'DELETE_SIDEBAR_ITEM_SUCCCESS';
export const DELETE_SIDEBAR_ITEM_REQUEST = 'DELETE_SIDEBAR_ITEM_REQUEST';
// fetch embeds and links
export const FETCH_EMBEDS_AND_LINKS_REQUEST = 'FETCH_EMBEDS_AND_LINKS_REQUEST';
export const FETCH_EMBEDS_AND_LINKS_SUCCESS = 'FETCH_EMBEDS_AND_LINKS_SUCCESS';
export const FETCH_EMBEDS_AND_LINKS_FAILED = 'FETCH_EMBEDS_AND_LINKS_FAILED';

// fetch embeds and links
export const FETCH_EMBEDS_AND_LINK_REQUEST = 'FETCH_EMBEDS_AND_LINK_REQUEST';
export const FETCH_EMBEDS_AND_LINK_SUCCESS = 'FETCH_EMBEDS_AND_LINK_SUCCESS';
export const FETCH_EMBEDS_AND_LINK_FAILED = 'FETCH_EMBEDS_AND_LINK_FAILED';

// Update embeds and links
export const UPDATE_EMBEDS_AND_LINK_REQUEST = 'UPDATE_EMBEDS_AND_LINK_REQUEST';
export const UPDATE_EMBEDS_AND_LINK_SUCCESS = 'UPDATE_EMBEDS_AND_LINK_SUCCESS';
export const UPDATE_EMBEDS_AND_LINK_FAILED = 'UPDATE_EMBEDS_AND_LINK_FAILED';

// Delete embeds and links
export const DELETE_EMBEDS_AND_LINK_REQUEST = 'DELETE_EMBEDS_AND_LINK_REQUEST';
export const DELETE_EMBEDS_AND_LINK_SUCCESS = 'DELETE_EMBEDS_AND_LINK_SUCCESS';
export const DELETE_EMBEDS_AND_LINK_FAILED = 'DELETE_EMBEDS_AND_LINK_FAILED';

export const CLEAR_EMBEDS_AND_LINK_REQUEST = 'CLEAR_EMBEDS_AND_LINK_REQUEST';
export const CLEAR_EMBEDS_AND_LINK_SUCCESS = 'CLEAR_EMBEDS_AND_LINK_SUCCESS';
export const CLEAR_EMBEDS_AND_LINK_FAILED = 'CLEAR_EMBEDS_AND_LINK_FAILED';

export const ADD_EMBEDS_AND_LINK_REQUEST = 'ADD_EMBEDS_AND_LINK_REQUEST';
export const ADD_EMBEDS_AND_LINK_SUCCESS = 'ADD_EMBEDS_AND_LINK_SUCCESS';
export const ADD_EMBEDS_AND_LINK_FAILED = 'ADD_EMBEDS_AND_LINK_FAILED';

export const CLEAR_EMBEDS_AND_LINK_FORM = 'CLEAR_EMBEDS_AND_LINK_FORM';


export const REMOVE_PAYMENT_ACCOUNT_REQUEST = 'REMOVE_PAYMENT_ACCOUNT_REQUEST';
export const REMOVE_PAYMENT_ACCOUNT_SUCCESS = 'REMOVE_PAYMENT_ACCOUNT_SUCCESS';
export const REMOVE_PAYMENT_ACCOUNT_FAILED = 'REMOVE_PAYMENT_ACCOUNT_FAILED';

// Region
export const ADD_REGION_REQUEST = 'ADD_REGION_REQUEST';
export const ADD_REGION_SUCCESS = 'ADD_REGION_SUCCESS';
export const ADD_REGION_FAILED = 'ADD_REGION_FAILED';

export const DELETE_REGION_REQUEST = 'DELETE_REGION_REQUEST';
export const DELETE_REGION_SUCCESS = 'DELETE_REGION_SUCCESS';
export const DELETE_REGION_FAILED = 'DELETE_REGION_FAILED';

export const ACTIVATE_REGION_REQUEST = 'ACTIVATE_REGION_REQUEST';
export const ACTIVATE_REGION_SUCCESS = 'ACTIVATE_REGION_SUCCESS';
export const ACTIVATE_REGION_FAILED = 'ACTIVATE_REGION_FAILED';

export const TOGGLE_B2B_REQUEST_REGION = 'TOGGLE_B2B_REQUEST_REGION';

export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILED = 'FETCH_REGIONS_FAILED';

export const FETCH_REGIONS_SEARCH_REQUEST = 'FETCH_REGIONS_SEARCH_REQUEST';
export const FETCH_REGIONS_SEARCH_SUCCESS = 'FETCH_REGIONS_SEARCH_SUCCESS';
export const FETCH_REGIONS_SEARCH_FAILED = 'FETCH_REGIONS_SEARCH_FAILED';

export const FETCH_REGION_BY_ID_REQUEST = 'FETCH_REGION_BY_ID_REQUEST';
export const FETCH_REGION_BY_ID_SUCCESS = 'FETCH_REGION_BY_ID_SUCCESS';
export const FETCH_REGION_BY_ID_FAILED = 'FETCH_REGION_BY_ID_FAILED';

export const FETCH_REGION_REQUEST = 'FETCH_REGION_REQUEST';
export const FETCH_REGION_SUCCESS = 'FETCH_REGION_SUCCESS';
export const FETCH_REGION_FAILED = 'FETCH_REGION_FAILED';

export const UPDATE_REGION_REQUEST = 'UPDATE_REGION_REQUEST';
export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_FAILED = 'UPDATE_REGION_FAILED';

export const DELETE_USERREGION_REQUEST = 'DELETE_USERREGION_REQUEST';
export const DELETE_USERREGION_SUCCESS = 'DELETE_USERREGION_SUCCESS';
export const DELETE_USERREGION_FAILED = 'DELETE_USERREGION_FAILED';

export const FETCH_USERREGION_REQUEST = 'FETCH_USERREGION_REQUEST';
export const FETCH_USERREGION_SUCCESS = 'FETCH_USERREGION_SUCCESS';
export const FETCH_USERREGION_FAILED = 'FETCH_USERREGION_FAILED';

export const FETCH_USERREGIONS_REQUEST = 'FETCH_USERREGIONS_REQUEST';
export const FETCH_USERREGIONS_SUCCESS = 'FETCH_USERREGIONS_SUCCESS';
export const FETCH_USERREGIONS_FAILED = 'FETCH_USERREGIONS_FAILED';

export const ADD_USERREGION_REQUEST = 'ADD_USERREGION_REQUEST';
export const ADD_USERREGION_SUCCESS = 'ADD_USERREGION_SUCCESS';
export const ADD_USERREGION_FAILED = 'ADD_USERREGION_FAILED';

export const UPDATE_USERREGION_REQUEST = 'UPDATE_USERREGION_REQUEST';
export const UPDATE_USERREGION_SUCCESS = 'UPDATE_USERREGION_SUCCESS';
export const UPDATE_USERREGION_FAILED = 'UPDATE_USERREGION_FAILED';


// HR Dashboards

export const FETCH_HREMPLOYEEDASHBOARDS_REQUEST = 'FETCH_HREMPLOYEEDASHBOARDS_REQUEST';
export const FETCH_HREMPLOYEEDASHBOARDS_SUCCESS = 'FETCH_HREMPLOYEEDASHBOARDS_SUCCESS';
export const FETCH_HREMPLOYEEDASHBOARDS_FAILED = 'FETCH_HREMPLOYEEDASHBOARDS_FAILED';

export const FETCH_ROLEFORHRDASHBOARDS_REQUEST = 'FETCH_ROLEFORHRDASHBOARDS_REQUEST';
export const FETCH_ROLEFORHRDASHBOARDS_SUCCESS = 'FETCH_ROLEFORHRDASHBOARDS_SUCCESS';
export const FETCH_ROLEFORHRDASHBOARDS_FAILED = 'FETCH_ROLEFORHRDASHBOARDS_FAILED';

// WEB CLOCK IN
export const FETCH_SCHEDULESBYUSER_REQUEST = 'FETCH_SCHEDULESBYUSER_REQUEST';
export const FETCH_SCHEDULESBYUSER_SUCCESS = 'FETCH_SCHEDULESBYUSER_SUCCESS';
export const FETCH_SCHEDULESBYUSER_FAILED = 'FETCH_SCHEDULESBYUSER_FAILED';

export const FETCH_TIMEFORSTORETIMEZONE_REQUEST = 'FETCH_TIMEFORSTORETIMEZONE_REQUEST';
export const FETCH_TIMEFORSTORETIMEZONE_SUCCESS = 'FETCH_TIMEFORSTORETIMEZONE_SUCCESS';
export const FETCH_TIMEFORSTORETIMEZONE_FAILED = 'FETCH_TIMEFORSTORETIMEZONE_FAILED';

export const FETCH_STATUSFORWEBCLOCKIN_REQUEST = 'FETCH_STATUSFORWEBCLOCKIN_REQUEST';
export const FETCH_STATUSFORWEBCLOCKIN_SUCCESS = 'FETCH_STATUSFORWEBCLOCKIN_SUCCESS';
export const FETCH_STATUSFORWEBCLOCKIN_FAILED = 'FETCH_STATUSFORWEBCLOCKIN_FAILED';

export const SET_CLOCKIN_USER_COMMENT = 'SET_CLOCKIN_USER_COMMENT';
export const SET_CLOCKIN_USER_LOADING = 'SET_CLOCKIN_USER_LOADING';

export const ADD_TIMECARDFORCLOCKIN_REQUEST = 'ADD_TIMECARDFORCLOCKIN_REQUEST';
export const ADD_TIMECARDFORCLOCKIN_SUCCESS = 'ADD_TIMECARDFORCLOCKIN_SUCCESS';
export const ADD_TIMECARDFORCLOCKIN_FAILED = 'ADD_TIMECARDFORCLOCKIN_FAILED';

export const UPDATE_TIMECARDFORCLOCKOUT_REQUEST = 'UPDATE_TIMECARDFORCLOCKOUT_REQUEST';
export const UPDATE_TIMECARDFORCLOCKOUT_SUCCESS = 'UPDATE_TIMECARDFORCLOCKOUT_SUCCESS';
export const UPDATE_TIMECARDFORCLOCKOUT_FAILED = 'UPDATE_TIMECARDFORCLOCKOUT_FAILED';

export const SET_WEBCLOCKIN_FACE_AUTH_PENDING = 'SET_WEBCLOCKIN_FACE_AUTH_PENDING';
export const SET_WEBCLOCKIN_FACE_AUTH_ERROR = 'SET_WEBCLOCKIN_FACE_AUTH_ERROR';
export const SET_WEBCLOCKIN_CLOCK_IN_SUCEESS = 'SET_WEBCLOCKIN_CLOCK_IN_SUCEESS';
export const SET_WEBCLOCKIN_CLOCK_OUT_SUCEESS = 'SET_WEBCLOCKIN_CLOCK_OUT_SUCEESS';

// timecard break
export const FETCH_BREAKSETTINGS_REQUEST = 'FETCH_BREAKSETTINGS_REQUEST';
export const FETCH_BREAKSETTINGS_SUCCESS = 'FETCH_BREAKSETTINGS_SUCCESS';
export const FETCH_BREAKSETTINGS_FAILED = 'FETCH_BREAKSETTINGS_FAILED';

export const ADD_TIMECARDBREAK_REQUEST = 'ADD_TIMECARDBREAK_REQUEST';
export const ADD_TIMECARDBREAK_SUCCESS = 'ADD_TIMECARDBREAK_SUCCESS';
export const ADD_TIMECARDBREAK_FAILED = 'ADD_TIMECARDBREAK_FAILED';

export const UPDATE_TIMECARDBREAK_REQUEST = 'UPDATE_TIMECARDBREAK_REQUEST';
export const UPDATE_TIMECARDBREAK_SUCCESS = 'UPDATE_TIMECARDBREAK_SUCCESS';
export const UPDATE_TIMECARDBREAK_FAILED = 'UPDATE_TIMECARDBREAK_FAILED';

export const SET_TIMECARDBREAK_FACE_AUTH_PENDING = 'SET_TIMECARDBREAK_FACE_AUTH_PENDING';
export const SET_TIMECARDBREAK_FACE_AUTH_ERROR = 'SET_TIMECARDBREAK_FACE_AUTH_ERROR';
export const SET_TIMECARDBREAK_START_SUCEESS = 'SET_TIMECARDBREAK_START_SUCEESS';
export const SET_TIMECARDBREAK_END_SUCEESS = 'SET_TIMECARDBREAK_END_SUCEESS';

export const FETCH_TIMECARDBREAK_TIMESHEET_REQUEST = 'FETCH_TIMECARDBREAK_TIMESHEET_REQUEST';
export const FETCH_TIMECARDBREAK_TIMESHEET_SUCCESS = 'FETCH_TIMECARDBREAK_TIMESHEET_SUCCESS';
export const FETCH_TIMECARDBREAK_TIMESHEET_FAILED = 'FETCH_TIMECARDBREAK_TIMESHEET_FAILED';

// hrsettingsstores
export const FETCH_HRSETTINGSSTORES_REQUEST = 'FETCH_HRSETTINGSSTORES_REQUEST';
export const FETCH_HRSETTINGSSTORES_SUCCESS = 'FETCH_HRSETTINGSSTORES_SUCCESS';
export const FETCH_HRSETTINGSSTORES_FAILED = 'FETCH_HRSETTINGSSTORES_FAILED';

export const UPDATE_HRSETTINGSSTORE_REQUEST = 'UPDATE_HRSETTINGSSTORE_REQUEST';
export const UPDATE_HRSETTINGSSTORE_SUCCESS = 'UPDATE_HRSETTINGSSTORE_SUCCESS';
export const UPDATE_HRSETTINGSSTORE_FAILED = 'UPDATE_HRSETTINGSSTORE_FAILED';

// HRSETTINGSCOMPANIES
export const FETCH_HRSETTINGSCOMPANIES_REQUEST = 'FETCH_HRSETTINGSCOMPANIES_REQUEST';
export const FETCH_HRSETTINGSCOMPANIES_SUCCESS = 'FETCH_HRSETTINGSCOMPANIES_SUCCESS';
export const FETCH_HRSETTINGSCOMPANIES_FAILED = 'FETCH_HRSETTINGSCOMPANIES_FAILED';

export const UPDATE_HRSETTINGSCOMPANY_REQUEST = 'UPDATE_HRSETTINGSCOMPANY_REQUEST';
export const UPDATE_HRSETTINGSCOMPANY_SUCCESS = 'UPDATE_HRSETTINGSCOMPANY_SUCCESS';
export const UPDATE_HRSETTINGSCOMPANY_FAILED = 'UPDATE_HRSETTINGSCOMPANY_FAILED';

export const ADD_USERSTORE_REQUEST = 'ADD_USERSTORE_REQUEST';
export const ADD_USERSTORE_SUCCESS = 'ADD_USERSTORE_SUCCESS';
export const ADD_USERSTORE_FAILED = 'ADD_USERSTORE_FAILED';

export const DELETE_USERSTORE_REQUEST = 'DELETE_USERSTORE_REQUEST';
export const DELETE_USERSTORE_SUCCESS = 'DELETE_USERSTORE_SUCCESS';
export const DELETE_USERSTORE_FAILED = 'DELETE_USERSTORE_FAILED';

export const FETCH_USERSTORES_REQUEST = 'FETCH_USERSTORES_REQUEST';
export const FETCH_USERSTORES_SUCCESS = 'FETCH_USERSTORES_SUCCESS';
export const FETCH_USERSTORES_FAILED = 'FETCH_USERSTORES_FAILED';

export const FETCH_USERSTORE_BY_ID_REQUEST = 'FETCH_USERSTORE_BY_ID_REQUEST';
export const FETCH_USERSTORE_BY_ID_SUCCESS = 'FETCH_USERSTORE_BY_ID_SUCCESS';
export const FETCH_USERSTORE_BY_ID_FAILED = 'FETCH_USERSTORE_BY_ID_FAILED';

export const FETCH_USERSTORE_REQUEST = 'FETCH_USERSTORE_REQUEST';
export const FETCH_USERSTORE_SUCCESS = 'FETCH_USERSTORE_SUCCESS';
export const FETCH_USERSTORE_FAILED = 'FETCH_USERSTORE_FAILED';

export const FETCH_USERSTORE_PRIMARY_BY_STOREID_REQUEST = 'FETCH_USERSTORE_PRIMARY_BY_STOREID_REQUEST';
export const FETCH_USERSTORE_PRIMARY_BY_STOREID_SUCCESS = 'FETCH_USERSTORE_PRIMARY_BY_STOREID_SUCCESS';
export const FETCH_USERSTORE_PRIMARY_BY_STOREID_FAILED = 'FETCH_USERSTORE_PRIMARY_BY_STOREID_FAILED';

export const FETCH_USERSTORE_STOREID_REQUEST = 'FETCH_USERSTORE_STOREID_REQUEST';
export const FETCH_USERSTORE_STOREID_SUCCESS = 'FETCH_USERSTORE_STOREID_SUCCESS';
export const FETCH_USERSTORE_STOREID_FAILED = 'FETCH_USERSTORE_STOREID_FAILED';

export const UPDATE_USERSTORE_REQUEST = 'UPDATE_USERSTORE_REQUEST';
export const UPDATE_USERSTORE_SUCCESS = 'UPDATE_USERSTORE_SUCCESS';
export const UPDATE_USERSTORE_FAILED = 'UPDATE_USERSTORE_FAILED';

export const FETCH_ACCESSTYPE_BY_ID_REQUEST = 'FETCH_ACCESSTYPE_BY_ID_REQUEST';
export const FETCH_ACCESSTYPE_BY_ID_SUCCESS = 'FETCH_ACCESSTYPE_BY_ID_SUCCESS';
export const FETCH_ACCESSTYPE_BY_ID_FAILED = 'FETCH_ACCESSTYPE_BY_ID_FAILED';


// Support Ticket
export const ADD_SUPPORTTICKET_REQUEST = 'ADD_SUPPORTTICKET_REQUEST';
export const ADD_SUPPORTTICKET_SUCCESS = 'ADD_SUPPORTTICKET_SUCCESS';
export const ADD_SUPPORTTICKET_FAILED = 'ADD_SUPPORTTICKET_FAILED';

export const DELETE_SUPPORTTICKET_REQUEST = 'DELETE_SUPPORTTICKET_REQUEST';
export const DELETE_SUPPORTTICKET_SUCCESS = 'DELETE_SUPPORTTICKET_SUCCESS';
export const DELETE_SUPPORTTICKET_FAILED = 'DELETE_SUPPORTTICKET_FAILED';

export const FETCH_SUPPORTTICKETS_REQUEST = 'FETCH_SUPPORTTICKETS_REQUEST';
export const FETCH_SUPPORTTICKETS_SUCCESS = 'FETCH_SUPPORTTICKETS_SUCCESS';
export const FETCH_SUPPORTTICKETS_FAILED = 'FETCH_SUPPORTTICKETS_FAILED';

export const FETCH_SUPPORTTICKET_BY_ID_REQUEST = 'FETCH_SUPPORTTICKET_BY_ID_REQUEST';
export const FETCH_SUPPORTTICKET_BY_ID_SUCCESS = 'FETCH_SUPPORTTICKET_BY_ID_SUCCESS';
export const FETCH_SUPPORTTICKET_BY_ID_FAILED = 'FETCH_SUPPORTTICKET_BY_ID_FAILED';

export const FETCH_SUPPORTTICKET_REQUEST = 'FETCH_SUPPORTTICKET_REQUEST';
export const FETCH_SUPPORTTICKET_SUCCESS = 'FETCH_SUPPORTTICKET_SUCCESS';
export const FETCH_SUPPORTTICKET_FAILED = 'FETCH_SUPPORTTICKET_FAILED';

export const UPDATE_SUPPORTTICKET_REQUEST = 'UPDATE_SUPPORTTICKET_REQUEST';
export const UPDATE_SUPPORTTICKET_SUCCESS = 'UPDATE_SUPPORTTICKET_SUCCESS';
export const UPDATE_SUPPORTTICKET_FAILED = 'UPDATE_SUPPORTTICKET_FAILED';

export const SHOW_SUPPORTTICKETS = 'SHOW_SUPPORTTICKETS';
export const HIDE_SUPPORTTICKETS = 'HIDE_SUPPORTTICKETS';

export const ADD_SUPPORT_TICKET_TYPE_REQUEST = 'ADD_SUPPORT_TICKET_TYPE_REQUEST';
export const ADD_SUPPORT_TICKET_TYPE_SUCCESS = 'ADD_SUPPORT_TICKET_TYPE_SUCCESS';
export const ADD_SUPPORT_TICKET_TYPE_FAILED = 'ADD_SUPPORT_TICKET_TYPE_FAILED';

export const DELETE_SUPPORT_TICKET_TYPE_REQUEST = 'DELETE_SUPPORT_TICKET_TYPE_REQUEST';
export const DELETE_SUPPORT_TICKET_TYPE_SUCCESS = 'DELETE_SUPPORT_TICKET_TYPE_SUCCESS';
export const DELETE_SUPPORT_TICKET_TYPE_FAILED = 'DELETE_SUPPORT_TICKET_TYPE_FAILED';

export const FETCH_SUPPORT_TICKET_TYPES_REQUEST = 'FETCH_SUPPORT_TICKET_TYPES_REQUEST';
export const FETCH_SUPPORT_TICKET_TYPES_SUCCESS = 'FETCH_SUPPORT_TICKET_TYPES_SUCCESS';
export const FETCH_SUPPORT_TICKET_TYPES_FAILED = 'FETCH_SUPPORT_TICKET_TYPES_FAILED';

export const FETCH_SUPPORT_TICKET_TYPE_BY_ID_REQUEST = 'FETCH_SUPPORT_TICKET_TYPE_BY_ID_REQUEST';
export const FETCH_SUPPORT_TICKET_TYPE_BY_ID_SUCCESS = 'FETCH_SUPPORT_TICKET_TYPE_BY_ID_SUCCESS';
export const FETCH_SUPPORT_TICKET_TYPE_BY_ID_FAILED = 'FETCH_SUPPORT_TICKET_TYPE_BY_ID_FAILED';

export const FETCH_SUPPORT_TICKET_TYPE_REQUEST = 'FETCH_SUPPORT_TICKET_TYPE_REQUEST';
export const FETCH_SUPPORT_TICKET_TYPE_SUCCESS = 'FETCH_SUPPORT_TICKET_TYPE_SUCCESS';
export const FETCH_SUPPORT_TICKET_TYPE_FAILED = 'FETCH_SUPPORT_TICKET_TYPE_FAILED';

export const UPDATE_SUPPORT_TICKET_TYPE_REQUEST = 'UPDATE_SUPPORT_TICKET_TYPE_REQUEST';
export const UPDATE_SUPPORT_TICKET_TYPE_SUCCESS = 'UPDATE_SUPPORT_TICKET_TYPE_SUCCESS';
export const UPDATE_SUPPORT_TICKET_TYPE_FAILED = 'UPDATE_SUPPORT_TICKET_TYPE_FAILED';

export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
export const UNSET_GLOBAL_LOADER = 'UNSET_GLOBAL_LOADER';


// Employees
export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILED = 'FETCH_EMPLOYEES_FAILED';

export const ADD_EMPLOYEES_REQUEST = 'ADD_EMPLOYEES_REQUEST';
export const ADD_EMPLOYEES_SUCCESS = 'ADD_EMPLOYEES_SUCCESS';
export const ADD_EMPLOYEES_FAILED = 'ADD_EMPLOYEES_FAILED';

export const CHANGE_EMPLOYEE_PAGE_BEGIN = 'CHANGE_EMPLOYEE_PAGE_BEGIN';
export const CHANGE_EMPLOYEE_PAGE_SUCCESS = 'CHANGE_EMPLOYEE_PAGE_SUCCESS';

export const FETCH_EMPLOYEE_BY_ID_REQUEST = 'FETCH_EMPLOYEE_BY_ID_REQUEST';
export const FETCH_EMPLOYEE_BY_ID_SUCCESS = 'FETCH_EMPLOYEE_BY_ID_SUCCESS';
export const FETCH_EMPLOYEE_BY_ID_FAILED = 'FETCH_EMPLOYEE_BY_ID_FAILED';
export const CLEAR_EMPLOYEE_BY_ID_DATA = 'CLEAR_EMPLOYEE_BY_ID_DATA';

export const GET_EMPLOYEES_BY_USERID_REQUEST = 'GET_EMPLOYEES_BY_USERID_REQUEST';
export const GET_EMPLOYEES_BY_USERID_SUCCESS = 'GET_EMPLOYEES_BY_USERID_SUCCESS';
export const GET_EMPLOYEES_BY_USERID_FAILED = 'GET_EMPLOYEES_BY_USERID_FAILED';

export const UPLOAD_PROFILE_PIC_REQUEST = 'UPLOAD_PROFILE_PIC_REQUEST';
export const UPLOAD_PROFILE_PIC_SUCCESS = 'UPLOAD_PROFILE_PIC_SUCCESS';
export const UPLOAD_PROFILE_PIC_FAILED = 'UPLOAD_PROFILE_PIC_FAILED';

export const DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';

export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILED = 'UPDATE_EMPLOYEE_FAILED';

export const TOGGLE_IS_UPDATE_EMP = 'TOGGLE_IS_UPDATE_EMP';
export const TOGGLE_IS_UPDATE_USER = 'TOGGLE_IS_UPDATE_USER';

// Employee bulk upload
export const EMPLOYEE_BULKUPLOAD_REQUEST = 'EMPLOYEE_BULKUPLOAD_REQUEST';
export const EMPLOYEE_BULKUPLOAD_SUCCESS = 'EMPLOYEE_BULKUPLOAD_SUCCESS';
export const EMPLOYEE_BULKUPLOAD_FAILED = 'EMPLOYEE_BULKUPLOAD_FAILED';

export const TOGGLE_B2B_REQUEST = 'TOGGLE_B2B_REQUEST';
export const TOGGLE_REACTIVATION_MODAL_REQUEST = 'TOGGLE_REACTIVATION_MODAL_REQUEST';

export const FETCH_STORES_LOCATIONS_REQUEST = 'FETCH_STORES_LOCATIONS_REQUEST';
export const FETCH_STORES_LOCATIONS_SUCCESS = 'FETCH_STORES_LOCATIONS_SUCCESS';
export const FETCH_STORES_LOCATIONS_FAILED = 'FETCH_STORES_LOCATIONS_FAILED';

export const FETCH_COMPANY_STORES_REQUEST = 'FETCH_COMPANY_STORES_REQUEST';
export const FETCH_COMPANY_STORES_SUCCESS = 'FETCH_COMPANY_STORES_SUCCESS';
export const FETCH_COMPANY_STORES_FAILED = 'FETCH_COMPANY_STORES_FAILED';

export const FETCH_SUPERVISORS_REQUEST = 'FETCH_SUPERVISORS_REQUEST';
export const FETCH_SUPERVISORS_SUCCESS = 'FETCH_SUPERVISORS_SUCCESS';
export const FETCH_SUPERVISORS_FAILED = 'FETCH_SUPERVISORS_FAILED';

export const MOBILE_PIN_CHANGE_REQUEST = 'MOBILE_PIN_CHANGE_REQUEST';
export const MOBILE_PIN_CHANGE_SUCCESS = 'MOBILE_PIN_CHANGE_SUCCESS';
export const MOBILE_PIN_CHANGE_FAILURE = 'MOBILE_PIN_CHANGE_FAILURE';

export const UPDATE_FACE_REQUEST = 'UPDATE_FACE_REQUEST';
export const UPDATE_FACE_SUCCESS = 'UPDATE_FACE_SUCCESS';
export const UPDATE_FACE_FAILURE = 'UPDATE_FACE_FAILURE';


// Required Fields
export const FETCH_REQUIRED_FIELDS_REQUEST = 'FETCH_REQUIRED_FIELDS_REQUEST';
export const FETCH_REQUIRED_FIELDS_SUCCESS = 'FETCH_REQUIRED_FIELDS_SUCCESS';
export const FETCH_REQUIRED_FIELDS_FAILED = 'FETCH_REQUIRED_FIELDS_FAILED';

export const ADD_REQUIRED_FIELDS_REQUEST = 'ADD_REQUIRED_FIELDS_REQUEST';
export const ADD_REQUIRED_FIELDS_SUCCESS = 'ADD_REQUIRED_FIELDS_SUCCESS';
export const ADD_REQUIRED_FIELDS_FAILED = 'ADD_REQUIRED_FIELDS_FAILED';

export const UPDATE_REQUIRED_FIELDS_REQUEST = 'UPDATE_REQUIRED_FIELDS_REQUEST';
export const UPDATE_REQUIRED_FIELDS_SUCCESS = 'UPDATE_REQUIRED_FIELDS_SUCCESS';
export const UPDATE_REQUIRED_FIELDS_FAILED = 'UPDATE_REQUIRED_FIELDS_FAILED';

// one-view-company widgets
export const FETCH_COMPANY_WIDGETS_REQUEST = 'FETCH_COMPANY_WIDGETS_REQUEST';
export const FETCH_COMPANY_WIDGETS_SUCCESS = 'FETCH_COMPANY_WIDGETS_SUCCESS';
export const FETCH_COMPANY_WIDGETS_FAILED = 'FETCH_COMPANY_WIDGETS_FAILED';

export const STORE_COMPANY_WIDGETS = 'STORE_COMPANY_WIDGETS';

export const FETCH_COMPANY_WIDGET_REQUEST = 'FETCH_COMPANY_WIDGET_REQUEST';
export const FETCH_COMPANY_WIDGET_SUCCESS = 'FETCH_COMPANY_WIDGET_SUCCESS';
export const FETCH_COMPANY_WIDGET_FAILED = 'FETCH_COMPANY_WIDGET_FAILED';

export const ADD_COMPANY_WIDGET_REQUEST = 'ADD_COMPANY_WIDGET_REQUEST';
export const ADD_COMPANY_WIDGET_SUCCESS = 'ADD_COMPANY_WIDGET_SUCCESS';
export const ADD_COMPANY_WIDGET_FAILED = 'ADD_COMPANY_WIDGET_FAILED';

export const UPDATE_COMPANY_WIDGET_REQUEST = 'UPDATE_COMPANY_WIDGET_REQUEST';
export const UPDATE_COMPANY_WIDGET_SUCCESS = 'UPDATE_COMPANY_WIDGET_SUCCESS';
export const UPDATE_COMPANY_WIDGET_FAILED = 'UPDATE_COMPANY_WIDGET_FAILED';

export const UPDATE_ONE_VIEW_BG_IMAGE_REQUEST = 'UPDATE_ONE_VIEW_BG_IMAGE_REQUEST';
export const UPDATE_ONE_VIEW_BG_IMAGE_SUCCESS = 'UPDATE_ONE_VIEW_BG_IMAGE_SUCCESS';
export const UPDATE_ONE_VIEW_BG_IMAGE_FAILED = 'UPDATE_ONE_VIEW_BG_IMAGE_FAILED';

export const DELETE_COMPANY_WIDGET_REQUEST = 'DELETE_COMPANY_WIDGET_REQUEST';
export const DELETE_COMPANY_WIDGET_SUCCESS = 'DELETE_COMPANY_WIDGET_SUCCESS';
export const DELETE_COMPANY_WIDGET_FAILED = 'DELETE_COMPANY_WIDGET_FAILED';

export const SET_IS_UPDATE = 'SET_IS_UPDATE';
export const SET_CURRENT_EDIT_MODE = 'SET_CURRENT_EDIT_MODE';
export const ENABLE_ONE_VIEW_EDIT = 'ENABLE_ONE_VIEW_EDIT';
export const DISABLE_ONE_VIEW_EDIT = 'DISABLE_ONE_VIEW_EDIT';
export const STORE_WIDGET_POSITION = 'STORE_WIDGET_POSITION';
export const STORE_BACKGROUND_IMAGE = 'STORE_BACKGROUND_IMAGE';
export const NEW_WIDGET_ALERT = 'NEW_WIDGET_ALERT';
export const BG_IMAGE_UPDATE_STATUS = 'BG_IMAGE_UPDATE_STATUS';
export const ENABLE_ONE_VIEW_EMPLOYEE_EDIT = 'ENABLE_ONE_VIEW_EMPLOYEE_EDIT';
export const DISABLE_ONE_VIEW_EMPLOYEE_EDIT = 'DISABLE_ONE_VIEW_EMPLOYEE_EDIT';

export const FETCH_STORE_REPORTS_REQUEST = 'FETCH_STORE_REPORTS_REQUEST';
export const FETCH_STORE_REPORTS_SUCCESS = 'FETCH_STORE_REPORTS_SUCCESS';
export const FETCH_STORE_REPORTS_FAILED = 'FETCH_STORE_REPORTS_FAILED';

export const HR_ACTIVE_SESSION = 'HR_ACTIVE_SESSION';

export const SET_CURRENT_SETTING_TAB = 'SET_CURRENT_SETTING_TAB';

export const UPDATE_MULTIPLE_EMP_TIMECARD_REQ_REQUEST = 'UPDATE_MULTIPLE_EMP_TIMECARD_REQ_REQUEST';
export const UPDATE_MULTIPLE_EMP_TIMECARD_REQ_SUCCESS = 'UPDATE_MULTIPLE_EMP_TIMECARD_REQ_SUCCESS';
export const UPDATE_MULTIPLE_EMP_TIMECARD_REQ_FAILED = 'UPDATE_MULTIPLE_EMP_TIMECARD_REQ_FAILED';

export const DELETE_MULTIPLE_EMP_TIMECARD_REQ_REQUEST = 'DELETE_MULTIPLE_EMP_TIMECARD_REQ_REQUEST';
export const DELETE_MULTIPLE_EMP_TIMECARD_REQ_SUCCESS = 'DELETE_MULTIPLE_EMP_TIMECARD_REQ_SUCCESS';
export const DELETE_MULTIPLE_EMP_TIMECARD_REQ_FAILED = 'DELETE_MULTIPLE_EMP_TIMECARD_REQ_FAILED';

export const GET_COORDINATES_REQUEST = 'GET_COORDINATES_REQUEST';
export const GET_COORDINATES_SUCCESS = 'GET_COORDINATES_SUCCESS';
export const GET_COORDINATES_FAILED = 'GET_COORDINATES_FAILED';

export const FETCH_HRUSERAUTOCLOCKEDOUT_REQUEST = 'FETCH_HRUSERAUTOCLOCKEDOUT_REQUEST';
export const FETCH_HRUSERAUTOCLOCKEDOUT_SUCCESS = 'FETCH_HRUSERAUTOCLOCKEDOUT_SUCCESS';
export const FETCH_HRUSERAUTOCLOCKEDOUT_FAILED = 'FETCH_HRUSERAUTOCLOCKEDOUT_FAILED';

// public contact us form
export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILED = 'CONTACT_US_FAILED';

export const FETCH_INTERESTS_REQUEST = 'FETCH_INTERESTS_REQUEST';
export const FETCH_INTERESTS_SUCCESS = 'FETCH_INTERESTS_SUCCESS';
export const FETCH_INTERESTS_FAILED = 'FETCH_INTERESTS_FAILED';

// Inventory Leads
export const FETCH_INVENTORY_LEADS_REQUEST = 'FETCH_INVENTORY_LEADS_REQUEST';
export const FETCH_INVENTORY_LEADS_SUCCESS = 'FETCH_INVENTORY_LEADS_SUCCESS';
export const FETCH_INVENTORY_LEADS_FAILED = 'FETCH_INVENTORY_LEADS_FAILED';

export const FETCH_INVENTORY_LEAD_REQUEST = 'FETCH_INVENTORY_LEAD_REQUEST';
export const FETCH_INVENTORY_LEAD_SUCCESS = 'FETCH_INVENTORY_LEAD_SUCCESS';
export const FETCH_INVENTORY_LEAD_FAILED = 'FETCH_INVENTORY_LEAD_FAILED';

export const ADD_INVENTORY_LEAD_REQUEST = 'ADD_INVENTORY_LEAD_REQUEST';
export const ADD_INVENTORY_LEAD_SUCCESS = 'ADD_INVENTORY_LEAD_SUCCESS';
export const ADD_INVENTORY_LEAD_FAILED = 'ADD_INVENTORY_LEAD_FAILED';

export const UPDATE_INVENTORY_LEAD_REQUEST = 'UPDATE_INVENTORY_LEAD_REQUEST';
export const UPDATE_INVENTORY_LEAD_SUCCESS = 'UPDATE_INVENTORY_LEAD_SUCCESS';
export const UPDATE_INVENTORY_LEAD_FAILED = 'UPDATE_INVENTORY_LEAD_FAILED';
export const UPDATE_INVENTORY_LEAD_STATE = 'UPDATE_INVENTORY_LEAD_STATE';

export const DELETE_INVENTORY_LEAD_REQUEST = 'DELETE_INVENTORY_LEAD_REQUEST';
export const DELETE_INVENTORY_LEAD_SUCCESS = 'DELETE_INVENTORY_LEAD_SUCCESS';
export const DELETE_INVENTORY_LEAD_FAILED = 'DELETE_INVENTORY_LEAD_FAILED';

export const FETCH_LEAD_SOURCES_REQUEST = 'FETCH_LEAD_SOURCES_REQUEST';
export const FETCH_LEAD_SOURCES_SUCCESS = 'FETCH_LEAD_SOURCES_SUCCESS';
export const FETCH_LEAD_SOURCES_FAILED = 'FETCH_LEAD_SOURCES_FAILED';
