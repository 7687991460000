import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GLOBAL_LOADER:
      return {
        ...state,
        loading: true,
      };
    case types.UNSET_GLOBAL_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
