import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  addPosCategory, deletePosCategory, getPosCategoryById, updatePosCategory, getPosCategoriesCount,
  getPosCategories,
} from 'api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.FETCH_POS_CATEGORIES_REQUEST, fetchCategoriesSaga);
  yield takeLatest(types.FETCH_POS_CATEGORY_REQUEST, fetchCategorySaga);
  yield takeLatest(types.ADD_POS_CATEGORY_REQUEST, addCategorySaga, context);
  yield takeLatest(types.UPDATE_POS_CATEGORY_REQUEST, updateCategorySaga, context);
  yield takeLatest(types.DELETE_POS_CATEGORY_REQUEST, deleteCategorySaga);
  yield takeLatest(types.FETCH_POS_CATEGORY_COUNTS, fetchCategoriesCountSaga);
}

export function* fetchCategoriesSaga({ payload }) {
  try {
    const res = yield call(getPosCategories, payload);
    yield all([
      put({ type: types.FETCH_POS_CATEGORIES_SUCCESS, res }),
    ]);
  } catch (error) {
    console.log(error);
    yield put({ type: types.FETCH_POS_CATEGORIES_FAILED, error });
  }
}

export function* fetchCategorySaga({ payload }) {
  try {
    const res = yield call(getPosCategoryById, payload);
    yield all([
      put({ type: types.FETCH_POS_CATEGORY_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_POS_CATEGORY_FAILED, error });
  }
}

export function* addCategorySaga({ history }, { payload }) {
  try {
    const res = yield call(addPosCategory, payload);
    yield all([
      put({ type: types.ADD_POS_CATEGORY_SUCCESS, res }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/categories');
    }
  } catch (error) {
    yield put({ type: types.ADD_POS_CATEGORY_FAILED, error });
  }
}

export function* updateCategorySaga({ history }, { payload }) {
  try {
    const res = yield call(updatePosCategory, payload);
    yield all([
      put({ type: types.UPDATE_POS_CATEGORY_SUCCESS, res }),
    ]);
    if (res && res.success && res.data && res.data.id && history) {
      history.push('/categories');
    }
  } catch (error) {
    yield put({ type: types.UPDATE_POS_CATEGORY_FAILED, error });
  }
}

export function* deleteCategorySaga({ payload }) {
  try {
    yield call(deletePosCategory, payload);
    yield all([
      put({ type: types.DELETE_POS_CATEGORY_SUCCESS, payload }),
    ]);
  } catch (error) {
    yield put({ type: types.DELETE_POS_CATEGORY_FAILED, error });
  }
}

export function* fetchCategoriesCountSaga({ payload }) {
  try {
    const res = yield call(getPosCategoriesCount, payload);
    yield all([
      put({ type: types.FETCH_POS_CATEGORY_COUNTS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_POS_CATEGORY_COUNTS_FAILED, error });
  }
}
