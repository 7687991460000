import { put, call, takeLatest, all } from 'redux-saga/effects';
import {savePublicContactUsData, fetchInterestsData, fetchLeadSourcesData} from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.CONTACT_US_REQUEST, addContactUsData, context);
  yield takeLatest(types.FETCH_INTERESTS_REQUEST, fetchInterestsSaga);
  yield takeLatest(types.FETCH_LEAD_SOURCES_REQUEST, fetchLeadSourcesSaga);
}

export function* addContactUsData({ history }, { payload }) {
  try {
    const res = yield call(savePublicContactUsData, payload);
    yield all([
      put({ type: types.CONTACT_US_SUCCESS, res }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: res.success,
          message: res.success ? 'Details saved' : res.message || 'Details not added',
        },
      }),
    ]);
  } catch (error) {
    yield all([
      put({ type: types.CONTACT_US_FAILED, error }),
      put({
        type: types.SET_NOTIFICATION,
        payload: {
          success: false,
          message: error && error.message ? error.message : 'Server error',
        },
      }),
    ]);
  }
}

export function* fetchInterestsSaga({ payload }) {
  try {
    const res = yield call(fetchInterestsData, payload);
    yield all([
      put({ type: types.FETCH_INTERESTS_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_INTERESTS_FAILED, error });
  }
}

export function* fetchLeadSourcesSaga({ payload }) {
  try {
    const res = yield call(fetchLeadSourcesData, payload);
    yield all([
      put({ type: types.FETCH_LEAD_SOURCES_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.FETCH_LEAD_SOURCES_FAILED, error });
  }
}
