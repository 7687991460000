import { put, call, takeLatest, all } from 'redux-saga/effects';
import { overridePhoneVerification } from '../../api/Api';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-use-before-define */
export default function* watchOptionsListener(context = {}) {
  yield takeLatest(types.OVERRRIDE_OTP_REQUEST, overridePhoneVerificationSaga);
}

export function* overridePhoneVerificationSaga({ payload }) {
  try {
    const res = yield call(overridePhoneVerification, payload);
    yield all([
      put({ type: types.OVERRRIDE_OTP_SUCCESS, res }),
    ]);
  } catch (error) {
    yield put({ type: types.OVERRRIDE_OTP_FAILED, error });
  }
}
