import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  company: {},
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  console.log(action, 'action');
  switch (action.type) {
    case types.FETCH_COMPANY_ID_REQUEST:
      return {
        ...state,
        company: {},
        loading: true,
      };
    case types.FETCH_COMPANY_ID_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          company: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        company: action.res.data || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_COMPANY_ID_FAILED:
      return {
        ...state,
        company: {},
        error: 'Bad Request',
        loading: false,
      };
    default:
      return state;
  }
};
