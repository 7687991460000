import { filter } from 'lodash';
import * as types from '../../constants/actionTypes';

/* eslint-disable no-case-declarations */

const initialState = {
  order: {},
  orders: [],
  total: 0,
  loading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ORDERS_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          orders: [],
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        orders: (action.res.data && action.res.data.results) || [],
        total: (action.res.data && action.res.data.total) || 0,
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_ORDERS_FAILED:
      return {
        ...state,
        orders: [],
        error: 'Bad Request',
        loading: false,
      };
    case types.FETCH_ORDER_REQUEST:
      return {
        ...state,
        order: {},
        loading: true,
      };
    case types.FETCH_ORDERS_REQUEST:
      return {
        ...state,
        orders: [],
        loading: true,
      };
    case types.ADD_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_ORDER_SUCCESS:
      if (!action.res.success) {
        return {
          ...state,
          order: {},
          error: '',
          loading: false,
        };
      }
      return {
        ...state,
        order: action.res.data || {},
        status: action.res.success,
        loading: false,
      };
    case types.FETCH_ORDER_FAILED:
      return {
        ...state,
        order: {},
        error: 'Bad Request',
        loading: false,
      };
    case types.ADD_ORDER_SUCCESS:
      return {
        ...state,
        // orders: [...state.orders, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.ADD_ORDER_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_ORDER_SUCCESS:
      let orders = filter(state.orders, item => item.id !== action.res.data.id);

      return {
        ...state,
        order: [...orders, action.res.data],
        status: action.res.success,
        loading: false,
      };
    case types.UPDATE_ORDER_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };
    case types.UPDATE_ORDER_STATE:
      orders = filter(state.orders, item => item.id !== action.payload.id);
      return {
        ...state,
        orders: [...orders, action.payload],
        order: action.payload,
        status: 'success',
        loading: false,
      };
    case types.DELETE_ORDER_SUCCESS:
      orders = filter(state.orders, item => item.id !== action.payload.id);

      return {
        ...state,
        orders: [...orders],
        status: true,
        loading: false,
      };
    case types.DELETE_ORDER_FAILED:
      return {
        ...state,
        error: 'Bad Request',
        loading: false,
      };

    case types.FETCH_OPEN_ORDER_COUNTS_SUCCESS:
      return {
        ...state,
        status: action.res.success,
        loading: false,
        theCount: action.res.data.count,
      };

    case types.FETCH_TODAY_OPEN_ORDER_COUNTS_SUCCESS:
      return {
        ...state,
        status: action.res.success,
        loading: false,
        theCountToday: action.res.data.count,
      };

    default:
      return state;
  }
};
