import React from 'react';

export const PublicAppConfig = {
  settings: {
    layout: {},
  },
  routeConfig: {
    exact: true,
  },
  auth: false,
  routes: [
    {
      path: '/contact-us',
      component: React.lazy(() => import('./ContactUs')),
    },
    {
      path: '/public/ticket-center',
      component: React.lazy(() => import('./PublicCCTicket/CreatePublicTicket')),
    },
  ],
};

export default PublicAppConfig;
