import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MultiMenuItems from '../Utils/MultiMenuItems';
import lotusResources from '../Utils/LotusResources';
import TermsOfUse from '../Utils/TermsOfUse';
import useStyless from '../Utils/MenuItemStyles';
import csoureData from '../Utils/csourceReprtingData';
import CSReporting from '../Utils/CSReporting';
import OneViewMenuItem from '../Utils/OneViewMenuItem';

const useStyles = makeStyles(theme => ({
  drawer: {
    // width: props => props.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // minHeight: '100%',
    paper: {
      background: props => `hsl(${props.hue}, ${props.saturation}%, ${props.luminance}%)`,

    },
    // borderRightColor: 'transparent',
  },
  drawerOpen: {
    // width: props => props.drawerWidth || '240',
    width: props => props.drawerWidth || '280',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: 'transparent',
    top: 0,
    [theme.breakpoints.up('md')]: {
      top: props => props.h || '60',
    },
    ...theme.overflowScrollBar,
  },
  drawerClose: {
    // : props => props.height,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    ...theme.overflowScrollBar,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    top: 0,
    [theme.breakpoints.up('md')]: {
      top: props => props.h || '60',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  iconStyle: {
    color: props => `hsl(${props.hue}, ${props.saturation}%, ${props.luminance}%)`,
  },
  drawerItems: {
    height: '100vh', // if topbar is 60px in height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: props => `hsl(${props.hue}, ${props.saturation}%, ${props.luminance}%)`,
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 64px)',
    },
  },
}));

const SideDrawer = (props) => {
  const userData = JSON.parse(localStorage.getItem('user'));
  const userAccessLevel = userData && userData.access_level;
  const companyId = userData && userData.company_id;
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [termsModal, setTermsModal] = useState(false);
  const classes = useStyles(props);
  const { open } = props;
  const [logo, setLogo] = useState(null);
  const logoWidth = '100px';
  const logoHeight = '56px';

  const theme = useTheme();
  const classess = useStyless();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(
    () => {
      const logoFromStorage = localStorage.getItem('logo');
      if (logoFromStorage) {
        setLogo(logoFromStorage);
      }
    },
    [logo],
  );

  const DrawerComp = (
    <React.Fragment>
      {/* <div className={classes.toolbar}>
        <IconButton onClick={props.handleDrawerClose}>
          <CloseOutlinedIcon className={classes.iconStyle} />
        </IconButton>
        <Link to="/">
          <img src={logo} alt="Main Logo" className="logo" width={logoWidth} height={logoHeight} />
        </Link>
      </div> */}
      {/* <Divider /> */}
      <div className={classes.drawerItems}>
        <div style={{ background: `hsl(${props.hue}, ${props.saturation}%, ${props.luminance}%)`, marginTop: 8 }}>
          {/* Nested tabs */}

          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader" style={{ color: '#FFFFFF', opacity: '0.5' }}>
                {open ? 'MENU' : <div style={{ marginTop: '5px' }}>{open ? '' : ''}</div>}
              </ListSubheader>
            }
          />
          {/* </List> */}

          {
            props.filteredMenuItem && props.filteredMenuItem[0] && props.filteredMenuItem[0].name === 'OneView' ? (
              <OneViewMenuItem drawerOpen={open} />
            ) : (
              <MultiMenuItems
                items={props.filteredMenuItem}
                drawerOpen={open}
                handleDrawerOpen={props.handleDrawerOpen}
                setCurrentSidebar={props.setCurrentSidebar}
                currentSidebar={props.currentSidebar}
              />
            )
          }
          {/* Direct tabs ticket and notification */}
          {/* {props && props.directParentRoutes.map(data => ( */}
          {/* <SingleMenuItem
            // key={data.route}
            items={props.directParentRoutes}
            drawerOpen={open}
            setCurrentSidebar={props.setCurrentSidebar}
            currentSidebar={props.currentSidebar}
          /> */}
          {/* ))
          } */}
          {
            window.location.origin === 'https://csourcedata.com' &&
            (
              <CSReporting
                // key={data.route}
                items={csoureData}
                drawerOpen={open}
                setCurrentSidebar={props.setCurrentSidebar}
                currentSidebar={props.currentSidebar}
              />
            )
          }
        </div>
        <div>
          <div style={{ background: `hsl(${props.hue}, ${100}%, ${35}%)` }} >

            {(companyId !== 52 || permissions.can_access_lotus_resources) && (<MultiMenuItems
              items={lotusResources(companyId)}
              drawerOpen={open}
              handleDrawerOpen={props.handleDrawerOpen}
              setCurrentSidebar={props.setCurrentSidebar}
              currentSidebar={props.currentSidebar}
              addBorder
            />)}

            {userAccessLevel === 4 ? (
              <MultiMenuItems
                items={props.filterdSettings}
                drawerOpen={open}
                handleDrawerOpen={props.handleDrawerOpen}
                setCurrentSidebar={props.setCurrentSidebar}
                currentSidebar={props.currentSidebar}
                addBorder
              />
            ) : null}

            <TermsOfUse
              termsModal={termsModal}
              drawerOpen={open}
              setTermsModal={setTermsModal}
              handleDrawerOpen={props.handleDrawerOpen}
              setCurrentSidebar={props.setCurrentSidebar}
              currentSidebar={props.currentSidebar}
            />
          </div>
          <Grid style={{ background: `hsl(${props.hue}, ${52}%, ${15}%)` }} container justify="center" direction="column" alignItems="center">
            {open
              ?
              <>
                <Grid item style={{ margin: '5px 0' }}>
                  <Typography variant="body2">&copy; {(new Date().getFullYear())}, Centraverse&trade;</Typography>
                </Grid >
                <Grid item style={{ marginBottom: '5px' }}>
                  <Typography variant="body2">Version 2.0.0</Typography>
                </Grid >
              </> :
              <Grid item style={{ margin: '10px 0' }}>
                <Typography variant="body2">&copy; {(new Date().getFullYear())}</Typography>
              </Grid >}
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
  return (
    !sm ? (
      <Drawer
        id="sidebar"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {DrawerComp}
      </Drawer>
    ) :
      (
        <Drawer
          id="mobile-sidebar"
          anchor="left"
          open={props.open}
          onClose={props.handleDrawerClose}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {DrawerComp}
        </Drawer>
      )

  );
};

export default SideDrawer;

SideDrawer.propTypes = {
  // drawerWidth: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  setCurrentSidebar: PropTypes.func.isRequired,
  currentSidebar: PropTypes.string.isRequired,
  filteredMenuItem: PropTypes.array.isRequired, /* eslint react/forbid-prop-types: 0 */
  // directParentRoutes: PropTypes.array.isRequired, /* eslint react/forbid-prop-types: 0 */
  filterdSettings: PropTypes.array.isRequired, /* eslint react/forbid-prop-types: 0 */
};
